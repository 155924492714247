import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';

import Details from '../../../component/detail';

import { getQuestionDetails } from '../../../service/quiz/question';

const QuestionDetails = props => {
  const dispatch = useDispatch();

  const {
    item: { question, question_localizations, answers = [] },
    ...other
  } = useSelector(state => state.quiz.question);

  useEffect(() => {
    getQuestionDetails(props.match.params.id)(dispatch);
  }, [dispatch, props.match.params.id]);

  return (
    <>
      <Details {...other}>
        <h2>EN: {question_localizations?.en || question || '-'}</h2>
        <h2>IL: {question_localizations?.il || '-'}</h2>
        <ListGroup className="col-12">
          {answers.map(({ answer, answer_localizations, score }) => (
            <ListGroup.Item>
              <div>
                Answer (EN): {answer_localizations?.en || answer || '-'}
              </div>
              <div>Answer (IL): {answer_localizations?.il || '-'}</div>
              <div>Score: {score}</div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Details>
    </>
  );
};

export default withRouter(QuestionDetails);
