import { additionalFeeTemplateTypes } from './additional-fee-template.types';

export const getAdditionalFeeTemplateListSuccess = list => ({
  type: additionalFeeTemplateTypes.GET_ADDITIONAL_FEE_TEMPLATE_LIST_SUCCESS,
  payload: list,
});

export const getAdditionalFeeTemplateListLoading = () => ({
  type: additionalFeeTemplateTypes.GET_ADDITIONAL_FEE_TEMPLATE_LIST_LOADING,
});

export const getAdditionalFeeTemplateByIdSuccess = data => ({
  type: additionalFeeTemplateTypes.GET_ADDITIONAL_FEE_TEMPLATE_BY_ID_SUCCESS,
  payload: data,
});
export const getAdditionalFeeTemplateByIdLoading = () => ({
  type: additionalFeeTemplateTypes.GET_ADDITIONAL_FEE_TEMPLATE_BY_ID_LOADING,
});

export const deleteAdditionalFeeTemplateSuccess = id => ({
  type: additionalFeeTemplateTypes.DELETE_ADDITIONAL_FEE_TEMPLATE_SUCCESS,
  payload: id,
});

export const additionalFeeTemplateClearItem = () => ({
  type: additionalFeeTemplateTypes.CLEAR_ITEM,
});
