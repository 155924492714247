import fetchWithAuth from '../index';
import setLoading from '../../redux/default';

import { URL_SUPER_ADMIN } from '../../constant';

import MESSAGES_FOR_USER from '../../constants/messages/en';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';
import {
  getPartnerProgramListSuccess,
  deletePartnerProgramFailed,
  deletePartnerProgramSuccess,
  getPartnerProgramByIdFailed,
  getPartnerProgramByIdSuccess,
  updatePartnerProgramFailed,
  updatePartnerProgramSuccess,
  getPartnerProgramListFailed,
} from '../../redux/partnerProgram/partnerProgram.action';
import { destinationUrls } from '../../component/menu/constants';

export const createPartnerProgram = (data, history) => async dispatch => {
  const result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/partner-program/`,
    method: 'POST',
    body: JSON.stringify(data),
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.PARTNER_TYPE.CREATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.PARTNER_TYPE.CREATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  history.push(destinationUrls.PARTNER_PROGRAM);
  return;
};

export const getPartnerProgram = () => async dispatch => {
  dispatch(setLoading('partner_type/GET_PARTNER_PROGRAM_LIST'));

  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/partner-program/`,
    method: 'GET',
  });

  if (result.ok) {
    dispatch(getPartnerProgramListSuccess(await result.json()));
    return;
  } else {
    const { statusText } = result;
    dispatch(getPartnerProgramListFailed(statusText));
    dispatch(
      showToastMessage({
        title: 'Request error',
        content: statusText,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
  }
};

export const deletePartnerProgramById = id => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/partner-program/${id}`,
    method: 'DELETE',
  });

  setLoading('DELETE_PARTNER_TYPE');

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.PARTNER_TYPE.DELETE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.PARTNER_TYPE.DELETE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(deletePartnerProgramFailed(await result.json()));
    return;
  }

  dispatch(deletePartnerProgramSuccess(id));
  return;
};

export const getPartnerProgramById = id => async dispatch => {
  let partnerProgramResult = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/partner-program/${id}`,
    method: 'GET',
  });

  let partnerProgramServicesResult = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/partner-program/service-partner/${id}`,
    method: 'GET',
  });

  const partnerProgramById = await partnerProgramResult.json();
  const partnerProgram = {
    ...partnerProgramById,
    services: await partnerProgramServicesResult.json(),
  };

  setLoading('partner_program/GET_PARTNER_PROGRAM_BY_ID');

  if (!partnerProgramResult.ok || !partnerProgramServicesResult.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.PARTNER_PROGRAM.GET_BY_ID_FAILED.TITLE,
        content: MESSAGES_FOR_USER.PARTNER_PROGRAM.GET_BY_ID_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(getPartnerProgramByIdFailed(partnerProgram));
    return;
  }

  dispatch(getPartnerProgramByIdSuccess(partnerProgram));
};

export const updatePartnerProgram =
  (id, updatedData, history) => async dispatch => {
    let result = await fetchWithAuth({
      path: `${URL_SUPER_ADMIN}/partner-program/${id}`,
      method: 'PUT',
      body: JSON.stringify(updatedData),
    });

    setLoading('UPDATE_PARTNER_PROGRAM');

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.PARTNER_PROGRAM.UPDATE_FAILED.TITLE,
          content: MESSAGES_FOR_USER.PARTNER_PROGRAM.UPDATE_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );
      dispatch(updatePartnerProgramFailed(await result.json()));
      return;
    }

    dispatch(updatePartnerProgramSuccess(id, updatedData));
    history.push(destinationUrls.PARTNER_PROGRAM);
    return;
  };
