import React, { useEffect } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import CustomSpinner from '../../component/spinner';
import ConfigurableTable from '../../component/table';

import {
  TABLE_ITEM_TYPE,
  REVIEWS_TABLE_HEAD,
} from '../../component/table/config';
import { reviewsSelector } from '../../redux/selectors/selectors';
import { getReviews } from '../../service/reviews';

export const Reviews = () => {
  const dispatch = useDispatch();
  const { loading, list, error } = useSelector(reviewsSelector);

  useEffect(() => {
    dispatch(getReviews());
  }, []);

  return (
    <Col sm={12} className="p-0 mt-3">
      {error && <Alert variant={'danger'}>{error}</Alert>}
      {loading || !list ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <ConfigurableTable
          tableHeaderColumns={REVIEWS_TABLE_HEAD}
          itemVariant={TABLE_ITEM_TYPE.REVIEWS_ITEM}
          rows={list}
        />
      )}
    </Col>
  );
};
