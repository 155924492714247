import fetchWithAuth from '../index';
import {
  addNewAnswerFail,
  addNewAnswerSuccess,
  deleteAnswerFail,
  deleteAnswerSuccess,
  editAnswerFail,
  editAnswerSuccess,
  editQuestionFail,
  editQuestionSuccess,
} from '../../redux/quiz/question/question.action';
import { URL_SUPER_ADMIN } from '../../constant';
import { editQuestionById } from './question';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import MESSAGES_FOR_USER from '../../constants/messages/en';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';

export const deleteAnswerById = (idQuestion, idAnswer) => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/quiz/question/${idQuestion}/answer/${idAnswer}`,
    method: 'DELETE',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.QUIZ.ANSWER.DELETE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.QUIZ.ANSWER.DELETE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );

    dispatch(deleteAnswerFail(await result.json()));
    return;
  }

  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.QUIZ.ANSWER.DELETE_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.QUIZ.ANSWER.DELETE_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );

  dispatch(deleteAnswerSuccess(idAnswer));
  return;
};

export const editAnswerById =
  (idQuestion, idAnswer, data) => async (dispatch, getState) => {
    const questionItemData = getState().quiz.question.item;

    const editedAnswerIndex = questionItemData.answers.findIndex(
      answer => answer._id === idAnswer,
    );
    const editedAnswers = [...questionItemData.answers];
    editedAnswers[editedAnswerIndex] = { _id: idAnswer, ...data };

    const newQuestionItemData = { ...questionItemData, answers: editedAnswers };

    let result = await fetchWithAuth({
      path: `${URL_SUPER_ADMIN}/quiz/question/${idQuestion}`,
      method: 'PUT',
      body: JSON.stringify(newQuestionItemData),
    });

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.QUIZ.ANSWER.UPDATED_FAILED.TITLE,
          content: MESSAGES_FOR_USER.QUIZ.ANSWER.UPDATED_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );

      dispatch(editQuestionFail(await result.json()));
      return;
    }

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.QUIZ.ANSWER.UPDATED_SUCCESS.TITLE,
        content: MESSAGES_FOR_USER.QUIZ.ANSWER.UPDATED_SUCCESS.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );

    dispatch(editQuestionSuccess(await result.json()));
    return;
  };

export const addNewAnswer = (idQuestion, data) => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/quiz/question/${idQuestion}/answer`,
    method: 'POST',
    body: JSON.stringify(data),
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.QUIZ.ANSWER.ADDED_FAILED.TITLE,
        content: MESSAGES_FOR_USER.QUIZ.ANSWER.ADDED_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );

    dispatch(addNewAnswerFail(await result.json()));
    return;
  }
  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.QUIZ.ANSWER.ADDED_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.QUIZ.ANSWER.ADDED_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );

  dispatch(addNewAnswerSuccess(await result.json()));
  return;
};
