import React, { useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import CustomSpinner from '../../component/spinner';
import ConfigurableTable from '../../component/table';
import {
  FOOD_PRICES_MODIFICATORS_TABLE_HEAD,
  TABLE_ITEM_TYPE,
} from '../../component/table/config';
import { foodPricesModificatorsSelector } from '../../redux/selectors/selectors';
import { FOOD_PRICES_MODIFICATORS_SERVICES } from '../../service/food-prices-modificators';

const FoodPricesModificatorsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { list, loading } = useSelector(foodPricesModificatorsSelector);

  useEffect(() => {
    dispatch(FOOD_PRICES_MODIFICATORS_SERVICES.getList());
  }, [dispatch]);

  return (
    <Row className="mt-3 d-flex align-items-center justify-content-center w-100 ml-0 mr-0">
      {loading ? (
        <CustomSpinner />
      ) : (
        <Col sm={12} className="p-0">
          <Button
            variant="primary"
            className="mb-3"
            onClick={() => history.push(window.location.pathname + '/create')}
          >
            Add new modificator
          </Button>
          <ConfigurableTable
            tableHeaderColumns={FOOD_PRICES_MODIFICATORS_TABLE_HEAD}
            itemVariant={TABLE_ITEM_TYPE.FOOD_PRICES_MODIFICATORS_ITEM}
            rows={list}
          />
        </Col>
      )}
    </Row>
  );
};

export default FoodPricesModificatorsList;
