export const validationRules = {
  title: {
    required: 'Title field is required!',
  },

  message: {
    required: 'Message field is required!',
  },

  countryId: {
    validate: value =>
      value === 'Choose country' ? 'Country field is required!' : undefined,
  },
};
