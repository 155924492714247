import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Input from '../inputs';
import { validationRules } from './validation';
import citiesOperations from '../../service/cities/cities';
import { getStatesList } from '../../service/states/states';
import countriesOperations from '../../service/countries/countries';
import { countriesSelector } from '../../redux/selectors/selectors';
import { useMemo } from 'react';

const defaultFormValues = {
  name_localizations: {
    en: '',
    il: '',
  },
  state: '',
  countryId: '',
};

const CityForm = ({ defaultValues = defaultFormValues, id }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ defaultValues });

  const countryId = watch('countryId');

  const { list: countries } = useSelector(countriesSelector);
  const countriesOptions = useMemo(() => {
    if (countries) {
      return countries.map(({ _id, name }) => ({ label: name, value: _id }));
    }
    return [];
  }, [countries]);

  useEffect(() => {
    dispatch(countriesOperations.getCountriesList());
  }, [dispatch]);

  const [statesOptions, setStatesOptions] = useState([]);

  useEffect(() => {
    if (countryId) {
      getStatesList(countryId).then(res => {
        const options = res.map(({ _id, name }) => ({
          label: name,
          value: _id,
        }));
        setStatesOptions(options);
      });
    }
  }, [countryId]);

  const onSubmit = data => {
    if (data.state === '') {
      delete data.state;
    }

    const body = {
      ...data,
      name: data.name_localizations.en,
    };

    if (id) {
      dispatch(citiesOperations.updateCity({ id, body })).then(() => {
        history.goBack();
      });

      return;
    }

    dispatch(citiesOperations.createCity({ body })).then(() => {
      history.goBack();
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group>
        <Form.Label className="d-flex justify-content-between align-items-center mt-2">
          City name (EN)
          {errors.name_localizations?.en && (
            <p style={{ fontSize: '12px' }} className="text-danger m-0">
              {errors.name_localizations?.en?.message}
            </p>
          )}
        </Form.Label>
        <Form.Control
          {...register('name_localizations.en', {
            ...validationRules.name_localizations.en,
          })}
          placeholder="City name..."
        />
        <Form.Label className="d-flex justify-content-between align-items-center mt-2">
          City name (IL)
          {errors.name_localizations?.il && (
            <p style={{ fontSize: '12px' }} className="text-danger m-0">
              {errors.name_localizations?.il?.message}
            </p>
          )}
        </Form.Label>
        <Form.Control
          {...register('name_localizations.il', {
            ...validationRules.name_localizations.il,
          })}
          placeholder="City name..."
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Country</Form.Label>

        <Input.select
          label="Country"
          name="countryId"
          register={register}
          optionsList={countriesOptions}
          validationRules={validationRules.countryId}
          error={errors.countryId}
        />
      </Form.Group>
      {statesOptions.length > 0 && (
        <Form.Group>
          <Form.Label>State</Form.Label>

          <Input.select
            label="State"
            name="state"
            disabled={!countryId || !statesOptions}
            register={register}
            optionsList={statesOptions}
            validationRules={validationRules.state}
            error={errors.state}
          />
        </Form.Group>
      )}

      <Button type="submit">{id ? 'Save changes' : 'Create city'}</Button>
    </Form>
  );
};

export default CityForm;
