import fetchWithAuth from '../index';
import {
  getDrPoopUserDataListLoading,
  getDrPoopUserDataListSuccess,
  getDrPoopUserDataListFail,
  deleteItemFromDrPoopUserDataList,
  drPoopUserDataItemRequested,
  getDrPoopUserDataItemFailed,
  getDrPoopUserDataItemSuccess,
  getDrPoopStatementListFail,
  getDrPoopStatementListSuccess,
  deleteItemFromDrPoopStatementList,
  drPoopStatementItemRequested,
  getDrPoopStatementItemFailed,
  getDrPoopStatementItemSuccess,
  getDrPoopCountGradeDistributionLoading,
  getDrPoopCountGradeDistributionFailed,
  getDrPoopCountGradeDistributionSucces,
  getDrPoopStatsLoading,
  getDrPoopStatsSuccess,
} from '../../redux/dr-poop/dr-poop.action';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';

import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';
import MESSAGES_FOR_USER from '../../constants/messages/en';
import { generateImageShapeForMaskDecoding } from '../../page/dr-poop-mask/utils';

export const getDrPoopUserDataList =
  ({
    pageSize = 50,
    pageNumber = 0,
    drPoopId,
    userId,
    dogId,
    status,
    dateBefore,
    dateAfter,
  }) =>
  async dispatch => {
    const drPoopIdParam = drPoopId ? `&drPoopId=${drPoopId}` : '';
    const userIdParam = userId ? `&userId=${userId}` : '';
    const dogIdParam = dogId ? `&dogId=${dogId}` : '';
    const statusParam = status ? `&status=${status}` : '';
    const dateAfterParam = dateAfter ? `&dateAfter=${dateAfter}` : '';
    const dateBeforeParam = dateBefore ? `&dateBefore=${dateBefore}` : '';

    const url =
      `/super-admin/dr-poop?pageNumber=${pageNumber}&pageSize=${pageSize}` +
      drPoopIdParam +
      userIdParam +
      dogIdParam +
      statusParam +
      dateAfterParam +
      dateBeforeParam;

    dispatch(getDrPoopUserDataListLoading());

    let result = await fetchWithAuth({
      path: url,
      method: 'GET',
    });

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.DR_POOP_USER_DATA.GET_LIST_FAILED.TITLE,
          content: MESSAGES_FOR_USER.DR_POOP_USER_DATA.GET_LIST_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );
      dispatch(getDrPoopUserDataListFail());
      return;
    }

    dispatch(getDrPoopUserDataListSuccess(await result.json()));
  };

export const getDrPoopStatementList = () => async dispatch => {
  let result = await fetchWithAuth({
    path: `/super-admin/dr-poop/statement`,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.DR_POOP_STATEMENT.GET_LIST_FAILED.TITLE,
        content: MESSAGES_FOR_USER.DR_POOP_STATEMENT.GET_LIST_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(getDrPoopStatementListFail());
    return;
  }

  dispatch(getDrPoopStatementListSuccess(await result.json()));
};

export const deleteDrPoopUserDataItem = itemID => async dispatch => {
  let result = await fetchWithAuth({
    path: `/super-admin/dr-poop/${itemID}`,
    method: 'DELETE',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.DR_POOP_USER_DATA.DELETING_FAILED.TITLE,
        content: MESSAGES_FOR_USER.DR_POOP_USER_DATA.DELETING_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  dispatch(deleteItemFromDrPoopUserDataList(itemID));
  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.DR_POOP_USER_DATA.DELETING_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.DR_POOP_USER_DATA.DELETING_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );
};

export const deleteDrPoopStatementItem = itemID => async dispatch => {
  let result = await fetchWithAuth({
    path: `/super-admin/dr-poop/statement/${itemID}`,
    method: 'DELETE',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.DR_POOP_STATEMENT.DELETING_FAILED.TITLE,
        content: MESSAGES_FOR_USER.DR_POOP_STATEMENT.DELETING_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  dispatch(deleteItemFromDrPoopStatementList(itemID));
  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.DR_POOP_STATEMENT.DELETING_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.DR_POOP_STATEMENT.DELETING_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );
};

export const updateDrPoopStatementItem =
  (itemID, updatedData) => async dispatch => {
    let result = await fetchWithAuth({
      path: `/super-admin/dr-poop/statement/${itemID}`,
      method: 'PUT',
      body: JSON.stringify(updatedData),
    });

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title:
            MESSAGES_FOR_USER.DR_POOP_STATEMENT.UPDATE_ITEM_STATUS_FAILED.TITLE,
          content:
            MESSAGES_FOR_USER.DR_POOP_STATEMENT.UPDATE_ITEM_STATUS_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );
      return;
    }

    dispatch(
      showToastMessage({
        title:
          MESSAGES_FOR_USER.DR_POOP_STATEMENT.UPDATE_ITEM_STATUS_SUCCESS.TITLE,
        content:
          MESSAGES_FOR_USER.DR_POOP_STATEMENT.UPDATE_ITEM_STATUS_SUCCESS.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );
  };

export const getDrPoopUserDataItemByID = itemID => async dispatch => {
  dispatch(drPoopUserDataItemRequested());

  let result = await fetchWithAuth({
    path: `/super-admin/dr-poop/${itemID}`,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.DR_POOP_USER_DATA.GET_ITEM_FAILED.TITLE,
        content: MESSAGES_FOR_USER.DR_POOP_USER_DATA.GET_ITEM_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(getDrPoopUserDataItemFailed());

    return;
  }

  dispatch(getDrPoopUserDataItemSuccess(await result.json()));
};

export const updatePoopStatusForDrPoopUserDataItem =
  (id, updatedStatusObj) => async dispatch => {
    let result = await fetchWithAuth({
      path: `/super-admin/dr-poop/${id}`,
      method: 'PUT',
      body: JSON.stringify(updatedStatusObj),
    });

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title:
            MESSAGES_FOR_USER.DR_POOP_USER_DATA.UPDATE_ITEM_STATUS_FAILED.TITLE,
          content:
            MESSAGES_FOR_USER.DR_POOP_USER_DATA.UPDATE_ITEM_STATUS_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );

      return;
    }

    dispatch(
      showToastMessage({
        title:
          MESSAGES_FOR_USER.DR_POOP_USER_DATA.UPDATE_ITEM_STATUS_SUCCESS.TITLE,
        content:
          MESSAGES_FOR_USER.DR_POOP_USER_DATA.UPDATE_ITEM_STATUS_SUCCESS.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );
  };

export const getDrPoopStatementItemByID = itemID => async dispatch => {
  dispatch(drPoopStatementItemRequested());

  let allStatementsResult = await fetchWithAuth({
    path: `/super-admin/dr-poop/statement/`,
    method: 'GET',
  });

  if (!allStatementsResult.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.DR_POOP_STATEMENT.GET_ITEM_FAILED.TITLE,
        content: MESSAGES_FOR_USER.DR_POOP_STATEMENT.GET_ITEM_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(getDrPoopStatementItemFailed());

    return;
  }

  const allStatements = await allStatementsResult.json();
  const statementById = allStatements.filter(({ _id }) => _id === itemID)[0];

  dispatch(getDrPoopStatementItemSuccess(statementById));
};

export const addDrPoopStatementItem = statementAnswer => async dispatch => {
  let result = await fetchWithAuth({
    path: `/super-admin/dr-poop/statement/`,
    method: 'POST',
    body: JSON.stringify(statementAnswer),
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title:
          MESSAGES_FOR_USER.DR_POOP_STATEMENT.UPDATE_ITEM_STATUS_FAILED.TITLE,
        content:
          MESSAGES_FOR_USER.DR_POOP_STATEMENT.UPDATE_ITEM_STATUS_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );

    return;
  }

  dispatch(
    showToastMessage({
      title:
        MESSAGES_FOR_USER.DR_POOP_STATEMENT.UPDATE_ITEM_STATUS_SUCCESS.TITLE,
      content:
        MESSAGES_FOR_USER.DR_POOP_STATEMENT.UPDATE_ITEM_STATUS_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );
};

export const getDrPoopCountGradeDistribution = () => async dispatch => {
  dispatch(getDrPoopCountGradeDistributionLoading());

  let response = await fetchWithAuth({
    path: `/super-admin/dr-poop/count-grade-distribution/`,
    method: 'GET',
  });

  if (!response.ok) {
    dispatch(getDrPoopCountGradeDistributionFailed());
    return;
  }
  const data = await response.json();

  dispatch(getDrPoopCountGradeDistributionSucces(data));
};

export const getDrPoopStatsOperation = () => async dispatch => {
  dispatch(getDrPoopStatsLoading());

  let response = await fetchWithAuth({
    path: `/super-admin/dr-poop/stats`,
    method: 'GET',
  });

  if (!response.ok) {
    dispatch(getDrPoopStatementItemFailed());
    return;
  }
  const data = await response.json();

  let result = [];
  const statsEntry = Object.entries(data);

  for (const [key, values] of statsEntry) {
    for (const keyItem in values) {
      if (Object.hasOwnProperty.call(values, keyItem)) {
        const element = values[keyItem];

        result.push({ ...element, name: `${key}-${keyItem}` });
      }
    }
  }
  dispatch(getDrPoopStatsSuccess(result));
};

export const sendPhotoForMask = async body => {
  const url = 'https://ai.devdogiz2.com/get_mask_rle';

  const formData = new FormData();
  formData.append('image', body.image);

  try {
    const maskShape = await generateImageShapeForMaskDecoding(body.image);

    let response = await fetch(url, {
      method: 'POST',
      body: formData,
    });
    const data = await response.json();
    return { mask: data.mask, shape: maskShape };
  } catch (error) {
    throw error;
  }
};
