import React from 'react';
import { Button, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const RewardsActionItem = ({ row }) => {
  return (
    <tr key={`section-${row._id}`}>
      <td>{row.name}</td>
      <td>{row.typeAction}</td>
      <td>{row.reward}</td>
      <td>{row.pushNotification ? '+' : '-'}</td>
      <td>
        <Row className="d-flex justify-content-around p-0 m-0">
          <Link to={`${window.location.pathname}/${row._id}`}>
            <Button size="sm" variant="success">
              <i className="fa fa-pencil"></i>
            </Button>
          </Link>
        </Row>
      </td>
    </tr>
  );
};

export default RewardsActionItem;
