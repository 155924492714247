import BREEDS_ACTIONS_TYPES from './breeds.types';

export const breedsActions = {
  operationLoading: () => ({
    type: BREEDS_ACTIONS_TYPES.OPERATION_LOADING,
  }),
  operationFailed: payload => ({
    type: BREEDS_ACTIONS_TYPES.OPERATION_FAILED,
    payload,
  }),
  getBreedsList: payload => ({
    type: BREEDS_ACTIONS_TYPES.GET_LIST,
    payload,
  }),
  getBreedById: payload => ({
    type: BREEDS_ACTIONS_TYPES.GET_BY_ID,
    payload,
  }),
  deleteBreed: payload => ({
    type: BREEDS_ACTIONS_TYPES.DELETE,
    payload,
  }),
  clearSelectedBreed: () => ({
    type: BREEDS_ACTIONS_TYPES.CLEAR_SELECTED,
  }),
};
