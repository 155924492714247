import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import GoBack from '../../../component/buttons/goBackButton';
import CustomSpinner from '../../../component/spinner';
import DeleteDogOptionsForm from '../../../component/delete-dog-options-form/delete-dog-options-form';

import { deleteDogOptionsSelector } from '../../../redux/selectors/selectors';
import {
  getDeleteDogOptionsById,
  updateDeleteDogOptions,
} from '../../../service/delete-dog/delete-dog-options';
import { deleteDogOptionsClearItem } from '../../../redux/delete-dog-options/delete-dog-options.action';

const DeleteDogOptionsEdit = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = props.match.params;
  const { item } = useSelector(deleteDogOptionsSelector);

  useEffect(() => {
    getDeleteDogOptionsById(id)(dispatch);

    return () => dispatch(deleteDogOptionsClearItem());
  }, [dispatch, id]);

  const onSubmit = body => {
    dispatch(updateDeleteDogOptions(id, body, history));
  };

  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      {Object.keys(item).length ? (
        <DeleteDogOptionsForm
          defaultValues={{
            reason_localizations: {
              en: item?.reason_localizations?.en || item?.reason || '',
              il: item?.reason_localizations?.il || '',
            },
          }}
          onSubmit={onSubmit}
        />
      ) : (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default DeleteDogOptionsEdit;
