import { ReactComponent as EmptyIcon } from '../../source/img/empty-result.svg';

const EmptyResult = () => {
  return (
    <div className="d-flex flex-column align-items-center mt-5">
      <div className="mb-3">
        <EmptyIcon />
      </div>
      <p>Nothing found for your request</p>
    </div>
  );
};

export default EmptyResult;
