export const scoreTypes = {
  GET_SCORES_WEIGHT_GROUP_LIST_SUCCESS:
    'scores/GET_SCORES_WEIGHT_GROUP_LIST_SUCCESS',
  GET_SCORES_WEIGHT_GROUP_LIST_FAIL: 'scores/GET_SCORES_WEIGHT_GROUP_LIST_FAIL',
  DELETE_SCORES_WEIGHT_GROUP_LIST_ITEM:
    'scores/DELETE_SCORES_WEIGHT_GROUP_LIST_ITEM',
  SCORES_WEIGHT_GROUP_ITEM_REQUESTED:
    'scores/SCORES_WEIGHT_GROUP_ITEM_REQUESTED',
  GET_SCORES_WEIGHT_GROUP_ITEM_SUCCESS:
    'scores/GET_SCORES_WEIGHT_GROUP_ITEM_SUCCESS',
  GET_SCORES_WEIGHT_GROUP_ITEM_FAIL: 'scores/GET_SCORES_WEIGHT_GROUP_ITEM_FAIL',
  CLEAR_SELECTED_ITEM: 'scores/CLEAR_SELECTED_ITEM',
  SET_SCORE_LOADER: 'scores/SET_SCORE_LOADER',

  GET_SCORES_FITNESS_LIST_SUCCESS: 'scores/GET_SCORES_FITNESS_LIST_SUCCESS',
  GET_SCORES_FITNESS_LIST_FAIL: 'scores/GET_SCORES_FITNESS_LIST_FAIL',
  DELETE_SCORES_FITNESS_LIST_ITEM: 'scores/DELETE_SCORES_FITNESS_LIST_ITEM',
  GET_SCORES_FITNESS_ITEM_SUCCESS: 'scores/GET_SCORES_FITNESS_ITEM_SUCCESS',
  GET_SCORES_FITNESS_ITEM_FAIL: 'scores/GET_SCORES_FITNESS_ITEM_FAIL',
};
