import { discountReasonsTypes } from './discount-reasons.types';

const discountReasonsOperationLoading = () => ({
  type: discountReasonsTypes.DISCOUNT_REASONS_OPERATION_LOADING,
});

const discountReasonsOperationFailed = payload => ({
  type: discountReasonsTypes.DISCOUNT_REASONS_OPERATION_FAILED,
  payload,
});

const getDiscountReasonsList = payload => ({
  type: discountReasonsTypes.GET_DISCOUNT_REASONS_LIST,
  payload,
});

const getDiscountReasonById = payload => ({
  type: discountReasonsTypes.GET_DISCOUNT_REASON_BY_ID,
  payload,
});

const createDiscountReason = payload => ({
  type: discountReasonsTypes.CREATE_DISCOUNT_REASON,
  payload,
});

const updateDiscountReason = payload => ({
  type: discountReasonsTypes.UPDATE_DISCOUNT_REASON,
  payload,
});

const deleteDiscountReason = payload => ({
  type: discountReasonsTypes.DELETE_DISCOUNT_REASON,
  payload,
});

const clearSelectedDiscountReason = () => ({
  type: discountReasonsTypes.CLEAR_SELECTED_DISCOUNT_REASON,
});

const clearDiscountReasons = () => ({
  type: discountReasonsTypes.CLEAR_DISCOUNT_REASONS,
});

export const discountReasonsActions = {
  discountReasonsOperationLoading,
  discountReasonsOperationFailed,
  getDiscountReasonsList,
  getDiscountReasonById,
  createDiscountReason,
  updateDiscountReason,
  deleteDiscountReason,
  clearSelectedDiscountReason,
  clearDiscountReasons,
};
