import { deletedDogsTypes } from './deleted-dogs.types';

export const getDeletedDogsListSuccess = list => ({
  type: deletedDogsTypes.GET_DELETED_DOGS_LIST_SUCCESS,
  payload: list,
});

export const getDeletedDogsListLoading = () => ({
  type: deletedDogsTypes.GET_DELETED_DOGS_LIST_LOADING,
});
