import fetchWithAuth from '../index';

import { URL_SUPER_ADMIN } from '../../constant';

import MESSAGES_FOR_USER from '../../constants/messages/en';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';

export const createPartnerWorker = (data, history) => async dispatch => {
  const result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/worker/partner/`,
    method: 'POST',
    body: JSON.stringify(data),
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.PARTNER_WORKER.CREATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.PARTNER_WORKER.CREATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.PARTNER_WORKER.CREATE_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.PARTNER_WORKER.CREATE_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );

  return;
};
