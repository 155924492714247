import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import store from './redux/store';

import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter } from 'react-router-dom';
import Router from './router/router';
import { mainPath } from './router/path';

import ConfirmationModal from './component/confirmationModal/confirmationModal';
import ToastMessage from './component/toast-message';

import './assets/styles/global.css';
import './assets/styles/expansion.css';

ReactDOM.render(
  <Provider store={store}>
    <Row className="m-0 p-0 min-w-100">
      <BrowserRouter>
        <Col className="col-12 d-flex align-items-center justify-content-center p-0">
          <Router listPath={[...mainPath]} />
          <ConfirmationModal />
          <ToastMessage />
        </Col>
      </BrowserRouter>
    </Row>
  </Provider>,
  document.getElementById('root'),
);
