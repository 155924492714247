import { useEffect } from 'react';
import { Col, Button, Alert, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CustomSpinner from '../../../component/spinner';

import { getQuestion } from '../../../service/quiz/question';
import ConfigurableTable from '../../../component/table';
import {
  QUIZ_QUESTION_TABLE_HEAD,
  TABLE_ITEM_TYPE,
} from '../../../component/table/config';

const QuestionList = () => {
  const dispatch = useDispatch();
  const { list, loading, error } = useSelector(state => state.quiz.question);

  useEffect(() => {
    getQuestion()(dispatch);
  }, [dispatch]);

  return (
    <Col sm={12} className="p-0 mt-3">
      {error && <Alert variant={'danger'}>{error.message}</Alert>}
      <Row>
        <Col>
          <div className="d-flex justify-content-between mb-3">
            <Link to={`create`}>
              <Button variant="primary">Add new question</Button>
            </Link>
          </div>
        </Col>
      </Row>

      {loading || !list ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <ConfigurableTable
          tableHeaderColumns={QUIZ_QUESTION_TABLE_HEAD}
          itemVariant={TABLE_ITEM_TYPE.QUIZ_QUESTION_ITEM_TYPE}
          rows={list}
        />
      )}
    </Col>
  );
};

export default QuestionList;
