import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Col, Row } from 'react-bootstrap';

import Input from '../../inputs';

import { validationRules } from './validation';
import { updateSplashPhotosItem } from '../../../service/splash-photo';

const SplashPhotosItemForm = ({ defaultValues = {}, itemId }) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const onSubmit = value => {
    const body = {
      ...value,
      title: value.title_localizations.en,
      subTitle: value.subTitle_localizations.en,
    };
    dispatch(updateSplashPhotosItem(body, itemId));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      id="splash-photos-item-form"
      className="w-100"
    >
      <Row>
        <Col sm={6}>
          <Input.text
            register={register}
            label="Title (en)"
            name="title_localizations.en"
            validationRules={validationRules.title_localizations.en}
            error={errors.title_localizations?.en}
          />
        </Col>
        <Col sm={6}>
          <Input.text
            register={register}
            label="Title (il)"
            name="title_localizations.il"
            validationRules={validationRules.title_localizations.il}
            error={errors.title_localizations?.il}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col sm={6}>
          <Input.text
            register={register}
            label="Subtitle (en)"
            name="subTitle_localizations.en"
            validationRules={validationRules.subTitle_localizations.en}
            error={errors.subTitle_localizations?.en}
          />
        </Col>
        <Col sm={6}>
          <Input.text
            register={register}
            label="Subtitle (il)"
            name="subTitle_localizations.il"
            validationRules={validationRules.subTitle_localizations.il}
            error={errors.subTitle_localizations?.il}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={6} className="mt-2">
          <Input.checkbox
            register={register}
            label="Visibility status"
            name="isVisible"
          />
        </Col>
      </Row>
    </form>
  );
};

export default SplashPhotosItemForm;
