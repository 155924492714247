import { BASE_URL_DEV, AUTH_HEADERS, RSA_PUBLIC_KEY } from '../../constant';
import setLoading from '../../redux/default';
import { setPhoneSuccess, setPhoneFail } from '../../redux/auth/auth.action';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import MESSAGES_FOR_USER from '../../constants/messages/en';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';
import { importPublicKeyAndEncrypt } from '../../helpers/crypto';

const sendPhone = data => async dispatch => {
  /* const a = new SubtleCrypto(); */
  let encryptedNumber = '';
  try {
    encryptedNumber = await importPublicKeyAndEncrypt(
      RSA_PUBLIC_KEY,
      data.phone,
    );
  } catch (e) {
    console.log(e);
  }
  data.message = encryptedNumber;
  const settings = {
    method: 'POST',
    headers: AUTH_HEADERS,
    body: JSON.stringify(data),
  };
  dispatch(setLoading('SET_PHONE'));
  const result = await fetch(BASE_URL_DEV + '/auth/set-phone', settings);
  let response;
  try {
    response = await result.json();
  } catch (error) {
    console.log(error);
  }

  if (!result.ok) {
    dispatch(setPhoneFail(response));
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.AUTH.PHONE.TITLE,
        content: MESSAGES_FOR_USER.AUTH.PHONE.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  dispatch(setPhoneSuccess(response));
  return;
};

export default sendPhone;
