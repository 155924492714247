// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./inter/Inter-Regular.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: 'Inter';\n    src: url( " + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n  }", "",{"version":3,"sources":["webpack://src/assets/fonts/index.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,gEAAyD;EAC3D","sourcesContent":["@font-face {\n    font-family: 'Inter';\n    src: url( './inter/Inter-Regular.ttf') format('truetype');\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
