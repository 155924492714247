import { SMS_CONFIGS_ACTIONS_TYPES } from './sms-configs.types';

export const SMS_CONFIGS_ACTIONS = {
  loading: () => ({
    type: SMS_CONFIGS_ACTIONS_TYPES.LOADING,
  }),

  getListSuccess: list => ({
    type: SMS_CONFIGS_ACTIONS_TYPES.GET_LIST_SUCCESS,
    payload: list,
  }),
  getByIdSuccess: item => ({
    type: SMS_CONFIGS_ACTIONS_TYPES.GET_BY_ID_SUCCESS,
    payload: item,
  }),
  updateSuccess: () => ({
    type: SMS_CONFIGS_ACTIONS_TYPES.UPDATE_SUCCESS,
  }),

  failed: error => ({
    type: SMS_CONFIGS_ACTIONS_TYPES.FAILED,
    payload: error,
  }),
};
