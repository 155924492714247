import React, { useEffect } from 'react';
import { Row, Button, Alert, Col, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import CustomSpinner from '../../../component/spinner';
import DrPoopItemForm from '../../../component/forms/dr-poop-item-form/dr-poop-item-form';
import GoBack from '../../../component/buttons/goBackButton';

import { drPoopUserDataSelector } from '../../../redux/selectors/selectors';
import { getDrPoopUserDataItemByID } from '../../../service/dr-poop';
import logoImg from '../../../source/img/mini.svg';

const DrPoopUserDataItem = ({ match: { params } }) => {
  const dispatch = useDispatch();
  const {
    selected: { loading, item },
  } = useSelector(drPoopUserDataSelector);

  useEffect(() => {
    dispatch(getDrPoopUserDataItemByID(params.id));
  }, [params.id, dispatch]);

  return (
    <Col sm={12} className="mt-3">
      <Row className="d-flex align-items-start justify-content-start">
        <GoBack />
      </Row>
      {loading ? (
        <CustomSpinner />
      ) : (
        <>
          <Row className="mt-2 d-flex align-items-center justify-content-center h-vh-70 ">
            <div
              className="blur-medium h-100"
              onClick={({ target }) => target.classList.remove('blur-medium')}
            >
              <img src={item?.url} className="img-thumbnail h-100" alt="" />
            </div>
          </Row>
          <Row className="mt-5">
            <Col className="col-6">
              <Card bg={'light'} className="w-100">
                <Card.Header>User answers</Card.Header>
                <Card.Body>
                  {item?.answers?.length ? (
                    item?.answers.map(item => (
                      <Alert
                        key={item.id}
                        variant={'primary'}
                        className="mb-0 dr-poop-alert-padding mb-1"
                      >
                        <i className="fa fa-check mr-2"></i>
                        {item?.answer_localizations?.en || item?.answer}
                      </Alert>
                    ))
                  ) : (
                    <Alert variant={'warning'}>User left no replies</Alert>
                  )}
                </Card.Body>
              </Card>
              <Card bg={'light'} className="w-100 mt-5">
                <Card.Header>Information about dog</Card.Header>
                <Card.Body>
                  <Row>
                    <Col className="col-8">
                      <img
                        src={item?.dogId?.url || logoImg}
                        className="img-thumbnail w-100"
                        alt=""
                      />
                    </Col>
                    <Col className="col-4 d-flex justify-content-center flex-column">
                      <Card.Title>Name: {item?.dogId?.name}</Card.Title>
                      <Card.Text>Breed: {item?.dogId?.breed?.name}</Card.Text>
                      <Card.Text>
                        Age: {item?.dogId?.age || 'Not specified'}
                      </Card.Text>
                      <Card.Text>
                        Weight / Size: {item?.dogId?.weight || 'Not specified'}
                      </Card.Text>
                      <Card.Text>Submission Id: {item?._id}</Card.Text>
                      <Card.Text>Sender Id: {item?.senderId}</Card.Text>
                      <Card.Text>Dog Id: {item?.dogId?._id}</Card.Text>
                      {item?.senderType && (
                        <Card.Text>Sender Type: {item?.senderType}</Card.Text>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-6">
              <Card bg={'light'} className="w-100 mb-5">
                <Card.Header>AI</Card.Header>
                <Card.Body>
                  <Card.Text>
                    AI Grade:{' '}
                    {item?.ai_grade ? item.ai_grade.toFixed(2) : 'NULL'}
                  </Card.Text>
                  {item?.ai_error && (
                    <Card.Text>AI Error: {item?.ai_error}</Card.Text>
                  )}
                </Card.Body>
              </Card>
              <DrPoopItemForm
                itemId={params.id}
                defaultValues={{
                  status: item.status,
                  dogiz_grade: item.dogiz_grade,
                  drNote: item.drNote,
                }}
              />
            </Col>
          </Row>
          <Row className="mt-5 mb-5 d-flex">
            <Button
              className="font-weight-bold p-3 w-100"
              type="submit"
              form="DR_POOP_ITEM_FORM"
            >
              Save
            </Button>
          </Row>
        </>
      )}
    </Col>
  );
};

export default DrPoopUserDataItem;
