import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import DiscountReasonForm from './components/discoutReasonsForm';
import GoBack from '../../component/buttons/goBackButton';
import CustomSpinner from '../../component/spinner';

import {
  getDiscountReasonById,
  updateDiscountReason,
} from '../../service/discount-reasons';
import { discountReasonsSelector } from '../../redux/selectors/selectors';

const DiscountReasonsEdit = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  const { loading, selectedItem } = useSelector(discountReasonsSelector);

  useEffect(() => {
    dispatch(getDiscountReasonById(id));
  }, [dispatch, id]);

  const onSubmit = data => {
    const body = {
      ...data,
      name: data.name_localizations.en,
    };

    dispatch(updateDiscountReason({ id, body })).then(() => {
      history.push('/discount-reasons');
    });
  };

  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      {loading || !selectedItem ? (
        <CustomSpinner />
      ) : (
        <DiscountReasonForm
          onSubmit={onSubmit}
          defaultValues={{
            name_localizations: {
              en: selectedItem.name_localizations?.en || selectedItem?.name,
              il: selectedItem.name_localizations?.il,
            },
          }}
        />
      )}
    </Col>
  );
};

export default DiscountReasonsEdit;
