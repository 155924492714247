import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { changeConfirmStatus } from '../../../redux/confirm/confirm.action';
import { confirmationKeys } from '../../../constant';

const PartnerProgramItem = ({ row }) => {
  const dispatch = useDispatch();

  return (
    <tr>
      <td className="text-center align-middle">{row._id}</td>
      <td className="text-left align-middle">
        EN:{' '}
        {row?.partnerType?.title_localizations?.en ||
          row?.partnerType?.title ||
          '-'}
        <br />
        IL: {row?.partnerType?.title_localizations?.il || '-'}
      </td>
      <td className="text-center align-middle">{row.currency.name}</td>
      <td className="text-center align-middle">{row.contractType || '-'}</td>

      <td className="align-middle text-center">
        <Link to={`/partner-program/${row._id}`}>
          <Button size={'sm'} variant="success" className="m-2">
            <i className="fa fa-pencil"></i>
          </Button>
        </Link>

        <Button
          size={'sm'}
          variant="danger"
          className="m-2"
          onClick={() =>
            dispatch(
              changeConfirmStatus({
                isVisible: true,
                content: row.partnerType.title,
                type: confirmationKeys.partnerProgramDeleteItem,
                actionParams: [row._id],
              }),
            )
          }
        >
          <i className="fa fa-trash-o"></i>
        </Button>
      </td>
    </tr>
  );
};

export default PartnerProgramItem;
