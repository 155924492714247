export const validationRules = {
  group: {
    validate: value =>
      value === 'Choose value' ? 'Group field is required!' : undefined,
  },

  weight: {
    validate: value =>
      value === 'Choose value' ? 'Weight group field is required!' : undefined,
  },

  value: {
    required: 'Value field is required!',
    min: {
      value: 1,
      message: 'Value field cant be less then 1',
    },
    max: {
      value: 100,
      message: 'Value field cant be more then 100',
    },
  },

  equal: {
    required: 'Equal field is required!',
    min: {
      value: -150,
      message: 'Equal field cant be less then -150',
    },
    max: {
      value: 150,
      message: 'Equal field cant be more then 150',
    },
  },

  more: {
    required: 'More field is required!',
    min: {
      value: 0,
      message: 'More field cant be less then 0',
    },
    max: {
      value: 150,
      message: 'More field cant be more then 150',
    },
  },

  smaller: {
    required: 'Smaller field is required!',
    min: {
      value: -150,
      message: 'Smaller field cant be less then -150',
    },
    max: {
      value: 150,
      message: 'Smaller field cant be more then 150',
    },
  },
};
