import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row } from 'react-bootstrap';
import GoBack from '../../../component/buttons/goBackButton';
import CustomSpinner from '../../../component/spinner';
import NotificationConfigForm from './form';

import { pushNotificationsSelector } from '../../../redux/selectors/selectors';
import { getNotificationsSettingsById } from '../../../service/notifications';
import { clearNotificationsSettingsItem } from '../../../redux/notifications/notifications.action';

const NotificationConfigEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    settings: { selectedItem, loading },
  } = useSelector(pushNotificationsSelector);

  const formatValues = values => {
    if (!values) return {};
    if ('unit_of_measure' in values) {
      return { [values.unit_of_measure]: values.value };
    }
    return values;
  };

  useEffect(() => {
    dispatch(getNotificationsSettingsById(id));
    return () => {
      dispatch(clearNotificationsSettingsItem());
    };
  }, [dispatch, id]);

  return (
    <>
      {loading || !selectedItem ? (
        <CustomSpinner />
      ) : (
        <>
          <Row className="pl-3 pr-3 pt-3">
            <div className="d-flex justify-content-between">
              <GoBack />
            </div>
          </Row>
          <NotificationConfigForm
            id={id}
            defaultValues={{
              hours:
                formatValues(selectedItem.value).hours ||
                formatValues(selectedItem.value).hour ||
                undefined,
              minutes:
                formatValues(selectedItem.value).minutes ||
                formatValues(selectedItem.value).minute ||
                undefined,
            }}
          />
        </>
      )}
    </>
  );
};

export default NotificationConfigEdit;
