export const validationRules = {
  name: {
    required: 'Name field is required!',
  },

  min: {
    required: 'Min field is required!',
    min: {
      value: 1,
      message: 'Min field cant be less then 1',
    },
    max: {
      value: 100,
      message: 'Min field cant be more then 100',
    },
  },

  max: {
    required: 'Max field is required!',
    min: {
      value: 1,
      message: 'Max field cant be less then 1',
    },
    max: {
      value: 100,
      message: 'Max field cant be more then 100',
    },
  },
};
