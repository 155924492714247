import COUNTRIES_ACTIONS_TYPES from './countries.types';

export const countriesActions = {
  countriesOperationLoading: () => ({
    type: COUNTRIES_ACTIONS_TYPES.OPERATION_LOADING,
  }),

  countriesOperationFailed: payload => ({
    type: COUNTRIES_ACTIONS_TYPES.OPERATION_LOADING,
    payload,
  }),

  getCountriesList: payload => ({
    type: COUNTRIES_ACTIONS_TYPES.GET_COUNTRIES_LIST,
    payload,
  }),

  getCountryById: payload => ({
    type: COUNTRIES_ACTIONS_TYPES.GET_COUNTRY_BY_ID,
    payload,
  }),

  deleteCountry: payload => ({
    type: COUNTRIES_ACTIONS_TYPES.DELETE_COUNTRY,
    payload,
  }),

  clearSelectedCountry: () => ({
    type: COUNTRIES_ACTIONS_TYPES.CLEAR_SELECTED_COUNTRY,
  }),
};
