export const validationRules = {
  smsCountToSendWarning: {
    required: 'This field is required',
    min: "This field can't be less 0",
  },
  smsCountAllowedPerMinute: {
    required: 'This field is required',
    min: "This field can't be less 0",
  },
  smsAllowedFromOneIpPerMinute: {
    required: 'This field is required',
    min: "This field can't be less 0",
  },
  smsAllowedFromOneNumberPerMinute: {
    required: 'This field is required',
    min: "This field can't be less 0",
  },
};
