import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import DiscountReasonForm from './components/discoutReasonsForm';
import GoBack from '../../component/buttons/goBackButton';

import { createDiscountReason } from '../../service/discount-reasons';

const DiscountReasonsCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = data => {
    const body = {
      ...data,
      name: data.name_localizations.en,
    };

    dispatch(createDiscountReason(body)).then(() => {
      history.push('/discount-reasons');
    });
  };

  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      <DiscountReasonForm onSubmit={onSubmit} />
    </Col>
  );
};

export default DiscountReasonsCreate;
