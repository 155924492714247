import List from './list';
import Edit from './edit';
import Create from './create';

const DiscountReasons = {
  List,
  Edit,
  Create,
};

export default DiscountReasons;
