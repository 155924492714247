import fetchWithAuth from '..';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';
import MESSAGES_FOR_USER from '../../constants/messages/en';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';

export const createBilling = params => async dispatch => {
  const result = await fetchWithAuth({
    path: `/super-admin/order/payment/` + params,
    method: 'POST',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.BILLING.CREATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.BILLING.CREATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  } else {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.BILLING.CREATE_SUCCESS.TITLE,
        content: MESSAGES_FOR_USER.BILLING.CREATE_SUCCESS.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );
  }

  return;
};
