import { toastMessageTypes } from './toast-message.types';

const initialState = {
  isVisible: false,
  title: '',
  content: '',
  type: '',
};

const toastMessage = (state = initialState, { type, payload }) => {
  switch (type) {
    case toastMessageTypes.SHOW_TOAST_MESSAGE:
      const { title, content, type } = payload;
      return {
        ...state,
        isVisible: true,
        title,
        content,
        type,
      };
    case toastMessageTypes.HIDE_TOAST_MESSAGE:
      return {
        ...state,
        isVisible: false,
      };
    default:
      return state;
  }
};

export default toastMessage;
