import React from 'react';
import { Button, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { changeConfirmStatus } from '../../../redux/confirm/confirm.action';
import { confirmationKeys } from '../../../constant';

const QuizQuestionItem = ({ row }) => {
  const dispatch = useDispatch();

  const handleDeclineButton = (id, content) => {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        content,
        type: confirmationKeys.questionTableDeleteItem,
        actionParams: [id],
      }),
    );
  };

  return (
    <tr key={`section-${row._id}`}>
      <td>{row.question_localizations?.en || row.question || '-'}</td>
      <td>{row.question_localizations?.il || '-'}</td>
      <td>{new Date(row.createdAt).toLocaleString()}</td>
      <td>
        <Row className="d-flex justify-content-around p-0 m-0">
          <Link to={`${row._id}`}>
            <Button size="sm" variant="primary">
              <i className="fa fa-eye"></i>
            </Button>
          </Link>

          <Link to={`${row._id}/edit`}>
            <Button size="sm" variant="success">
              <i className="fa fa-pencil"></i>
            </Button>
          </Link>

          <Button
            size="sm"
            variant="danger"
            onClick={() => handleDeclineButton(row._id, row.question)}
          >
            <i className="fa fa-trash-o"></i>
          </Button>
        </Row>
      </td>
    </tr>
  );
};

export default QuizQuestionItem;
