import { serviceTypes } from './serviceType.types';

export const getServiceTypeListSuccess = list => ({
  type: serviceTypes.GET_SERVICE_TYPE_LIST_SUCCESS,
  payload: list,
});

export const getServiceTypeByIdSuccess = reason => ({
  type: serviceTypes.GET_SERVICE_TYPE_BY_ID_SUCCESS,
  payload: reason,
});
export const getServiceTypeByIdFailed = err => ({
  type: serviceTypes.GET_SERVICE_TYPE_BY_ID_FAIL,
  payload: err,
});

export const deleteServiceTypeSuccess = id => ({
  type: serviceTypes.DELETE_SERVICE_TYPE_SUCCESS,
  payload: id,
});
export const deleteServiceTypeFailed = err => ({
  type: serviceTypes.DELETE_SERVICE_TYPE_FAIL,
  payload: err,
});

export const clearServiceTypeItemState = () => ({
  type: serviceTypes.CLEAR_ITEM,
});
export const updateServiceTypeFailed = err => ({
  type: serviceTypes.UPDATE_SERVICE_TYPE_FAIL,
  payload: err,
});
export const updateServiceTypeSuccess = (id, updatedData) => ({
  type: serviceTypes.UPDATE_SERVICE_TYPE_SUCCESS,
  payload: { updatedData, id },
});
