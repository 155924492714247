import fetchWithAuth from '../index';
import setLoading from '../../redux/default';

import { URL_SUPER_ADMIN } from '../../constant';

import MESSAGES_FOR_USER from '../../constants/messages/en';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';

import { createFormData } from '../../helpers/formDataConvertor';
import { destinationUrls } from '../../component/menu/constants';
import {
  getServicePartnerListSuccess,
  deleteServicePartnerFailed,
  deleteServicePartnerSuccess,
  getServicePartnerByIdSuccess,
  getServicePartnerByIdFailed,
  updateServicePartnerFailed,
  updateServicePartnerSuccess,
} from '../../redux/servicePartner/servicePartner.action';

export const createServicePartner = (data, history) => async dispatch => {
  const result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/service-partner/`,
    method: 'POST',
    body: JSON.stringify(data),
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SERVICE_PARTNER.CREATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.SERVICE_PARTNER.CREATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  history.push(destinationUrls.SERVICE_PARTNER);
  return;
};

export const getServicePartner = () => async dispatch => {
  dispatch(setLoading('service_partner/GET_SERVICE_PARTNER_LIST'));

  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/service-partner/`,
    method: 'GET',
  });

  dispatch(getServicePartnerListSuccess(await result.json()));
  return;
};

export const deleteServicePartnerById = id => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/service-partner/${id}`,
    method: 'DELETE',
  });

  setLoading('DELETE_SERVICE_TYPE');

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SERVICE_TYPE.DELETE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.SERVICE_TYPE.DELETE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(deleteServicePartnerFailed(await result.json()));
    return;
  }

  dispatch(deleteServicePartnerSuccess(id));
  return;
};

export const getServicePartnerById = id => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/service-partner/${id}`,
    method: 'GET',
  });

  setLoading('GET_SERVICE_TYPE_BY_ID');

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SERVICE_TYPE.GET_BY_ID_FAILED.TITLE,
        content: MESSAGES_FOR_USER.SERVICE_TYPE.GET_BY_ID_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(getServicePartnerByIdFailed(await result.json()));
    return;
  }

  dispatch(getServicePartnerByIdSuccess(await result.json()));
};

export const updateServicePartner = (id, data, history) => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/service-partner/${id}`,
    method: 'PUT',
    body: JSON.stringify(data),
  });

  setLoading('UPDATE_SERVICE_TYPE');

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SERVICE_PARTNER.UPDATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.SERVICE_PARTNER.UPDATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(updateServicePartnerFailed(await result.json()));
    return;
  }

  dispatch(updateServicePartnerSuccess(id, data));
  history.push(destinationUrls.SERVICE_PARTNER);
  return;
};
