export const workerTypes = {
  GET_WORKER_TYPES_LOADING: 'worker_type/GET_WORKER_TYPES_LOADING',
  GET_WORKER_TYPES_SUCCESS: 'worker_type/GET_WORKER_TYPES_SUCCESS',
  GET_WORKER_TYPES_FAIL: 'worker_type/GET_WORKER_TYPES_FAIL',

  GET_WORKER_TYPE_BY_ID_LOADING: 'worker_type/GET_WORKER_TYPE_BY_ID_LOADING',
  GET_WORKER_TYPE_BY_ID_SUCCESS: 'worker_type/GET_WORKER_TYPE_BY_ID_SUCCESS',
  GET_WORKER_TYPE_BY_ID_FAIL: 'worker_type/GET_WORKER_TYPE_BY_ID_FAIL',

  CREATE_WORKER_TYPES_LOADING: 'worker_type/CREATE_WORKER_TYPES_LOADING',
  CREATE_WORKER_TYPES_SUCCESS: 'worker_type/CREATE_WORKER_TYPES_SUCCESS',
  CREATE_WORKER_TYPES_FAIL: 'worker_type/CREATE_WORKER_TYPES_FAIL',

  UPDATE_WORKER_TYPES_LOADING: 'worker_type/UPDATE_WORKER_TYPES_LOADING',
  UPDATE_WORKER_TYPES_SUCCESS: 'worker_type/UPDATE_WORKER_TYPES_SUCCESS',
  UPDATE_WORKER_TYPES_FAIL: 'worker_type/UPDATE_WORKER_TYPES_FAIL',

  DELETE_WORKER_TYPES_LOADING: 'worker_type/DELETE_WORKER_TYPES_LOADING',
  DELETE_WORKER_TYPES_SUCCESS: 'worker_type/DELETE_WORKER_TYPES_SUCCESS',
  DELETE_WORKER_TYPES_FAIL: 'worker_type/DELETE_WORKER_TYPES_FAIL',
};
