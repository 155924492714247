export const sectionTypes = {
  CREATE_SECTION_SUCCESS: 'section/CREATE_SECTION_SUCCESS',
  GET_SECTION_LIST_SUCCESS: 'section/GET_SECTION_LIST_SUCCESS',
  GET_SECTION_LIST_FAIL: 'section/GET_SECTION_LIST_FAIL',
  GET_SECTION_DATAILS_SUCCESS: 'section/GET_SECTION_DATAILS_SUCCESS',
  GET_SECTION_DATAILS_FAIL: 'section/GET_SECTION_DATAILS_FAIL',
  EDIT_SECTION_SUCCESS: 'section/EDIT_SECTION_SUCCESS',
  EDIT_SECTION_FAIL: 'section/EDIT_SECTION_FAIL',
  DELETE_SECTION_SUCCESS: 'section/DELETE_SECTION_SUCCESS',
  DELETE_SECTION_FAIL: 'section/DELETE_SECTION_FAIL',
  GET_QUESTION_NOT_SECTION_SUCCESS: 'section/GET_QUESTION_NOT_SECTION_SUCCESS',
  GET_QUESTION_NOT_SECTION_FAIL: 'section/GET_QUESTION_NOT_SECTION_FAIL',
  UPDATE_TITLE_SECTION: 'section/UPDATE_TITLE_SECTION',
  ADD_QUESTION_BY_SECTION: 'section/ADD_QUESTION_BY_SECTION',
  DELETE_QUESTION_FROM_SECTION: 'section/DELETE_QUESTION_FROM_SECTION',
  SEND_MODIFIED_QUESTION_SUCCESS: 'section/SEND_MODIFIED_QUESTION_SUCCESS',
  SEND_MODIFIED_QUESTION_FAIL: 'section/SEND_MODIFIED_QUESTION_FAIL',
  CLEAR_SECTION_PAGE_DATA: 'section/CLEAR_SECTION_PAGE_DATA',
};
