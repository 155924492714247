import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import Input from '../inputs';

import { sendPushNotification } from '../../service/notifications';
import { validationRules } from './validation';

const PushNotificationsForm = ({ countriesList = [] }) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = values => {
    const updatedValues = {
      redirect: 'string',
      notificationType: 'SERVICE',
      visible: true,
      ...values
    };
    dispatch(sendPushNotification(updatedValues));
    reset()
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-100"
      id="push-notifications-form"
    >
      <Row className="pl-3 pr-3 pt-3">
        <Input.text
          register={register}
          label="Title"
          name="title"
          validationRules={validationRules.title}
          error={errors.title}
        />
      </Row>
      <Row className="p-3">
        <Input.text
          register={register}
          label="Message"
          name="message"
          validationRules={validationRules.message}
          error={errors.message}
        />
      </Row>
      <Row className="d-flex">
        <Col>
          <label htmlFor="countryId" className="form-label">
            Country
          </label>
          <Input.select
            optionsList={countriesList}
            label="Choose country"
            name="countryId"
            register={register}
            validationRules={validationRules.countryId}
            error={errors.countryId}
          />
        </Col>
      </Row>
      <Row className="mt-5 ml-0 mr-0">
        <Button className="w-100 p-3" type="submit">
          Send
        </Button>
      </Row>
    </form>
  );
};

export default PushNotificationsForm;
