import PublicationList from './list';
import PublicationCreate from './create';
import PublicationDetails from './details';
import PublicationEdit from './edit';

const Publication = {
  list: PublicationList,
  create: PublicationCreate,
  edit: PublicationEdit,
  details: PublicationDetails,
};

export default Publication;
