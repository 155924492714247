import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import CustomSpinner from '../../component/spinner';
import ConfigurableTable from '../../component/table';

import { clearListOnActionsPage } from '../../redux/rewards/rewards.action';
import { rewardsSelector } from '../../redux/selectors/selectors';
import { getRewardsActionList } from '../../service/rewards';
import {
  REWARDS_ACTION_TABLE_HEAD,
  TABLE_ITEM_TYPE,
} from '../../component/table/config';

const RewardsActionsList = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(rewardsSelector);

  useEffect(() => {
    dispatch(getRewardsActionList());

    return () => dispatch(clearListOnActionsPage());
  }, [dispatch]);

  return (
    <Row className="mt-3 d-flex align-items-center justify-content-center w-100 ml-0 mr-0">
      {loading ? (
        <CustomSpinner />
      ) : (
        <Col sm={12} className="p-0">
          <ConfigurableTable
            tableHeaderColumns={REWARDS_ACTION_TABLE_HEAD}
            itemVariant={TABLE_ITEM_TYPE.REWARD_ACTION_ITEM_TYPE}
            rows={list}
          />
        </Col>
      )}
    </Row>
  );
};

export default RewardsActionsList;
