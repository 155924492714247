import fetchWithAuth from '..';

import { SMS_CONFIGS_ACTIONS } from '../../redux/sms-configs/sms-configs.actions';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';
import MESSAGES_FOR_USER from '../../constants/messages/en';

const getList = () => async dispatch => {
  const url = `/sms-settings`;

  dispatch(SMS_CONFIGS_ACTIONS.loading());

  let result = await fetchWithAuth({
    path: url,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SMS_CONFIGS.GET_FAILED.TITLE,
        content: MESSAGES_FOR_USER.GET_FAILED.GET_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );

    dispatch(SMS_CONFIGS_ACTIONS.failed('ERROR'));
    return;
  }

  const data = await result.json();

  dispatch(SMS_CONFIGS_ACTIONS.getListSuccess(data));
};

const getById = id => async dispatch => {
  const url = `/sms-settings/${id}`;

  dispatch(SMS_CONFIGS_ACTIONS.loading());

  let result = await fetchWithAuth({
    path: url,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SMS_CONFIGS.GET_FAILED.TITLE,
        content: MESSAGES_FOR_USER.SMS_CONFIGS.GET_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );

    dispatch(SMS_CONFIGS_ACTIONS.failed('ERROR'));
    return;
  }

  const data = await result.json();

  dispatch(SMS_CONFIGS_ACTIONS.getByIdSuccess(data));
};

const update =
  ({ body, id }) =>
  async dispatch => {
    const url = `/sms-settings/${id}`;

    dispatch(SMS_CONFIGS_ACTIONS.loading());

    let result = await fetchWithAuth({
      path: url,
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.SMS_CONFIGS.UPDATE_FAILED.TITLE,
          content: MESSAGES_FOR_USER.SMS_CONFIGS.UPDATE_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );

      dispatch(SMS_CONFIGS_ACTIONS.failed('ERROR'));
      return;
    }

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SMS_CONFIGS.UPDATE_SUCCESS.TITLE,
        content: MESSAGES_FOR_USER.SMS_CONFIGS.UPDATE_SUCCESS.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );
  };

export const SMS_CONFIGS_SERVICES = {
  getList,
  getById,
  update,
};
