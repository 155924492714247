export const partnerWorkerTypes = {
  CREATE_PARTNER_WORKER_FAIL: 'partner_worker/CREATE_PARTNER_WORKER_FAIL',

  GET_PARTNER_WORKER_LIST_SUCCESS:
    'partner_worker/GET_PARTNER_WORKER_LIST_SUCCESS',
  GET_PARTNER_WORKER_LIST_LOADING:
    'partner_worker/GET_PARTNER_WORKER_LIST_LOADING',
  GET_PARTNER_WORKER_LIST_FAIL: 'partner_worker/GET_PARTNER_WORKER_LIST_FAIL',

  GET_PARTNER_WORKER_BY_ID_SUCCESS:
    'partner_worker/GET_PARTNER_WORKER_BY_ID_SUCCESS',
  GET_PARTNER_WORKER_BY_ID_FAIL: 'partner_worker/GET_PARTNER_WORKER_BY_ID_FAIL',

  DELETE_PARTNER_WORKER_SUCCESS: 'partner_worker/DELETE_PARTNER_WORKER_SUCCESS',
  DELETE_PARTNER_WORKER_FAIL: 'partner_worker/DELETE_PARTNER_WORKER_FAIL',

  CLEAR_ITEM: 'partner_worker/CLEAR_ITEM',

  UPDATE_PARTNER_WORKER_SUCCESS: 'partner_worker/UPDATE_PARTNER_WORKER_SUCCESS',
  UPDATE_PARTNER_WORKER_FAIL: 'partner_worker/UPDATE_PARTNER_WORKER_FAIL',
};
