export const partnerProgramTypes = {
  CREATE_PARTNER_PROGRAM_FAIL: 'partner_program/CREATE_PARTNER_PROGRAM_FAIL',

  GET_PARTNER_PROGRAM_LIST_SUCCESS:
    'partner_program/GET_PARTNER_PROGRAM_LIST_SUCCESS',
  GET_PARTNER_PROGRAM_LIST_LOADING:
    'partner_program/GET_PARTNER_PROGRAM_LIST_LOADING',
  GET_PARTNER_PROGRAM_LIST_FAIL:
    'partner_program/GET_PARTNER_PROGRAM_LIST_FAIL',

  GET_PARTNER_PROGRAM_BY_ID_SUCCESS:
    'partner_program/GET_PARTNER_PROGRAM_BY_ID_SUCCESS',
  GET_PARTNER_PROGRAM_BY_ID_FAIL:
    'partner_program/GET_PARTNER_PROGRAM_BY_ID_FAIL',

  DELETE_PARTNER_PROGRAM_SUCCESS:
    'partner_program/DELETE_PARTNER_PROGRAM_SUCCESS',
  DELETE_PARTNER_PROGRAM_FAIL: 'partner_program/DELETE_PARTNER_PROGRAM_FAIL',

  CLEAR_ITEM: 'partner_program/CLEAR_ITEM',
  UPDATE_PARTNER_PROGRAM_SUCCESS:
    'partner_program/UPDATE_PARTNER_PROGRAM_SUCCESS',
  UPDATE_PARTNER_PROGRAM_FAIL: 'partner_program/UPDATE_PARTNER_PROGRAM_FAIL',
};
