import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import GoBack from '../../../component/buttons/goBackButton';
import ServiceTypeForm from '../../../component/serviceTypeForm/serviceTypeForm';
import { useDispatch, useSelector } from 'react-redux';
import { clearServiceTypeItemState } from '../../../redux/serviceType/serviceType.action';
import { getServiceTypeById } from '../../../service/services';
import CustomSpinner from '../../../component/spinner';
import { serviceTypeSelector } from '../../../redux/selectors/selectors';

const ServiceTypeEdit = props => {
  const dispatch = useDispatch();
  const { id } = props.match.params;
  const { item } = useSelector(serviceTypeSelector);

  useEffect(() => {
    getServiceTypeById(id)(dispatch);

    return () => dispatch(clearServiceTypeItemState());
  }, [dispatch, id]);

  return (
    <Col sm={9} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      {Object.keys(item).length ? (
        <ServiceTypeForm
          type="EDIT"
          defaultValues={{
            title_localizations: {
              en: item.title_localizations?.en || item.title || '',
              il: item.title_localizations?.il || '',
            },
            desc_localizations: {
              en: item.desc_localizations?.en || item.desc || '',
              il: item.desc_localizations?.il || '',
            },
            icon: item.icon,
            image: item.image,
          }}
          id={id}
        />
      ) : (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default ServiceTypeEdit;
