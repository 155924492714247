import fetchWithAuth from '../index';
import {
  addItemToSplashPhotosListSuccess,
  deleteItemFromSplashPhotosList,
  getSplashPhotosListSuccess,
  addItemToSplashPhotosListRequested,
  setIsListHasAnyChangesStatus,
  getSplashPhotosListFail,
  getSplashPhotosItem,
  getSplashPhotosItemFail,
  getSplashPhotosItemSuccess,
} from '../../redux/splash-photos/splash-photos.action';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';

import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';
import MESSAGES_FOR_USER from '../../constants/messages/en';

export const getSlashPhotosList = () => async dispatch => {
  let result = await fetchWithAuth({
    path: `/splash-photo`,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SPLASH_PHOTOS.GET_LIST_FAILED.TITLE,
        content: MESSAGES_FOR_USER.SPLASH_PHOTOS.GET_LIST_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(getSplashPhotosListFail());
    return;
  }

  dispatch(getSplashPhotosListSuccess(await result.json()));
};

export const addSplashPhotoItem =
  (file = {}, splashPhotoOrder = 1) =>
  async dispatch => {
    dispatch(addItemToSplashPhotosListRequested());

    const formData = new FormData();

    formData.append('photo', file);
    formData.append('order', splashPhotoOrder);
    formData.append('isVisible', true);

    let result = await fetchWithAuth(
      {
        path: '/splash-photo',
        method: 'POST',
        body: formData,
      },
      true,
    );

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.FILE_UPLOADER.LOADING_FAILED.TITLE,
          content: MESSAGES_FOR_USER.FILE_UPLOADER.LOADING_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );
      return;
    }

    setTimeout(async () => {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.FILE_UPLOADER.LOADING_SUCCESS.TITLE,
          content: MESSAGES_FOR_USER.FILE_UPLOADER.LOADING_SUCCESS.BODY,
          type: TOAST_MESSAGE_TYPES.SUCCESS,
        }),
      );
      dispatch(addItemToSplashPhotosListSuccess(await result.json()));
    }, 500);
  };

export const deleteSlashPhotoItem = itemID => async dispatch => {
  let result = await fetchWithAuth({
    path: `/splash-photo/${itemID}`,
    method: 'DELETE',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.FILE_UPLOADER.DELETING_FAILED.TITLE,
        content: MESSAGES_FOR_USER.FILE_UPLOADER.DELETING_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  dispatch(deleteItemFromSplashPhotosList(itemID));
  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.FILE_UPLOADER.DELETING_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.FILE_UPLOADER.DELETING_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );
};

export const updateSplashPhotosList =
  (updatedList = []) =>
  async dispatch => {
    const updatedOrderInList = updatedList.map((item, index) => {
      item.order = index + 1;
      return item;
    });

    let result = await fetchWithAuth({
      path: '/splash-photo/change-order',
      method: 'PUT',
      body: JSON.stringify(updatedOrderInList),
    });

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.SPLASH_PHOTOS.UPDATE_LIST_FAILED.TITLE,
          content: MESSAGES_FOR_USER.SPLASH_PHOTOS.UPDATE_LIST_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );
      return;
    }

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SPLASH_PHOTOS.UPDATE_LIST_SUCCESS.TITLE,
        content: MESSAGES_FOR_USER.SPLASH_PHOTOS.UPDATE_LIST_SUCCESS.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );

    dispatch(setIsListHasAnyChangesStatus(false));
  };

export const getSplashPhotosItemByID = itemID => async dispatch => {
  dispatch(getSplashPhotosItem());

  let result = await fetchWithAuth({
    path: `/splash-photo/${itemID}`,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SPLASH_PHOTOS.GET_ITEM_FAILED.TITLE,
        content: MESSAGES_FOR_USER.SPLASH_PHOTOS.GET_ITEM_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(getSplashPhotosItemFail());

    return;
  }

  dispatch(getSplashPhotosItemSuccess(await result.json()));
};

export const updateSplashPhotosItem = (splashPhotosItemObj, itemID) => async dispatch => {
  return new Promise(async resolve => {
    let result = await fetchWithAuth({
      path: `/splash-photo/${itemID}`,
      method: 'PUT',
      body: JSON.stringify(splashPhotosItemObj),
    });

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.SPLASH_PHOTOS.UPDATE_ITEM_FAILED.TITLE,
          content: MESSAGES_FOR_USER.SPLASH_PHOTOS.UPDATE_ITEM_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );

      return;
    }

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SPLASH_PHOTOS.UPDATE_ITEM_SUCCESS.TITLE,
        content: MESSAGES_FOR_USER.SPLASH_PHOTOS.UPDATE_ITEM_SUCCESS.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );
    resolve();
  });
};
