import React, { useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import DraggableSplashPhotoItem from './components/draggableSplashPhotoItem';

import { DRAGGABLE_ITEM_TYPE } from './config';

const DraggableTable = ({
  draggableItemVariant,
  draggableRows,
  onDragFinished,
  tableHeaderColumn,
}) => {
  const getDraggableVariant = () => {
    switch (draggableItemVariant) {
      case DRAGGABLE_ITEM_TYPE.SPLASH_PHOTOS_ITEM:
        return (
          <>
            {draggableRows.map((row, index) => (
              <DraggableSplashPhotoItem
                key={row._id}
                row={row}
                index={index}
                moveRow={moveRow}
              />
            ))}
          </>
        );

      default:
        break;
    }
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const draggableRow = draggableRows[dragIndex];
      onDragFinished(
        update(draggableRows, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, draggableRow],
          ],
        }),
      );
    },
    [draggableRows],
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <table className="table table-striped">
        <thead className="bg-primary text-light">
          <tr>
            {tableHeaderColumn.map((item, index) => {
              return (
                <th style={{ width: item.columnWidth }} key={index}>
                  {item.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{getDraggableVariant()}</tbody>

      </table>
    </DndProvider>
  );
};

export default DraggableTable;
