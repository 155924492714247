export const validationRulesForQuizSectionEditForm = {
  reward: {
    required: 'Value field is required!',
    min: {
      value: 1,
      message: 'Value field cant be less then 1',
    },
  },

  title_localizations: {
    en: {
      required: 'Title field is required!',
    },
    il: {
      required: 'Title field is required!',
    },
  },
};
