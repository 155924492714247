import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Card, Col } from 'react-bootstrap';

import CustomPieChart from './components/chart';
import {
  getDrPoopCountGradeDistribution,
  getDrPoopStatsOperation,
} from '../../../service/dr-poop';
import {
  drPoopCountGradeDistributionSelector,
  drPoopStatsSelector,
} from '../../../redux/selectors/selectors';
import CustomSpinner from '../../../component/spinner';
import StakedBarChart from './components/staked-bar-chart';

const colors = {
  red: '#EF3E36',
  blue: '#17BEBB',
  black: '#2e282a',
  beige: '#edb88b',
  pink: '#fad8d6',
};

const calculateTotalForChart = data => {
  let total = 0;

  Object.keys(data).forEach(item => {
    total += data[item];
  });

  return total;
};

const calculateTotalForStackedChart = data => {
  let total = 0;

  data.forEach(item => {
    Object.values(item).forEach(objItem => {
      if (!isNaN(objItem)) {
        total += objItem;
      }
    });
  });

  return total;
};

const DrPoopAnalyticsBoard = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(drPoopCountGradeDistributionSelector);
  const { data: statsData, loading: statsLoading } =
    useSelector(drPoopStatsSelector);

  const chartTotalValue = useMemo(() => calculateTotalForChart(data), [data]);

  const dataForChart = [
    { name: 'Bad', value: data.badCount, color: '#FF2F54' },
    { name: 'Good', value: data.goodCount, color: '#01C297' },
    { name: 'Medium', value: data.mediumCount, color: '#FF764A' },
    { name: 'Reject', value: data.rejectCount, color: colors.black },
    { name: 'Processing', value: data.processingCount, color: colors.pink },
  ];

  useEffect(() => {
    dispatch(getDrPoopCountGradeDistribution());
    dispatch(getDrPoopStatsOperation());
  }, [dispatch]);

  return (
    <>
      <Row className="w-100 mt-3">
        <Col>
          <Card className="shadow rounded-lg mb-3">
            <Card.Body>
              <Card.Title className="text-lg border-bottom pb-3">
                Grades
              </Card.Title>
              <div
                style={{
                  width: '350px',
                  height: 'auto',
                  margin: '0 auto',
                  position: 'relative',
                }}
              >
                {loading ? (
                  <CustomSpinner />
                ) : (
                  <>
                    <CustomPieChart data={dataForChart} />
                    <span
                      style={{
                        position: 'absolute',
                        right: '0',
                        top: '24px',
                      }}
                    >
                      Total: {chartTotalValue}
                    </span>
                  </>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="w-100">
        <Col>
          <Card className="shadow rounded-lg mb-3">
            <Card.Body>
              <Card.Title className="text-lg border-bottom pb-3">
              Year distribution chart
              </Card.Title>
              <div
                style={{
                  width: '100%',
                  height: '400px',
                  margin: '0 auto',
                  position: 'relative',
                }}
              >
                {statsLoading ? (
                  <CustomSpinner />
                ) : (
                  <>
                    <StakedBarChart data={statsData} />
                    <span
                      style={{
                        position: 'absolute',
                        right: '0',
                        top: '24px',
                      }}
                    >
                      Total: {calculateTotalForStackedChart(statsData)}
                    </span>
                  </>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DrPoopAnalyticsBoard;
