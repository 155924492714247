import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row, Button, Form, ListGroup } from 'react-bootstrap';
import SectionForm from '../../../component/quizForms/SectionForm';
import GoBack from '../../../component/buttons/goBackButton';
import {
  getSectionDetails,
  sendModifiedQuestions,
  getQuestionNotInSection,
} from '../../../service/quiz/section';
import { clearSectionPageData } from '../../../redux/quiz/section/section.action';
import CustomSpinner from '../../../component/spinner';

import './styles.css';

const SectionEdit = props => {
  const dispatch = useDispatch();

  const {
    item,
    loading,
    questionNotSection = [],
  } = useSelector(state => state.quiz.section);

  const { questions, title, title_localizations, _id, reward = 0 } = item;

  const [isOpenQuestionForm, changeQuestionFormStatus] = useState(false);
  const [availableQuestions, setAvailableQuestions] =
    useState(questionNotSection);
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [selectedQuestions, setSelectedQuestions] = useState(questions);

  useEffect(() => {
    getSectionDetails(props.match.params.id)(dispatch);
    getQuestionNotInSection()(dispatch);

    return () => dispatch(clearSectionPageData());
  }, [dispatch, props.match.params.id]);

  useEffect(() => {
    if (isOpenQuestionForm) setSelectedQuestion(questionNotSection[0]._id);
    else if (!questionNotSection) setSelectedQuestion('');
  }, [isOpenQuestionForm, questionNotSection]);

  const removeSelectedQuestion = id => {
    setSelectedQuestions(
      selectedQuestions
        .filter(i => i._id !== id)
        .map((i, idx) => ({ ...i, order: idx })),
    );
    const available = [...availableQuestions];

    available.push(questionNotSection.find(i => i.id === id));

    setAvailableQuestions(available);
  };

  const addSelectedQuestion = id => {
    id = id || availableQuestions[0]._id;
    let questions = [...selectedQuestions];
    questions.push(availableQuestions.find(i => i._id === id));
    questions = questions.map((i, idx) => ({ ...i, order: idx }));

    setSelectedQuestions(questions);
    setAvailableQuestions(availableQuestions.filter(i => i._id !== id));
  };

  const changeSelectedQuestionOrder = (id, oldOrder, newOrder) => {
    if (newOrder < 0 || newOrder > selectedQuestions.length - 1) return;

    const questions = selectedQuestions
      .map(i => {
        if (i.id === id) {
          return { ...i, order: newOrder };
        }

        if (oldOrder > newOrder && i.order === newOrder) {
          return { ...i, order: oldOrder };
        }

        if (newOrder > oldOrder && i.order === newOrder) {
          return { ...i, order: oldOrder };
        }
        return i;
      })
      .sort((a, b) => a.order - b.order);

    setSelectedQuestions(questions);
  };

  const selectQuestion = id => {
    setSelectedQuestion(id);
  };

  useEffect(
    () => setAvailableQuestions(questionNotSection),
    [questionNotSection],
  );
  useEffect(() => setSelectedQuestions(item.questions), [item.questions]);

  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>

      {(loading || !item) && (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      )}

      {title || title_localizations ? (
        <SectionForm
          idSection={_id}
          defaultValues={{
            title_localizations: {
              en: title_localizations?.en || title || '',
              il: title_localizations?.il || '',
            },
            reward,
          }}
        />
      ) : null}

      <ListGroup className="section-edit__question-list mt-3 mb-3">
        {selectedQuestions?.map(
          ({ question, question_localizations, _id, order }) => (
            <ListGroup.Item key={_id} className="section-edit__question-item">
              <>
                EN: {question_localizations?.en || question || '-'}
                <br />
                IL: {question_localizations?.il || '-'}
                <div>
                  <Button
                    className="mr-1"
                    onClick={() => {
                      changeSelectedQuestionOrder(_id, order, order - 1);
                    }}
                    variant="secondary"
                  >
                    <i className="fa fa-arrow-up"></i>
                  </Button>

                  <Button
                    className="mr-1"
                    onClick={() => {
                      changeSelectedQuestionOrder(_id, order, order + 1);
                    }}
                    variant="secondary"
                  >
                    <i className="fa fa-arrow-down"></i>
                  </Button>

                  <Button
                    onClick={() => removeSelectedQuestion(_id)}
                    variant="danger"
                  >
                    <i className="fa fa-trash-o"></i>
                  </Button>
                </div>
              </>
            </ListGroup.Item>
          ),
        )}
      </ListGroup>

      {isOpenQuestionForm && questionNotSection.length > 0 && (
        <Row className="section-edit__create-container col-12 p-0">
          <Col>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Control
                as="select"
                onChange={e => selectQuestion(e.target.value)}
              >
                {questionNotSection.map(
                  ({ question, question_localizations, _id }) => (
                    <option value={_id}>
                      {question_localizations?.en || question || '-'}
                    </option>
                  ),
                )}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm={3} className="p-0">
            <Button
              size={'sm'}
              onClick={() => addSelectedQuestion(selectedQuestion)}
              variant="success"
            >
              Add
            </Button>
            <Button
              className="ml-1"
              size={'sm'}
              onClick={() => changeQuestionFormStatus(false)}
              variant="danger"
            >
              Close
            </Button>
          </Col>
        </Row>
      )}

      {!isOpenQuestionForm && questionNotSection.length > 0 && (
        <Button
          onClick={() => changeQuestionFormStatus(!isOpenQuestionForm)}
          variant="success"
        >
          Add new question
        </Button>
      )}
      <br />
      <Button
        className="mt-3"
        // disabled={!isChanged}
        onClick={() =>
          sendModifiedQuestions(_id, {
            newQuestions: selectedQuestions.map(i => ({
              id: i._id,
              order: i.order,
            })),
          })(dispatch)
        }
        variant="primary"
      >
        Save questions changes
      </Button>
    </Col>
  );
};

export default SectionEdit;
