const MESSAGES_FOR_USER = {
  AUTH: {
    EXPIRED_TOKEN: {
      TITLE: 'Information about token',
      BODY: 'Your session was expired! Please login again!',
    },
    CODE: {
      TITLE: 'Information about code',
      BODY: 'Wrong verification code!',
    },
    PHONE: {
      TITLE: 'Information about phone',
      BODY: 'Wrong phone number!',
    },
    ROLE: {
      TITLE: 'Login information',
      BODY: "You don't have enough rights...",
    },
  },
  QUIZ: {
    SECTION: {
      UPDATED_SUCCESS: {
        TITLE: 'Quiz section item status',
        BODY: 'Quiz section item was updated successfully!',
      },
      UPDATED_FAILED: {
        TITLE: 'Quiz section item status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
    },
    ANSWER: {
      UPDATED_SUCCESS: {
        TITLE: 'Quiz answer item status',
        BODY: 'Quiz answer item was updated successfully!',
      },
      UPDATED_FAILED: {
        TITLE: 'Quiz answer item status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
      DELETE_SUCCESS: {
        TITLE: 'Quiz answer item status',
        BODY: 'Quiz answer item was deleted successfully!',
      },
      DELETE_FAILED: {
        TITLE: 'Quiz answer item status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
      ADDED_SUCCESS: {
        TITLE: 'Quiz answer item status',
        BODY: 'Quiz answer item was added successfully!',
      },
      ADDED_FAILED: {
        TITLE: 'Quiz answer item status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
    },
    QUESTION: {
      CREATE_SUCCESS: {
        TITLE: 'Quiz question item status',
        BODY: 'Quiz question item was created successfully!',
      },
      CREATE_FAILED: {
        TITLE: 'Quiz question item status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
      UPDATE_SUCCESS: {
        TITLE: 'Quiz question item status',
        BODY: 'Quiz question item was updated successfully!',
      },
      UPDATE_FAILED: {
        TITLE: 'Quiz question item status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
      DELETE_SUCCESS: {
        TITLE: 'Quiz question item status',
        BODY: 'Quiz question item was deleted successfully!',
      },
      DELETE_FAILED: {
        TITLE: 'Quiz question item status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
    },
  },
  SPLASH_PHOTOS: {
    GET_LIST_FAILED: {
      TITLE: 'Splash photos list',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_LIST_FAILED: {
      TITLE: 'Splash photos list',
      BODY: 'Something went wrong! Please, try again!',
    },
    UPDATE_LIST_SUCCESS: {
      TITLE: 'Splash photos list',
      BODY: 'Splash photos list was updated successfully!',
    },
    GET_ITEM_FAILED: {
      TITLE: 'Splash photos item status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_ITEM_SUCCESS: {
      TITLE: 'Splash photos item status',
      BODY: 'Splash photos item was changed successfully!',
    },
    UPDATE_ITEM_FAILED: {
      TITLE: 'Splash photos item status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
  },
  FILE_UPLOADER: {
    LOADING_FAILED: {
      TITLE: 'File upload status',
      BODY: 'Something went wrong! Please, try again!',
    },
    LOADING_SUCCESS: {
      TITLE: 'File upload status',
      BODY: 'File was uploaded successfully!',
    },
    DELETING_FAILED: {
      TITLE: 'File deleting status',
      BODY: 'Something went wrong! Please, try again!',
    },
    DELETING_SUCCESS: {
      TITLE: 'File deleting status',
      BODY: 'File was deleted successfully!',
    },
  },
  DR_POOP_USER_DATA: {
    GET_LIST_FAILED: {
      TITLE: 'Dr. Poop user data list',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETING_FAILED: {
      TITLE: 'Dr. Poop user data deleting status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETING_SUCCESS: {
      TITLE: 'Dr. Poop user data deleting status',
      BODY: 'Dr. Poop item was deleted successfully!',
    },
    GET_ITEM_FAILED: {
      TITLE: 'Dr. Poop user data item status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_ITEM_STATUS_SUCCESS: {
      TITLE: 'Dr. Poop user data item status',
      BODY: 'Status was changed successfully!',
    },
    UPDATE_ITEM_STATUS_FAILED: {
      TITLE: 'Dr. Poop user data item status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
  },
  DR_POOP_STATEMENT: {
    GET_LIST_FAILED: {
      TITLE: 'Dr. Poop statement list',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETING_FAILED: {
      TITLE: 'Dr. Poop statement deleting status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETING_SUCCESS: {
      TITLE: 'Dr. Poop statement deleting status',
      BODY: 'Dr. Poop item was deleted successfully!',
    },
    GET_ITEM_FAILED: {
      TITLE: 'Dr. Poop statement item status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_ITEM_STATUS_SUCCESS: {
      TITLE: 'Dr. Poop statement item status',
      BODY: 'Status was changed successfully!',
    },
    UPDATE_ITEM_STATUS_FAILED: {
      TITLE: 'Dr. Poop statement item status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
  },
  REWARDS: {
    GET_LIST_FAILED: {
      TITLE: 'Rewards list',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETING_FAILED: {
      TITLE: 'Rewards deleting status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETING_SUCCESS: {
      TITLE: 'Rewards deleting status',
      BODY: 'Rewards item was deleted successfully!',
    },
    CREATING_SUCCESS: {
      TITLE: 'Rewards creating status',
      BODY: 'Rewards item was created successfully!',
    },
    CREATING_FAILED: {
      TITLE: 'Rewards creating status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    GET_ITEM_FAILED: {
      TITLE: 'Reward item status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_ITEM_SUCCESS: {
      TITLE: 'Reward item status',
      BODY: 'Reward item was changed successfully!',
    },
    UPDATE_ITEM_FAILED: {
      TITLE: 'Reward item status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    COINS_MODIFICATORS: {
      GET_LIST_FAILED: {
        TITLE: 'Coins modificators status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
      GET_ITEM_FAILED: {
        TITLE: 'Coins modificator item status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
      UPDATE_FAILED: {
        TITLE: 'Coins modificator item status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
      UPDATE_SUCCESS: {
        TITLE: 'Coins modificator item status',
        BODY: 'Reward item was changed successfully!',
      },
      CREATE_FAILED: {
        TITLE: 'Coins modificator item status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
      CREATE_SUCCESS: {
        TITLE: 'Coins modificator item status',
        BODY: 'Reward item was created successfully!',
      },
    },
  },
  SCORE: {
    WEIGHT_GROUP: {
      GET_LIST_FAILED: {
        TITLE: 'Scores weight group list',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
      DELETING_FAILED: {
        TITLE: 'Scores weight group item deleting status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
      DELETING_SUCCESS: {
        TITLE: 'Scores weight group item deleting status',
        BODY: 'Scores weight group item was deleted successfully!',
      },
      GET_ITEM_FAILED: {
        TITLE: 'Scores weight group item status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
      CREATING_SUCCESS: {
        TITLE: 'Scores weight group item creating status',
        BODY: 'Scores weight group item was created successfully!',
      },
      CREATING_FAILED: {
        TITLE: 'Scores weight group item creating status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
      UPDATE_ITEM_SUCCESS: {
        TITLE: 'Scores weight group item status',
        BODY: 'Scores weight group item was changed successfully!',
      },
      UPDATE_ITEM_FAILED: {
        TITLE: 'Scores weight group item status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
    },
    FITNESS: {
      GET_LIST_FAILED: {
        TITLE: 'Scores fitness list',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
      DELETING_FAILED: {
        TITLE: 'Scores fitness item deleting status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
      DELETING_SUCCESS: {
        TITLE: 'Scores fitness item deleting status',
        BODY: 'Scores fitness item was deleted successfully!',
      },
      GET_ITEM_FAILED: {
        TITLE: 'Scores fitness item status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
      CREATING_SUCCESS: {
        TITLE: 'Scores fitness item creating status',
        BODY: 'Scores fitness item was created successfully!',
      },
      CREATING_FAILED: {
        TITLE: 'Scores fitness item creating status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
      UPDATE_ITEM_SUCCESS: {
        TITLE: 'Scores fitness item status',
        BODY: 'Scores fitness item was changed successfully!',
      },
      UPDATE_ITEM_FAILED: {
        TITLE: 'Scores fitness item status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
    },
  },
  NOTIFICATIONS: {
    COUNTRIES_LIST_RECEIVED_FAILED: {
      TITLE: 'Notifications status',
      BODY: 'Can`t receive countries list! Please, try to refresh this page!',
    },
    SENT_ERROR: {
      TITLE: 'Notifications status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    SENT_SUCCESS: {
      TITLE: 'Notifications status',
      BODY: 'Push notification was successfully sent!',
    },
    UPDATE_CONFIG_ERROR: {
      TITLE: 'Notifications settings status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_CONFIG_SUCCESS: {
      TITLE: 'Notifications settings status',
      BODY: 'Notification settings was successfully updated!',
    },
  },
  SUBSCRIPTION: {
    REASONS: {
      CREATE_FAILED: {
        TITLE: 'Creating cancel reason status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
      DELETE_FAILED: {
        TITLE: 'Deleting cancel reason status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
      GET_BY_ID_FAILED: {
        TITLE: 'Getting reason status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
      UPDATE_FAILED: {
        TITLE: 'Updating reason status',
        BODY: 'Something went wrong! Please, try to refresh this page!',
      },
    },
  },
  SERVICE_TYPE: {
    CREATE_FAILED: {
      TITLE: 'Creating service type status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETE_FAILED: {
      TITLE: 'Deleting service type status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    GET_BY_ID_FAILED: {
      TITLE: 'Getting service type status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_FAILED: {
      TITLE: 'Updating service type status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
  },
  ADDITIONA_FEE_TEMPLATE: {
    CREATE_FAILED: {
      TITLE: 'Creating fee template status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETE_FAILED: {
      TITLE: 'Deleting fee template status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    GET_BY_ID_FAILED: {
      TITLE: 'Getting fee template status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_FAILED: {
      TITLE: 'Updating fee template status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
  },
  ADDITIONAL_SERVICE: {
    CREATE_FAILED: {
      TITLE: 'Creating additional service status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETE_FAILED: {
      TITLE: 'Deleting additional service status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    GET_BY_ID_FAILED: {
      TITLE: 'Getting additional service status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_FAILED: {
      TITLE: 'Updating additional service status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
  },
  DELETE_DOG_OPTIONS: {
    CREATE_FAILED: {
      TITLE: 'Creating delete dog options status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETE_FAILED: {
      TITLE: 'Deleting delete dog options status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    GET_BY_ID_FAILED: {
      TITLE: 'Getting delete dog options status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_FAILED: {
      TITLE: 'Updating delete dog options status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
  },
  PARTNER_TYPE: {
    CREATE_FAILED: {
      TITLE: 'Creating partner type status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETE_FAILED: {
      TITLE: 'Deleting partner type status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    GET_BY_ID_FAILED: {
      TITLE: 'Getting partner type status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_FAILED: {
      TITLE: 'Updating partner type status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
  },
  SERVICE_PARTNER: {
    CREATE_FAILED: {
      TITLE: 'Creating partner program status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETE_FAILED: {
      TITLE: 'Deleting partner program status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    GET_BY_ID_FAILED: {
      TITLE: 'Getting partner program status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_FAILED: {
      TITLE: 'Updating partner program status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
  },
  PARTNER_PROGRAM: {
    CREATE_FAILED: {
      TITLE: 'Creating partner program status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETE_FAILED: {
      TITLE: 'Deleting partner program status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    GET_BY_ID_FAILED: {
      TITLE: 'Getting partner program status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_FAILED: {
      TITLE: 'Updating partner program status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
  },
  PARTNER_WORKER: {
    CREATE_FAILED: {
      TITLE: 'Creating partner program status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    CREATE_SUCCESS: {
      TITLE: 'Worker status',
      BODY: 'Worker was successfully created!',
    },
    DELETE_FAILED: {
      TITLE: 'Deleting partner program status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    GET_BY_ID_FAILED: {
      TITLE: 'Getting partner program status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_FAILED: {
      TITLE: 'Updating partner program status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
  },
  CONTRACTS: {
    CREATE_FAILED: {
      TITLE: 'Creating contracts status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETE_FAILED: {
      TITLE: 'Deleting contracts status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    GET_BY_ID_FAILED: {
      TITLE: 'Getting contracts status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_FAILED: {
      TITLE: 'Updating contracts status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
  },
  WORKER_TYPE: {
    CREATE_FAILED: {
      TITLE: 'Creating worker type status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETE_FAILED: {
      TITLE: 'Deleting worker type status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    GET_BY_ID_FAILED: {
      TITLE: 'Getting worker type status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_FAILED: {
      TITLE: 'Updating worker type status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
  },
  RECOMENDATION: {
    CREATE_FAILED: {
      TITLE: 'Creating recommendation type status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETE_FAILED: {
      TITLE: 'Deleting recommendation type status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_FAILED: {
      TITLE: 'Editing recommendation type status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    CREATE_SUCCESS: {
      TITLE: 'Creating recommendation type status',
      BODY: 'Recommendation was successfully created',
    },
    DELETE_SUCCESS: {
      TITLE: 'Deleting recommendation type status',
      BODY: 'Recommendation was successfully deleted',
    },
    UPDATE_SUCCESS: {
      TITLE: 'Editing recommendation type status',
      BODY: 'Recommendation was successfully updated',
    },
  },
  BILLING: {
    CREATE_FAILED: {
      TITLE: 'Creating billing status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    CREATE_SUCCESS: {
      TITLE: 'Creating billing status',
      BODY: 'Billing was successfully created',
    },
  },
  REVIEWS: {
    UPDATE_FAILED: {
      TITLE: 'Updating review status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_SUCCESS: {
      TITLE: 'Updating review status',
      BODY: 'Review was successfully updated',
    },
  },
  DISCOUNT_REASONS: {
    GET_FAILED: {
      TITLE: 'Getting discount reason status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    CREATE_FAILED: {
      TITLE: 'Creating discount reason status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    CREATE_SUCCESS: {
      TITLE: 'Creating discount reason status',
      BODY: 'Discount reason was successfully created',
    },
    UPDATE_FAILED: {
      TITLE: 'Updating discount reason status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_SUCCESS: {
      TITLE: 'Updating discount reason status',
      BODY: 'Discount reason was successfully updated',
    },
    DELETE_FAILED: {
      TITLE: 'Deleting discount reason status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETE_SUCCESS: {
      TITLE: 'Deleting discount reason status',
      BODY: 'Discount reason was successfully deleted',
    },
  },
  COUNTRIES: {
    GET_FAILED: {
      TITLE: 'Getting countries status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    GET_BY_ID_FAILED: {
      TITLE: 'Getting country status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    CREATE_FAILED: {
      TITLE: 'Creating country status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    CREATE_SUCCESS: {
      TITLE: 'Creating country status',
      BODY: 'Country was successfully created',
    },
    UPDATE_FAILED: {
      TITLE: 'Updating country status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_SUCCESS: {
      TITLE: 'Updating country status',
      BODY: 'Country was successfully updated',
    },
    DELETE_FAILED: {
      TITLE: 'Deleting country status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETE_SUCCESS: {
      TITLE: 'Deleting country status',
      BODY: 'Country was successfully deleted',
    },
  },
  CITIES: {
    GET_FAILED: {
      TITLE: 'Getting cities status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    GET_BY_ID_FAILED: {
      TITLE: 'Getting city status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    CREATE_FAILED: {
      TITLE: 'Creating city status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    CREATE_SUCCESS: {
      TITLE: 'Creating city status',
      BODY: 'Country was successfully created',
    },
    UPDATE_FAILED: {
      TITLE: 'Updating city status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_SUCCESS: {
      TITLE: 'Updating city status',
      BODY: 'Country was successfully updated',
    },
    DELETE_FAILED: {
      TITLE: 'Deleting city status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETE_SUCCESS: {
      TITLE: 'Deleting city status',
      BODY: 'Country was successfully deleted',
    },
  },

  BREEDS: {
    GET_FAILED: {
      TITLE: 'Getting breeds status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    CREATE_FAILED: {
      TITLE: 'Creating breed status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    CREATE_SUCCESS: {
      TITLE: 'Creating breed status',
      BODY: 'Breed was successfully created',
    },
    UPDATE_FAILED: {
      TITLE: 'Updating breed status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_SUCCESS: {
      TITLE: 'Updating breed status',
      BODY: 'Breed was successfully updated',
    },
    DELETE_FAILED: {
      TITLE: 'Deleting breed status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETE_SUCCESS: {
      TITLE: 'Deleting breed status',
      BODY: 'Breed was successfully deleted',
    },
  },
  FOOD_PRICES_MODIFICATORS: {
    GET_FAILED: {
      TITLE: 'Getting food prices modificators status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    CREATE_FAILED: {
      TITLE: 'Creating food prices modificator status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    CREATE_SUCCESS: {
      TITLE: 'Creating food prices modificator status',
      BODY: 'Food prices modificator was successfully created',
    },
    UPDATE_FAILED: {
      TITLE: 'Updating food prices modificator status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_SUCCESS: {
      TITLE: 'Updating food prices modificator status',
      BODY: 'Food prices modificator was successfully updated',
    },
    DELETE_FAILED: {
      TITLE: 'Deleting food prices modificator status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    DELETE_SUCCESS: {
      TITLE: 'Deleting food prices modificator status',
      BODY: 'Food prices modificator was successfully deleted',
    },
  },
  SMS_CONFIGS: {
    GET_FAILED: {
      TITLE: 'Getting SMS configs list status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_FAILED: {
      TITLE: 'Updating SMS config status',
      BODY: 'Something went wrong! Please, try to refresh this page!',
    },
    UPDATE_SUCCESS: {
      TITLE: 'Updating SMS config status',
      BODY: 'SMS config was successfully updated',
    },
  },
};

export default MESSAGES_FOR_USER;
