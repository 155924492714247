export const setCurrentEnv = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'qa':
      return 'https://devdogiz2.com/api/v1';
    case 'dev':
      return 'https://backend-dev.dogiz.lampawork.com/api/v1';
    case 'beta':
      return 'https://backend-beta.devdogiz2.com/api/v1';

    default:
      return 'https://backend.appdogiz.com/api/v1';
  }
};
