import { additionalServiceTypes } from './additional-service.types';

export const getAdditionalServiceListSuccess = list => ({
  type: additionalServiceTypes.GET_ADDITIONAL_SERVICE_LIST_SUCCESS,
  payload: list,
});

export const getAdditionalServiceListLoading = () => ({
  type: additionalServiceTypes.GET_ADDITIONAL_SERVICE_LIST_LOADING,
});

export const getAdditionalServiceByIdSuccess = data => ({
  type: additionalServiceTypes.GET_ADDITIONAL_SERVICE_BY_ID_SUCCESS,
  payload: data,
});
export const getAdditionalServiceByIdLoading = () => ({
  type: additionalServiceTypes.GET_ADDITIONAL_SERVICE_BY_ID_LOADING,
});

export const deleteAdditionalServiceSuccess = id => ({
  type: additionalServiceTypes.DELETE_ADDITIONAL_SERVICE_SUCCESS,
  payload: id,
});

export const additionalServiceClearItem = () => ({
  type: additionalServiceTypes.CLEAR_ADDITIONAL_SERVICE_ITEM,
});
