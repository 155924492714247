import { Col, Table, Button, Alert, Row } from 'react-bootstrap';
import CustomSpinner from '../../../component/spinner';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getSection } from '../../../service/quiz/section';

import { changeConfirmStatus } from '../../../redux/confirm/confirm.action';
import { confirmationKeys } from '../../../constant';

const SectionList = () => {
  const dispatch = useDispatch();
  const {
    list = [],
    loading,
    error,
  } = useSelector(state => state.quiz.section);

  useEffect(() => {
    getSection()(dispatch);
  }, [dispatch]);

  const handleDeclineButton = (id, content) => {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        content,
        type: confirmationKeys.sectionTableDeleteItem,
        actionParams: [id],
      }),
    );
  };

  return (
    <Col sm={12} className="p-0 mt-3">
      {error && <Alert variant={'danger'}>{error.message}</Alert>}
      <Link to="create">
        <Button className="mb-3" variant="primary">
          Create section
        </Button>
      </Link>
      {loading || !list ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <Table bordered size="md" responsive="md">
          <thead>
            <tr>
              <th>Title (EN)</th>
              <th>Title (IL)</th>
              <th>Create Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {list.map(item => {
              return (
                <tr key={`section-${item._id}`}>
                  <td>{item.title_localizations?.en || item.title || '-'}</td>
                  <td>{item.title_localizations?.il || '-'}</td>
                  <td>{new Date(item.createdAt).toLocaleString()}</td>
                  <td>
                    <ul className="list-inline m-0 p-0">
                      <li className="list-inline-item">
                        <Link to={item._id}>
                          <Button size={'sm'} variant="primary">
                            <i className="fa fa-eye"></i>
                          </Button>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link to={`${item._id}/edit`}>
                          <Button size={'sm'} variant="success">
                            <i className="fa fa-pencil"></i>
                          </Button>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Button
                          size={'sm'}
                          variant="danger"
                          onClick={() =>
                            handleDeclineButton(item._id, item.title)
                          }
                        >
                          <i className="fa fa-trash-o"></i>
                        </Button>
                      </li>
                    </ul>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </Col>
  );
};

export default SectionList;
