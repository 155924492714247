import { logOut } from '../redux/auth/auth.action';
import { authTypes } from '../redux/auth/auth.types';
import store from '../redux/store';

export class CustomFetch {
  constructor(baseURL = '', headers) {
    this.baseURL = baseURL;
    this.headers = headers;
  }

  isTokenRefreshing = false;
  failedChain = [];

  processChain = (error, token = null) => {
    this.failedChain.forEach(promise => {
      if (error) {
        promise.reject(error);
      } else {
        promise.resolve(token);
      }
    });

    this.failedChain = [];
  };

  request = async (
    { path = '', method = 'GET', body = null, reqHeaders = '' },
    formadata = false,
  ) => {
    let headers = {
      ...this.headers,
      ...reqHeaders,
    };

    if (!formadata) headers['Content-Type'] = 'application/json';

    let authData = store.getState().auth.authSession || false;

    if (authData) {
      headers['Authorization'] = 'Bearer ' + authData.accessToken;
    }

    let response = await fetch(this.baseURL + path, {
      method,
      headers,
      body,
    });

    if (response.ok) {
      return response;
    } else {
      const originalConfig = { path, method, body, formadata, headers };

      if (response.status === 401 && !originalConfig.retry) {
        if (this.isTokenRefreshing) {
          return new Promise((resolve, reject) => {
            this.failedChain.push({ resolve, reject });
          })
            .then(token => {
              originalConfig.headers['Authorization'] = 'Bearer ' + token;
              return this.request(
                {
                  path: originalConfig.path,
                  method: originalConfig.method,
                  reqHeaders: originalConfig.headers,
                  body: originalConfig.body,
                },
                originalConfig.formadata,
              );
            })
            .catch(err => {
              return Promise.reject(err);
            });
        }

        originalConfig.retry = true;
        this.isTokenRefreshing = true;

        const refreshToken = store.getState().auth.authSession.refreshToken;

        return new Promise(async (resolve, reject) => {
          originalConfig.headers['Content-Type'] = 'application/json';
          originalConfig.headers['accept'] =
            'application/x-www-form-urlencoded';
          originalConfig.headers['deviceId'] = '123456789';
          fetch(this.baseURL + '/auth/update-token', {
            method: 'POST',
            headers: originalConfig.headers,
            body: JSON.stringify({ refreshToken }),
          })
            .then(async result => {
              if (result.status === 200) {
                const data = await result.json();
                store.dispatch({
                  type: authTypes.REFRESH_TOKEN_SUCCESS,
                  payload: data,
                });
                originalConfig.headers['Authorization'] = data.auth.accessToken;
                this.processChain(null, data.auth.accessToken);

                resolve(
                  this.request(
                    {
                      path: originalConfig.path,
                      method: originalConfig.method,
                      body: originalConfig.body || null,
                      headers: originalConfig.headers,
                    },
                    originalConfig.formadata,
                  ),
                );
              } else {
                throw response.status;
              }
            })
            .catch(err => {
              this.request({ method: 'POST', path: '/auth/logout' }).finally(
                () => {
                  store.dispatch(logOut());
                },
              );
              this.processChain(err, null);
              reject(err);
            })
            .finally(() => {
              // Here setTimeout is needed because the token can be updated
              // before we get the response to the last failed request and the
              // token will be updated again
              setTimeout(() => {
                this.isTokenRefreshing = false;
              }, 2000);
            });
        });
      }
    }
    return response;
  };
}
