import { Col, Row } from 'react-bootstrap';

import GoBack from '../../../component/buttons/goBackButton';
import CancelReasonForm from '../../../component/cancelReasonForm/cancel-reason-form';

import './styles.css';

const ReasonCreate = () => {
  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      <CancelReasonForm />
    </Col>
  );
};

export default ReasonCreate;
