export const servicePartnerTypes = {
  CREATE_SERVICE_PARTNER_FAIL: 'service_partner/CREATE_SERVICE_PARTNER_FAIL',

  GET_SERVICE_PARTNER_LIST_SUCCESS:
    'service_partner/GET_SERVICE_PARTNER_LIST_SUCCESS',
  GET_SERVICE_PARTNER_LIST_LOADING:
    'service_partner/GET_SERVICE_PARTNER_LIST_LOADING',
  GET_SERVICE_PARTNER_LIST_FAIL:
    'service_partner/GET_SERVICE_PARTNER_LIST_FAIL',

  GET_SERVICE_PARTNER_BY_ID_SUCCESS:
    'service_partner/GET_SERVICE_PARTNER_BY_ID_SUCCESS',
  GET_SERVICE_PARTNER_BY_ID_FAIL:
    'service_partner/GET_SERVICE_PARTNER_BY_ID_FAIL',

  DELETE_SERVICE_PARTNER_SUCCESS:
    'service_partner/DELETE_SERVICE_PARTNER_SUCCESS',
  DELETE_SERVICE_PARTNER_FAIL: 'service_partner/DELETE_SERVICE_PARTNER_FAIL',

  CLEAR_ITEM: 'service_partner/CLEAR_ITEM',
  UPDATE_SERVICE_PARTNER_SUCCESS:
    'service_partner/UPDATE_SERVICE_PARTNER_SUCCESS',
  UPDATE_SERVICE_PARTNER_FAIL: 'service_partner/UPDATE_SERVICE_PARTNER_FAIL',
};
