import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import GoBack from '../../../component/buttons/goBackButton';
import { getPartnerType } from '../../../service/partners';
import { useDispatch, useSelector } from 'react-redux';
import {
  partnerTypeSelector,
  partnerProgramSelector,
  servicePartnerSelector,
  currencySelector,
} from '../../../redux/selectors/selectors';
import CustomSpinner from '../../../component/spinner';
import { getPartnerProgramById } from '../../../service/partners/partnerProgram';
import { getServicePartner } from '../../../service/services/servicePartner';
import { getCurrency } from '../../../service/currency';
import { getNormalizedEntities } from '../../../helpers/getNormalizedEntities';
import PartnerProgramForm from '../../../component/partnerProgramForm/partnerProgramForm';
import { clearPartnerProgramItemState } from '../../../redux/partnerProgram/partnerProgram.action';

const PartnerProgramEdit = props => {
  const { id } = props.match.params;

  const dispatch = useDispatch();

  const { item, loading } = useSelector(partnerProgramSelector);
  const { list: servicePartnerList, loading: servicePartnerLoading } =
    useSelector(servicePartnerSelector);
  const { list: partnerTypeList, loading: partnerTypeListLoading } =
    useSelector(partnerTypeSelector);

  const { list: currencyList, loading: currencyListLoading } =
    useSelector(currencySelector);

  useEffect(() => {
    if (!servicePartnerList) getServicePartner()(dispatch);
  }, [dispatch, servicePartnerList]);

  useEffect(() => {
    if (!partnerTypeList) getPartnerType()(dispatch);
  }, [dispatch, partnerTypeList]);

  useEffect(() => {
    if (!currencyList) getCurrency()(dispatch);
  }, [dispatch, currencyList]);

  const [normalizedPartnerTypes, partnerTypeIds] = getNormalizedEntities(
    partnerTypeList,
    ['title', 'title_localizations'],
  );

  const [normalizedServicePartners, servicePartnerIds] = getNormalizedEntities(
    servicePartnerList,
    ['service'],
  );

  const [normalizedCurrency, currencyIds] = getNormalizedEntities(
    currencyList,
    ['_id', 'name', 'code'],
  );

  useEffect(() => {
    getPartnerProgramById(id)(dispatch);
    return () => dispatch(clearPartnerProgramItemState());
  }, [dispatch, id]);

  const itemServiceIds = item && item.services.map(service => service._id);

  return (
    <Col sm={9} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>

      {loading ||
      !item ||
      servicePartnerLoading ||
      !servicePartnerList ||
      currencyListLoading ||
      !currencyList ||
      partnerTypeListLoading ||
      !partnerTypeList ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <PartnerProgramForm
          normalizedServicePartners={normalizedServicePartners}
          partnerTypeIds={partnerTypeIds}
          servicePartnerIds={servicePartnerIds}
          normalizedPartnerTypes={normalizedPartnerTypes}
          normalizedCurrency={normalizedCurrency}
          currencyIds={currencyIds}
          id={id}
          countryName={
            item?.location?.country?.name_localizations?.en ||
            item?.location?.country?.name
          }
          cityName={
            item?.location?.city?.name_localizations?.en ||
            item?.location?.city?.name
          }
          defaultValues={{
            location: {
              country: item?.location?.country?._id,
              city: item?.location?.city?._id,
            },
            contractType: 'testContractType',
            partnerType: item.partnerType._id,
            services: servicePartnerIds.map(
              servicePartnerId =>
                itemServiceIds.includes(servicePartnerId) && servicePartnerId,
            ),
            currency: item.currency._id,
          }}
          type="EDIT"
        />
      )}
    </Col>
  );
};

export default PartnerProgramEdit;
