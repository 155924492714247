export const reasonTypes = {
  CREATE_REASON_FAIL: 'reason/CREATE_REASON_FAIL',

  GET_REASON_LIST_SUCCESS: 'reason/GET_REASON_LIST_SUCCESS',
  GET_REASON_LIST_LOADING: 'reason/GET_REASON_LIST_LOADING',
  GET_REASON_LIST_FAIL: 'reason/GET_REASON_LIST_FAIL',

  GET_REASON_BY_ID_SUCCESS: 'reason/GET_REASON_BY_ID_SUCCESS',
  GET_REASON_BY_ID_FAIL: 'reason/GET_REASON_BY_ID_FAIL',

  DELETE_REASON_SUCCESS: 'reason/DELETE_REASON_SUCCESS',
  DELETE_REASON_FAIL: 'reason/DELETE_REASON_FAIL',

  CLEAR_ITEM: 'reason/CLEAR_ITEM',
  UPDATE_REASON_SUCCESS: 'reason/UPDATE_REASON_SUCCESS',
  UPDATE_REASON_FAIL: 'reason/UPDATE_REASON_FAIL',
};
