export const authTypes = {
  SET_PHONE_SUCCESS: 'auth/SET_PHONE_SUCCESS',
  SET_PHONE_FAIL: 'auth/SET_PHONE_FAIL',
  CHECK_CODE_SUCCESS: 'auth/CHECK_CODE_SUCCESS',
  CHECK_CODE_FAIL: 'auth/CHECK_CODE_FAIL',
  CHECK_ROLE_FAIL: 'auth/CHECK_ROLE_FAIL',

  REFRESH_TOKEN_LOADING: 'auth/REFRESH_TOKEN_LOADING',
  REFRESH_TOKEN_SUCCESS: 'auth/REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAIL: 'auth/REFRESH_TOKEN_FAIL',

  SET_LOGOUT: 'auth/SET_LOGOUT',
};
