import { servicePartnerTypes } from './servicePartner.types';

const initialSate = {
  loading: false,
  error: false,
  list: null,
  item: false,
};

const servicePartner = (state = initialSate, { type, payload }) => {
  switch (type) {
    case servicePartnerTypes.GET_SERVICE_PARTNER_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case servicePartnerTypes.GET_SERVICE_PARTNER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload,
      };
    case servicePartnerTypes.GET_SERVICE_PARTNER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };
    case servicePartnerTypes.DELETE_SERVICE_PARTNER_SUCCESS:
      const newList = state.list.filter(item => item._id !== payload);
      return {
        ...state,
        list: newList,
        loading: false,
      };
    case servicePartnerTypes.CLEAR_ITEM:
      return {
        ...state,
        item: false,
      };
    case servicePartnerTypes.UPDATE_SERVICE_PARTNER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case servicePartnerTypes.GET_SERVICE_PARTNER_LIST_FAIL:
    case servicePartnerTypes.CREATE_SERVICE_PARTNER_FAIL:
    case servicePartnerTypes.DELETE_SERVICE_PARTNER_FAIL:
    case servicePartnerTypes.GET_SERVICE_PARTNER_BY_ID_FAIL:
    case servicePartnerTypes.UPDATE_SERVICE_PARTNER_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default servicePartner;
