import { publicationTypes } from './publication.types';

const initialState = {
  loading: false,
  error: false,
  list: [],
  item: {},
};

const publication = (state = initialState, { type, payload }) => {
  switch (type) {
    case publicationTypes.GET_PUBLICATION_LIST_LOADING:
    case publicationTypes.GET_PUBLICATION_ITEM_BY_ID_LOADING:
    case publicationTypes.POST_PUBLICATION_LOADING:
    case publicationTypes.PUT_PUBLICATION_ITEM_BY_ID_LOADING:
    case publicationTypes.DELETE_PUBLICATION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case publicationTypes.GET_PUBLICATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        list: payload,
      };
    case publicationTypes.DELETE_PUBLICATION_SUCCESS:
      const newListOfPublication = state.list.filter(
        item => item._id !== payload,
      );
      return {
        ...state,
        error: false,
        loading: false,
        list: newListOfPublication,
      };
    case publicationTypes.GET_PUBLICATION_ITEM_BY_ID_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        item: payload,
      };

    case publicationTypes.CLEAR_PUBLICATION_ITEM:
      return {
        ...state,
        item: {},
      };
    default:
      return state;
  }
};

export default publication;
