const generateCsvFile = csvString => {
  const link = document.createElement('a');
  link.download = `report.csv`;

  const blob = new Blob([csvString], {
    type: 'data:text/csv;charset=utf-8,',
  });

  link.href = window.URL.createObjectURL(blob);
  link.click();
};

const convertArrayToCSV = (headersArray, dataArray) => {
  if (!dataArray || dataArray?.length === 0) {
    throw new Error('Nothing found...');
  }

  const csvString = [
    [...headersArray],
    ...dataArray.map(item => [
      item._id || '-',
      item.userId || '-',
      item.dog_owner_id || '-',
      item.dogId ? item.dogId._id : '-',
      item.senderType || '-',
      item.ai_grade ? item.ai_grade.toFixed(2) : 'NULL',
      item.ai_status || '-',
      item.dogiz_grade || '-',
      item.status || '-',
      new Date(item.createDate).toLocaleString().replace(',', ' '),
    ]),
  ]
    .map(e => e.join(','))
    .join('\n');

  return csvString;
};

export { convertArrayToCSV, generateCsvFile };
