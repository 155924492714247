import PartnerProgramList from './list';
import PartnerProgramCreate from './create';
import PartnerProgramEdit from './edit';

const PartnerProgram = {
  list: PartnerProgramList,
  create: PartnerProgramCreate,
  edit: PartnerProgramEdit,
};

export default PartnerProgram;
