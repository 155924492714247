import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import Input from '../inputs';

import { validationRules } from './validation';
import {
  createScoresWeightGroupItem,
  updateScoresWeightGroupItem,
} from '../../service/score';

const WeightGroupForm = ({ defaultValues = {}, itemPageId }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { 
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const onSubmit = values => {
    if (itemPageId) {
      updateScoresWeightGroupItem(
        values,
        itemPageId,
      )(dispatch).then(() => history.goBack());
    } else {
      createScoresWeightGroupItem(values)(dispatch).then(() =>
        history.goBack(),
      );
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-100"
      id="weight-group-form"
    >
      <Row className="pl-3 pr-3 pt-3">
        <Input.text
          register={register}
          label="Name"
          name="name"
          validationRules={validationRules.name}
          error={errors.name}
        />
      </Row>
      <Row className="d-flex">
        <Col>
          <Input.number
            register={register}
            label="Min"
            name="min"
            validationRules={validationRules.min}
            error={errors.min}
          />
        </Col>
        <Col>
          <Input.number
            register={register}
            label="Max"
            name="max"
            validationRules={validationRules.max}
            error={errors.max}
          />
        </Col>
      </Row>
      <Row className="mt-5 ml-0 mr-0">
        <Button className="w-100 p-3" type="submit">
          Save
        </Button>
      </Row>
    </form>
  );
};

export default WeightGroupForm;
