import { splashPhotosTypes } from './splash-photos.types';

export const getSplashPhotosListSuccess = list => ({
  type: splashPhotosTypes.GET_SPLASH_PHOTOS_LIST_SUCCESS,
  payload: list,
});

export const getSplashPhotosListFail = () => ({
  type: splashPhotosTypes.GET_SPLASH_PHOTOS_LIST_FAIL,
});

export const setOrderInSplashPhotosList = list => ({
  type: splashPhotosTypes.SET_ORDER_IN_SPLASH_PHOTOS_LIST,
  payload: list,
});

export const deleteItemFromSplashPhotosList = id => ({
  type: splashPhotosTypes.DELETE_SPLASH_PHOTOS_LIST_ITEM,
  payload: id,
});

export const addItemToSplashPhotosListSuccess = photo => ({
  type: splashPhotosTypes.ADD_ITEM_TO_SPLASH_PHOTOS_LIST_SUCCESS,
  payload: photo,
});

export const addItemToSplashPhotosListRequested = () => ({
  type: splashPhotosTypes.ADD_ITEM_TO_SPLASH_PHOTOS_LIST_REQUESTED,
});

export const changeSplashPhotosListItemVisibilityStatus = id => ({
  type: splashPhotosTypes.CHANGE_SPLASH_PHOTOS_LIST_ITEM_VISIBILITY_STATUS,
  payload: id,
});

export const setIsListHasAnyChangesStatus = status => ({
  type: splashPhotosTypes.SET_IS_LIST_HAS_ANY_CHANGES_STATUS,
  payload: status,
});

export const getSplashPhotosItem = () => ({
  type: splashPhotosTypes.SPLASH_PHOTOS_ITEM_REQUESTED,
});

export const getSplashPhotosItemSuccess = splashPhotosItem => ({
  type: splashPhotosTypes.SPLASH_PHOTOS_ITEM_SUCCESS,
  payload: splashPhotosItem,
});

export const getSplashPhotosItemFail = () => ({
  type: splashPhotosTypes.SPLASH_PHOTOS_ITEM_FAILED,
});
