import React from 'react';
import { Alert, Row, Button, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { updateRewardActionItem } from '../../service/rewards';

import Input from '../inputs';

import { validationRules } from './validation';

const RewardsActionForm = ({ defaultValues = {}, itemPageId }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const onSubmit = values => {
    updateRewardActionItem(
      values,
      itemPageId,
    )(dispatch).then(() => history.goBack());
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-100"
      id="rewards-action-form"
    >
      <Row className="pl-3 pr-3 pt-3 d-flex justify-content-center">
        <Col sm={3}>
          <Alert variant="primary" className="text-center font-weight-bold">
            {defaultValues?.name}
          </Alert>
        </Col>
      </Row>
      <Row className="pl-3 pr-3 pt-3">
        <Input.number
          register={register}
          label="Reward"
          name="reward"
          validationRules={validationRules.reward}
          error={errors.reward}
        />
      </Row>
      <Row className="pl-3 pr-3 pt-3">
        <Input.checkbox
          register={register}
          label="Push notification"
          name="pushNotification"
        />
      </Row>

      <Row className="mt-5 ml-0 mr-0">
        <Button className="w-100 p-3" type="submit">
          Save
        </Button>
      </Row>
    </form>
  );
};

export default RewardsActionForm;
