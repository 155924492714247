import { deleteDogOptionsTypes } from './delete-dog-options.types';

const initialSate = {
  loading: false,
  error: false,
  list: null,
  item: false,
};

const deleteDogOptions = (state = initialSate, { type, payload }) => {
  switch (type) {
    case deleteDogOptionsTypes.GET_DELETE_DOG_OPTIONS_BY_ID_LOADING:
    case deleteDogOptionsTypes.GET_DELETE_DOG_OPTIONS_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case deleteDogOptionsTypes.GET_DELETE_DOG_OPTIONS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload,
      };
    case deleteDogOptionsTypes.GET_DELETE_DOG_OPTIONS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };
    case deleteDogOptionsTypes.DELETE_DOG_OPTIONS_SUCCESS:
      const newList = state.list.filter(item => item._id !== payload);
      return {
        ...state,
        list: newList,
        loading: false,
      };
    case deleteDogOptionsTypes.CLEAR_ITEM:
      return {
        ...state,
        item: false,
      };

    default:
      return state;
  }
};

export default deleteDogOptions;
