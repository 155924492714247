import PartnerTypeList from './list';
import PartnerTypeCreate from './create';
import PartnerTypeEdit from './edit';

const PartnerType = {
  list: PartnerTypeList,
  create: PartnerTypeCreate,
  edit: PartnerTypeEdit,
};

export default PartnerType;
