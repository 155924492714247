import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import GoBack from '../../component/buttons/goBackButton';
import RewardsForm from '../../component/rewardsForm/RewardsForm';
import CustomSpinner from '../../component/spinner';

import { getRewardItemByID } from '../../service/rewards';
import { rewardsSelector } from '../../redux/selectors/selectors';
import {
  clearSelectedItemState,
  setRewardsLoader,
} from '../../redux/rewards/rewards.action';
import { isItemPage } from '../../helpers/isItemPage';
import { useParams } from 'react-router';

const RewardsDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { selectedItem, loading } = useSelector(rewardsSelector);

  useEffect(() => {
    if (isItemPage(id)) {
      dispatch(getRewardItemByID(id));
    } else {
      dispatch(setRewardsLoader(false));
    }

    return () => dispatch(clearSelectedItemState());
  }, [dispatch, id]);

  return (
    <Col sm={12} className="mt-3">
      <Row className="d-flex">
        <GoBack />
      </Row>
      <Row className="mt-3 d-flex align-items-start justify-content-center">
        {loading ? (
          <CustomSpinner />
        ) : (
          <RewardsForm
            defaultValues={
              selectedItem && {
                title_localizations: {
                  en:
                    selectedItem.title_localizations?.en ||
                    selectedItem.title ||
                    '',
                  il: selectedItem.title_localizations?.il || '',
                },
                desc_localizations: {
                  en:
                    selectedItem.desc_localizations?.en ||
                    selectedItem.desc ||
                    '',
                  il: selectedItem.desc_localizations?.il || '',
                },
                phase: selectedItem.phase,
                condition: selectedItem.condition,
                pushNotification: selectedItem.pushNotification,
                reward: selectedItem.reward,
              }
            }
            itemPageId={isItemPage(id)}
          />
        )}
      </Row>
    </Col>
  );
};

export default RewardsDetails;
