import AdditionalServiceCreate from './create';
import AdditionalServiceEdit from './edit';
import AdditionalServiceList from './list';

const AdditionalService = {
  list: AdditionalServiceList,
  create: AdditionalServiceCreate,
  edit: AdditionalServiceEdit,
};

export default AdditionalService;
