import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { TOAST_MESSAGE_TYPES } from '../../../../component/toast-message/config';
import { convertArrayToCSV, generateCsvFile } from '../../../../helpers/csv';
import { showToastMessage } from '../../../../redux/toast-message/toast-message.action';
import fetchWithAuth from '../../../../service';

const today = new Date().toISOString().split('T')[0];

const defaultValues = {
  dateBefore: today,
  dateAfter: today,
};

const headersArray = [
  'SUBMISSION ID',
  'SENDER ID',
  'DOG OWNER ID',
  'DOG ID',
  'SENDER TYPE',
  'AI STATUS',
  'AI GRADE',
  'DOGIZ GRADE',
  'DOGIZ STATUS',
  'CREATION DATE',
];

export const useExportCSV = closeModal => {
  const dispatch = useDispatch();
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const { handleSubmit, register, reset, watch } = useForm({
    defaultValues,
  });

  const onSubmit = async data => {
    try {
      const dateAfterParam = `&dateAfter=${data.dateAfter}`;
      const dateBeforeParam = `&dateBefore=${data.dateBefore}`;
      const sendResultWithoutPagination = '&sendResultWithoutPagination=true';

      const url =
        `/super-admin/dr-poop?` +
        dateAfterParam +
        dateBeforeParam +
        sendResultWithoutPagination;

      setIsCsvLoading(true);

      const response = await fetchWithAuth({
        path: url,
        method: 'GET',
      });
      const result = await response.json();
      const csvString = convertArrayToCSV(headersArray, result.items);

      generateCsvFile(csvString);

      setIsCsvLoading(false);

      reset({ defaultValues });

      closeModal && closeModal();
    } catch (error) {
      setIsCsvLoading(false);
      dispatch(
        showToastMessage({
          title: 'Dowloading CSV',
          content: error.message,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );
    }
  };

  return {
    handleSubmit,
    register,
    onSubmit,
    minDate: watch('dateAfter'),
    isCsvLoading,
  };
};
