export const CONTENT_FOR_TOOLTIPS = {
  DISTANCE_WALK: {
    KEY: 'DISTANCE_WALK',
    VALUE:
      'Specify the average daily value in km that the user should walk with the dog',
    EQUAL:
      'The user`s score will change by this value if he/she has equal average walking distance per day than indicated in the "value" field. (You can set "3" or "-3" for example',
    MORE: 'The user`s score will change by this value if he/she has more average walking distance per day than indicated in the "value" field (You can set "3" for example) ',
    SMALLER:
      'The user`s score will change by this value if he/she has less average walking distance per day than indicated in the "value" field. (You can set  "-3" for example) ',
  },
  COUNT_WALK: {
    KEY: 'COUNT_WALK',
    VALUE:
      'Specify the daily value of the number of walks that the user should walk with the dog',
    EQUAL:
      'The user`s score will change by this value if he/she has equal quantity of walks per day than indicated in the value field.  (You can set "3" or "-3" for example) ',
    MORE: 'The user`s score will change by this value if he/she has more walks per day than indicated in the value field. (You can set "3" for example) ',
    SMALLER:
      'The user`s score will change  by this value if he/she has less number of walks per day than indicated in the value field. (You can set  "-3" for example)',
  },
  DURATION_WALK: {
    KEY: 'DURATION_WALK',
    VALUE:
      'Specify the average daily value of the walking time in hours (for example "3") that the user should walk with the dog',
    EQUAL:
      'The user`s score will change  by this value if he/she has equal average walks duration per day than indicated in the value field. (You can set "3" or "-3" for example) ',
    MORE: 'The user`s score will change  by this value if he/she has more average walks duration per day than indicated in the value field. (You can set "3" for example)',
    SMALLER:
      'The user`s score will change by this value if he/she has less average walks duration per day than indicated in the value field. (You can set "-3" for example)',
  },
  DEFAULT: 'Please, select group!',
};
