import { setCurrentEnv } from './helpers/env';

export const BASE_URL_DEV = setCurrentEnv();

export const URL_SUPER_ADMIN = '/super-admin';

export const RSA_PUBLIC_KEY = process.env.REACT_APP_RSA_PUBLIC_KEY.replace(
  /\\n/g,
  '\n',
);

export const HEADERS = {
  accept: 'application/x-www-form-urlencoded',
  'X-User-Language': 'admin',
};
export const AUTH_HEADERS = {
  accept: 'application/x-www-form-urlencoded',
  'Content-Type': 'application/json',
  deviceId: '123456789',
  platform: 'WEB',
};

export const INVALID_TOKEN = 'Invalid token!';

export const confirmationKeys = {
  questionTableDeleteItem: 'questionTableDeleteItem',
  sectionTableDeleteItem: 'sectionTableDeleteItem',
  sectionEditDeleteItem: 'sectionEditDeleteItem',
  questionEditDeleteItem: 'questionEditDeleteItem',
  publicationTableDeleteItem: 'publicationTableDeleteItem',
  questionSplashPhotosDraggableTableDeleteItem:
    'questionSplashPhotosDraggableTableDeleteItem',
  questionDrPoopUserDataTableDeleteItem:
    'questionDrPoopUserDataTableDeleteItem',
  questionDrPoopStatementTableDeleteItem:
    'questionDrPoopStatementTableDeleteItem',
  rewardsDeleteItem: 'rewardsDeleteItem',
  scoresWeightGroupDeleteItem: 'scoresWeightGroupDeleteItem',
  scoresFitnessDeleteItem: 'scoresFitnessDeleteItem',
  reasonTableDeleteItem: 'reasonTableDeleteItem',
  serviceTypeTableDeleteItem: 'serviceTypeTableDeleteItem',
  servicePartnerDeleteItem: 'servicePartnerDeleteItem',
  partnerTypeTableDeleteItem: 'partnerTypeTableDeleteItem',
  partnerProgramDeleteItem: 'partnerProgramDeleteItem',
  additionalFeeTemplateDeleteItem: 'additionalFeeTemplateDeleteItem',
  additionalServiceDeleteItem: 'additionalServiceDeleteItem',
  deleteDogOptionsItem: 'deleteDogOptionsItem',
  contractDeleteItem: 'contractDeleteItem',
  workerTypeTableDeleteItem: 'workerTypeTableDeleteItem',
  discountReasonTableDeleteItem: 'discountReasonTableDeleteItem',
  countryTableDeleteItem: 'countryTableDeleteItem',
  cityTableDeleteItem: 'cityTableDeleteItem',
  breedsTableDeleteItem: 'breedsTableDeleteItem',
  foodPricesModificatorsTableDeleteItem:
    'foodPricesModificatorsTableDeleteItem',
};

export const destinationType = [
  { label: 'web', value: 'WEB' },
  { label: 'app', value: 'APP' },
];

export const PARTNER_TYPES = {
  VET: 'VET',
  DOG_WALKER: 'DOG_WALKER',
  DOG_BOARDING: 'DOG_BOARDING',
  FOOD: 'FOOD',
  DAY_CARE: 'DAY_CARE',
  TRAINER: 'TRAINER',
  GROOMER: 'GROOMER',
  E_COMMERCE: 'E_COMMERCE',
  IN_SEWER_ANTS: 'IN_SEWER_ANTS',
};
