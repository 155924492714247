import React from 'react';
import { useDispatch } from 'react-redux';
import { updateReview } from '../../../service/reviews';

const ReviewsItem = ({ row }) => {
  const dispatch = useDispatch();

  const handleChangeProcessing = e => {
    dispatch(updateReview(row._id, { in_process: e.target.checked }));
  };

  return (
    <tr>
      <td className="text-center align-middle">
        <input
          type="checkbox"
          checked={row?.in_process}
          onChange={handleChangeProcessing}
        />
      </td>
      <td className="text-center align-middle">{row?._id}</td>
      <td className="text-center align-middle">{row?.userId}</td>
      <td className="text-center align-middle">{row?.rate}</td>
      <td className="text-center align-middle">{row?.text}</td>
      <td className="text-center align-middle">
        {row?.updateProcessDate
          ? new Date(row?.updateProcessDate).toLocaleString()
          : '-'}
      </td>
    </tr>
  );
};

export default ReviewsItem;
