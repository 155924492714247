import fetchWithAuth from '../index';
import setLoading from '../../redux/default';

import { URL_SUPER_ADMIN } from '../../constant';
import {
  getCountryListSuccess,
  setCountrySearch,
} from '../../redux/user/country/country.action';
import {
  getCityListSuccess,
  setCitySearch,
} from '../../redux/user/city/city.action';

export const getCountry = search => async dispatch => {
  dispatch(setCountrySearch(search));
  dispatch(setLoading('user_country/GET_COUNTRY_LIST_BY_SEARCH'));

  let result = await fetchWithAuth({
    path: `/user/countries/?country=${search}`,
    method: 'GET',
  });
  const response = await result.json();
  dispatch(getCountryListSuccess({ ...response, search }));
  return;
};

export const getCity = (search, countryId) => async dispatch => {
  dispatch(setCitySearch(search));
  dispatch(setLoading('user_city/GET_CITY_LIST_BY_SEARCH'));

  let result = await fetchWithAuth({
    path: `/user/cities/?city=${search}&country=${countryId}`,
    method: 'GET',
  });
  const response = await result.json();
  dispatch(getCityListSuccess({ ...response, search }));
  return;
};
