import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import GoBack from '../../../component/buttons/goBackButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  additionalServiceSelector,
  serviceTypeSelector,
} from '../../../redux/selectors/selectors';
import { getServiceType } from '../../../service/services';
import ServicePartnerForm from '../../../component/servicePartnerForm/servicePartnerForm';
import CustomSpinner from '../../../component/spinner';

const ServiceTypeCreate = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(serviceTypeSelector);
  const { list: additionalServiceList } = useSelector(
    additionalServiceSelector,
  );

  useEffect(() => {
    if (!list) getServiceType()(dispatch);
  }, [dispatch, list]);

  const serviceOptions =
    list &&
    Object.fromEntries(
      list.map(({ _id, title, title_localizations }) => [
        _id,
        title_localizations?.en || title,
      ]),
    );

  const defaultValues = list && {
    service: list[0]._id,
    price: 1,
    repeat: true,
    subscription: false,
    none: false,
  };

  const transformListToOptions = () => {
    const result = [];

    additionalServiceList.forEach(item => {
      if (item.additionServiceType) {
        result.push({
          label:
            item.additionServiceType?.title_localizations?.en ||
            item.additionServiceType?.title,
          value: item.additionServiceType?._id,
        });
      }
    });

    return result;
  };

  return (
    <Col sm={9} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>

      {loading || !list ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <ServicePartnerForm
          defaultValues={defaultValues}
          serviceOptions={serviceOptions}
          additionServiceList={transformListToOptions()}
          type="CREATE"
        />
      )}
    </Col>
  );
};

export default ServiceTypeCreate;
