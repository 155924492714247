import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { Col, Row, Button, Form, ListGroup } from 'react-bootstrap';
import GoBack from '../../../component/buttons/goBackButton';

import {
  createSection,
  getQuestionNotInSection,
} from '../../../service/quiz/section';

import { clearSectionPageData } from '../../../redux/quiz/section/section.action';

import './styles.css';

const SectionCreate = props => {
  const dispatch = useDispatch();
  const list = useSelector(state => state.quiz.section.questionNotSection);

  const { register, handleSubmit } = useForm({});

  const [isOpenQuestionForm, changeQuestionFormStatus] = useState(true);

  useEffect(() => {
    getQuestionNotInSection()(dispatch);

    return () => dispatch(clearSectionPageData());
  }, [dispatch]);

  const [availableQuestions, setAvailableQuestions] = useState(list);
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const removeSelectedQuestion = id => {
    setSelectedQuestions(
      selectedQuestions
        .filter(i => i._id !== id)
        .map((i, idx) => ({ ...i, order: idx })),
    );
    const available = [...availableQuestions];

    available.push(list.find(i => i.id === id));

    setAvailableQuestions(available);
  };

  const addSelectedQuestion = id => {
    id = id || availableQuestions[0]._id;
    let questions = [...selectedQuestions];
    questions.push(availableQuestions.find(i => i._id === id));
    questions = questions.map((i, idx) => ({ ...i, order: idx }));

    setSelectedQuestions(questions);
    setAvailableQuestions(availableQuestions.filter(i => i._id !== id));
  };

  const changeSelectedQuestionOrder = (id, oldOrder, newOrder) => {
    if (newOrder < 0 || newOrder > selectedQuestions.length - 1) return;

    const questions = selectedQuestions
      .map(i => {
        if (i.id === id) {
          return { ...i, order: newOrder };
        }

        if (oldOrder > newOrder && i.order === newOrder) {
          return { ...i, order: oldOrder };
        }

        if (newOrder > oldOrder && i.order === newOrder) {
          return { ...i, order: oldOrder };
        }
        return i;
      })
      .sort((a, b) => a.order - b.order);

    setSelectedQuestions(questions);
  };

  const selectQuestion = id => {
    setSelectedQuestion(id);
  };

  useEffect(() => setAvailableQuestions(list), [list]);

  const onSubmit = data => {
    const body = {
      ...data,
      title: data.title_localizations.en,
      newQuestions: selectedQuestions.map(i => ({
        id: i._id,
        order: i.order,
      })),
    };

    dispatch(createSection(body, props.history));
  };

  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="d-flex align-items-center">
          <Col>
            <Form.Group className="mb-3" controlId="formGridEmail">
              <Form.Label className="mt-3">Section title (EN)</Form.Label>
              <Form.Control
                {...register('title_localizations.en', { require: true })}
                placeholder="Section title"
              />
              <Form.Label className="mt-3">Section title (IL)</Form.Label>
              <Form.Control
                {...register('title_localizations.il', { require: true })}
                placeholder="Section title"
              />
            </Form.Group>
          </Col>
        </Row>

        <ListGroup className="section-edit__question-list mt-3 mb-3">
          {selectedQuestions?.map(
            ({ _id, question, question_localizations, order }) => (
              <ListGroup.Item key={_id} className="section-edit__question-item">
                EN: {question_localizations?.en || question || '-'}
                <br />
                IL: {question_localizations?.il || '-'}
                <div>
                  <Button
                    className="mr-1"
                    onClick={() => {
                      changeSelectedQuestionOrder(_id, order, order - 1);
                    }}
                    variant="secondary"
                  >
                    <i className="fa fa-arrow-up"></i>
                  </Button>

                  <Button
                    className="mr-1"
                    onClick={() => {
                      changeSelectedQuestionOrder(_id, order, order + 1);
                    }}
                    variant="secondary"
                  >
                    <i className="fa fa-arrow-down"></i>
                  </Button>

                  <Button
                    onClick={() => removeSelectedQuestion(_id)}
                    variant="danger"
                  >
                    <i className="fa fa-trash-o"></i>
                  </Button>
                </div>
              </ListGroup.Item>
            ),
          )}
        </ListGroup>

        {isOpenQuestionForm && availableQuestions.length > 0 && (
          <Row className="section-edit__create-container col-12 p-0">
            <Col>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control
                  as="select"
                  onChange={e => selectQuestion(e.target.value)}
                >
                  {availableQuestions?.map(
                    ({ _id, question, question_localizations }) => (
                      <option value={_id}>
                        {question_localizations?.en || question}
                      </option>
                    ),
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm={3} className="p-0">
              <Button
                size={'sm'}
                onClick={() => addSelectedQuestion(selectedQuestion)}
                variant="success"
              >
                Add
              </Button>
              <Button
                className="ml-1"
                size={'sm'}
                onClick={() => changeQuestionFormStatus(false)}
                variant="danger"
              >
                Close
              </Button>
            </Col>
          </Row>
        )}

        {!isOpenQuestionForm && availableQuestions.length > 0 && (
          <Button
            onClick={() => changeQuestionFormStatus(!isOpenQuestionForm)}
            variant="success"
          >
            Add new question
          </Button>
        )}
        <br />
        <Button className="mt-3" type="submit" variant="primary">
          Save change
        </Button>
      </Form>
    </Col>
  );
};

export default SectionCreate;
