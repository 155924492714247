import { Children, forwardRef, useState, useEffect } from 'react';
import { FormControl, Dropdown, Row, Col } from 'react-bootstrap';
import CustomSpinner from '../spinner';

const CustomMenu = forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Search..."
          onChange={e => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {Children.toArray(children).filter(
            child =>
              !value || child.props.children.toLowerCase().startsWith(value),
          )}
        </ul>
      </div>
    );
  },
);

export const SearchDropdown = ({
  className,
  normalizedOptions,
  onSearch,
  search,
  selectedLabel,
  onSelect,
  isLoading = false,
}) => {
  return (
    <Dropdown className={className} onSelect={onSelect}>
      <Dropdown.Toggle variant="success" id="dropdown-custom-components">
        {selectedLabel || 'Select'}
      </Dropdown.Toggle>

      <Dropdown.Menu
        style={{ transform: 'translate(0px, 40px)' }}
        as={forwardRef(
          (
            { children, style, className, 'aria-labelledby': labeledBy },
            ref,
          ) => {
            const [value, setValue] = useState(search || '');

            useEffect(() => {
              let timeOutId = null;
              if (value !== search) {
                timeOutId = setTimeout(
                  () => value.length > 1 && onSearch(value),
                  500,
                );
              }
              return () => clearTimeout(timeOutId);
            }, [value]);

            return (
              <div
                ref={ref}
                style={{ ...style, transform: 'translate(0px, 40px)' }}
                className={className}
                aria-labelledby={labeledBy}
              >
                <FormControl
                  autoFocus
                  className="mx-3 my-2 w-auto"
                  placeholder="Search..."
                  onChange={e => setValue(e.target.value)}
                  value={value}
                />
                {isLoading ? (
                  <Row>
                    <Col>
                      <CustomSpinner />
                    </Col>
                  </Row>
                ) : (
                  <ul className="list-unstyled">
                    {Children.toArray(children).filter(
                      child =>
                        !value ||
                        child.props.children
                          .toLowerCase()
                          .startsWith(value.toLowerCase()),
                    )}
                  </ul>
                )}
              </div>
            );
          },
        )}
      >
        {Object.keys(normalizedOptions).length
          ? Object.entries(normalizedOptions).map(([value, label]) => (
              <Dropdown.Item key={value} eventKey={value}>
                {label}
              </Dropdown.Item>
            ))
          : null}
      </Dropdown.Menu>
    </Dropdown>
  );
};
