import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import Input from '../inputs';

import { validationRules } from './validation';
import {
  createScoresFitnessItem,
  updateScoresFitnessItem,
} from '../../service/score';
import { SCORE_FITNESS_VALUES } from '../../constants/score';
import { CONTENT_FOR_TOOLTIPS } from './constants';
import LabelWithTooltip from '../label-with-tooltip';
import './index.css';

const FitnessForm = ({
  defaultValues = {},
  itemPageId,
  parsedWeightGroups = [],
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const watchGroup = watch('group', false);

  const onSubmit = values => {
    if (itemPageId) {
      updateScoresFitnessItem(
        values,
        itemPageId,
      )(dispatch).then(() => history.goBack());
    } else {
      createScoresFitnessItem(values)(dispatch).then(() => history.goBack());
    }
  };

  const setTooltipContent = inputName => {
    switch (watchGroup || defaultValues?.group) {
      case CONTENT_FOR_TOOLTIPS.COUNT_WALK.KEY:
        return CONTENT_FOR_TOOLTIPS.COUNT_WALK[inputName.toUpperCase()];
      case CONTENT_FOR_TOOLTIPS.DISTANCE_WALK.KEY:
        return CONTENT_FOR_TOOLTIPS.DISTANCE_WALK[inputName.toUpperCase()];
      case CONTENT_FOR_TOOLTIPS.DURATION_WALK.KEY:
        return CONTENT_FOR_TOOLTIPS.DURATION_WALK[inputName.toUpperCase()];
      default:
        return CONTENT_FOR_TOOLTIPS.DEFAULT;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-100" id="fitness-form">
      <Row className="d-flex mb-3">
        <Col>
          <label htmlFor="group" className="form-label">
            Group
          </label>
          <Input.select
            optionsList={SCORE_FITNESS_VALUES}
            label="Choose value"
            name="group"
            register={register}
            validationRules={validationRules.group}
            error={errors.group}
          />
        </Col>
        <Col>
          <Input.number
            register={register}
            label={
              <LabelWithTooltip
                configs={{
                  label: 'Value',
                  hiddenContent: setTooltipContent('value'),
                }}
              />
            }
            name="value"
            validationRules={validationRules.value}
            error={errors.value}
          />
        </Col>
        <Col>
          <label htmlFor="group" className="form-label">
            Weight group
          </label>
          <Input.select
            optionsList={parsedWeightGroups}
            label="Choose value"
            name="weight"
            register={register}
            validationRules={validationRules.weight}
            error={errors.weight}
          />
        </Col>
      </Row>
      <Row className="d-flex">
        <Col>
          <Input.number
            register={register}
            label={
              <LabelWithTooltip
                configs={{
                  label: 'Smaller',
                  hiddenContent: setTooltipContent('smaller'),
                }}
              />
            }
            name="condition.smaller"
            validationRules={validationRules.smaller}
            error={errors?.condition?.smaller}
          />
        </Col>
        <Col>
          <Input.number
            register={register}
            label={
              <LabelWithTooltip
                configs={{
                  label: 'Equal',
                  hiddenContent: setTooltipContent('equal'),
                }}
              />
            }
            name="condition.equal"
            validationRules={validationRules.equal}
            error={errors?.condition?.equal}
          />
        </Col>
        <Col>
          <Input.number
            register={register}
            label={
              <LabelWithTooltip
                configs={{
                  label: 'More',
                  hiddenContent: setTooltipContent('more'),
                }}
              />
            }
            name="condition.more"
            validationRules={validationRules.more}
            error={errors?.condition?.more}
          />
        </Col>
      </Row>
      <Row className="mt-5 ml-0 mr-0">
        <Button className="w-100 p-3" type="submit">
          Save
        </Button>
      </Row>
    </form>
  );
};

export default FitnessForm;
