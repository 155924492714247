import React, { useEffect } from 'react';
import { Row, Button, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import CustomSpinner from '../../../component/spinner';
import ConfigurableTable from '../../../component/table';

import {
  DR_POOP_STATEMENT_TABLE_HEAD,
  TABLE_ITEM_TYPE,
} from '../../../component/table/config';

import { drPoopStatementSelector } from '../../../redux/selectors/selectors';
import { getDrPoopStatementList } from '../../../service/dr-poop';

const DrPoopStatementsList = ({ history }) => {
  const { list, loading } = useSelector(drPoopStatementSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDrPoopStatementList());
  }, [dispatch]);

  return (
    <Row className="mt-3 d-flex align-items-center justify-content-center w-100 ml-0 mr-0">
      {loading ? (
        <CustomSpinner />
      ) : (
        <Col sm={12} className="p-0">
          <Button
            variant="primary"
            className="mb-3"
            onClick={() => history.push(window.location.pathname + '/create')}
          >
            Add new statement
          </Button>
          <ConfigurableTable
            tableHeaderColumns={DR_POOP_STATEMENT_TABLE_HEAD}
            itemVariant={TABLE_ITEM_TYPE.DR_POOP_STATEMENT_ITEM_TYPE}
            rows={list}
          />
        </Col>
      )}
    </Row>
  );
};

export default DrPoopStatementsList;
