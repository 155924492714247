import { SMS_CONFIGS_ACTIONS_TYPES } from './sms-configs.types';

const initialState = {
  loading: false,
  error: '',
  list: [],
  selectedItem: null,
};

const smsConfigsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SMS_CONFIGS_ACTIONS_TYPES.LOADING:
      return {
        ...state,
        list: [...state.list],
        error: '',
        loading: true,
      };

    case SMS_CONFIGS_ACTIONS_TYPES.FAILED:
      return {
        ...state,
        list: [...state.list],
        loading: false,
        error: payload,
      };

    case SMS_CONFIGS_ACTIONS_TYPES.GET_LIST_SUCCESS:
      return {
        ...state,
        list: payload,
        loading: false,
        error: '',
      };

    case SMS_CONFIGS_ACTIONS_TYPES.GET_BY_ID_SUCCESS:
      return {
        ...state,
        list: [...state.list],
        selectedItem: payload,
        loading: false,
        error: '',
      };

    default:
      return state;
  }
};

export default smsConfigsReducer;
