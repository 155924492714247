import { Col, Button, Alert, Row } from 'react-bootstrap';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CustomSpinner from '../../../component/spinner';
import { partnerTypeSelector } from '../../../redux/selectors/selectors';
import ConfigurableTable from '../../../component/table';
import {
  TABLE_ITEM_TYPE,
  PARTNER_TYPE_TABLE_HEAD,
} from '../../../component/table/config';
import { getPartnerType } from '../../../service/partners';

const PartnerTypeList = () => {
  const dispatch = useDispatch();

  const { list = [], loading, error } = useSelector(partnerTypeSelector);

  useEffect(() => {
    getPartnerType()(dispatch);
  }, [dispatch]);

  return (
    <Col sm={12} className="p-0 mt-3">
      {error && <Alert variant={'danger'}>{error.message}</Alert>}
      <Link to="create">
        <Button className="mb-3" variant="primary">
          Create partner
        </Button>
      </Link>
      {loading || !list ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <ConfigurableTable
          tableHeaderColumns={PARTNER_TYPE_TABLE_HEAD}
          itemVariant={TABLE_ITEM_TYPE.PARTNER_TYPE_ITEM_TYPE}
          rows={list}
        />
      )}
    </Col>
  );
};

export default PartnerTypeList;
