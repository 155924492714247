export const validationRules = {
  title_localizations: {
    en: {
      required: 'Title field is required!',
    },
    il: {
      required: 'Title field is required!',
    },
  },

  desc_localizations: {
    en: {
      required: 'Description field is required!',
    },
    il: {
      required: 'Description field is required!',
    },
  },

  condition: {
    required: 'Condition field is required!',
    min: {
      value: 1,
      message: 'Condition cant be less then 1',
    },
    max: {
      value: 100000,
      message: 'Condition cant be more then 100000',
    },
  },

  reward: {
    required: 'Reward field is required!',
  },
};
