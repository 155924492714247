import { currencyTypes } from './currency.types';

const initialSate = {
  loading: false,
  error: false,
  list: null,
};

const currency = (state = initialSate, { type, payload }) => {
  switch (type) {
    case currencyTypes.GET_COUNTRY_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case currencyTypes.GET_CURRENCY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload,
      };

    case currencyTypes.GET_CURRENCY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default currency;
