export const countryTypes = {
  GET_COUNTRY_LIST_BY_SEARCH_SUCCESS:
    'user_country/GET_COUNTRY_LIST_BY_SEARCH_SUCCESS',
  GET_COUNTRY_LIST_BY_SEARCH_LOADING:
    'user_country/GET_COUNTRY_LIST_BY_SEARCH_LOADING',
  GET_COUNTRY_LIST_FAIL: 'user_country/GET_COUNTRY_LIST_FAIL',

  SET_ITEM: 'user_country/SET_ITEM',
  CLEAR_ITEM: 'user_country/CLEAR_ITEM',
  SET_SEARCH: 'user_country/SET_SEARCH',
};
