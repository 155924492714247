import { servicePartnerTypes } from './servicePartner.types';

export const getServicePartnerListSuccess = list => ({
  type: servicePartnerTypes.GET_SERVICE_PARTNER_LIST_SUCCESS,
  payload: list,
});

export const getServicePartnerByIdSuccess = reason => ({
  type: servicePartnerTypes.GET_SERVICE_PARTNER_BY_ID_SUCCESS,
  payload: reason,
});
export const getServicePartnerByIdFailed = err => ({
  type: servicePartnerTypes.GET_SERVICE_PARTNER_BY_ID_FAIL,
  payload: err,
});

export const deleteServicePartnerSuccess = id => ({
  type: servicePartnerTypes.DELETE_SERVICE_PARTNER_SUCCESS,
  payload: id,
});
export const deleteServicePartnerFailed = err => ({
  type: servicePartnerTypes.DELETE_SERVICE_PARTNER_FAIL,
  payload: err,
});

export const clearServicePartnerItemState = () => ({
  type: servicePartnerTypes.CLEAR_ITEM,
});
export const updateServicePartnerFailed = err => ({
  type: servicePartnerTypes.UPDATE_SERVICE_PARTNER_FAIL,
  payload: err,
});
export const updateServicePartnerSuccess = (id, updatedData) => ({
  type: servicePartnerTypes.UPDATE_SERVICE_PARTNER_SUCCESS,
  payload: { updatedData, id },
});
