import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import GoBack from '../../../component/buttons/goBackButton';
import DeleteDogOptionsForm from '../../../component/delete-dog-options-form/delete-dog-options-form';

import { createDeleteDogOptions } from '../../../service/delete-dog/delete-dog-options';

const DeleteDogOptionsCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = body => {
    dispatch(createDeleteDogOptions(body, history));
  };

  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      <DeleteDogOptionsForm onSubmit={onSubmit} />
    </Col>
  );
};

export default DeleteDogOptionsCreate;
