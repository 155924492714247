import fetchWithAuth from '../index';
import { discountReasonsActions } from '../../redux/discount-reasons/discount-reasons.actions';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';
import MESSAGES_FOR_USER from '../../constants/messages/en';

export const getDiscountReasonsList = params => async dispatch => {
  const reqParams = params
    ? `?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}&name=${params.name}`
    : '';
  const url = '/app-worker/order-discount-reason' + reqParams;

  dispatch(discountReasonsActions.discountReasonsOperationLoading());

  const response = await fetchWithAuth({
    path: url,
    method: 'GET',
  });

  if (!response.ok) {
    dispatch(discountReasonsActions.discountReasonsOperationFailed);
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.DISCOUNT_REASONS.GET_FAILED.TITLE,
        content: MESSAGES_FOR_USER.DISCOUNT_REASONS.GET_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }
  const data = await response.json();
  dispatch(discountReasonsActions.getDiscountReasonsList(data));
};

export const getDiscountReasonById = id => async dispatch => {
  const url = '/app-worker/order-discount-reason/' + id;

  dispatch(discountReasonsActions.discountReasonsOperationLoading());

  const response = await fetchWithAuth({
    path: url,
    method: 'GET',
  });

  if (!response.ok) {
    dispatch(discountReasonsActions.discountReasonsOperationFailed);

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.DISCOUNT_REASONS.GET_FAILED.TITLE,
        content: MESSAGES_FOR_USER.DISCOUNT_REASONS.GET_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }
  const data = await response.json();
  dispatch(discountReasonsActions.getDiscountReasonById(data));
};

export const createDiscountReason = body => async dispatch => {
  const url = '/app-worker/order-discount-reason';

  dispatch(discountReasonsActions.discountReasonsOperationLoading());

  const response = await fetchWithAuth({
    path: url,
    body: JSON.stringify(body),
    method: 'POST',
  });

  if (!response.ok) {
    dispatch(discountReasonsActions.discountReasonsOperationFailed);

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.DISCOUNT_REASONS.CREATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.DISCOUNT_REASONS.CREATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );

    return;
  }
  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.DISCOUNT_REASONS.CREATE_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.DISCOUNT_REASONS.CREATE_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );
};

export const updateDiscountReason =
  ({ id, body }) =>
  async dispatch => {
    const url = '/app-worker/order-discount-reason/' + id;

    dispatch(discountReasonsActions.discountReasonsOperationLoading());

    const response = await fetchWithAuth({
      path: url,
      body: JSON.stringify(body),
      method: 'PUT',
    });

    if (!response.ok) {
      dispatch(discountReasonsActions.discountReasonsOperationFailed);
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.DISCOUNT_REASONS.UPDATE_FAILED.TITLE,
          content: MESSAGES_FOR_USER.DISCOUNT_REASONS.UPDATE_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );

      return;
    }

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.DISCOUNT_REASONS.UPDATE_SUCCESS.TITLE,
        content: MESSAGES_FOR_USER.DISCOUNT_REASONS.UPDATE_SUCCESS.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );
  };

export const deleteDiscountReason = id => async dispatch => {
  const url = '/app-worker/order-discount-reason/' + id;

  const response = await fetchWithAuth({
    path: url,
    method: 'DELETE',
  });

  if (!response.ok) {
    dispatch(discountReasonsActions.discountReasonsOperationFailed);
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.DISCOUNT_REASONS.DELETE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.DISCOUNT_REASONS.DELETE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );

    return;
  }
  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.DISCOUNT_REASONS.DELETE_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.DISCOUNT_REASONS.DELETE_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );

  dispatch(getDiscountReasonsList());
};
