import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import AdditionalFeeTemplateForm from '../../../component/additional-fee-template-form/additional-fee-template-form';
import GoBack from '../../../component/buttons/goBackButton';
import { createAdditionalFeeTemplate } from '../../../service/services/additional-fee-template';

const AdditionalFeeTemplateCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = data => {
    const body = {
      ...data,
      title: data.title_localizations.en,
      desc: data.desc_localizations.en,
    };

    dispatch(createAdditionalFeeTemplate(body, history));
  };

  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      <AdditionalFeeTemplateForm onSubmit={onSubmit} />
    </Col>
  );
};

export default AdditionalFeeTemplateCreate;
