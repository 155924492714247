import { Col, Button, Alert, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CustomSpinner from '../../../component/spinner';
import { getReason } from '../../../service/subscription/reason';
import { reasonSelector } from '../../../redux/selectors/selectors';
import ConfigurableTable from '../../../component/table';
import {
  TABLE_ITEM_TYPE,
  CANCEL_REASON_TABLE_HEAD,
} from '../../../component/table/config';

const ReasonList = () => {
  const dispatch = useDispatch();

  const { list = [], loading, error } = useSelector(reasonSelector);

  useEffect(() => {
    dispatch(getReason());
  }, [dispatch]);

  return (
    <Col sm={12} className="p-0 mt-3">
      {error && <Alert variant={'danger'}>{error.message}</Alert>}
      <Link to="create">
        <Button className="mb-3" variant="primary">
          Create reason
        </Button>
      </Link>
      {loading || !list.length ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <ConfigurableTable
          tableHeaderColumns={CANCEL_REASON_TABLE_HEAD}
          itemVariant={TABLE_ITEM_TYPE.CANCEL_REASON_ITEM}
          rows={list}
        />
      )}
    </Col>
  );
};

export default ReasonList;
