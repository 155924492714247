import COUNTRY_ACTIONS_TYPES from './countries.types';

const initialState = {
  loading: false,
  error: null,
  list: [],
  selectedItem: null,
};

const countriesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case COUNTRY_ACTIONS_TYPES.OPERATION_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case COUNTRY_ACTIONS_TYPES.OPERATION_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case COUNTRY_ACTIONS_TYPES.GET_COUNTRY_BY_ID:
      return {
        ...state,
        loading: false,
        error: null,
        selectedItem: payload,
      };

    case COUNTRY_ACTIONS_TYPES.GET_COUNTRIES_LIST:
      return {
        ...state,
        loading: false,
        error: null,
        list: payload.result,
      };

    case COUNTRY_ACTIONS_TYPES.DELETE_COUNTRY:
      return {
        ...state,
        loading: false,
        error: null,
        list: state.list.filter(i => i._id !== payload),
      };

    case COUNTRY_ACTIONS_TYPES.CLEAR_SELECTED_COUNTRY:
      return {
        ...state,
        selectedItem: null,
      };

    default:
      return state;
  }
};

export default countriesReducer;
