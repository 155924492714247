import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';

import GoBack from '../../../component/buttons/goBackButton';
import { useDispatch, useSelector } from 'react-redux';
import CustomSpinner from '../../../component/spinner';
import { additionalFeeTemplateSelector } from '../../../redux/selectors/selectors';
import {
  getAdditionalFeeTemplateById,
  updateAdditionalFeeTemplate,
} from '../../../service/services/additional-fee-template';
import { additionalFeeTemplateClearItem } from '../../../redux/additional-fee-template/additional-fee-template.action';
import AdditionalFeeTemplateForm from '../../../component/additional-fee-template-form/additional-fee-template-form';

const AdditionalFeeTemplateEdit = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = props.match.params;
  const { item } = useSelector(additionalFeeTemplateSelector);

  useEffect(() => {
    getAdditionalFeeTemplateById(id)(dispatch);

    return () => dispatch(additionalFeeTemplateClearItem());
  }, [dispatch, id]);

  const onSubmit = data => {
    const body = {
      ...data,
      title: data.title_localizations.en,
      desc: data.desc_localizations.en,
    };

    dispatch(updateAdditionalFeeTemplate(id, body, history));
  };

  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      {Object.keys(item).length ? (
        <AdditionalFeeTemplateForm
          defaultValues={{
            title_localizations: {
              en: item?.title_localizations?.en || item.title,
              il: item?.title_localizations?.il,
            },
            desc_localizations: {
              en: item?.desc_localizations?.en || item.desc,
              il: item?.desc_localizations?.il,
            },
          }}
          onSubmit={onSubmit}
        />
      ) : (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default AdditionalFeeTemplateEdit;
