import { FOOD_PRICES_MODIFICATORS_ACTIONS_TYPES } from './food-prices-modificators.types';

export const FOOD_PRICES_MODIFICATORS_ACTIONS = {
  loading: () => ({
    type: FOOD_PRICES_MODIFICATORS_ACTIONS_TYPES.LOADING,
  }),

  getListSuccess: list => ({
    type: FOOD_PRICES_MODIFICATORS_ACTIONS_TYPES.GET_LIST_SUCCESS,
    payload: list,
  }),
  getByIdSuccess: item => ({
    type: FOOD_PRICES_MODIFICATORS_ACTIONS_TYPES.GET_BY_ID_SUCCESS,
    payload: item,
  }),
  createSuccess: () => ({
    type: FOOD_PRICES_MODIFICATORS_ACTIONS_TYPES.CREATE_SUCCESS,
  }),
  updateSuccess: () => ({
    type: FOOD_PRICES_MODIFICATORS_ACTIONS_TYPES.UPDATE_SUCCESS,
  }),
  deleteSuccess: id => ({
    type: FOOD_PRICES_MODIFICATORS_ACTIONS_TYPES.DELETE_SUCCESS,
    payload: id,
  }),

  failed: error => ({
    type: FOOD_PRICES_MODIFICATORS_ACTIONS_TYPES.FAILED,
    payload: error,
  }),
};
