export const validationRules = {
  name_localizations: {
    en: {
      required: 'Name field is required!',
    },
    il: {
      required: 'Name field is required!',
    },
  },
  countryId: {
    required: 'Country field is required!',
  },
};
