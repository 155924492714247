import React, { useState, useEffect } from 'react';

import UploadedImage from './UploadedImage/UploadedImage';
import InitialImage from './InitialImage/InitialImage';

import './styles.css';

const ImageUploadCard = ({
  defaultImage = '',
  handleUploadImageChange = () => {},
  onChange = () => {},
  isNeedToAddImagePlaceholder = true,
}) => {
  const [upload, setUpload] = useState({
    mainState: 'initial',
    imageUploaded: 0,
    selectedFile: null,
  });

  useEffect(() => {
    if (defaultImage) {
      setUpload({
        mainState: 'uploaded',
        selectedFile: defaultImage,
        imageUploaded: 1,
      });
    }
  }, [defaultImage]);

  const handleUploadClick = event => {
    if (isNeedToAddImagePlaceholder) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        setUpload({
          mainState: 'uploaded',
          selectedFile: [reader.result],
          imageUploaded: 1,
        });
      };

      setUpload({
        ...upload,
        mainState: 'uploaded',
        selectedFile: event.target.files[0],
        imageUploaded: 1,
      });

      onChange(event.target.files[0]);
    }

    handleUploadImageChange(event.target.files[0]);
  };

  const handleResetUpload = event => {
    setUpload({
      ...upload,
      mainState: 'initial',
      selectedFile: null,
      imageUploaded: 0,
    });
    onChange(null);
    handleUploadImageChange(null);
  };

  return (
    <div>
      <div className="upload-image-container">
        {upload.mainState === 'initial' && (
          <InitialImage
            defaultImage={defaultImage}
            handleUploadClick={handleUploadClick}
          />
        )}
        {upload.mainState === 'uploaded' && (
          <UploadedImage
            imageResetHandler={handleResetUpload}
            selectedFile={upload.selectedFile}
          />
        )}
      </div>
    </div>
  );
};

export default ImageUploadCard;
