import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import GoBack from '../../../component/buttons/goBackButton';
import PartnerTypeForm from '../../../component/partnerTypeForm/partnerTypeForm';
import { getPartnerTypeById } from '../../../service/partners';
import { useDispatch, useSelector } from 'react-redux';
import {
  serviceTypeSelector,
  partnerTypeSelector,
} from '../../../redux/selectors/selectors';
import CustomSpinner from '../../../component/spinner';
import { getServiceType } from '../../../service/services';
import { PARTNER_TYPES } from '../../../constant';
import { clearPartnerTypeItemState } from '../../../redux/partnerType/partnerType.action';

const ServiceTypeCreate = props => {
  const { id } = props.match.params;

  const dispatch = useDispatch();
  const { list = [], loading } = useSelector(serviceTypeSelector);
  const { item, loading: partnerLoading } = useSelector(partnerTypeSelector);

  useEffect(() => {
    if (!list) getServiceType()(dispatch);
  }, [dispatch, list]);

  useEffect(() => {
    getPartnerTypeById(id)(dispatch);
    return () => dispatch(clearPartnerTypeItemState());
  }, [dispatch, id]);

  const normalizedOptions =
    list &&
    Object.fromEntries(
      list.map(({ title, title_localizations, _id }) => [
        _id,
        title_localizations?.en || title,
      ]),
    );

  const partnerTypes = Object.values(PARTNER_TYPES).map(val => ({
    label: val,
    value: val,
  }));

  return (
    <Col sm={9} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>

      {loading || partnerLoading || !list || !item ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <PartnerTypeForm
          type="EDIT"
          partnerTypes={partnerTypes}
          normalizedOptions={normalizedOptions}
          id={item._id}
          defaultValues={{
            typePartner: item.typePartner,
            title_localizations: {
              en: item.title_localizations?.en || item.title || '',
              il: item.title_localizations?.il || '',
            },
            desc_localizations: {
              en: item.desc_localizations?.en || item.desc || '',
              il: item.desc_localizations?.il || '',
            },
            service: list.map(({ _id }) => item.service.includes(_id) && _id),
          }}
        />
      )}
    </Col>
  );
};

export default ServiceTypeCreate;
