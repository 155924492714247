import { useState } from 'react';
import sendPhone from '../../service/auth/setPhone';
import FormLogin from '../../component/formLogin';
import { useDispatch } from 'react-redux';

const SetPhone = () => {
  const [phone, updatePhone] = useState();

  const dispatch = useDispatch();

  const send = e => {
    e.preventDefault();
    sendPhone({ phone })(dispatch);
  };

  return (
    <FormLogin send={send} buttonName={'Log In'}>
      <input
        name="phone"
        type="phone"
        className="fadeIn second"
        placeholder="Phone"
        onChange={e => updatePhone(e.target.value)}
      />
    </FormLogin>
  );
};

export default SetPhone;
