import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import GoBack from '../../../component/buttons/goBackButton';
import PartnerTypeForm from '../../../component/partnerTypeForm/partnerTypeForm';
import { serviceTypeSelector } from '../../../redux/selectors/selectors';
import CustomSpinner from '../../../component/spinner';
import { getServiceType } from '../../../service/services';
import { PARTNER_TYPES } from '../../../constant';

const PartnerTypeCreate = () => {
  const dispatch = useDispatch();
  const { list = [], loading } = useSelector(serviceTypeSelector);

  useEffect(() => {
    if (!list) getServiceType()(dispatch);
  }, [dispatch, list]);

  const normalizedOptions =
    list &&
    Object.fromEntries(
      list.map(({ title, title_localizations, _id }) => [
        _id,
        title_localizations?.en || title,
      ]),
    );

  const partnerTypes = Object.values(PARTNER_TYPES).map(val => ({
    label: val,
    value: val,
  }));

  const defaultValues = list && {
    typePartner: PARTNER_TYPES.VET,
    title: '',
    desc: '',
    service: list.map(() => false),
  };

  return (
    <Col sm={9} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>

      {loading || !list ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <PartnerTypeForm
          type="CREATE"
          partnerTypes={partnerTypes}
          normalizedOptions={normalizedOptions}
          defaultValues={defaultValues}
        />
      )}
    </Col>
  );
};

export default PartnerTypeCreate;
