export const discountReasonsTypes = {
  DISCOUNT_REASONS_OPERATION_LOADING:
    'discount-reasons/DISCOUNT_REASONS_OPERATION_LOADING',
  DISCOUNT_REASONS_OPERATION_FAILED:
    'discount-reasons/DISCOUNT_REASONS_OPERATION_FAILED',

  GET_DISCOUNT_REASONS_LIST: 'discount-reasons/GET_DISCOUNT_REASONS_LIST',

  GET_DISCOUNT_REASON_BY_ID: 'discount-reasons/GET_DISCOUNT_REASON_BY_ID',

  CREATE_DISCOUNT_REASON: 'discount-reasons/CREATE_DISCOUNT_REASON',

  UPDATE_DISCOUNT_REASON: 'discount-reasons/UPDATE_DISCOUNT_REASON',

  DELETE_DISCOUNT_REASON: 'discount-reasons/DELETE_DISCOUNT_REASON',

  CLEAR_SELECTED_DISCOUNT_REASON:
    'discount-reasons/CLEAR_SELECTED_DISCOUNT_REASON',

  CLEAR_DISCOUNT_REASONS: 'discount-reasons/CLEAR_DISCOUNT_REASONS',
};
