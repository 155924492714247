import React from 'react';

import { ReactComponent as CameraIcon } from '../../../source/img/camera.svg';

import './styles.css';

const InitialImage = ({ handleUploadClick }) => (
  <>
    <input
      className="initial-button"
      accept="image/*"
      id="contained-button-file"
      multiple
      type="file"
      onChange={handleUploadClick}
    />
    <label htmlFor="contained-button-file">
      <div className="initial-container">
        <div className="initial-icon-container">
          <CameraIcon className="initial-icon" />
        </div>
        <p className="initial-description">
          Сlick the icon or this text to add a photo
        </p>
      </div>
    </label>
  </>
);

export default InitialImage;
