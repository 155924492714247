import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import GoBack from '../../../component/buttons/goBackButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  contractsSelector,
  partnerProgramSelector,
} from '../../../redux/selectors/selectors';
import CustomSpinner from '../../../component/spinner';
import { getPartnerProgram } from '../../../service/partners/partnerProgram';
import { getContractsList } from '../../../service/contracts/contracts';
import PartnerWorkerForm from '../../../component/partnerWorkerForm/partnerWorkerForm';
import { getNormalizedEntities } from '../../../helpers/getNormalizedEntities';

const PartnerWorkerCreate = () => {
  const dispatch = useDispatch();
  const { list = [], loading } = useSelector(partnerProgramSelector);
  const { list: contractsList, loading: contractsLoading } =
    useSelector(contractsSelector);

  useEffect(() => {
    (async () => {
      await getContractsList()(dispatch);
      await getPartnerProgram()(dispatch);
    })();
  }, [dispatch]);

  const [normalizedPrograms, programIds] = getNormalizedEntities(list, [
    '_id',
    'partnerType',
  ]);

  const defaultValues = list && {
    phone: '',
    email: '',
    partners: programIds.map(id => false),
  };

  return (
    <Col sm={9} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>

      {loading || contractsLoading || !list || !contractsList ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <PartnerWorkerForm
          type="CREATE"
          normalizedPrograms={normalizedPrograms}
          programIds={programIds}
          defaultValues={defaultValues}
          contractsList={contractsList}
        />
      )}
    </Col>
  );
};

export default PartnerWorkerCreate;
