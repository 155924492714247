import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row } from 'react-bootstrap';
import CustomSpinner from '../../../component/spinner';
import ConfigurableTable from '../../../component/table';
import {
  TABLE_ITEM_TYPE,
  NOTIFICATIONS_CONFIG_TABLE_HEAD,
} from '../../../component/table/config';

import { getNotificationsSettings } from '../../../service/notifications';
import { pushNotificationsSelector } from '../../../redux/selectors/selectors';

const NotificationConfig = () => {
  const dispatch = useDispatch();

  const {
    settings: { list, loading },
  } = useSelector(pushNotificationsSelector);

  useEffect(() => {
    dispatch(getNotificationsSettings({ pageNumber: 0, pageSize: 1000 }));
  }, [dispatch]);

  return (
    <Col sm={12} className="p-0 mt-3">
      {loading || !list.length ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <ConfigurableTable
          tableHeaderColumns={NOTIFICATIONS_CONFIG_TABLE_HEAD}
          itemVariant={TABLE_ITEM_TYPE.NOTIFICATION_CONFIG_ITEM}
          rows={list}
        />
      )}
    </Col>
  );
};

export default NotificationConfig;
