export const validationRules = {
  country: {
    required: 'Country field is required!',
  },

  modificator: {
    required: 'Modificator field is required!',
    min: {
      value: 0,
      message: 'Condition cant be less then 0',
    },
    max: {
      value: 100000,
      message: 'Condition cant be more then 100000',
    },
  },
};
