import React from 'react';
import { Row, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import Input from '../inputs';
import { validationRules } from './validation';

const DeleteDogOptionsForm = ({ onSubmit, defaultValues }) => {
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-100"
      id="delete-dog-options-form"
    >
      <Row className="d-flex align-items-center p-3">
        <Input.text
          register={register}
          label="Reason (EN)"
          name="reason_localizations.en"
          validationRules={validationRules.reason}
          error={errors.reason}
        />
      </Row>
      <Row className="d-flex align-items-center px-3">
        <Input.text
          register={register}
          label="Reason (IL)"
          name="reason_localizations.il"
          validationRules={validationRules.reason}
          error={errors.reason}
        />
      </Row>

      <Button
        disabled={!isDirty}
        type="submit"
        variant="primary"
        className="mt-3 p-3 w-100"
      >
        Save changes
      </Button>
    </form>
  );
};

export default DeleteDogOptionsForm;
