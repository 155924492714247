export const serviceTypes = {
  CREATE_SERVICE_TYPE_FAIL: 'service_type/CREATE_SERVICE_TYPE_FAIL',

  GET_SERVICE_TYPE_LIST_SUCCESS: 'service_type/GET_SERVICE_TYPE_LIST_SUCCESS',
  GET_SERVICE_TYPE_LIST_LOADING: 'service_type/GET_SERVICE_TYPE_LIST_LOADING',
  GET_SERVICE_TYPE_LIST_FAIL: 'service_type/GET_SERVICE_TYPE_LIST_FAIL',

  GET_SERVICE_TYPE_BY_ID_SUCCESS: 'service_type/GET_SERVICE_TYPE_BY_ID_SUCCESS',
  GET_SERVICE_TYPE_BY_ID_FAIL: 'service_type/GET_SERVICE_TYPE_BY_ID_FAIL',

  DELETE_SERVICE_TYPE_SUCCESS: 'service_type/DELETE_SERVICE_TYPE_SUCCESS',
  DELETE_SERVICE_TYPE_FAIL: 'service_type/DELETE_SERVICE_TYPE_FAIL',

  CLEAR_ITEM: 'service_type/CLEAR_ITEM',
  UPDATE_SERVICE_TYPE_SUCCESS: 'service_type/UPDATE_SERVICE_TYPE_SUCCESS',
  UPDATE_SERVICE_TYPE_FAIL: 'service_type/UPDATE_SERVICE_TYPE_FAIL',
};
