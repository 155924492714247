import RewardsList from './list';
import RewardsDetails from './details';
import RewardsActionsList from './actions';
import RewardsActionDetails from './actions-details';
import RewardsCoinsModificatorsList from './coins-modificators';
import RewardsCoinsModificatorsDetails from './coins-modificators-details';

const Rewards = {
  list: RewardsList,
  details: RewardsDetails,
  actions: RewardsActionsList,
  actionDetails: RewardsActionDetails,
  coinsModificators: RewardsCoinsModificatorsList,
  coinsModificatorsDetails: RewardsCoinsModificatorsDetails,
};

export default Rewards;
