import ServiceTypeList from './list';
import ServiceTypeCreate from './create';
import ServiceTypeEdit from './edit';

const ServiceType = {
  list: ServiceTypeList,
  create: ServiceTypeCreate,
  edit: ServiceTypeEdit,
};

export default ServiceType;
