import React from 'react';

const Number = ({ name, register, label, validationRules, error }) => {
  return (
    <>
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <div className="input-group">
        <input
          type="number"
          className="form-control text-left ml-0 mr-0 mt-0"
          aria-describedby="basic-addon3"
          {...register(name, validationRules)}
          step="0.01"
        />
        {error?.message && (
          <p className="error">
            <i className="fa fa-exclamation-circle"></i> {error.message}
          </p>
        )}
      </div>
    </>
  );
};

export default Number;
