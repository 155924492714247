import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
  addDrPoopStatementItem,
  updateDrPoopStatementItem,
} from '../../../service/dr-poop';

import Input from '../../inputs';

import { validationRules } from './validation';

const DrPoopStatementItemForm = ({ defaultValues = {}, itemPageId }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const onSubmit = values => {
    const body = {
      ...values,
      answer: values.answer_localizations.en,
    };

    if (itemPageId) {
      updateDrPoopStatementItem(
        itemPageId,
        body,
      )(dispatch).then(() => history.goBack());
    } else {
      addDrPoopStatementItem(body)(dispatch).then(() => history.goBack());
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-100"
      id="statement-form"
    >
      <Row className="pl-3 pr-3 pt-3">
        <Input.text
          register={register}
          label="Answer(en)"
          name="answer_localizations.en"
          validationRules={validationRules.answer_localizations.en}
          error={errors.answer_localizations?.en}
        />
      </Row>
      <Row className="pl-3 pr-3 pt-3">
        <Input.text
          register={register}
          label="Answer(il)"
          name="answer_localizations.il"
          validationRules={validationRules.answer_localizations.il}
          error={errors.answer_localizations?.il}
        />
      </Row>

      <Row className="mt-5 ml-0 mr-0">
        <Button className="w-100 p-3" type="submit">
          Save
        </Button>
      </Row>
    </form>
  );
};

export default DrPoopStatementItemForm;
