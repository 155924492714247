import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Col, Row, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import GoBack from '../../../component/buttons/goBackButton';

import { getPublicationItemById } from '../../../service/publication/publication';
import { clearPublicationItem } from '../../../redux/publication/publication.action';
import CustomSpinner from '../../../component/spinner';

const PublicationDetails = props => {
  const {
    item: {
      title,
      title_localizations,
      subTitle,
      subTitle_localizations,
      content,
      content_localizations,
      url,
    },
    item,
    loading,
  } = useSelector(state => state.publication);
  const dispatch = useDispatch();

  useEffect(() => {
    getPublicationItemById(props.match.params.id)(dispatch);

    return () => dispatch(clearPublicationItem());
  }, [dispatch, props.match.params.id]);

  return (
    <>
      <Col className="mt-3">
        <Row className="mb-4">
          <Col>
            <div className="d-flex justify-content-between">
              <GoBack />
            </div>
          </Col>
        </Row>
        {loading && !item ? (
          <Row className="mt-5">
            <Col>
              <CustomSpinner />
            </Col>
          </Row>
        ) : (
          <Card className="w-10">
            <Card.Header>
              Title (EN): {title_localizations?.en || title || '-'}
            </Card.Header>
            <Card.Header>
              Title (IL): {title_localizations?.il || '-'}
            </Card.Header>
            <Col className="p-4" xs={6} md={4}>
              <Image
                style={{ width: 'auto', height: '300px' }}
                src={url}
                rounded
              />
            </Col>
            <Card.Body>
              <blockquote className="blockquote mb-0">
                <p>
                  Subtitle (EN): {subTitle_localizations?.en || subTitle || '-'}
                </p>
                <p>Subtitle (IL): {subTitle_localizations?.il || '-'} </p>
                <p
                  className="blockquote-footer"
                  dangerouslySetInnerHTML={{
                    __html: `<p>Content (EN): </p> ${
                      content_localizations?.en || content || '-'
                    }`,
                  }}
                ></p>
                <p
                  className="blockquote-footer"
                  dangerouslySetInnerHTML={{
                    __html: `<p>Content (IL): </p> ${
                      content_localizations?.il || '-'
                    }`,
                  }}
                ></p>
              </blockquote>
            </Card.Body>
          </Card>
        )}
      </Col>
    </>
  );
};

export default withRouter(PublicationDetails);
