import { useDispatch } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { withRouter } from 'react-router';

import UploadImage from '../uploadImage/UploadImage';
import { createServiceType, updateServiceType } from '../../service/services';

const ServiceTypeForm = ({
  history,
  defaultValues = {},
  type = '',
  id = null,
}) => {
  const dispatch = useDispatch();

  const { register, handleSubmit, control } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const onSubmit = async data => {
    const body = {
      ...data,
      title: data.title_localizations.en,
      desc: data.desc_localizations.en,
    };

    type === 'CREATE' && dispatch(createServiceType(body, history));
    type === 'EDIT' && dispatch(updateServiceType(id, body, history));
  };

  return (
    <Form className="border p-4 mt-4" onSubmit={handleSubmit(onSubmit)}>
      <Form.Label>Title(en)</Form.Label>
      <Form.Control
        {...register('title_localizations.en', {
          required: true,
        })}
        placeholder="Service title..."
      />
      <Form.Label className="mt-3">Title(il)</Form.Label>
      <Form.Control
        {...register('title_localizations.il', {
          required: true,
        })}
        placeholder="Service title..."
      />

      <Form.Label className="mt-3">Description(en)</Form.Label>
      <Form.Control
        {...register('desc_localizations.en', {
          required: true,
        })}
        as="textarea"
        placeholder="Service description..."
      />
      <Form.Label className="mt-3">Description(il)</Form.Label>
      <Form.Control
        {...register('desc_localizations.il', {
          required: true,
        })}
        as="textarea"
        placeholder="Service description..."
      />

      <Form.Label className="mt-3">Image</Form.Label>
      <Controller
        control={control}
        name="image"
        rules={{ required: true }}
        render={({ field: { onChange } }) => (
          <UploadImage defaultImage={defaultValues.image} onChange={onChange} />
        )}
      />
      <Form.Label className="mt-3">Icon</Form.Label>
      <Controller
        control={control}
        name="icon"
        rules={{ required: true }}
        render={({ field: { onChange } }) => (
          <UploadImage defaultImage={defaultValues.icon} onChange={onChange} />
        )}
      />

      <Button className="mt-4" variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

export default withRouter(ServiceTypeForm);
