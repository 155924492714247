import fetchWithAuth from '../index';
import {
  createWorkerTypesFail,
  createWorkerTypesLoading,
  createWorkerTypesSuccess,
  deleteWorkerTypesFail,
  deleteWorkerTypesLoading,
  deleteWorkerTypesSuccess,
  getWorkerTypeByIdFail,
  getWorkerTypeByIdLoading,
  getWorkerTypeByIdSuccess,
  getWorkerTypesFail,
  getWorkerTypesLoading,
  getWorkerTypesSuccess,
  updateWorkerTypesFail,
  updateWorkerTypesLoading,
  updateWorkerTypesSuccess,
} from '../../redux/workerType/workerType.actions';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import MESSAGES_FOR_USER from '../../constants/messages/en';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';

export const getWorkerTypes = () => async dispatch => {
  let url = '/app-worker/worker-type';

  dispatch(getWorkerTypesLoading());

  let response = await fetchWithAuth({ path: url });

  if (response.ok) {
    const result = await response.json();

    dispatch(getWorkerTypesSuccess(result.item));
    return;
  } else {
    dispatch(getWorkerTypesFail(response.statusText));
  }
};

export const getWorkerTypeById = id => async dispatch => {
  let url = '/app-worker/worker-type/' + id;

  dispatch(getWorkerTypeByIdLoading());

  let response = await fetchWithAuth({ path: url });

  if (response.ok) {
    const result = await response.json();

    dispatch(getWorkerTypeByIdSuccess(result));
    return;
  } else {
    dispatch(getWorkerTypeByIdFail(response.statusText));
  }
};

export const createWorkerType = (data, history) => async dispatch => {
  const params = {
    path: '/app-worker/worker-type',
    method: 'POST',
    body: JSON.stringify(data),
  };

  dispatch(createWorkerTypesLoading());

  let response = await fetchWithAuth(params);

  if (!response.ok) {
    dispatch(createWorkerTypesFail(response.statusText));
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.WORKER_TYPE.CREATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.WORKER_TYPE.CREATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }
  dispatch(createWorkerTypesSuccess());

  history.push('/worker-type/');
};

export const updateWorkerType = (id, data, history) => async dispatch => {
  const params = {
    path: '/app-worker/worker-type/' + id,
    method: 'PUT',
    body: JSON.stringify(data),
  };

  dispatch(updateWorkerTypesLoading());

  let response = await fetchWithAuth(params);

  if (!response.ok) {
    dispatch(updateWorkerTypesFail(response.statusText));
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.WORKER_TYPE.UPDATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.WORKER_TYPE.UPDATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }
  dispatch(updateWorkerTypesSuccess());

  history.push('/worker-type/');
};

export const deleteWorkerType = id => async dispatch => {
  const params = {
    path: '/app-worker/worker-type/' + id,
    method: 'DELETE',
  };

  dispatch(deleteWorkerTypesLoading());

  let response = await fetchWithAuth(params);

  if (!response.ok) {
    dispatch(deleteWorkerTypesFail(response.statusText));
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.WORKER_TYPE.UPDATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.WORKER_TYPE.UPDATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }
  dispatch(getWorkerTypes());
  dispatch(deleteWorkerTypesSuccess());
};
