import { questionTypes } from './question.types';

const initialState = {
  loading: false,
  error: false,
  list: null,
  item: {},
};

const quiz = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'GET_QUESTION_LIST_LOADING':
    case 'GET_QUESTION_DATAILS_LOADING':
    case 'DELETE_QUESTION_LOADING':
      return {
        ...state,
        loading: true,
      };
    case questionTypes.GET_QUESTION_LIST_FAIL:
    case questionTypes.GET_QUESTION_DATAILS_FAIL:
    case questionTypes.DELETE_QUESTION_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case questionTypes.GET_QUESTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        list: payload,
      };

    case questionTypes.EDIT_QUESTION_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        item: payload,
      };
    case questionTypes.GET_QUESTION_DATAILS_SUCCESS:
      return {
        ...state,
        item: payload,
        error: false,
        loading: false,
      };
    case questionTypes.DELETE_QUESTION_SUCCESS:
      const newList = state.list.filter(item => item._id !== payload);
      return {
        ...state,
        list: newList,
        error: false,
        loading: false,
      };

    case questionTypes.DELETE_ANSWER_SUCCESS:
      const newListOfAnswers = state.item.answers.filter(
        item => item._id !== payload,
      );
      return {
        ...state,
        error: false,
        loading: false,
        item: {
          ...state.item,
          answers: newListOfAnswers,
        },
      };
    case questionTypes.EDIT_ANSWER_SUCCESS:
      const newEditedListOfAnswers = state.item.answers.reduce(
        (acc, current) =>
          current._id === payload._id
            ? [...acc, { ...current, ...payload }]
            : [...acc, current],
        [],
      );

      return {
        ...state,
        error: false,
        loading: false,
        item: {
          ...state.item,
          answers: newEditedListOfAnswers,
        },
      };
    case questionTypes.ADD_NEW_ANSWER_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        item: payload,
      };

    case questionTypes.CREATE_NEW_QUESTION_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        list: [...state.list, payload],
      };

    case questionTypes.CLEAR_QUESTION_PAGE_DATA:
      return {
        ...state,
        error: false,
        loading: false,
        item: initialState.item,
      };
    default:
      return state;
  }
};

export default quiz;
