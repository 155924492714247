import { deleteDogOptionsTypes } from './delete-dog-options.types';

export const getDeleteDogOptionsListSuccess = list => ({
  type: deleteDogOptionsTypes.GET_DELETE_DOG_OPTIONS_LIST_SUCCESS,
  payload: list,
});

export const getDeleteDogOptionsListLoading = () => ({
  type: deleteDogOptionsTypes.GET_DELETE_DOG_OPTIONS_LIST_LOADING,
});

export const getDeleteDogOptionsByIdSuccess = data => ({
  type: deleteDogOptionsTypes.GET_DELETE_DOG_OPTIONS_BY_ID_SUCCESS,
  payload: data,
});
export const getDeleteDogOptionsByIdLoading = () => ({
  type: deleteDogOptionsTypes.GET_DELETE_DOG_OPTIONS_BY_ID_LOADING,
});

export const deleteDeleteDogOptionsSuccess = id => ({
  type: deleteDogOptionsTypes.DELETE_DOG_OPTIONS_SUCCESS,
  payload: id,
});

export const deleteDogOptionsClearItem = () => ({
  type: deleteDogOptionsTypes.CLEAR_ITEM,
});
