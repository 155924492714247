import { useState } from 'react';
import { Sector } from 'recharts';

export const usePieActiveIndex = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return {
    activeIndex,
    onPieEnter,
  };
};

export const renderActiveShape = props => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius - 2}
        outerRadius={outerRadius + 5}
        fill={fill}
      />
      <text
        x={0}
        y={24}
        dy={8}
        textAnchor="start"
        fontFamily="Roboto Slab"
        fontSize="24"
        fill={fill}
      >
        {payload.name}
      </text>
      <text
        x={0}
        y={24}
        dy={30}
        textAnchor="start"
        fill={'#333'}
        fontFamily="Roboto"
        fontSize={16}
      >{`Count: ${value}`}</text>
      <text
        x={0}
        y={24}
        dy={30 + 18}
        textAnchor="start"
        fill={'gray'}
        fontFamily="Roboto"
        fontSize={16}
      >
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};
