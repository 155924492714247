import React from 'react';
import SunEditor from 'suneditor-react';

import suneditorConfiguration from '../../configs/suneditor-configuration';
import 'suneditor/dist/css/suneditor.min.css';

const Suneditor = ({ onChange, onBlur, value }) => {
  return (
    <SunEditor
      setContents={value}
      showToolbar={true}
      setOptions={suneditorConfiguration}
      height="200"
      resize={false}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};
export default Suneditor;
