import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Button, Col } from 'react-bootstrap';
import { useHistory } from 'react-router';

import CustomSpinner from '../../component/spinner';
import ConfigurableTable from '../../component/table';

import { rewardsSelector } from '../../redux/selectors/selectors';
import { getRewardsList } from '../../service/rewards';
import {
  REWARDS_TABLE_HEAD,
  TABLE_ITEM_TYPE,
} from '../../component/table/config';
import { getPhaseForCurrentRoute } from '../../helpers/rewards';

const RewardsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { list, loading } = useSelector(rewardsSelector);

  useEffect(() => {
    dispatch(getRewardsList(getPhaseForCurrentRoute()));
  }, [dispatch]);

  return (
    <Row className="mt-3 d-flex align-items-center justify-content-center w-100 ml-0 mr-0">
      {loading ? (
        <CustomSpinner />
      ) : (
        <Col sm={12} className="p-0">
          <Button
            variant="primary"
            className="mb-3"
            onClick={() => history.push(window.location.pathname + '/create')}
          >
            Add new
          </Button>
          <ConfigurableTable
            tableHeaderColumns={REWARDS_TABLE_HEAD}
            itemVariant={TABLE_ITEM_TYPE.REWARD_ITEM_TYPE}
            rows={list}
          />
        </Col>
      )}
    </Row>
  );
};

export default RewardsList;
