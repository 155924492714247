export const TABLE_ITEM_TYPE = {
  DR_POOP_USER_DATA_ITEM_TYPE: 'DR_POOP_USER_DATA_ITEM_TYPE',
  DR_POOP_STATEMENT_ITEM_TYPE: 'DR_POOP_STATEMENT_ITEM_TYPE',
  QUIZ_QUESTION_ITEM_TYPE: 'QUIZ_QUESTION_ITEM_TYPE',
  REWARD_ITEM_TYPE: 'REWARD_ITEM_TYPE',
  SCORE_WEIGHT_GROUP_ITEM_TYPE: 'SCORE_WEIGHT_GROUP_ITEM_TYPE',
  SCORE_FITNESS_ITEM_TYPE: 'SCORE_FITNESS_ITEM_TYPE',
  REWARD_ACTION_ITEM_TYPE: 'REWARD_ACTION_ITEM_TYPE',
  COINS_MODIFICATORS_ITEM_TYPE: 'COINS_MODIFICATORS_ITEM_TYPE',
  SERVICE_TYPE_ITEM_TYPE: 'SERVICE_TYPE_ITEM_TYPE',
  ADDITIONAL_FEE_TEMPLATE_ITEM_TYPE: 'ADDITIONAL_FEE_TEMPLATE_ITEM_TYPE',
  ADDITIONAL_SERVICE_ITEM_TYPE: 'ADDITIONAL_SERVICE_ITEM_TYPE',
  PARTNER_TYPE_ITEM_TYPE: 'PARTNER_TYPE_ITEM_TYPE',
  WORKER_TYPE_ITEM_TYPE: 'WORKER_TYPE_ITEM_TYPE',
  SERVICE_PARTNER_ITEM_TYPE: 'SERVICE_PARTNER_ITEM_TYPE',
  PARTNER_PROGRAM_ITEM_TYPE: 'PARTNER_PROGRAM_ITEM_TYPE',
  DELETE_DOG_OPTIONS: 'DELETE_DOG_OPTIONS',
  DELETED_DOGS: 'DELETED_DOGS',
  CONTRACT_ITEM: 'CONTRACT_ITEM',
  REVIEWS_ITEM: 'REVIEWS_ITEM',
  DISCOUNT_REASON_ITEM: 'DISCOUNT_REASON_ITEM',
  CANCEL_REASON_ITEM: 'CANCEL_REASON_ITEM',
  COUNTRY_ITEM: 'COUNTRY_ITEM',
  CITY_ITEM: 'CITY_ITEM',
  BREED_ITEM: 'BREED_ITEM',
  NOTIFICATION_CONFIG_ITEM: 'NOTIFICATION_CONFIG_ITEM',
  FOOD_PRICES_MODIFICATORS_ITEM: 'FOOD_PRICES_MODIFICATORS_ITEM',
  SMS_CONFIGS_ITEM: 'SMS_CONFIGS_ITEM',
};

export const DR_POOP_USER_DATA_TABLE_HEAD = [
  {
    title: '#',
    columnWidth: '5%',
  },
  {
    title: 'SUBMISSION ID',
    columnWidth: '5%',
  },
  {
    title: 'SENDER ID',
    columnWidth: '10%',
  },
  {
    title: 'DOG OWNER ID',
    columnWidth: '10%',
  },
  {
    title: 'DOG ID',
    columnWidth: '10%',
  },
  {
    title: 'SENDER TYPE',
    columnWidth: '10%',
  },
  {
    title: 'AI GRADE',
    columnWidth: '10%',
  },
  {
    title: 'AI STATUS',
    columnWidth: '10%',
  },
  {
    title: 'DOGIZ GRADE',
    columnWidth: '15%',
  },
  {
    title: 'DOGIZ STATUS',
    columnWidth: '15%',
  },
  {
    title: 'IMAGE',
    columnWidth: '20%',
  },
  {
    title: 'CREATION DATE',
    columnWidth: '15%',
  },
  {
    title: 'ACTION',
    columnWidth: '15%',
  },
];

export const DR_POOP_STATEMENT_TABLE_HEAD = [
  {
    title: '#',
    columnWidth: '5%',
  },
  {
    title: 'ID',
    columnWidth: '20%',
  },
  {
    title: 'ANSWER (EN)',
    columnWidth: '30%',
  },
  {
    title: 'ANSWER (IL)',
    columnWidth: '30%',
  },
  {
    title: 'ACTION',
    columnWidth: '15%',
  },
];

export const QUIZ_QUESTION_TABLE_HEAD = [
  {
    title: 'Question title (EN)',
    columnWidth: '35%',
  },
  {
    title: 'Question title (IL)',
    columnWidth: '35%',
  },

  {
    title: 'Created date',
    columnWidth: '15%',
  },
  {
    title: 'Action',
    columnWidth: '15%',
  },
];

export const REWARDS_TABLE_HEAD = [
  {
    title: 'TITLE(EN)',
    columnWidth: '17.5%',
  },
  {
    title: 'TITLE(IL)',
    columnWidth: '17.5%',
  },
  {
    title: 'SUBTITLE(EN)',
    columnWidth: '17.5%',
  },
  {
    title: 'SUBTITLE(IL)',
    columnWidth: '17.5%',
  },

  {
    title: 'CONDITION',
    columnWidth: '10%',
  },
  {
    title: 'REWARDS',
    columnWidth: '10%',
  },
  {
    title: 'ACTION',
    columnWidth: '10%',
  },
];

export const REWARDS_ACTION_TABLE_HEAD = [
  {
    title: 'Name',
    columnWidth: '35%',
  },
  {
    title: 'Action type',
    columnWidth: '35%',
  },
  {
    title: 'Reward',
    columnWidth: '10%',
  },
  {
    title: 'Notification',
    columnWidth: '10%',
  },
  {
    title: 'Action',
    columnWidth: '10%',
  },
];

export const REWARDS_COINS_MODIFICATORS_TABLE_HEAD = [
  {
    title: 'ID',
    columnWidth: '35%',
  },
  {
    title: 'Country',
    columnWidth: '35%',
  },
  {
    title: 'Modificator',
    columnWidth: '10%',
  },
  {
    title: 'Action',
    columnWidth: '20%',
  },
];

export const SCORE_WEIGHT_GROUP_TABLE_HEAD = [
  {
    title: 'Name',
    columnWidth: '45%',
  },
  {
    title: 'Min',
    columnWidth: '20%',
  },
  {
    title: 'Max',
    columnWidth: '20%',
  },
  {
    title: 'Action',
    columnWidth: '15%',
  },
];

export const SCORE_FITNESS_TABLE_HEAD = [
  {
    title: 'Group',
    columnWidth: '35%',
  },
  {
    title: 'Value',
    columnWidth: '10%',
  },
  {
    title: 'Weight group name',
    columnWidth: '40%',
  },
  {
    title: 'Action',
    columnWidth: '15%',
  },
];

export const SERVICE_TYPE_TABLE_HEAD = [
  {
    title: 'SERVICE ID',
    columnWidth: '20%',
  },
  {
    title: 'TITLE (EN)',
    columnWidth: '10%',
  },
  {
    title: 'TITLE (IL)',
    columnWidth: '10%',
  },
  {
    title: 'DESCRIPTION(EN)',
    columnWidth: '30%',
  },
  {
    title: 'DESCRIPTION(IL)',
    columnWidth: '30%',
  },

  {
    title: 'IMAGE',
    columnWidth: '15%',
  },
  {
    title: 'ICON',
    columnWidth: '15%',
  },
  {
    title: 'ACTION',
    columnWidth: '10%',
  },
];

export const ADDITIONAL_FEE_TEMPLATE = [
  {
    title: 'ID',
    columnWidth: '10%',
  },
  {
    title: 'Title(EN)',
    columnWidth: '20%',
  },
  {
    title: 'Title(IL)',
    columnWidth: '20%',
  },
  {
    title: 'Description(EN)',
    columnWidth: '20%',
  },
  {
    title: 'Description(IL)',
    columnWidth: '20%',
  },

  {
    title: 'Actions',
    columnWidth: '10%',
  },
];

export const DELETE_DOG_OPTIONS = [
  {
    title: 'ID',
    columnWidth: '20%',
  },
  {
    title: 'Reason (EN)',
    columnWidth: '35%',
  },
  {
    title: 'Reason (IL)',
    columnWidth: '35%',
  },

  {
    title: 'Actions',
    columnWidth: '10%',
  },
];

export const CONTRACTS = [
  {
    title: 'ID',
    columnWidth: '25%',
  },
  {
    title: 'Name',
    columnWidth: '50%',
  },
  {
    title: 'Actions',
    columnWidth: '25%',
  },
];

export const DELETED_DOGS = [
  {
    title: 'ID',
    columnWidth: '25%',
  },
  {
    title: 'Name',
    columnWidth: '20%',
  },
  {
    title: 'Age',
    columnWidth: '25%',
  },
  {
    title: 'Reason',
    columnWidth: '25%',
  },
];

export const ADDITIONAL_SERVICE = [
  {
    title: 'ID',
    columnWidth: '25%',
  },
  {
    title: 'Additional service type',
    columnWidth: '25%',
  },
  {
    title: 'Price',
    columnWidth: '25%',
  },
  {
    title: 'Actions',
    columnWidth: '25%',
  },
];

export const PARTNER_TYPE_TABLE_HEAD = [
  {
    title: 'PartnerId',
    columnWidth: '10%',
  },
  {
    title: 'Title (EN)',
    columnWidth: '10%',
  },
  {
    title: 'Title (IL)',
    columnWidth: '10%',
  },
  {
    title: 'Description (EN)',
    columnWidth: '20%',
  },
  {
    title: 'Description (IL)',
    columnWidth: '20%',
  },
  {
    title: 'Type',
    columnWidth: '15%',
  },
  {
    title: 'Action',
    columnWidth: '15%',
  },
];

export const WORKER_TYPE_TABLE_HEAD = [
  {
    title: 'ID',
    columnWidth: '50%',
  },
  {
    title: 'Type',
    columnWidth: '30%',
  },
  {
    title: 'Action',
    columnWidth: '20%',
  },
];

export const SERVICE_PARTNER_TABLE_HEAD = [
  {
    title: 'Service partner id',
    columnWidth: '15%',
  },
  {
    title: 'Service id',
    columnWidth: '15%',
  },
  {
    title: 'Service name',
    columnWidth: '20%',
  },
  {
    title: 'Cancellation time',
    columnWidth: '10%',
  },
  {
    title: 'Repeat',
    columnWidth: '10%',
  },
  {
    title: 'Subscription',
    columnWidth: '10%',
  },
  {
    title: 'Price',
    columnWidth: '10%',
  },
  {
    title: 'Action',
    columnWidth: '15%',
  },
];

export const PARTNER_PROGRAM_TABLE_HEAD = [
  {
    title: 'Service partner id',
    columnWidth: '20%',
  },
  {
    title: 'Partner title',
    columnWidth: '20%',
  },
  {
    title: 'Currency',
    columnWidth: '10%',
  },
  {
    title: 'Contract type',
    columnWidth: '35%',
  },
  {
    title: 'Action',
    columnWidth: '15%',
  },
];

export const REVIEWS_TABLE_HEAD = [
  {
    title: 'Processing',
    columnWidth: '10%',
  },
  {
    title: 'Id',
    columnWidth: '20%',
  },
  {
    title: 'User Id',
    columnWidth: '20%',
  },
  {
    title: 'Rate',
    columnWidth: '10%',
  },
  {
    title: 'Text',
    columnWidth: '30%',
  },
  {
    title: 'Processing date',
    columnWidth: '10%',
  },
];

export const DISCOUNT_REASONS_TABLE_HEAD = [
  {
    title: '#',
    columnWidth: '5%',
  },
  {
    title: 'ID',
    columnWidth: '20%',
  },
  {
    title: 'REASON(EN)',
    columnWidth: '30%',
  },
  {
    title: 'REASON(IL)',
    columnWidth: '30%',
  },

  {
    title: 'ACTION',
    columnWidth: '15%',
  },
];

export const CANCEL_REASON_TABLE_HEAD = [
  {
    title: 'ID',
    columnWidth: '10%',
  },
  {
    title: 'REASON(EN)',
    columnWidth: '40%',
  },
  {
    title: 'REASON(IL)',
    columnWidth: '40%',
  },
  {
    title: 'ACTION',
    columnWidth: '10%',
  },
];

export const COUNTRY_TABLE_HEAD = [
  {
    title: 'NAME (EN)',
    columnWidth: '25%',
  },
  {
    title: 'NAME (IL)',
    columnWidth: '25%',
  },
  {
    title: 'COUNTRY CODE',
    columnWidth: '20%',
  },
  {
    title: 'PHONE CODE',
    columnWidth: '20%',
  },
  {
    title: 'ACTIONS',
    columnWidth: '10%',
  },
];

export const CITY_TABLE_HEAD = [
  {
    title: 'NAME (EN)',
    columnWidth: '25%',
  },
  {
    title: 'NAME (IL)',
    columnWidth: '25%',
  },
  {
    title: 'STATE',
    columnWidth: '20%',
  },
  {
    title: 'COUNTRY',
    columnWidth: '20%',
  },
  {
    title: 'ACTIONS',
    columnWidth: '10%',
  },
];

export const BREEDS_TABLE_HEAD = [
  {
    title: 'ID',
    columnWidth: '20%',
  },
  {
    title: 'NAME(EN)',
    columnWidth: '30%',
  },
  {
    title: 'NAME(IL)',
    columnWidth: '30%',
  },
  {
    title: 'ACTION',
    columnWidth: '20%',
  },
];

export const NOTIFICATIONS_CONFIG_TABLE_HEAD = [
  {
    title: 'DESCRIPTION',
    columnWidth: '50%',
  },
  {
    title: 'HOURS',
    columnWidth: '15%',
  },
  {
    title: 'MINUTES',
    columnWidth: '15%',
  },
  {
    title: 'ACTIONS',
    columnWidth: '20%',
  },
];

export const FOOD_PRICES_MODIFICATORS_TABLE_HEAD = [
  {
    title: 'ID',
    columnWidth: '25%',
  },
  {
    title: 'INCREASED BY, %',
    columnWidth: '25%',
  },
  {
    title: 'COUNTRY',
    columnWidth: '30%',
  },
  {
    title: 'ACTIONS',
    columnWidth: '20%',
  },
];

export const SMS_CONFIGS_TABLE_HEAD = [
  {
    title: 'ID',
    columnWidth: '20%',
  },
  {
    title: 'COUNT PER MINUTE',
    columnWidth: '15%',
  },
  {
    title: 'COUNT PER MINUTE FROM ONE IP',
    columnWidth: '15%',
  },
  {
    title: 'COUNT PER MINUTE FROM ONE PHONE NUMBER',
    columnWidth: '15%',
  },
  {
    title: 'COUNT TO SEND WARNING',
    columnWidth: '15%',
  },
  {
    title: 'BLOCK ALL',
    columnWidth: '10%',
  },
  {
    title: 'ACTIONS',
    columnWidth: '10%',
  },
];
