import AdditionalFeeTemplateCreate from './create';
import AdditionalFeeTemplateEdit from './edit';
import AdditionalFeeTemplateList from './list';

const AdditionalFeeTemplate = {
  list: AdditionalFeeTemplateList,
  create: AdditionalFeeTemplateCreate,
  edit: AdditionalFeeTemplateEdit,
};

export default AdditionalFeeTemplate;
