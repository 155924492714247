import Text from './text';
import Select from './select';
import Number from './number';
import Checkbox from './checkbox';
import TextArea from './textarea';
import Date from './date';

const Input = {
  select: Select,
  text: Text,
  number: Number,
  checkbox: Checkbox,
  textarea: TextArea,
  Date: Date,
};

export default Input;
