import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import GoBack from '../../../component/buttons/goBackButton';
import AdditionalServiceForm from '../../../component/additional-service-form/additional-service-form';

import { additionalServiceClearItem } from '../../../redux/additional-service/additional-service.action';
import {
  getAdditionalServiceById,
  updateAdditionalService,
} from '../../../service/services/additional-service';
import CustomSpinner from '../../../component/spinner';
import {
  additionalFeeTemplateSelector,
  additionalServiceSelector,
} from '../../../redux/selectors/selectors';
import { getAdditionalFeeTemplateList } from '../../../service/services/additional-fee-template';

const AdditionalServiceEdit = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = props.match.params;
  const { item } = useSelector(additionalServiceSelector);
  const { list = [], loading } = useSelector(additionalFeeTemplateSelector);

  const transformArrayToObjectByKeys = list => {
    const result = {};

    list.map(
      item => (result[item._id] = item?.title_localizations?.en || item?.title),
    );

    return result;
  };

  const setDefaultValues = item => {
    const result = { ...item };

    if (item.weekend) {
      result.weekend = item.weekend.map(item => `${item}`);
    }

    if (item.additionServiceType) {
      delete result.additionServiceType;
      result.additionServiceType = item.additionServiceType._id;
    }

    return result;
  };

  useEffect(() => {
    getAdditionalFeeTemplateList()(dispatch);
    getAdditionalServiceById(id)(dispatch);

    return () => dispatch(additionalServiceClearItem());
  }, [dispatch, id]);

  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      {Object.keys(item).length && !loading ? (
        <AdditionalServiceForm
          defaultValues={setDefaultValues(item)}
          additionalServiceTypes={transformArrayToObjectByKeys(list)}
          onSubmit={data =>
            updateAdditionalService(id, data, history)(dispatch)
          }
        />
      ) : (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default AdditionalServiceEdit;
