export const validationRules = {
  name_localizations: {
    en: {
      required: 'Name (EN) field is required!',
    },
    il: {
      required: 'Name (IL) field is required!',
    },
  },
};
