export const DRAGGABLE_ITEM_TYPE = {
  SPLASH_PHOTOS_ITEM: 'SPLASH_PHOTOS_ITEM',
};

export const SPLASH_PHOTOS_DRAGGABLE_TABLE_HEAD = [
  {
    title: '#',
    columnWidth: '5%',
  },
  {
    title: 'IMAGE',
    columnWidth: '25%',
  },
  {
    title: 'TITLE (EN)',
    columnWidth: '15%',
  },
  {
    title: 'TITLE (IL)',
    columnWidth: '15%',
  },
  {
    title: 'SUBTITLE (EN)',
    columnWidth: '15%',
  },
  {
    title: 'SUBTITLE (IL)',
    columnWidth: '15%',
  },

  {
    title: 'ACTION',
    columnWidth: '10%',
  },
];
