export const validationRules = {
  answer_localizations: {
    en: {
      required: 'Title field is required!',
    },
    il: {
      required: 'Title field is required!',
    },
  },
};
