const countryTypes = {
  OPERATION_LOADING: 'countries/OPERATION_LOADING',
  OPERATION_FAILED: 'countries/OPERATION_FAILED',

  GET_COUNTRIES_LIST: 'countries/GET_COUNTRIES_LIST',
  GET_COUNTRY_BY_ID: 'countries/GET_COUNTRY_BY_ID',
  DELETE_COUNTRY: 'countries/DELETE_COUNTRY',

  CLEAR_SELECTED_COUNTRY: 'countries/CLEAR_SELECTED_COUNTRY',
};

export default countryTypes;
