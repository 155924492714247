import { cityTypes } from './city.types';

export const getCityListSuccess = list => ({
  type: cityTypes.GET_CITY_LIST_SUCCESS,
  payload: list,
});

export const getCityListLoading = list => ({
  type: cityTypes.GET_CITY_LIST_LOADING,
  payload: list,
});

export const getCityListFail = list => ({
  type: cityTypes.GET_CITY_LIST_FAIL,
  payload: list,
});

export const clearCityItem = () => ({
  type: cityTypes.CLEAR_ITEM,
  payload: null,
});

export const clearCity = () => ({
  type: cityTypes.CLEAR,
  payload: null,
});

export const setCityItem = ({ _id, countryId, name }) => ({
  type: cityTypes.SET_ITEM,
  payload: { _id, countryId, name },
});

export const setCitySearch = search => ({
  type: cityTypes.SET_SEARCH,
  payload: search,
});
