import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SmsConfigsItem = ({ row }) => {
  return (
    <tr>
      <th scope="col" className="text-center align-middle">
        {row?._id || '-'}
      </th>
      <th scope="col" className="text-center align-middle font-weight-normal">
        {row?.smsCountAllowedPerMinute || '-'}
      </th>
      <th scope="col" className="text-center align-middle font-weight-normal">
        {row?.smsAllowedFromOneIpPerMinute || '-'}
      </th>
      <th scope="col" className="text-center align-middle font-weight-normal">
        {row?.smsAllowedFromOneNumberPerMinute || '-'}
      </th>
      <th scope="col" className="text-center align-middle font-weight-normal">
        {row?.smsCountToSendWarning || '-'}
      </th>
      <th scope="col" className="text-center align-middle font-weight-normal">
        {row?.blockAll?.toString() || '-'}
      </th>
      <th scope="col" className="align-middle text-center">
        <Link to={`/sms-configs/${row._id}`}>
          <Button size={'sm'} variant="success">
            <i className="fa fa-pencil"></i>
          </Button>
        </Link>
      </th>
    </tr>
  );
};

export default SmsConfigsItem;
