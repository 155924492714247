export const publicationTypes = {
  GET_PUBLICATION_LIST_LOADING: 'publication/GET_PUBLICATION_LIST_LOADING',
  GET_PUBLICATION_LIST_SUCCESS: 'publication/GET_PUBLICATION_LIST_SUCCESS',
  GET_PUBLICATION_LIST_FAIL: 'publication/GET_PUBLICATION_LIST_FAIL',
  //
  DELETE_PUBLICATION_LOADING: 'publication/DELETE_PUBLICATION_LOADING',
  DELETE_PUBLICATION_SUCCESS: 'publication/DELETE_PUBLICATION_SUCCESS',
  DELPOSTBLICATION_FAIL: 'publication/DELETE_PUBLICATION_FAIL',
  //
  POST_PUBLICATION_LOADING: 'publication/POST_PUBLICATION_LOADING',
  POST_PUBLICATION_SUCCESS: 'publication/POST_PUBLICATION_SUCCESS',
  POST_PUBLICATION_FAIL: 'publication/POST_PUBLICATION_FAIL',
  //
  GET_PUBLICATION_ITEM_BY_ID_LOADING:
    'publication/GET_PUBLICATION_ITEM_BY_ID_LOADING',
  GET_PUBLICATION_ITEM_BY_ID_SUCCESS:
    'publication/GET_PUBLICATION_ITEM_BY_ID_SUCCESS',
  GET_PUBLICATION_ITEM_BY_ID_FAIL:
    'publication/GET_PUBLICATION_ITEM_BY_ID_FAIL',
  //
  PUT_PUBLICATION_ITEM_BY_ID_LOADING:
    'publication/PUT_PUBLICATION_ITEM_BY_ID_LOADING',
  PUT_PUBLICATION_ITEM_BY_ID_SUCCESS:
    'publication/PUT_PUBLICATION_ITEM_BY_ID_SUCCESS',
  PUT_PUBLICATION_ITEM_BY_ID_FAIL:
    'publication/PUT_PUBLICATION_ITEM_BY_ID_FAIL',

  CLEAR_PUBLICATION_ITEM: 'publication/CLEAR_PUBLICATION_ITEM',
};
