import fetchWithAuth from '../index';

import MESSAGES_FOR_USER from '../../constants/messages/en';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';

import { destinationUrls } from '../../component/menu/constants';
import {
  getDeleteDogOptionsByIdLoading,
  getDeleteDogOptionsByIdSuccess,
  getDeleteDogOptionsListLoading,
  getDeleteDogOptionsListSuccess,
  deleteDeleteDogOptionsSuccess,
} from '../../redux/delete-dog-options/delete-dog-options.action';

export const getDeleteDogOptionsList = () => async dispatch => {
  dispatch(getDeleteDogOptionsListLoading());

  let result = await fetchWithAuth({
    path: `/dog/delete-reason`,
    method: 'GET',
  });

  dispatch(getDeleteDogOptionsListSuccess(await result.json()));
  return;
};

export const createDeleteDogOptions = (data, history) => async dispatch => {
  const result = await fetchWithAuth({
    path: `/dog/delete-reason`,
    method: 'POST',
    body: JSON.stringify(data),
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.ADDITIONA_FEE_TEMPLATE.CREATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.ADDITIONA_FEE_TEMPLATE.CREATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  history.push(destinationUrls.DELETE_DOG_OPTIONS);
  return;
};

export const getDeleteDogOptionsById = id => async dispatch => {
  dispatch(getDeleteDogOptionsByIdLoading());
  let result = await fetchWithAuth({
    path: `/dog/delete-reason/${id}`,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.DELETE_DOG_OPTIONS.UPDATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.DELETE_DOG_OPTIONS.UPDATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  dispatch(getDeleteDogOptionsByIdSuccess(await result.json()));
};

export const deleteDeleteDogOptionsById = id => async dispatch => {
  let result = await fetchWithAuth({
    path: `/dog/delete-reason/${id}`,
    method: 'DELETE',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.DELETE_DOG_OPTIONS.DELETE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.DELETE_DOG_OPTIONS.DELETE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  dispatch(deleteDeleteDogOptionsSuccess(id));
};

export const updateDeleteDogOptions = (id, data, history) => async dispatch => {
  let result = await fetchWithAuth({
    path: `/dog/delete-reason/${id}`,
    method: 'PUT',
    body: JSON.stringify(data),
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.DELETE_DOG_OPTIONS.UPDATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.DELETE_DOG_OPTIONS.UPDATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  history.push(destinationUrls.DELETE_DOG_OPTIONS);
  return;
};
