import { countryTypes } from './country.types';

export const getCountryListSuccess = list => ({
  type: countryTypes.GET_COUNTRY_LIST_BY_SEARCH_SUCCESS,
  payload: list,
});

export const getCountryListLoading = list => ({
  type: countryTypes.GET_COUNTRY_LIST_BY_SEARCH_LOADING,
  payload: list,
});

export const getCountryListFail = list => ({
  type: countryTypes.GET_COUNTRY_LIST_FAIL,
  payload: list,
});

export const setCountryItem = ({ _id, name }) => ({
  type: countryTypes.SET_ITEM,
  payload: { _id, name },
});

export const clearCountryItem = () => ({
  type: countryTypes.CLEAR_ITEM,
  payload: null,
});

export const setCountrySearch = search => ({
  type: countryTypes.SET_SEARCH,
  payload: search,
});
