import { React } from 'react';
import { Form, Col, Row, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Input from '../inputs';
import { validationRulesForQuizSectionEditForm } from './validation';

const TitleFormView = ({ onSubmit, defaultValues }) => {
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="quiz-section-edit-form">
      <Row className="d-flex align-items-center">
        <Col className="mt-3">
          <Row className="p-3">
            <Input.text
              register={register}
              label="Title (en)"
              name="title_localizations.en"
              validationRules={
                validationRulesForQuizSectionEditForm.title_localizations.en
              }
              error={errors.title_localizations?.en}
            />
          </Row>
          <Row className="p-3">
            <Input.text
              register={register}
              label="Title (il)"
              name="title_localizations.il"
              validationRules={
                validationRulesForQuizSectionEditForm.title_localizations.il
              }
              error={errors.title_localizations?.il}
            />
          </Row>

          <Row className="p-3">
            <Input.number
              register={register}
              label="Reward"
              name="reward"
              validationRules={validationRulesForQuizSectionEditForm.reward}
              error={errors.reward}
            />
          </Row>

          <Button
            disabled={!isDirty}
            type="submit"
            variant="primary"
            className="mt-3 w-100"
          >
            Save changes
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default TitleFormView;
