import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Dropdown } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { withRouter } from 'react-router';

import { workerValidation } from './workerValidation';
import { createPartnerWorker } from '../../service/partners/partnerWorker';
import { getCountry } from '../../service/user';
import { SearchDropdown } from '../inputs/searchDropdown';
import { userCountrySelector } from '../../redux/selectors/selectors';
import { setCountryItem } from '../../redux/user/country/country.action';

const PartnerWorkerForm = ({
  defaultValues = {},
  programIds = null,
  normalizedPrograms = null,
  type = '',
  contractsList = [],
}) => {
  const dispatch = useDispatch();
  const checkboxesRef = useRef([]);
  const {
    list: countryList = [],
    item: selectedCountry,
    loading: countryLoading,
    search,
  } = useSelector(userCountrySelector);

  const {
    register,
    handleSubmit,
    control,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: defaultValues,
  });

  useEffect(() => {
    checkboxesRef.current.forEach((checkboxEl, index) => {
      checkboxEl.checked = !!defaultValues.partners[index];
    });
  }, [defaultValues.partners]);

  const formReset = () => {
    reset(defaultValues);
    checkboxesRef.current.forEach(checkboxEl => {
      checkboxEl.checked = false;
    });
  };

  const normalizedCountryOptions = countryList
    ? Object.fromEntries(
        countryList.map(({ name, name_localizations, _id }) => [
          _id,
          name_localizations?.en || name,
        ]),
      )
    : [];

  const handleCountrySearch = search => {
    dispatch(getCountry(search));
  };

  const onSubmit = async data => {
    const partners = data.partners.filter(Boolean);
    if (!partners.length) {
      setError('partners', {
        type: 'manual',
        message: 'Worker should has at least one partner program',
      });
      return;
    }
    const body = { ...data, partners };

    if (!data.contract) {
      body.contract = contractsList[0]._id;
    }
    formReset();
    type === 'CREATE' && dispatch(createPartnerWorker(body));
  };

  return (
    <Form className="border p-4 mt-4" onSubmit={handleSubmit(onSubmit)}>
      <Form.Label>Email</Form.Label>
      <Form.Control
        {...register('email', {
          required: 'Email is required',
          validate: workerValidation.email,
        })}
        placeholder="Email..."
      />
      {errors.email && <p className="mt-1">{errors.email.message}</p>}
      <Form.Label className="mt-3">Phone</Form.Label>
      <Form.Control
        {...register('phone', {
          required: 'Phone is required',
        })}
        placeholder="Phone..."
      />
      {errors.phone && <p className="mt-1">{errors.phone.message}</p>}
      <Form.Label className="mt-3">Partner programs</Form.Label>
      {programIds.map((id, index) => (
        <Controller
          key={id}
          control={control}
          name={`partners.${index}`}
          render={({ field: { onChange } }) => {
            return (
              <Form.Check
                ref={el => (checkboxesRef.current[index] = el)}
                onChange={({ target }) => {
                  if (errors.partners && target.checked)
                    clearErrors('partners');
                  onChange(target.checked && id);
                }}
                defaultValue={!!id}
                type={'checkbox'}
                id={`program-${id}`}
                label={
                  normalizedPrograms[id].partnerType.title_localizations?.en ||
                  normalizedPrograms[id].partnerType.title
                }
              />
            );
          }}
        />
      ))}
      {errors.partners && <p className="mt-1">{errors.partners.message}</p>}
      <Form.Label className="mt-3">Contract</Form.Label>
      <Controller
        control={control}
        name="contract"
        render={({ field: { onChange, value } }) => {
          return (
            <Dropdown onSelect={onChange}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {contractsList.find(item => item._id === value)?.name ||
                  contractsList[0].name}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {contractsList.map(item => (
                  <Dropdown.Item key={item._id} eventKey={item._id}>
                    {item.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          );
        }}
      />
      <Form.Label className="mt-3">Country</Form.Label>
      <Controller
        control={control}
        name="address.country"
        rules={{ required: true }}
        render={({ field: { onChange } }) => {
          const handleSelect = newValue => {
            onChange(newValue);
            dispatch(
              setCountryItem({
                _id: newValue,
                name: normalizedCountryOptions[newValue],
              }),
            );
          };
          return (
            <SearchDropdown
              normalizedOptions={normalizedCountryOptions}
              onSearch={handleCountrySearch}
              search={search}
              selectedLabel={selectedCountry.name}
              onSelect={handleSelect}
              isLoading={countryLoading}
            />
          );
        }}
      />
      <Button className="mt-4" variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

export default withRouter(PartnerWorkerForm);
