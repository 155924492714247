import { authTypes } from './auth.types';

export const setPhoneSuccess = (code = false) => ({
  type: authTypes.SET_PHONE_SUCCESS,
  payload: code,
});

export const setPhoneFail = payload => ({
  type: authTypes.SET_PHONE_FAIL,
  payload,
});

export const checkCodeSuccess = payload => ({
  type: authTypes.CHECK_CODE_SUCCESS,
  payload,
});

export const checkCodeFail = payload => ({
  type: authTypes.CHECK_CODE_FAIL,
  payload,
});

export const checkRoleFail = () => ({
  type: authTypes.CHECK_ROLE_FAIL,
});

export const refreshTokenLoading = () => ({
  type: authTypes.REFRESH_TOKEN_LOADING,
});

export const refreshTokenSucess = payload => ({
  type: authTypes.REFRESH_TOKEN_SUCCESS,
  payload,
});

export const refreshTokenFail = payload => ({
  type: authTypes.REFRESH_TOKEN_FAIL,
  payload,
});

export const logOut = () => ({
  type: authTypes.SET_LOGOUT,
});
