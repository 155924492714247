import { Col, Row } from 'react-bootstrap';
import { Switch, withRouter, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PrivateRoute from '../component/privateRoute/PrivateRoute';
import Menu from '../component/menu';
import ErrorBoundary from '../component/errorBoundary';

const Router = props => {
  const { listPath = [] } = props;
  const isAuth = useSelector(state => state.auth.authSession);

  const route = () =>
    listPath.map(({ path, component: Component, isExact }) => (
      <PrivateRoute
        key={path}
        path={path}
        exact={isExact}
        component={Component}
      />
    ));

  const setColumnWidth = () => {
    return isAuth ? 'col-lg-10 col-9 overflow-auto vh-100' : 'col-12';
  };

  return (
    <Row className="min-w-100 m-0">
      {isAuth && (
        <Col className="col-lg-2 col-3 bg-white text-light min-vh-100 menu">
          <Menu />
        </Col>
      )}

      <Col className={setColumnWidth()}>
        <ErrorBoundary>
          <Switch>
            {route()}
            <Redirect
              to={{
                pathname: '/publication',
              }}
            />
          </Switch>
        </ErrorBoundary>
      </Col>
    </Row>
  );
};

export default withRouter(Router);
