import { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

// State management
import { foodPricesModificatorsSelector } from '../../redux/selectors/selectors';
import { FOOD_PRICES_MODIFICATORS_SERVICES } from '../../service/food-prices-modificators';

// Components
import GoBack from '../../component/buttons/goBackButton';
import CustomSpinner from '../../component/spinner';
import FoodPricesModificatorsForm from './components/food-prices-modificators-form/FoodPricesModificatorsForm';

const FoodPricesModificatorsEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { loading, selectedItem } = useSelector(foodPricesModificatorsSelector);

  const defaultValues = useMemo(() => {
    if (!selectedItem) return;

    return {
      priceIncreasePercent: selectedItem?.priceIncreasePercent || 0,
      country: [
        selectedItem?.country?.name_localizations?.en ||
          selectedItem?.country?.name,
        selectedItem?.country?._id,
      ],
    };
  }, [selectedItem]);

  useEffect(() => {
    if (id) {
      dispatch(FOOD_PRICES_MODIFICATORS_SERVICES.getById(id));
    }
    /* return () => dispatch(clearSelectedCoinsModificator()); */
  }, [id, dispatch]);

  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      {loading ? (
        <CustomSpinner />
      ) : (
        <FoodPricesModificatorsForm id={id} defaultValues={defaultValues} />
      )}
    </Col>
  );
};

export default FoodPricesModificatorsEdit;
