import { workerTypes } from './workerType.types';

const initialState = {
  loading: null,
  error: null,
  list: [],
};

const workerTypeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    //loading
    case workerTypes.GET_WORKER_TYPES_LOADING:
    case workerTypes.CREATE_WORKER_TYPES_LOADING:
    case workerTypes.UPDATE_WORKER_TYPES_LOADING:
    case workerTypes.DELETE_WORKER_TYPES_LOADING:
    case workerTypes.GET_WORKER_TYPE_BY_ID_LOADING:
      return { ...state, loading: true };

    // success
    case workerTypes.GET_WORKER_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload,
      };
    case workerTypes.GET_WORKER_TYPE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, payload],
      };
    case workerTypes.DELETE_WORKER_TYPES_SUCCESS:
    case workerTypes.UPDATE_WORKER_TYPES_SUCCESS:
    case workerTypes.CREATE_WORKER_TYPES_SUCCESS:
      return { ...state, loading: false };

    //failed
    case workerTypes.GET_WORKER_TYPES_FAIL:
    case workerTypes.GET_WORKER_TYPE_BY_ID_FAIL:
    case workerTypes.CREATE_WORKER_TYPES_FAIL:
    case workerTypes.UPDATE_WORKER_TYPES_FAIL:
    case workerTypes.DELETE_WORKER_TYPES_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default workerTypeReducer;
