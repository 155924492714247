import { rewardsTypes } from './rewards.types';

const initialState = {
  loading: true,
  list: [],
  selectedItem: null,
  coinsModificators: {
    loading: true,
    list: [],
    totalCount: 0,
    selectedItem: null,
  },
};

const rewards = (state = initialState, { type, payload }) => {
  switch (type) {
    case rewardsTypes.GET_REWARDS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload,
      };
    case rewardsTypes.GET_REWARDS_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };

    case rewardsTypes.DELETE_REWARDS_LIST_ITEM:
      return {
        ...state,
        list: state.list.filter(item => item._id !== payload),
      };

    case rewardsTypes.REWARD_ITEM_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case rewardsTypes.GET_REWARD_ITEM_SUCCESS:
      const { id, _id, ...rest } = payload;

      return {
        ...state,
        loading: false,
        selectedItem: rest,
      };

    case rewardsTypes.GET_REWARD_ITEM_FAIL:
      return {
        ...state,
        loading: false,
      };

    case rewardsTypes.CLEAR_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: null,
      };
    case rewardsTypes.CLEAR_LIST_ON_ACTIONS_PAGE:
      return {
        ...state,
        list: null,
        loading: true,
      };

    case rewardsTypes.SET_REWARDS_LOADER:
      return {
        ...state,
        loading: payload,
      };

    //COINS MODIFICATORS
    case rewardsTypes.GET_COINS_MODIFICATORS_LOADING:
    case rewardsTypes.GET_COINS_MODIFICATOR_BY_ID_LOADING:
    case rewardsTypes.UPDATE_COINS_MODIFICATORS_LOADING:
    case rewardsTypes.CREATE_COINS_MODIFICATORS_LOADING:
      return {
        ...state,
        coinsModificators: {
          ...state.coinsModificators,
          loading: true,
        },
      };
    case rewardsTypes.GET_COINS_MODIFICATORS_SUCCESS:
      return {
        ...state,
        coinsModificators: {
          ...state.coinsModificators,
          list: payload.result,
          totalCount: payload.totalCount,
          loading: false,
        },
      };
    case rewardsTypes.GET_COINS_MODIFICATOR_BY_ID_SUCCESS:
      return {
        ...state,
        coinsModificators: {
          ...state.coinsModificators,
          selectedItem: payload,
          loading: false,
        },
      };

    case rewardsTypes.GET_COINS_MODIFICATOR_BY_ID_FAILED:
    case rewardsTypes.GET_COINS_MODIFICATORS_FAILED:
    case rewardsTypes.UPDATE_COINS_MODIFICATORS_FAILED:
    case rewardsTypes.CREATE_COINS_MODIFICATORS_FAILED:
      return {
        ...state,
        loading: false,
      };

    case rewardsTypes.CLEAR_COINS_MODIFICATORS_STATE:
      return {
        ...state,
        coinsModificators: {
          ...state.coinsModificators,
          list: [],
          totalCount: 0,
          loading: true,
        },
      };
    case rewardsTypes.CLEAR_SELECTED_COINS_MODIFICATOR:
      return {
        ...state,
        coinsModificators: {
          ...state.coinsModificators,
          selectedItem: null,
          loading: true,
        },
      };

    default:
      return state;
  }
};

export default rewards;
