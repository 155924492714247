import { authTypes } from './auth.types';

const initialSate = {
  loading: false,
  error: false,
  step: 'setPhone',
  code: 0,
  authSession: JSON.parse(localStorage.getItem('auth')) || false,
};

const auth = (state = initialSate, { type, payload }) => {
  switch (type) {
    case 'SET_PHONE_LOADING':
    case 'CHECK_CODE_LOADING':
    case authTypes.REFRESH_TOKEN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case authTypes.SET_PHONE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case authTypes.SET_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
        code: payload?.code || '',
        step: 'checkCode',
      };
    case authTypes.CHECK_CODE_FAIL:
      return {
        ...state,
        step: 'checkCode',
        loading: false,
      };
    case authTypes.CHECK_ROLE_FAIL:
      return {
        ...state,
        step: 'setPhone',
        loading: false,
      };
    case authTypes.CHECK_CODE_SUCCESS:
      //This is a temporary solution, it is necessary to write a better solution
      if (payload.auth) {
        localStorage.setItem('auth', JSON.stringify(payload.auth));

        return {
          ...state,
          step: 'mainPage',
          authSession: payload.auth,
          loading: false,
        };
      }

      return {
        ...state,
        step: 'setPhone',
        loading: false,
      };
    case authTypes.REFRESH_TOKEN_SUCCESS:
      localStorage.setItem('auth', JSON.stringify(payload.auth));
      return {
        ...state,
        authSession: payload.auth,
        loading: false,
      };
    case authTypes.REFRESH_TOKEN_FAIL:
    case authTypes.SET_LOGOUT:
      localStorage.removeItem('auth');
      return {
        ...state,
        step: 'setPhone',
        loading: false,
        authSession: false,
      };
    default:
      return state;
  }
};

export default auth;
