import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { changeConfirmStatus } from '../../../redux/confirm/confirm.action';
import { confirmationKeys } from '../../../constant';

const ServicePartnerItem = ({ row }) => {
  const dispatch = useDispatch();

  const formatCancellationTime = time => {
    if (time) {
      if (time.toString().length < 2) {
        return '0' + time.toString() + ':00';
      } else {
        return time.toString() + ':00';
      }
    } else {
      return '-';
    }
  };

  return (
    <tr>
      <td className="text-center align-middle">{row._id}</td>
      <td className="text-center align-middle">{row.service._id}</td>
      <td className="text-left align-middle">
        EN: {row.service?.title_localizations?.en || row?.service?.title || '-'}
        <br />
        IL: {row.service?.title_localizations?.il || '-'}
      </td>
      <td className="text-center align-middle">
        {formatCancellationTime(row.cancellation_time)}
      </td>
      <td className="text-center align-middle">{row.repeat ? 'Yes' : 'No'}</td>
      <td className="text-center align-middle">
        {row.subscription ? 'Yes' : 'No'}
      </td>
      <td className="text-center align-middle">{row.price}</td>

      <td className="align-middle text-center">
        <Link to={`/service-partner/${row._id}`}>
          <Button size={'sm'} variant="success" className="m-2">
            <i className="fa fa-pencil"></i>
          </Button>
        </Link>

        <Button
          size={'sm'}
          variant="danger"
          className="m-2"
          onClick={() =>
            dispatch(
              changeConfirmStatus({
                isVisible: true,
                content: row._id,
                type: confirmationKeys.servicePartnerDeleteItem,
                actionParams: [row._id],
              }),
            )
          }
        >
          <i className="fa fa-trash-o"></i>
        </Button>
      </td>
    </tr>
  );
};

export default ServicePartnerItem;
