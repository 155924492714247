export const validationRules = {
  name_localizations: {
    en: {
      required: 'Name field is required!',
    },
    il: {
      required: 'Name field is required!',
    },
  },
  countryCode: {
    required: 'Country code field is required!',
    validate: value => {
      const reg = /[\uD83C][\uDDE6-\uDDFF][\uD83C][\uDDE6-\uDDFF]/;
      return !reg.test(value) ? 'Must be emoji flag' : undefined;
    },
  },
  flag: {
    required: 'Flag link field is required!',
    validate: value => {
      const reg =
        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;

      return !reg.test(value) ? 'Must be URL format' : undefined;
    },
  },
  phoneCode: {
    required: 'Phone code field is required!',
    validate: value =>
      value.charAt(0) !== '+' ? 'First symbol must be "+"' : undefined,
  },
};
