import fetchWithAuth from '../index';
import {
  getReviewsFail,
  getReviewsLoading,
  getReviewsSuccess,
  updateReviewFailed,
  updateReviewLoading,
  updateReviewSuccess,
} from '../../redux/reviews/reviews.actions';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import MESSAGES_FOR_USER from '../../constants/messages/en';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';

export const getReviews = () => async dispatch => {
  let url = '/review/';

  dispatch(getReviewsLoading());

  let response = await fetchWithAuth({ path: url });

  if (response.ok) {
    const result = await response.json();

    dispatch(getReviewsSuccess(result.item));
    return;
  } else {
    dispatch(getReviewsFail(response.statusText));
  }
};

export const updateReview = (id, body) => async dispatch => {
  let url = '/review/' + id;

  dispatch(updateReviewLoading());

  let response = await fetchWithAuth({
    path: url,
    method: 'PUT',
    body: JSON.stringify(body),
  });

  if (response.ok) {
    const result = await response.json();

    dispatch(updateReviewSuccess(result));
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.REVIEWS.UPDATE_SUCCESS.TITLE,
        content: MESSAGES_FOR_USER.REVIEWS.UPDATE_SUCCESS.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );
    return;
  } else {
    dispatch(updateReviewFailed(response.statusText));
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.REVIEWS.UPDATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.REVIEWS.UPDATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
  }
};
