import { questionTypes } from './question.types';

export const getQuestionListSuccess = list => ({
  type: questionTypes.GET_QUESTION_LIST_SUCCESS,
  payload: list,
});

export const getQuestionListFail = error => ({
  type: questionTypes.GET_QUESTION_LIST_FAIL,
  payload: error,
});

export const getQuestionDetailsSuccess = section => ({
  type: questionTypes.GET_QUESTION_DATAILS_SUCCESS,
  payload: section,
});

export const getQuestionDetailsFail = error => ({
  type: questionTypes.GET_QUESTION_DATAILS_FAIL,
  payload: error,
});

export const editQuestionSuccess = section => ({
  type: questionTypes.EDIT_QUESTION_SUCCESS,
  payload: section,
});

export const editQuestionFail = error => ({
  type: questionTypes.EDIT_QUESTION_FAIL,
  payload: error,
});

export const deleteQuestionSuccess = id => ({
  type: questionTypes.DELETE_QUESTION_SUCCESS,
  payload: id,
});

export const deleteQuestionFail = error => ({
  type: questionTypes.DELETE_QUESTION_FAIL,
  payload: error,
});

export const deleteAnswerSuccess = id => ({
  type: questionTypes.DELETE_ANSWER_SUCCESS,
  payload: id,
});

export const deleteAnswerFail = error => ({
  type: questionTypes.DELETE_ANSWER_FAIL,
  payload: error,
});

export const editAnswerSuccess = payload => ({
  type: questionTypes.EDIT_ANSWER_SUCCESS,
  payload,
});

export const editAnswerFail = error => ({
  type: questionTypes.EDIT_ANSWER_FAIL,
  payload: error,
});

export const addNewAnswerSuccess = payload => ({
  type: questionTypes.ADD_NEW_ANSWER_SUCCESS,
  payload,
});

export const addNewAnswerFail = error => ({
  type: questionTypes.ADD_NEW_ANSWER_FAIL,
  payload: error,
});

export const clearQuestionPageData = () => ({
  type: questionTypes.CLEAR_QUESTION_PAGE_DATA,
});

export const createNewQuestionSuccess = payload => ({
  type: questionTypes.CREATE_NEW_QUESTION_SUCCESS,
  payload,
});

export const createNewQuestionFail = error => ({
  type: questionTypes.CREATE_NEW_QUESTION_FAIL,
  payload: error,
});
