import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { changeConfirmStatus } from '../../../redux/confirm/confirm.action';
import { confirmationKeys } from '../../../constant';

const DrPoopStatementItem = ({ index, row }) => {
  const dispatch = useDispatch();

  return (
    <tr>
      <th scope="col" className="text-center align-middle font-weight-normal">
        {index + 1}
      </th>
      <th scope="col" className="text-center align-middle">
        {row._id}
      </th>
      <th scope="col" className="align-middle font-weight-normal">
        {row.answer_localizations?.en || row.answer || '-'}
      </th>
      <th scope="col" className="align-middle font-weight-normal">
        {row.answer_localizations?.il || '-'}
      </th>

      <th scope="col" className="align-middle text-center">
        <Link to={`/dr-poop/statements/${row._id}`}>
          <Button size={'sm'} variant="success" className="mr-3">
            <i className="fa fa-pencil"></i>
          </Button>
        </Link>

        <Button
          size={'sm'}
          variant="danger"
          onClick={() =>
            dispatch(
              changeConfirmStatus({
                isVisible: true,
                content: row.answer,
                customLabel: 'Answer',
                type: confirmationKeys.questionDrPoopStatementTableDeleteItem,
                actionParams: [row._id],
              }),
            )
          }
        >
          <i className="fa fa-trash-o"></i>
        </Button>
      </th>
    </tr>
  );
};

export default DrPoopStatementItem;
