import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Row, Col, Button, Form } from 'react-bootstrap';

import Input from '../../../../component/inputs';
import { SearchDropdown } from '../../../../component/inputs/searchDropdown';
import { userCountrySelector } from '../../../../redux/selectors/selectors';
import {
  clearCountryItem,
  setCountryItem,
} from '../../../../redux/user/country/country.action';
import { getCountry } from '../../../../service/user';
import { FOOD_PRICES_MODIFICATORS_SERVICES } from '../../../../service/food-prices-modificators';
import { validationRules } from './validation';

const FoodPricesModificatorsForm = ({
  defaultValues = { country: ['', ''], priceIncreasePercent: 0 },
  id = null,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      priceIncreasePercent: defaultValues?.priceIncreasePercent,
      country: defaultValues?.country[1],
    },
  });

  useEffect(() => {
    if (id && defaultValues) {
      reset({ priceIncreasePercent: defaultValues.priceIncreasePercent });
    }
  }, [defaultValues, reset, id]);

  const {
    list: countryList = [],
    item: selectedCountry,
    loading: countryLoading,
    search,
  } = useSelector(userCountrySelector);

  const normalizedCountryOptions = countryList
    ? Object.fromEntries(
        countryList.map(({ name_localizations, _id }) => [
          _id,
          name_localizations?.en,
        ]),
      )
    : [];

  const handleCountrySearch = search => {
    dispatch(getCountry(search));
  };

  useEffect(() => {
    return () => {
      dispatch(clearCountryItem());
    };
  }, [dispatch]);

  const onSubmit = body => {
    if (id) {
      delete body.country;
      dispatch(FOOD_PRICES_MODIFICATORS_SERVICES.update({ id, body })).then(
        () => history.goBack(),
      );
      return;
    }

    dispatch(FOOD_PRICES_MODIFICATORS_SERVICES.create(body)).then(() =>
      history.goBack(),
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-100"
      id="coins-modificator-form"
    >
      {!id && (
        <Row className="pl-3 pr-3 pt-3">
          <Controller
            control={control}
            name="country"
            rules={{ required: 'This field is required' }}
            render={({ field: { onChange } }) => {
              const handleSelect = newValue => {
                onChange(newValue);
                dispatch(
                  setCountryItem({
                    _id: newValue,
                    name: normalizedCountryOptions[newValue],
                  }),
                );
              };
              return (
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <SearchDropdown
                    normalizedOptions={normalizedCountryOptions}
                    onSearch={handleCountrySearch}
                    search={search}
                    selectedLabel={
                      selectedCountry.name || defaultValues?.country[0]
                    }
                    onSelect={handleSelect}
                    isLoading={countryLoading}
                  />
                  <Form.Text>{errors.country?.message}</Form.Text>
                </Form.Group>
              );
            }}
          />
        </Row>
      )}
      <Row className="pl-3 pr-3 pt-3">
        <Col>
          <Input.number
            register={register}
            label="Modificator, %"
            name="priceIncreasePercent"
            validationRules={validationRules.priceIncreasePercent}
            error={errors.priceIncreasePercent}
          />
        </Col>
      </Row>
      <Row className="mt-5 ml-0 mr-0">
        <Button className="w-100 p-3" type="submit">
          Save
        </Button>
      </Row>
    </form>
  );
};

export default FoodPricesModificatorsForm;
