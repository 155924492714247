import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Col, Row } from 'react-bootstrap';
import GoBack from '../../../component/buttons/goBackButton';

import './styles.css';
import { getReasonById } from '../../../service/subscription/reason';
import { clearItemState } from '../../../redux/subscription/reason/reason.action';
import { reasonSelector } from '../../../redux/selectors/selectors';
import CancelReasonForm from '../../../component/cancelReasonForm/cancel-reason-form';
import CustomSpinner from '../../../component/spinner';

const ReasonEdit = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { item } = useSelector(reasonSelector);

  useEffect(() => {
    id && dispatch(getReasonById(id));

    return () => dispatch(clearItemState());
  }, [dispatch, id]);

  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>

      {!item ? (
        <CustomSpinner />
      ) : (
        <CancelReasonForm
          defaultValues={{
            reason_localizations: {
              en: item.reason_localizations?.en || item.reason,
              il: item.reason_localizations?.il,
            },
          }}
          id={id}
        />
      )}
    </Col>
  );
};

export default ReasonEdit;
