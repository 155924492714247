import { React } from 'react';
import { useDispatch } from 'react-redux';

import TitleFormView from './TitleForm';
import { editSectionById } from '../../service/quiz/section';

const SectionForm = ({ idSection = '', defaultValues = {} }) => {
  const dispatch = useDispatch();

  const onSubmit = data => {
    dispatch(editSectionById(idSection, data));
  };

  return <TitleFormView onSubmit={onSubmit} defaultValues={defaultValues} />;
};

export default SectionForm;
