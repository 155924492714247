import { scoreTypes } from './score.types';

export const getScoresWeightGroupSuccess = (list, isNeedEnableLoader) => ({
  type: scoreTypes.GET_SCORES_WEIGHT_GROUP_LIST_SUCCESS,
  payload: {
    isNeedEnableLoader: isNeedEnableLoader,
    list: list,
  },
});

export const getScoresWeightGroupListFailed = () => ({
  type: scoreTypes.GET_SCORES_WEIGHT_GROUP_LIST_FAIL,
});

export const deleteItemFromScoresWeightGroupList = id => ({
  type: scoreTypes.DELETE_SCORES_WEIGHT_GROUP_LIST_ITEM,
  payload: id,
});

export const scoresWeightGroupItemRequested = () => ({
  type: scoreTypes.SCORES_WEIGHT_GROUP_ITEM_REQUESTED,
});

export const getWeightGroupItemSuccess = itemData => ({
  type: scoreTypes.GET_SCORES_WEIGHT_GROUP_ITEM_SUCCESS,
  payload: itemData,
});

export const getWeightGroupItemFailed = () => ({
  type: scoreTypes.GET_SCORES_WEIGHT_GROUP_ITEM_FAIL,
});

export const clearSelectedItemState = () => ({
  type: scoreTypes.CLEAR_SELECTED_ITEM,
});

export const setScoresLoader = status => ({
  type: scoreTypes.SET_SCORE_LOADER,
  payload: status,
});

export const getScoresFitnessListSuccess = list => ({
  type: scoreTypes.GET_SCORES_FITNESS_LIST_SUCCESS,
  payload: list,
});

export const getScoresFitnessListFailed = () => ({
  type: scoreTypes.GET_SCORES_FITNESS_LIST_FAIL,
});

export const deleteItemFromScoresFitnessList = id => ({
  type: scoreTypes.DELETE_SCORES_FITNESS_LIST_ITEM,
  payload: id,
});

export const getFitnessItemSuccess = itemData => ({
  type: scoreTypes.GET_SCORES_FITNESS_ITEM_SUCCESS,
  payload: itemData,
});

export const getFitnessItemFailed = () => ({
  type: scoreTypes.GET_SCORES_FITNESS_ITEM_FAIL,
});
