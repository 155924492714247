import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Button, Col } from 'react-bootstrap';

import CustomSpinner from '../../component/spinner';

import { pushNotificationsSelector } from '../../redux/selectors/selectors';
import { getCountriesList } from '../../service/notifications';
import PushNotificationsForm from '../../component/pushNotificationsForm/PushNotificationsForm';

const PushNotifications = () => {
  const dispatch = useDispatch();
  const { countriesList, loading } = useSelector(pushNotificationsSelector);

  useEffect(() => {
    dispatch(getCountriesList());
  }, []);

  return (
    <Row className="mt-3 d-flex align-items-center justify-content-center w-100 ml-0 mr-0">
      {loading ? (
        <CustomSpinner />
      ) : (
        <Col sm={12} className="p-0">
          <PushNotificationsForm countriesList={countriesList} />
        </Col>
      )}
    </Row>
  );
};

export default PushNotifications;
