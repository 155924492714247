import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

// UIKit
import { Col, Row } from 'react-bootstrap';

// State management
import { smsConfigsSelector } from '../../redux/selectors/selectors';
import { SMS_CONFIGS_SERVICES } from '../../service/sms-configs';

// Components
import GoBack from '../../component/buttons/goBackButton';
import CustomSpinner from '../../component/spinner';
import SmsConfigForm from './components/sms-config-form/SmsConfigForm';

const Edit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { loading, selectedItem } = useSelector(smsConfigsSelector);

  const defaultValues = useMemo(() => {
    if (!selectedItem) return;
    const formattedValues = { ...selectedItem };

    delete formattedValues._id;
    delete formattedValues.createdAt;
    delete formattedValues.updatedAt;

    return formattedValues;
  }, [selectedItem]);

  useEffect(() => {
    if (id) {
      dispatch(SMS_CONFIGS_SERVICES.getById(id));
    }
  }, [id, dispatch]);

  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      {loading ? (
        <CustomSpinner />
      ) : (
        <SmsConfigForm id={id} defaultValues={defaultValues} />
      )}
    </Col>
  );
};

export default Edit;
