import fetchWithAuth from '../index';
import {
  getScoresWeightGroupSuccess,
  getScoresWeightGroupListFailed,
  deleteItemFromScoresWeightGroupList,
  scoresWeightGroupItemRequested,
  getWeightGroupItemFailed,
  getWeightGroupItemSuccess,
  getScoresFitnessListFailed,
  getScoresFitnessListSuccess,
  setScoresLoader,
  deleteItemFromScoresFitnessList,
  getFitnessItemFailed,
  getFitnessItemSuccess,
} from '../../redux/score/score.action';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';

import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';
import MESSAGES_FOR_USER from '../../constants/messages/en';
import { isItemPage } from '../../helpers/isItemPage';
import store from '../../redux/store';

export const getScoresWeightGroupList =
  (isNeedEnableLoader = false) =>
  async dispatch => {
    let result = await fetchWithAuth({
      path: '/super-admin/score/weight',
      method: 'GET',
    });

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.SCORE.WEIGHT_GROUP.GET_LIST_FAILED.TITLE,
          content: MESSAGES_FOR_USER.SCORE.WEIGHT_GROUP.GET_LIST_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );
      dispatch(getScoresWeightGroupListFailed());
      return;
    }

    dispatch(
      getScoresWeightGroupSuccess(await result.json(), isNeedEnableLoader),
    );
  };

export const deleteScoresWeightGroupItem = itemID => async dispatch => {
  let result = await fetchWithAuth({
    path: `/super-admin/score/weight/${itemID}`,
    method: 'DELETE',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SCORE.WEIGHT_GROUP.DELETING_FAILED.TITLE,
        content: MESSAGES_FOR_USER.SCORE.WEIGHT_GROUP.DELETING_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  dispatch(deleteItemFromScoresWeightGroupList(itemID));
  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.SCORE.WEIGHT_GROUP.DELETING_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.SCORE.WEIGHT_GROUP.DELETING_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );
};

export const getScoresWeightGroupItemByID = itemID => async dispatch => {
  dispatch(scoresWeightGroupItemRequested());

  let result = await fetchWithAuth({
    path: `/super-admin/score/weight/${itemID}`,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SCORE.WEIGHT_GROUP.GET_ITEM_FAILED.TITLE,
        content: MESSAGES_FOR_USER.SCORE.WEIGHT_GROUP.GET_ITEM_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(getWeightGroupItemFailed());

    return;
  }

  dispatch(getWeightGroupItemSuccess(await result.json()));
};

export const createScoresWeightGroupItem = weightGroupObj => async dispatch => {
  return new Promise(async resolve => {
    let result = await fetchWithAuth({
      path: `/super-admin/score/weight`,
      method: 'POST',
      body: JSON.stringify(weightGroupObj),
    });

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.SCORE.WEIGHT_GROUP.CREATING_FAILED.TITLE,
          content: MESSAGES_FOR_USER.SCORE.WEIGHT_GROUP.CREATING_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );

      return;
    }

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SCORE.WEIGHT_GROUP.CREATING_SUCCESS.TITLE,
        content: MESSAGES_FOR_USER.SCORE.WEIGHT_GROUP.CREATING_SUCCESS.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );
    resolve();
  });
};

export const updateScoresWeightGroupItem =
  (weightGroupObj, itemId) => async dispatch => {
    return new Promise(async resolve => {
      let result = await fetchWithAuth({
        path: `/super-admin/score/weight/${itemId}`,
        method: 'PUT',
        body: JSON.stringify(weightGroupObj),
      });

      if (!result.ok) {
        dispatch(
          showToastMessage({
            title:
              MESSAGES_FOR_USER.SCORE.WEIGHT_GROUP.UPDATE_ITEM_FAILED.TITLE,
            content:
              MESSAGES_FOR_USER.SCORE.WEIGHT_GROUP.UPDATE_ITEM_FAILED.BODY,
            type: TOAST_MESSAGE_TYPES.ERROR,
          }),
        );

        return;
      }

      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.SCORE.WEIGHT_GROUP.UPDATE_ITEM_SUCCESS.TITLE,
          content:
            MESSAGES_FOR_USER.SCORE.WEIGHT_GROUP.UPDATE_ITEM_SUCCESS.BODY,
          type: TOAST_MESSAGE_TYPES.SUCCESS,
        }),
      );
      resolve();
    });
  };

export const getScoresFitnessList = () => async dispatch => {
  let result = await fetchWithAuth({
    path: '/super-admin/score/fitness',
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SCORE.FITNESS.GET_LIST_FAILED.TITLE,
        content: MESSAGES_FOR_USER.SCORE.FITNESS.GET_LIST_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(getScoresFitnessListFailed());
    return;
  }

  dispatch(getScoresFitnessListSuccess(await result.json()));
};

export const deleteScoresFitnessItem = itemID => async dispatch => {
  let result = await fetchWithAuth({
    path: `/super-admin/score/fitness/${itemID}`,
    method: 'DELETE',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SCORE.FITNESS.DELETING_FAILED.TITLE,
        content: MESSAGES_FOR_USER.SCORE.FITNESS.DELETING_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  dispatch(deleteItemFromScoresFitnessList(itemID));
  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.SCORE.FITNESS.DELETING_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.SCORE.FITNESS.DELETING_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );
};

export const createScoresFitnessItem = fitnessObj => async dispatch => {
  return new Promise(async resolve => {
    let result = await fetchWithAuth({
      path: `/super-admin/score/fitness`,
      method: 'POST',
      body: JSON.stringify(fitnessObj),
    });

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.SCORE.FITNESS.CREATING_FAILED.TITLE,
          content: MESSAGES_FOR_USER.SCORE.FITNESS.CREATING_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );

      return;
    }

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SCORE.FITNESS.CREATING_SUCCESS.TITLE,
        content: MESSAGES_FOR_USER.SCORE.FITNESS.CREATING_SUCCESS.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );
    resolve();
  });
};

export const getScoresFitnessItemByID = itemID => async dispatch => {
  dispatch(setScoresLoader(true));
  let result = await fetchWithAuth({
    path: `/super-admin/score/fitness/${itemID}`,
    method: 'GET',
  });
  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SCORE.FITNESS.GET_ITEM_FAILED.TITLE,
        content: MESSAGES_FOR_USER.SCORE.FITNESS.GET_ITEM_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(getFitnessItemFailed());
    return;
  }
  dispatch(getFitnessItemSuccess(await result.json()));
};

export const getFitnessDetailsPageConfiguration = id => async dispatch => {
  const {
    score: {
      loading,
    },
  } = store.getState();

  dispatch(getScoresWeightGroupList(true));

  if (isItemPage(id)) {
    dispatch(getScoresFitnessItemByID(id));
  } else if (loading) {
    dispatch(setScoresLoader(false));
  }
};

export const updateScoresFitnessItem =
  (fitnessObj, itemId) => async dispatch => {
    return new Promise(async resolve => {
      let result = await fetchWithAuth({
        path: `/super-admin/score/fitness/${itemId}`,
        method: 'PUT',
        body: JSON.stringify(fitnessObj),
      });

      if (!result.ok) {
        dispatch(
          showToastMessage({
            title:
              MESSAGES_FOR_USER.SCORE.FITNESS.UPDATE_ITEM_FAILED.TITLE,
            content:
              MESSAGES_FOR_USER.SCORE.FITNESS.UPDATE_ITEM_FAILED.BODY,
            type: TOAST_MESSAGE_TYPES.ERROR,
          }),
        );

        return;
      }

      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.SCORE.FITNESS.UPDATE_ITEM_SUCCESS.TITLE,
          content:
            MESSAGES_FOR_USER.SCORE.FITNESS.UPDATE_ITEM_SUCCESS.BODY,
          type: TOAST_MESSAGE_TYPES.SUCCESS,
        }),
      );
      resolve();
    });
  };
