import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { changeConfirmStatus } from '../../../redux/confirm/confirm.action';
import { confirmationKeys } from '../../../constant';
import { destinationUrls } from '../../../component/menu/constants';

const BreedItem = ({ row }) => {
  const dispatch = useDispatch();
  return (
    <tr>
      <td className="text-center align-middle">{row?._id}</td>
      <td className="text-center align-middle">
        {row?.name_localizations?.en || row?.name || '-'}
      </td>
      <td className="text-center align-middle">
        {row?.name_localizations?.il || '-'}
      </td>
      <td className="text-center align-middle">
        <Link to={`${destinationUrls.BREEDS}/${row._id}`}>
          <Button size={'sm'} variant="success" className="mr-3">
            <i className="fa fa-pencil"></i>
          </Button>
        </Link>

        <Button
          size={'sm'}
          variant="danger"
          onClick={() =>
            dispatch(
              changeConfirmStatus({
                isVisible: true,
                content: row?.name_localizations?.en || row?.name || '-',
                customLabel: 'Breed',
                type: confirmationKeys.breedsTableDeleteItem,
                actionParams: [row._id],
              }),
            )
          }
        >
          <i className="fa fa-trash-o"></i>
        </Button>
      </td>
    </tr>
  );
};

export default BreedItem;
