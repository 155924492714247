import React, { useEffect } from 'react';
import { Row, Button, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import GoBack from '../../component/buttons/goBackButton';
import SplashPhotosItemForm from '../../component/forms/splash-photos-item-form/splash-photos-item-form';
import CustomSpinner from '../../component/spinner';

import { splashPhotosSelector } from '../../redux/selectors/selectors';
import { getSplashPhotosItemByID } from '../../service/splash-photo';

const SplashPhotoItem = ({ match: { params } }) => {
  const dispatch = useDispatch();
  const { loading, selected } = useSelector(splashPhotosSelector);

  useEffect(() => {
    dispatch(getSplashPhotosItemByID(params.id));
  }, [params.id, dispatch]);

  return (
    <Col sm={12} className="mt-3">
      <Row className="d-flex align-items-start justify-content-start">
        <GoBack />
      </Row>
      {loading ? (
        <CustomSpinner />
      ) : (
        <>
          <Row className="mt-2 d-flex align-items-center justify-content-center h-vh-70 ">
            <div
              className="h-100"
              onClick={({ target }) => target.classList.remove('blur-medium')}
            >
              <img
                src={
                  selected.url ||
                  'https://backend-dev.dogiz.lampawork.com/static/images/photo-1635252544652-797305804.png'
                }
                className="img-thumbnail h-100"
                alt=""
              />
            </div>
          </Row>
          <Row className="mt-5">
            <SplashPhotosItemForm
              defaultValues={{
                title_localizations: {
                  en: selected.title_localizations?.en || selected.title || '',
                  il: selected.title_localizations?.il || '',
                },
                subTitle_localizations: {
                  en:
                    selected.subTitle_localizations?.en ||
                    selected.subTitle ||
                    '',
                  il: selected.subTitle_localizations?.il || '',
                },
                isVisible: selected.isVisible,
              }}
              itemId={params.id}
            />
          </Row>
          <Row className="mt-5 mb-5 d-flex">
            <Button
              className="font-weight-bold p-3 w-100"
              type="submit"
              form="splash-photos-item-form"
            >
              Save
            </Button>
          </Row>
        </>
      )}
    </Col>
  );
};

export default SplashPhotoItem;
