import WorkerTypeList from './list';
import WorkerTypeCreate from './create';
import WorkerTypeEdit from './edit';

const WorkerType = {
  list: WorkerTypeList,
  create: WorkerTypeCreate,
  edit: WorkerTypeEdit,
};

export default WorkerType;
