import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import GoBack from '../../../component/buttons/goBackButton';
import { useDispatch, useSelector } from 'react-redux';
import { workerTypeSelector } from '../../../redux/selectors/selectors';
import CustomSpinner from '../../../component/spinner';
import WorkerTypeForm from '../../../component/workerTypeForm/workerTypeForm';
import { getWorkerTypeById } from '../../../service/workers/type';

const WorkerTypeEdit = props => {
  const { id } = props.match.params;
  const dispatch = useDispatch();
  const { list, loading } = useSelector(workerTypeSelector);
  let item = list.filter(i => i?.id === id)[0];

  useEffect(() => {
    if (list.length > 0) return;
    dispatch(getWorkerTypeById(id));
  }, [dispatch, list]);

  return (
    <Col sm={9} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      {loading || !item ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <WorkerTypeForm
          type="EDIT"
          id={item._id}
          defaultValues={{
            name: item.name,
          }}
        />
      )}
    </Col>
  );
};

export default WorkerTypeEdit;
