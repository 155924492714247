import setLoading from '../../redux/default';
import fetchWithAuth from '..';
import { getCurrencyListSuccess } from '../../redux/currency/currency.action';
import { URL_SUPER_ADMIN } from '../../constant';
export const getCurrency = () => async dispatch => {
  dispatch(setLoading('currency/GET_CURRENCY_LIST'));

  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/currency/`,
    method: 'GET',
  });

  dispatch(getCurrencyListSuccess(await result.json()));
  return;
};
