import React from 'react';
import { Col, Row } from 'react-bootstrap';

import GoBack from '../../../component/buttons/goBackButton';
import ServiceTypeForm from '../../../component/serviceTypeForm/serviceTypeForm';

const ServiceTypeCreate = () => {
  return (
    <Col sm={9} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      <ServiceTypeForm type="CREATE" />
    </Col>
  );
};

export default ServiceTypeCreate;
