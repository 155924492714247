import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import GoBack from '../../component/buttons/goBackButton';
import CustomSpinner from '../../component/spinner';
import BreedForm from '../../component/breed-form/breed-form';
import BREEDS_OPERATIONS from '../../service/breeds/breeds';
import { breedsSelector } from '../../redux/selectors/selectors';
import { breedsActions } from '../../redux/breeds/breeds.actions';

const BreedsEdit = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { selectedItem: breed, loading } = useSelector(breedsSelector);

  useEffect(() => {
    if (id) {
      dispatch(BREEDS_OPERATIONS.getBreedById(id));
    }

    return () => {
      dispatch(breedsActions.clearSelectedBreed());
    };
  }, [dispatch, id]);

  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      {loading || !breed ? (
        <CustomSpinner />
      ) : (
        <BreedForm
          id={id}
          defaultValues={{
            name_localizations: {
              en: breed?.name_localizations?.en || breed?.name || '',
              il: breed?.name_localizations?.il || '',
            },
          }}
        />
      )}
    </Col>
  );
};

export default BreedsEdit;
