import { useDispatch } from 'react-redux';
import { Button, Form, Dropdown } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { withRouter } from 'react-router';

import { useEffect, useMemo, useRef } from 'react';
import { createPartnerType, updatePartnerType } from '../../service/partners';

const PartnerTypeForm = ({
  history,
  defaultValues = {},
  type = '',
  id = null,
  partnerTypes = null,
  normalizedOptions = null,
}) => {
  const dispatch = useDispatch();
  const checkboxesRef = useRef([]);
  const optionList = useMemo(
    () => Object.entries(normalizedOptions),
    [normalizedOptions],
  );

  const {
    register,
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    checkboxesRef.current.forEach((checkboxEl, index) => {
      checkboxEl.checked = !!defaultValues.service[index];
    });
  }, [defaultValues.service]);

  const onSubmit = async data => {
    const services = data.service.filter(Boolean);
    if (!services.length) {
      setError('service', {
        type: 'manual',
        message: 'Partner should has at least one service',
      });
      return;
    }
    const body = {
      ...data,
      title: data.title_localizations.en,
      desc: data.desc_localizations.en,
      service: services,
    };

    type === 'CREATE' && dispatch(createPartnerType(body, history));
    type === 'EDIT' && dispatch(updatePartnerType(id, body, history));
  };

  return (
    <Form className="border p-4 mt-4" onSubmit={handleSubmit(onSubmit)}>
      <Form.Label>Title (en)</Form.Label>
      <Form.Control
        {...register('title_localizations.en', {
          required: true,
          minLength: 3,
        })}
        placeholder="Partner title..."
      />

      <Form.Label>Title (il)</Form.Label>
      <Form.Control
        {...register('title_localizations.il', {
          required: true,
          minLength: 3,
        })}
        placeholder="Partner title..."
      />

      <Form.Label className="mt-3">Description (en)</Form.Label>
      <Form.Control
        {...register('desc_localizations.en', {
          required: true,
          minLength: 3,
        })}
        as="textarea"
        placeholder="Partner description..."
      />

      <Form.Label className="mt-3">Description (il)</Form.Label>
      <Form.Control
        {...register('desc_localizations.il', {
          required: true,
          minLength: 3,
        })}
        as="textarea"
        placeholder="Partner description..."
      />

      <Form.Label className="mt-3">Partner type</Form.Label>

      <Controller
        control={control}
        name="typePartner"
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Dropdown onSelect={onChange}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {value}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {partnerTypes.map(({ value, label }) => (
                <Dropdown.Item key={value} eventKey={value}>
                  {label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      />

      <Form.Label className="mt-3">Service</Form.Label>
      {optionList.map(([value, label], index) => (
        <Controller
          key={value}
          control={control}
          name={`service.${index}`}
          render={({ field: { onChange } }) => {
            return (
              <Form.Check
                ref={el => (checkboxesRef.current[index] = el)}
                onChange={({ target }) => {
                  if (errors.service && target.checked) clearErrors('service');
                  onChange(target.checked && value);
                }}
                defaultValue={!!value}
                type={'checkbox'}
                id={`service-${value}`}
                label={label}
              />
            );
          }}
        />
      ))}
      {errors.service && <p>{errors.service.message}</p>}

      <Button className="mt-4" variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

export default withRouter(PartnerTypeForm);
