import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Dropdown } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { withRouter } from 'react-router';

import { useEffect, useRef } from 'react';
import { SearchDropdown } from '../inputs/searchDropdown';
import {
  userCountrySelector,
  userCitySelector,
} from '../../redux/selectors/selectors';
import { getCountry, getCity } from '../../service/user';
import {
  setCountryItem,
  clearCountryItem,
} from '../../redux/user/country/country.action';
import {
  setCityItem,
  clearCityItem,
  clearCity,
} from '../../redux/user/city/city.action';
import {
  createPartnerProgram,
  updatePartnerProgram,
} from '../../service/partners/partnerProgram';

const PartnerProgramForm = ({
  history,
  defaultValues = {},
  type = '',
  id = null,
  countryName,
  cityName,
  normalizedPartnerTypes = null,
  partnerTypeIds = null,
  servicePartnerIds = null,
  normalizedServicePartners = null,
  normalizedCurrency = null,
  currencyIds = null,
}) => {
  const dispatch = useDispatch();

  const checkboxesRef = useRef([]);

  const {
    list: countryList = [],
    item: selectedCountry,
    loading: countryLoading,
    search,
  } = useSelector(userCountrySelector);

  const {
    list: cityList = [],
    item: selectedCity,
    loading: cityLoading,
    search: citySearch,
  } = useSelector(userCitySelector);

  useEffect(() => {
    checkboxesRef.current.forEach((checkboxEl, index) => {
      checkboxEl.checked = !!defaultValues.services[index];
    });
  }, [defaultValues.services]);

  const normalizedCountryOptions = countryList
    ? Object.fromEntries(
        countryList.map(({ name, name_localizations, _id }) => [
          _id,
          name_localizations?.en || name,
        ]),
      )
    : [];

  const normalizedCityOptions = cityList
    ? Object.fromEntries(
        cityList.map(({ name, name_localizations, _id }) => [
          _id,
          name_localizations?.en || name,
        ]),
      )
    : [];

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const handleCountrySearch = search => {
    dispatch(getCountry(search));
  };

  const handleCitySearch = search => {
    dispatch(
      getCity(search, selectedCountry._id || defaultValues.location.country),
    );
  };

  useEffect(() => {
    return () => {
      dispatch(clearCountryItem());
      dispatch(clearCityItem());
    };
  }, [dispatch]);

  const onSubmit = async data => {
    const services = data.services.filter(Boolean);
    const modeCreate = type === 'CREATE';

    if (!services.length) {
      setError('services', {
        type: 'manual',
        message: 'Partner Program should has at least one service',
      });
      return;
    }
    const body = {
      ...data,
      currency: modeCreate ? normalizedCurrency[data.currency] : data.currency,
      services: services,
    };

    type === 'CREATE' && dispatch(createPartnerProgram(body, history));
    if (type === 'EDIT') {
      const dataToUpdate = {};
      Object.keys(dirtyFields, key => {
        if (dirtyFields[key] === true) dataToUpdate[key] = body[key];
      });
      if (dirtyFields?.location) dataToUpdate.location = body.location;
      if (dirtyFields?.services) dataToUpdate.services = body.services;
      dispatch(updatePartnerProgram(id, dataToUpdate, history));
    }
  };

  return (
    <Form className="border p-4 mt-4" onSubmit={handleSubmit(onSubmit)}>
      <Form.Label className="mt-3">Country</Form.Label>
      <Controller
        control={control}
        name="location.country"
        rules={{ required: false }}
        render={({ field: { onChange } }) => {
          const handleSelect = newValue => {
            onChange(newValue);
            dispatch(
              setCountryItem({
                _id: newValue,
                name: normalizedCountryOptions[newValue],
              }),
            );
            dispatch(clearCity());
          };
          return (
            <SearchDropdown
              normalizedOptions={normalizedCountryOptions}
              onSearch={handleCountrySearch}
              search={search}
              selectedLabel={selectedCountry.name || countryName}
              onSelect={handleSelect}
              isLoading={countryLoading}
            />
          );
        }}
      />

      <Form.Label className="mt-3">City</Form.Label>
      <Controller
        control={control}
        name="location.city"
        rules={{ required: false }}
        render={({ field: { onChange } }) => {
          const handleSelect = newValue => {
            onChange(newValue);
            dispatch(
              setCityItem({
                _id: newValue,
                countryId: selectedCountry._id,
                name: normalizedCityOptions[newValue],
              }),
            );
          };
          return (
            <SearchDropdown
              normalizedOptions={normalizedCityOptions}
              onSearch={handleCitySearch}
              search={citySearch}
              selectedLabel={selectedCity.name || cityName}
              onSelect={handleSelect}
              isLoading={cityLoading}
            />
          );
        }}
      />

      <Form.Label className="mt-3">Partner type</Form.Label>

      <Controller
        control={control}
        name="partnerType"
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Dropdown onSelect={onChange}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {normalizedPartnerTypes[value].title_localizations?.en ||
                normalizedPartnerTypes[id].title}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {partnerTypeIds.map(id => (
                <Dropdown.Item key={id} eventKey={id}>
                  {normalizedPartnerTypes[id].title_localizations?.en ||
                    normalizedPartnerTypes[id].title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      />

      <Form.Label className="mt-3">Currency</Form.Label>

      <Controller
        control={control}
        name="currency"
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Dropdown onSelect={onChange}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {normalizedCurrency[value].name}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {currencyIds.map(id => (
                <Dropdown.Item key={id} eventKey={id}>
                  {normalizedCurrency[id].name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      />

      {/* <Form.Label className="mt-3">City</Form.Label>
      <SearchDropdown /> */}

      <Form.Label className="mt-3">Service</Form.Label>
      {servicePartnerIds.map((id, index) => (
        <Controller
          key={id}
          control={control}
          name={`services.${index}`}
          render={({ field: { onChange } }) => {
            return (
              <Form.Check
                ref={el => (checkboxesRef.current[index] = el)}
                onChange={({ target }) => {
                  if (errors.services && target.checked)
                    clearErrors('services');
                  onChange(target.checked && id);
                }}
                defaultValue={!!id}
                type={'checkbox'}
                id={`service-${id}`}
                label={
                  normalizedServicePartners[id].service.title_localizations
                    ?.en || normalizedServicePartners[id].service.title
                }
              />
            );
          }}
        />
      ))}
      {errors.services && <p>{errors.services.message}</p>}
      {(errors.location?.country || errors.location?.city) && (
        <p>Country and city is required</p>
      )}

      <Button className="mt-4" variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

export default withRouter(PartnerProgramForm);
