import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import GoBack from '../../component/buttons/goBackButton';
import CustomSpinner from '../../component/spinner';

import { getRewardActionItemByID } from '../../service/rewards';
import { rewardsSelector } from '../../redux/selectors/selectors';
import { clearSelectedItemState } from '../../redux/rewards/rewards.action';
import RewardsActionForm from '../../component/rewardsActionForm/RewardsActionForm';

const RewardsActionDetails = ({ match: { params } }) => {
  const dispatch = useDispatch();
  const rewardsState = useSelector(rewardsSelector);

  useEffect(() => {
    const { id } = params;

    dispatch(getRewardActionItemByID(id));

    return () => dispatch(clearSelectedItemState());
  }, [dispatch]);

  return (
    <Col sm={12} className="mt-3">
      <Row className="d-flex">
        <GoBack />
      </Row>
      <Row className="mt-3 d-flex align-items-start justify-content-center">
        {rewardsState.loading ? (
          <CustomSpinner />
        ) : (
          <RewardsActionForm defaultValues={rewardsState.selectedItem} itemPageId={params.id}/>
        )}
      </Row>
    </Col>
  );
};

export default RewardsActionDetails;
