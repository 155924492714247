import fetchWithAuth from '../index';

import {
  getDeletedDogsListSuccess,
  getDeletedDogsListLoading,
} from '../../redux/deleted-dogs/deleted-dogs.action';

export const getDeletedDogsList = () => async dispatch => {
  dispatch(getDeletedDogsListLoading());

  let result = await fetchWithAuth({
    path: `/deleted-dogs`,
    method: 'GET',
  });

  dispatch(getDeletedDogsListSuccess(await result.json()));
  return;
};
