import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import { getPublicationItemById } from '../../../service/publication/publication';
import { clearPublicationItem } from '../../../redux/publication/publication.action';
import PublicationForm from '../../../component/publicationForm/PublicationForm';

import GoBack from '../../../component/buttons/goBackButton';
import CustomSpinner from '../../../component/spinner';

const PublicationEdit = props => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { item, loading } = useSelector(state => state.publication);

  useEffect(() => {
    getPublicationItemById(props.match.params.id)(dispatch);

    return () => dispatch(clearPublicationItem());
  }, [dispatch, props.match.params.id]);

  return (
    <Col sm={9} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      {loading && !Object.keys(item).length ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <PublicationForm
          defaultValues={{
            title_localizations: {
              en: item?.title_localizations?.en || item?.title || '',
              il: item?.title_localizations?.il || '',
            },
            subTitle_localizations: {
              en: item?.subTitle_localizations?.en || item?.subTitle || '',
              il: item?.subTitle_localizations?.il || '',
            },
            content_localizations: {
              en: item?.content_localizations?.en || item?.content || '',
              il: item?.content_localizations?.il || '',
            },
            redirect_localizations: {
              en: {
                destination:
                  item?.redirect_localizations?.en?.destination ||
                  item?.redirect?.destination ||
                  '',
                link:
                  item?.redirect_localizations?.en?.link ||
                  item?.redirect?.link ||
                  '',
              },
              il: {
                destination:
                  item?.redirect_localizations?.il?.destination || '',
                link: item?.redirect_localizations?.il?.link || '',
              },
            },

            isShow: item?.isShow,
            image: item?.url,
          }}
          type="EDIT"
          id={id}
        />
      )}
    </Col>
  );
};

export default withRouter(PublicationEdit);
