import { BASE_URL_DEV, AUTH_HEADERS } from '../../constant';
import setLoading from '../../redux/default';
import {
  checkCodeSuccess,
  checkCodeFail,
  checkRoleFail,
} from '../../redux/auth/auth.action';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import MESSAGES_FOR_USER from '../../constants/messages/en';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';

const checkCode = data => async dispatch => {
  const settings = {
    method: 'POST',
    headers: AUTH_HEADERS,
    body: JSON.stringify(data),
  };

  dispatch(setLoading('CHECK_CODE'));
  let result = await fetch(BASE_URL_DEV + '/auth/check-code', settings);

  const response = await result.json();

  if (!result.ok) {
    dispatch(checkCodeFail(response));
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.AUTH.CODE.TITLE,
        content: MESSAGES_FOR_USER.AUTH.CODE.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  if (response?.user?.role === 'USER') {
    dispatch(checkRoleFail());
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.AUTH.ROLE.TITLE,
        content: MESSAGES_FOR_USER.AUTH.ROLE.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  dispatch(checkCodeSuccess(response));
  return;
};

export default checkCode;
