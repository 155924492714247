import CountryForm from '../../component/country-form/country-form';
import { Col, Row } from 'react-bootstrap';

import GoBack from '../../component/buttons/goBackButton';

const CountriesCreate = () => {
  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      <CountryForm />
    </Col>
  );
};
export default CountriesCreate;
