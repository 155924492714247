import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { validationRules } from './validation';
import BREED_OPERATIONS from '../../service/breeds/breeds';

const defaultFormValues = {
  name_localizations: {
    en: '',
    il: '',
  },
};

const BreedForm = ({ defaultValues = defaultFormValues, id }) => {
  const dispatch = useDispatch();
  const { goBack } = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = body => {
    if (id) {
      dispatch(BREED_OPERATIONS.updateBreed({ id, body })).then(() => goBack());
    } else {
      dispatch(BREED_OPERATIONS.createBreed({ body })).then(() => goBack());
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group>
        <Form.Label className="d-flex justify-content-between align-items-center mt-2">
          Name (EN)
          {errors.name_localizations?.en && (
            <p style={{ fontSize: '12px' }} className="text-danger m-0">
              {errors.name_localizations?.en?.message}{' '}
            </p>
          )}
        </Form.Label>
        <Form.Control
          {...register('name_localizations.en', {
            ...validationRules.name_localizations.en,
          })}
          placeholder="Breed name..."
        />

        <Form.Label className="d-flex justify-content-between align-items-center mt-2">
          Name (IL)
          {errors.name_localizations?.il && (
            <p style={{ fontSize: '12px' }} className="text-danger m-0">
              {errors.name_localizations?.il?.message}{' '}
            </p>
          )}
        </Form.Label>
        <Form.Control
          {...register('name_localizations.il', {
            ...validationRules.name_localizations.il,
          })}
          placeholder="Breed name..."
        />
      </Form.Group>
      <Button type="submit">{id ? 'Save changes' : 'Create breed'}</Button>
    </Form>
  );
};

export default BreedForm;
