import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { changeConfirmStatus } from '../../../redux/confirm/confirm.action';
import { confirmationKeys } from '../../../constant';

const PartnerTypeItem = ({ row }) => {
  const dispatch = useDispatch();

  return (
    <tr>
      <td className="text-center align-middle">{row._id}</td>
      <td className="text-center align-middle">
        {row.title_localizations?.en || row.title || '-'}
      </td>
      <td className="text-center align-middle">
        {row.title_localizations?.il || '-'}
      </td>
      <td className="text-center align-middle">
        {row.desc_localizations?.en || row.desc || '-'}
      </td>
      <td className="text-center align-middle">
        {row.desc_localizations?.il || '-'}
      </td>
      <td className="text-center align-middle">{row.typePartner}</td>

      <td className="align-middle text-center">
        <Link to={`/partner-type/${row._id}`}>
          <Button size={'sm'} variant="success" className="m-2">
            <i className="fa fa-pencil"></i>
          </Button>
        </Link>

        <Button
          size={'sm'}
          variant="danger"
          className="m-2"
          onClick={() =>
            dispatch(
              changeConfirmStatus({
                isVisible: true,
                content: row.title,
                type: confirmationKeys.partnerTypeTableDeleteItem,
                actionParams: [row._id],
              }),
            )
          }
        >
          <i className="fa fa-trash-o"></i>
        </Button>
      </td>
    </tr>
  );
};

export default PartnerTypeItem;
