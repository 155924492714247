import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { destinationUrls } from '../../../component/menu/constants';

const NotificationsConfigItem = ({ row }) => {
  const formatValues = values => {
    if (!values) return {};
    if ('unit_of_measure' in values) {
      return { [values.unit_of_measure]: values.value };
    }
    return values;
  };
  return (
    <tr>
      <td className="text-center align-middle">{row?.desc || '-'}</td>
      <td className="text-center align-middle">
        {formatValues(row?.value)?.hour || formatValues(row?.value)?.hours}
      </td>
      <td className="text-center align-middle">
        {formatValues(row?.value)?.minute || formatValues(row?.value)?.minutes}
      </td>
      <td className="text-center align-middle">
        <Link to={`${destinationUrls.NOTIFICATIONS_CONFIG}/${row._id}`}>
          <Button size={'sm'} variant="success" className="mr-3">
            <i className="fa fa-pencil"></i>
          </Button>
        </Link>
      </td>
    </tr>
  );
};

export default NotificationsConfigItem;
