import React from 'react';
import { Table } from 'react-bootstrap';

import DrPoopItem from './components/drPoopItem';
import DrPoopStatementItem from './components/drPoopStatementItem';
import QuizQuestionItem from './components/quizQuestionItem';
import RewardsActionItem from './components/rewardsActionItem';
import RewardsItem from './components/rewardsItem';
import ScoresFitnessItem from './components/scoresFitnessItem';
import ScoresWeightGroupItem from './components/scoresWeightGroupItem';

import { TABLE_ITEM_TYPE } from './config';
import ServiceTypeItem from './components/serviceTypeItem';
import PartnerTypeItem from './components/partnerTypeItem';
import ServicePartnerItem from './components/servicePartnerTypeItem';
import PartnerProgramItem from './components/partnerProgramItem';
import AdditionalFeeTemplateItem from './components/additionalFeeTemplateItem';
import AdditionalServiceItem from './components/additionalServiceItem';
import DeleteDogOptionsItem from './components/deleteDogOptionsItem';
import DeletedDogsItem from './components/deletedDogsItem';
import ContractItem from './components/contractItem';
import WorkerTypeItem from './components/workerTypeItem';
import ReviewsItem from './components/reviewsItem';
import CoinsModificatorsItem from './components/coinsModificatorsItem';
import DiscountReasonItem from './components/discountReasonItem';
import CancelReasonItem from './components/cancelReasonItem';
import CountryItem from './components/countryItem';
import CityItem from './components/cityItem';
import BreedItem from './components/breedItem';
import NotificationsConfigItem from './components/notificationsConfigItem';
import FoodPricesModificatorsItem from './components/foodPricesModificatorsItem';
import SmsConfigsItem from './components/smsConfigsItem.js';

const ConfigurableTable = ({
  itemVariant,
  tableHeaderColumns = [],
  rows = [],
}) => {
  const getItemVariant = () => {
    switch (itemVariant) {
      case TABLE_ITEM_TYPE.DR_POOP_USER_DATA_ITEM_TYPE:
        return (
          <>
            {rows.map((row, index) => (
              <DrPoopItem key={row._id} row={row} index={index} />
            ))}
          </>
        );
      case TABLE_ITEM_TYPE.DR_POOP_STATEMENT_ITEM_TYPE:
        return (
          <>
            {rows.map((row, index) => (
              <DrPoopStatementItem key={row._id} row={row} index={index} />
            ))}
          </>
        );
      case TABLE_ITEM_TYPE.QUIZ_QUESTION_ITEM_TYPE:
        return (
          <>
            {rows.map(row => (
              <QuizQuestionItem key={row._id} row={row} />
            ))}
          </>
        );
      case TABLE_ITEM_TYPE.REWARD_ITEM_TYPE:
        return (
          <>
            {rows.map(row => (
              <RewardsItem key={row._id} row={row} />
            ))}
          </>
        );
      case TABLE_ITEM_TYPE.SCORE_WEIGHT_GROUP_ITEM_TYPE:
        return (
          <>
            {rows.map(row => (
              <ScoresWeightGroupItem key={row._id} row={row} />
            ))}
          </>
        );

      case TABLE_ITEM_TYPE.SCORE_FITNESS_ITEM_TYPE:
        return (
          <>
            {rows.map(row => (
              <ScoresFitnessItem key={row._id} row={row} />
            ))}
          </>
        );

      case TABLE_ITEM_TYPE.REWARD_ACTION_ITEM_TYPE:
        return (
          <>
            {rows?.map(row => (
              <RewardsActionItem key={row._id} row={row} />
            ))}
          </>
        );

      case TABLE_ITEM_TYPE.COINS_MODIFICATORS_ITEM_TYPE:
        return (
          <>
            {rows?.map(row => (
              <CoinsModificatorsItem key={row._id} row={row} />
            ))}
          </>
        );

      case TABLE_ITEM_TYPE.SERVICE_TYPE_ITEM_TYPE:
        return (
          <>
            {rows.map((row, index) => (
              <ServiceTypeItem key={row._id} row={row} index={index} />
            ))}
          </>
        );

      case TABLE_ITEM_TYPE.PARTNER_TYPE_ITEM_TYPE:
        return (
          <>
            {rows.map((row, index) => (
              <PartnerTypeItem key={row._id} row={row} index={index} />
            ))}
          </>
        );

      case TABLE_ITEM_TYPE.SERVICE_PARTNER_ITEM_TYPE:
        return (
          <>
            {rows.map((row, index) => (
              <ServicePartnerItem key={row._id} row={row} index={index} />
            ))}
          </>
        );

      case TABLE_ITEM_TYPE.PARTNER_PROGRAM_ITEM_TYPE:
        return (
          <>
            {rows.map((row, index) => (
              <PartnerProgramItem key={row._id} row={row} index={index} />
            ))}
          </>
        );
      case TABLE_ITEM_TYPE.ADDITIONAL_FEE_TEMPLATE_ITEM_TYPE:
        return (
          <>
            {rows.map((row, index) => (
              <AdditionalFeeTemplateItem
                key={row._id}
                row={row}
                index={index}
              />
            ))}
          </>
        );
      case TABLE_ITEM_TYPE.ADDITIONAL_SERVICE_ITEM_TYPE:
        return (
          <>
            {rows.map((row, index) => (
              <AdditionalServiceItem key={row._id} row={row} index={index} />
            ))}
          </>
        );

      case TABLE_ITEM_TYPE.DELETE_DOG_OPTIONS:
        return (
          <>
            {rows.map((row, index) => (
              <DeleteDogOptionsItem key={row._id} row={row} index={index} />
            ))}
          </>
        );

      case TABLE_ITEM_TYPE.DELETED_DOGS:
        return (
          <>
            {rows.map((row, index) => (
              <DeletedDogsItem key={row._id} row={row} index={index} />
            ))}
          </>
        );
      case TABLE_ITEM_TYPE.CONTRACT_ITEM:
        return (
          <>
            {rows.map((row, index) => (
              <ContractItem key={row._id} row={row} index={index} />
            ))}
          </>
        );
      case TABLE_ITEM_TYPE.WORKER_TYPE_ITEM_TYPE:
        return (
          <>
            {rows.map((row, index) => (
              <WorkerTypeItem key={row._id} row={row} index={index} />
            ))}
          </>
        );
      case TABLE_ITEM_TYPE.REVIEWS_ITEM:
        return (
          <>
            {rows.map((row, index) => (
              <ReviewsItem key={row._id} row={row} />
            ))}
          </>
        );
      case TABLE_ITEM_TYPE.DISCOUNT_REASON_ITEM:
        return (
          <>
            {rows.map((row, index) => (
              <DiscountReasonItem key={row._id} row={row} index={index} />
            ))}
          </>
        );

      case TABLE_ITEM_TYPE.CANCEL_REASON_ITEM:
        return (
          <>
            {rows.map((row, index) => (
              <CancelReasonItem key={row._id} row={row} index={index} />
            ))}
          </>
        );
      case TABLE_ITEM_TYPE.COUNTRY_ITEM:
        return (
          <>
            {rows.map(row => (
              <CountryItem key={row._id} row={row} />
            ))}
          </>
        );
      case TABLE_ITEM_TYPE.CITY_ITEM:
        return (
          <>
            {rows.map(row => (
              <CityItem key={row._id} row={row} />
            ))}
          </>
        );

      case TABLE_ITEM_TYPE.BREED_ITEM:
        return (
          <>
            {rows.map(row => (
              <BreedItem key={row._id} row={row} />
            ))}
          </>
        );

      case TABLE_ITEM_TYPE.NOTIFICATION_CONFIG_ITEM:
        return (
          <>
            {rows.map(row => (
              <NotificationsConfigItem key={row._id} row={row} />
            ))}
          </>
        );

      case TABLE_ITEM_TYPE.FOOD_PRICES_MODIFICATORS_ITEM:
        return (
          <>
            {rows.map(row => (
              <FoodPricesModificatorsItem key={row._id} row={row} />
            ))}
          </>
        );
      case TABLE_ITEM_TYPE.SMS_CONFIGS_ITEM:
        return (
          <>
            {rows.map(row => (
              <SmsConfigsItem key={row._id} row={row} />
            ))}
          </>
        );

      default:
        break;
    }
  };

  return (
    <Table bordered>
      <thead>
        <tr>
          {tableHeaderColumns.map((item, index) => {
            return (
              <th
                style={{ width: item.columnWidth }}
                key={index}
                className="text-center align-middle"
              >
                {item.title}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>{getItemVariant()}</tbody>
    </Table>
  );
};

export default ConfigurableTable;
