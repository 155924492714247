const drawMask = (byteArray, ctx) => {
  ctx.lineWidth = 1;
  ctx.strokeStyle = '#00000090';
  ctx.globalCompositeOperation = 'source-over';

  const pathArray = [];

  let firstEmptyX1 = 0;
  let lastEmptyX1 = 0;

  byteArray.forEach((y, idxY) => {
    if (!y.includes(1)) {
      pathArray.push({ y: idxY, x1: 0, x2: y.length });
      return;
    }

    y.forEach((x, idxX) => {
      const isCurrentPointWithContent = x === 1;
      const isCurrentPointEmpty = x === 0;
      const isPrevPointWithContent = y[idxX - 1] === 1;
      const isPrevPointEmpty = y[idxX - 1] === 0;
      const isLastPoint = idxX === y.length - 1;

      if (isCurrentPointWithContent && isPrevPointWithContent) {
        return;
      }

      if (isCurrentPointEmpty && isPrevPointWithContent) {
        firstEmptyX1 = idxX;
        return;
      }

      if (isCurrentPointWithContent && isPrevPointEmpty) {
        lastEmptyX1 = idxX - 1;
        pathArray.push({ y: idxY, x1: firstEmptyX1, x2: lastEmptyX1 });
      }

      if (isLastPoint) {
        lastEmptyX1 = idxX;
        pathArray.push({ y: idxY, x1: firstEmptyX1, x2: lastEmptyX1 });
        firstEmptyX1 = 0;
      }
    });
  });

  const drowLine = ({ y, x1, x2 }) => {
    ctx.beginPath();
    ctx.moveTo(x1, y);
    ctx.lineTo(x2, y);
    ctx.closePath();
    ctx.stroke();
  };

  pathArray.forEach(i => {
    drowLine(i);
  });
};

export const createMask = (canvasRef, image, maskData) => {
  const canvas = canvasRef.current;
  const ctx = canvas.getContext('2d');

  const img = new Image();
  img.onload = () => {
    ctx.globalCompositeOperation = 'destination-over';
    const height = img.height;
    const width = img.width;

    canvas.width = width;
    canvas.height = height;

    ctx.drawImage(img, 0, 0, width, height);

    drawMask(maskData, ctx);
  };

  img.src = URL.createObjectURL(image);
};

export const rle2mask = (rle, maskShape) => {
  const array = new Array(maskShape.height * maskShape.width).fill(0);
  let position = 0;
  let count = 0;
  rle.split(' ').forEach((el, idx, arr) => {
    if (idx % 2 === 0) {
      position = Number(el) - 1;
      count = Number(arr[idx + 1]);
      array.splice(position, count, ...new Array(count).fill(1));
    }
  });

  const mask = [];

  for (let i = 0; i < maskShape.height; i++) {
    const startIdx = i * maskShape.width;

    mask.push(array.slice(startIdx, startIdx + maskShape.width));
  }

  return mask;
};

export const generateImageShapeForMaskDecoding = async file => {
  const blob = await readFileAsync(file);
  const image = await loadImageFromBlobAsync(blob);
  return { width: image.naturalWidth, height: image.naturalHeight };
};

const readFileAsync = async file => {
  return new Promise((res, rej) => {
    const reader = new FileReader();

    reader.onload = () => {
      res(reader.result);
    };
    reader.onerror = rej;
    reader.readAsDataURL(file);
  });
};

const loadImageFromBlobAsync = async blob => {
  return new Promise((res, rej) => {
    const image = new Image();
    image.onload = () => {
      res(image);
    };

    image.onerror = rej;

    image.src = blob;
  });
};
