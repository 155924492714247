import React from 'react';
import { Button, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { changeConfirmStatus } from '../../../redux/confirm/confirm.action';
import { confirmationKeys } from '../../../constant';

const ScoresWeightGroupItem = ({ row }) => {
  const dispatch = useDispatch();

  const handleDeclineButton = (id, content) => {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        content,
        type: confirmationKeys.scoresWeightGroupDeleteItem,
        actionParams: [id],
      }),
    );
  };

  return (
    <tr key={`section-${row._id}`}>
      <td>{row.name}</td>
      <td>{row.min}</td>
      <td>{row.max}</td>
      <td>
        <Row className="d-flex justify-content-around p-0 m-0">
          <Link to={`${window.location.pathname}/${row._id}`}>
            <Button size="sm" variant="success">
              <i className="fa fa-pencil"></i>
            </Button>
          </Link>

          <Button
            size="sm"
            variant="danger"
            onClick={() => handleDeclineButton(row._id, row.name)}
          >
            <i className="fa fa-trash-o"></i>
          </Button>
        </Row>
      </td>
    </tr>
  );
};

export default ScoresWeightGroupItem;
