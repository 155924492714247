import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

// UIKit
import { Row, Col, Button, Form } from 'react-bootstrap';

// Components
import Input from '../../../../component/inputs';

// Constants
import { validationRules } from './validation';

// Services
import { SMS_CONFIGS_SERVICES } from '../../../../service/sms-configs';

const SmsConfigForm = ({
  defaultValues = {
    smsCountToSendWarning: 0,
    smsCountAllowedPerMinute: 0,
    smsAllowedFromOneIpPerMinute: 0,
    smsAllowedFromOneNumberPerMinute: 0,
    blockAll: false,
  },
  id = '',
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    if (id && defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset, id]);

  const onSubmit = body => {
    dispatch(SMS_CONFIGS_SERVICES.update({ id, body })).then(() =>
      history.goBack(),
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-100"
      id="sms-config-form"
    >
      <Row className="pl-3 pr-3 pt-3">
        <Col>
          <Input.number
            register={register}
            label="Attempt count per minute"
            name="smsCountAllowedPerMinute"
            validationRules={validationRules.smsCountAllowedPerMinute}
            error={errors.smsCountAllowedPerMinute}
          />
        </Col>
        <Col>
          <Input.number
            register={register}
            label="Attempt count per minute from one IP"
            name="smsAllowedFromOneIpPerMinute"
            validationRules={validationRules.smsAllowedFromOneIpPerMinute}
            error={errors.smsAllowedFromOneIpPerMinute}
          />
        </Col>
      </Row>
      <Row className="pl-3 pr-3 pt-3">
        <Col>
          <Input.number
            register={register}
            label="Attempt count per minute from one phone number"
            name="smsAllowedFromOneNumberPerMinute"
            validationRules={validationRules.smsCountAllowedPerMinute}
            error={errors.smsCountAllowedPerMinute}
          />
        </Col>
        <Col>
          <Input.number
            register={register}
            label="Attempt count per minute for send warning"
            name="smsCountToSendWarning"
            validationRules={validationRules.smsCountToSendWarning}
            error={errors.smsAllowedFromOneIpPerMinute}
          />
        </Col>
      </Row>
      <Row className="pl-3 pr-3 pt-3">
        <Col>
          <Form.Group className="m-0">
            <Form.Label className="mt-3">Block all</Form.Label>
            <Form.Check {...register('blockAll')} type="checkbox" />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-5 ml-0 mr-0">
        <Button className="w-100 p-3" type="submit">
          Save
        </Button>
      </Row>
    </form>
  );
};

export default SmsConfigForm;
