import ReasonList from './list';
import ReasonCreate from './create';
import ReasonEdit from './edit';

const Section = {
  list: ReasonList,
  create: ReasonCreate,
  edit: ReasonEdit,
};

export default Section;
