import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormLogin from '../../component/formLogin';
import checkCode from '../../service/auth/checkCode';

const CheckCode = () => {
  const dispatch = useDispatch();
  const [code, updateCode] = useState(useSelector(state => state.auth.code));

  const send = e => {
    e.preventDefault();
    checkCode({ code })(dispatch);
  };

  return (
    <FormLogin send={send} buttonName={'Confirm Code'}>
      <input
        name="code"
        type="text"
        value={code}
        className="fadeIn second"
        placeholder="code"
        onChange={e => updateCode(e.target.value)}
      />
    </FormLogin>
  );
};

export default CheckCode;
