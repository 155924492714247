import { Col, Button, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CustomSpinner from '../../component/spinner';
import ConfigurableTable from '../../component/table';

import { TABLE_ITEM_TYPE, CONTRACTS } from '../../component/table/config';
import { contractsSelector } from '../../redux/selectors/selectors';
import { destinationUrls } from '../../component/menu/constants';
import { getContractsList } from '../../service/contracts/contracts';

const ContractsList = props => {
  const dispatch = useDispatch();

  const { list = [], loading } = useSelector(contractsSelector);

  useEffect(() => {
    getContractsList()(dispatch);
  }, [dispatch]);

  return (
    <Col sm={12} className="p-0 mt-3">
      <Link to={`${destinationUrls.CONTRACTS}/create`}>
        <Button className="mb-3" variant="primary">
          Create contract
        </Button>
      </Link>
      {loading || !list ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <ConfigurableTable
          tableHeaderColumns={CONTRACTS}
          itemVariant={TABLE_ITEM_TYPE.CONTRACT_ITEM}
          rows={list}
        />
      )}
    </Col>
  );
};

export default ContractsList;
