export const SCORE_FITNESS_VALUES = [
  {
    label: 'Duration',
    value: 'DURATION_WALK',
  },
  {
    label: 'Distance',
    value: 'DISTANCE_WALK',
  },
  {
    label: 'Count',
    value: 'COUNT_WALK',
  },
];
