import CreateContract from './create';
import EditContract from './edit';
import ContractsList from './list';

const Contracts = {
  list: ContractsList,
  create: CreateContract,
  edit: EditContract,
};

export default Contracts;
