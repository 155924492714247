import { discountReasonsTypes } from './discount-reasons.types';

const initialState = {
  list: [],
  totalCount: 0,
  selectedItem: null,
  loading: false,
  error: '',
};

const discountReasonsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case discountReasonsTypes.DISCOUNT_REASONS_OPERATION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case discountReasonsTypes.DISCOUNT_REASONS_OPERATION_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case discountReasonsTypes.GET_DISCOUNT_REASONS_LIST:
      return {
        ...state,
        list: payload.result,
        totalCount: payload.totalCount,
        loading: false,
        error: '',
      };

    case discountReasonsTypes.GET_DISCOUNT_REASON_BY_ID:
      return {
        ...state,
        selectedItem: payload,
        loading: false,
        error: '',
      };

    default:
      return state;
  }
};

export default discountReasonsReducer;
