import fetchWithAuth from '../index';
import setLoading from '../../redux/default';

import { URL_SUPER_ADMIN } from '../../constant';

import MESSAGES_FOR_USER from '../../constants/messages/en';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';
import { createFormData } from '../../helpers/formDataConvertor';
import {
  updatePartnerTypeSuccess,
  getPartnerTypeListSuccess,
  deletePartnerTypeFailed,
  deletePartnerTypeSuccess,
  getPartnerTypeByIdFailed,
  getPartnerTypeByIdSuccess,
  updatePartnerTypeFailed,
} from '../../redux/partnerType/partnerType.action';

export const createPartnerType = (data, history) => async dispatch => {
  const result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/partner-type/`,
    method: 'POST',
    body: JSON.stringify(data),
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.PARTNER_TYPE.CREATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.PARTNER_TYPE.CREATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  history.push('/partner-type/');
  return;
};

export const getPartnerType = () => async dispatch => {
  dispatch(setLoading('partner_type/GET_PARTNER_TYPE_LIST'));

  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/partner-type/`,
    method: 'GET',
  });

  dispatch(getPartnerTypeListSuccess(await result.json()));
  return;
};

export const deletePartnerTypeById = id => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/partner-type/${id}`,
    method: 'DELETE',
  });

  setLoading('DELETE_PARTNER_TYPE');

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.PARTNER_TYPE.DELETE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.PARTNER_TYPE.DELETE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(deletePartnerTypeFailed(await result.json()));
    return;
  }

  dispatch(deletePartnerTypeSuccess(id));
  return;
};

export const getPartnerTypeById = id => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/partner-type/${id}`,
    method: 'GET',
  });

  setLoading('GET_PARTNER_TYPE_BY_ID');

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.PARTNER_TYPE.GET_BY_ID_FAILED.TITLE,
        content: MESSAGES_FOR_USER.PARTNER_TYPE.GET_BY_ID_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(getPartnerTypeByIdFailed(await result.json()));
    return;
  }

  dispatch(getPartnerTypeByIdSuccess(await result.json()));
};

export const updatePartnerType =
  (id, updatedData, history) => async dispatch => {
    let result = await fetchWithAuth({
      path: `${URL_SUPER_ADMIN}/partner-type/${id}`,
      method: 'PUT',
      body: JSON.stringify(updatedData),
    });

    setLoading('UPDATE_PARTNER_TYPE');

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.PARTNER_TYPE.UPDATE_FAILED.TITLE,
          content: MESSAGES_FOR_USER.PARTNER_TYPE.UPDATE_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );
      dispatch(updatePartnerTypeFailed(await result.json()));
      return;
    }

    dispatch(updatePartnerTypeSuccess(id, updatedData));
    history.push('/partner-type/');
    return;
  };
