import fetchWithAuth from '../index';
import setLoading from '../../redux/default';

import { URL_SUPER_ADMIN } from '../../constant';
import {
  getReasonListSuccess,
  deleteReasonFailed,
  deleteReasonSuccess,
  getReasonByIdSuccess,
  getReasonByIdFailed,
  updateReasonSuccess,
} from '../../redux/subscription/reason/reason.action';
import MESSAGES_FOR_USER from '../../constants/messages/en';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';

export const createReason = (data, history) => async dispatch => {
  const result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/subscription/cancelReason`,
    method: 'POST',
    body: JSON.stringify(data),
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SUBSCRIPTION.REASONS.CREATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.SUBSCRIPTION.REASONS.CREATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  history.push('/subscription/reason/');
  return;
};

export const getReason = () => async dispatch => {
  dispatch(setLoading('reason/GET_REASON_LIST'));

  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/subscription/cancelReason/all`,
    method: 'GET',
  });

  dispatch(getReasonListSuccess(await result.json()));
  return;
};

export const deleteReasonById = id => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/subscription/cancelReason/${id}`,
    method: 'DELETE',
  });

  setLoading('DELETE_REASON');

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SUBSCRIPTION.REASONS.DELETE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.SUBSCRIPTION.REASONS.DELETE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(deleteReasonFailed(await result.json()));
    return;
  }

  dispatch(deleteReasonSuccess(id));
  return;
};

export const getReasonById = id => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/subscription/cancelReason/${id}`,
    method: 'GET',
  });

  setLoading('GET_REASON_BY_ID');

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SUBSCRIPTION.REASONS.GET_BY_ID_FAILED.TITLE,
        content: MESSAGES_FOR_USER.REWARDS.GET_BY_ID_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(getReasonByIdFailed(await result.json()));
    return;
  }

  dispatch(getReasonByIdSuccess(await result.json()));
};

export const updateReason = (id, updatedData, history) => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/subscription/cancelReason/${id}`,
    method: 'PUT',
    body: JSON.stringify(updatedData),
  });

  setLoading('UPDATE_REASON');

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SUBSCRIPTION.REASONS.UPDATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.SUBSCRIPTION.REASONS.UPDATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(deleteReasonFailed(await result.json()));
    return;
  }

  dispatch(updateReasonSuccess(id, updatedData));
  history.push('/subscription/reason/');
  return;
};
