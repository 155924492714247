import React from 'react';
import { useState } from 'react';
import { Row, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import Input from '../inputs';

const BillingForm = ({ onSubmit, defaultValues }) => {
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const [inputs, setInputs] = useState([
    <Row className="w-100 d-flex pl-3 mt-3">
      <Input.text
        register={register}
        label="UserId"
        name="userId"
        error={errors.name}
      />
    </Row>,
  ]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-100"
      id="contract-form"
    >
      <Row className="p-3 w-50">
        {inputs}
        <Button
          onClick={() => {
            setInputs(p => [
              ...p,
              <Row className="w-100 d-flex pl-3 mt-3">
                <Input.text
                  className="w-100"
                  register={register}
                  label="UserId"
                  name={`userId${p.length + 1}`}
                  error={errors.name}
                />
              </Row>,
            ]);
          }}
          type="button"
          variant="primary"
          className="mt-3 p-3 w-100"
        >
          Add input
        </Button>
        <Button
          disabled={!isDirty}
          type="submit"
          variant="primary"
          className="mt-3 p-3 w-100"
        >
          Bill
        </Button>
      </Row>
    </form>
  );
};

export default BillingForm;
