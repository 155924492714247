import fetchWithAuth from '../index';
import setLoading from '../../redux/default';
import {
  getQuestionListSuccess,
  getQuestionListFail,
  getQuestionDetailsSuccess,
  getQuestionDetailsFail,
  editQuestionSuccess,
  editQuestionFail,
  deleteQuestionSuccess,
  deleteQuestionFail,
  createNewQuestionFail,
  createNewQuestionSuccess,
} from '../../redux/quiz/question/question.action';
import { URL_SUPER_ADMIN } from '../../constant';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import MESSAGES_FOR_USER from '../../constants/messages/en';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';

export const getQuestion = () => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/quiz/question`,
    method: 'GET',
  });

  dispatch(setLoading('GET_QUESTION_LIST'));
  if (!result.ok) {
    dispatch(getQuestionListFail(await result.json()));
    return;
  }

  dispatch(getQuestionListSuccess(await result.json()));
  return;
};

export const getQuestionDetails = id => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/quiz/question/${id}`,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(getQuestionDetailsFail(await result.json()));
    return;
  }

  dispatch(getQuestionDetailsSuccess(await result.json()));
  return;
};

export const editQuestionById = (id, data) => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/quiz/question/${id}`,
    method: 'PUT',
    body: JSON.stringify(data),
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.QUIZ.QUESTION.UPDATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.QUIZ.QUESTION.UPDATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );

    dispatch(editQuestionFail(await result.json()));

    return;
  }
  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.QUIZ.QUESTION.UPDATE_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.QUIZ.QUESTION.UPDATE_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );

  dispatch(editQuestionSuccess(await result.json()));
  return;
};

export const deleteQuestionById = id => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/quiz/question/${id}`,
    method: 'DELETE',
  });

  setLoading('DELETE_QUESTION');

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.QUIZ.QUESTION.DELETE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.QUIZ.QUESTION.DELETE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );

    dispatch(deleteQuestionFail(await result.json()));
    return;
  }
  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.QUIZ.QUESTION.DELETE_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.QUIZ.QUESTION.DELETE_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );

  dispatch(deleteQuestionSuccess(id));
  return;
};

export const createQuestion = data => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/quiz/question/`,
    method: 'POST',
    body: JSON.stringify(data),
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.QUIZ.QUESTION.CREATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.QUIZ.QUESTION.CREATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(createNewQuestionFail(await result.json()));
    return;
  }
  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.QUIZ.QUESTION.CREATE_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.QUIZ.QUESTION.CREATE_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );

  return;
};
