import { useState } from 'react';
import { Button, Form, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { createQuestion, editQuestionById } from '../../service/quiz/question';

const listOfQuestionTypes = [
  { value: 'SINGLE', renderText: 'Single' },
  { value: 'MULTIPLE', renderText: 'Multiple' },
  { value: 'DROPDOWN', renderText: 'Dropdown' },
];

const CreateQuestionForm = ({ defaultValues, id }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, unregister } = useForm({ defaultValues });

  const [answers, setAnswers] = useState(
    defaultValues?.answers_localizations
      ? defaultValues.answers_localizations.map((_, idx) => idx)
      : [0],
  );

  const addAnswer = () => {
    setAnswers(p => (p ? [...p, p.length] : [0]));
  };

  const removeAnswer = answer => {
    unregister(`answers_localizations.${answer}`);
    setAnswers(p => [...p.filter(i => i !== answer)]);
  };

  const onSubmit = data => {
    const body = {
      ...data,
      question: data.question_localizations.en,

      answers:
        data.answers_localizations
          ?.map(i => ({
            answer: i.answer.en,
            score: i.score,
          }))
          ?.filter(i => i.answer) || [],

      answers_localizations:
        data.answers_localizations?.filter(i => i.answer.en) || [],
    };

    if (id) {
      dispatch(editQuestionById(id, body)).then(() =>
        history.push('/quiz/question/'),
      );

      return;
    }

    dispatch(createQuestion(body)).then(() => history.push('/quiz/question/'));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="mt-3">
      <Form.Group>
        <Form.Label>Choose question type</Form.Label>
        <Form.Control
          as="select"
          defaultValue="Choose..."
          {...register('typeQuestion', {
            required: true,
          })}
        >
          {listOfQuestionTypes.map(({ renderText, value }) => (
            <option key={value} value={value}>
              {renderText}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Label>Question (en)</Form.Label>
        <Form.Control
          {...register('question_localizations.en', { required: true })}
          placeholder="Question..."
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Question (il)</Form.Label>
        <Form.Control
          {...register('question_localizations.il', { required: true })}
          placeholder="Question..."
        />
      </Form.Group>
      <Form.Group>
        {answers.map(answer => (
          <Card key={answer} className="mb-3">
            <Card.Body>
              <Form.Group>
                <Form.Label>Answer (en)</Form.Label>
                <Form.Control
                  {...register(`answers_localizations.${answer}.answer.en`, {
                    required: true,
                  })}
                />

                <Form.Label>Answer (il)</Form.Label>
                <Form.Control
                  {...register(`answers_localizations.${answer}.answer.il`, {
                    required: true,
                  })}
                />
                <Form.Label>Score</Form.Label>
                <Form.Control
                  {...register(`answers_localizations.${answer}.score`, {
                    required: true,
                  })}
                />
              </Form.Group>

              <Button variant="danger" onClick={() => removeAnswer(answer)}>
                Remove answer
              </Button>
            </Card.Body>
          </Card>
        ))}

        <Button onClick={addAnswer} variant="primary">
          Add answer
        </Button>
      </Form.Group>
      <Button type="submit" variant="primary">
        {id ? 'Save changes' : 'Create question'}
      </Button>
    </Form>
  );
};

export default CreateQuestionForm;
