import { Row, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Input from '../inputs/';
import { validationRules } from './validation';
import { createReason, updateReason } from '../../service/subscription/reason';

const CancelReasonForm = ({ id, defaultValues }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({ defaultValues });

  const onSubmit = data => {
    const body = {
      ...data,
      reason: data.reason_localizations.en,
    };

    if (id) {
      dispatch(updateReason(id, body, history));
      return;
    }
    dispatch(createReason(body, history));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-100"
      id="addition-fee-template-form"
    >
      <Row className="d-flex align-items-center">
        <Col className="mt-3">
          <Row className="p-3">
            <Input.text
              register={register}
              label="Reason(en)"
              name="reason_localizations.en"
              validationRules={validationRules.reason_localizations.en}
              error={errors?.reason_localizations?.en}
            />
          </Row>
        </Col>
      </Row>

      <Row className="d-flex align-items-center">
        <Col className="mt-3">
          <Row className="p-3">
            <Input.text
              register={register}
              label="Reason(il)"
              name="reason_localizations.il"
            />
          </Row>
        </Col>
      </Row>

      <Button
        disabled={!isDirty}
        type="submit"
        variant="primary"
        className="mt-3 p-3 w-100"
      >
        Save changes
      </Button>
    </form>
  );
};
export default CancelReasonForm;
