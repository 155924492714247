import { confirmTypes } from './confirm.types';

const initialState = {
  isVisible: false,
  content: '',
  type: null,
  actionParams: [],
  customLabel: '',
};

const confirm = (state = initialState, { type, payload }) => {
  switch (type) {
    case confirmTypes.CHANGE_CONFIRM_STATUS:
      return payload;

    default:
      return state;
  }
};

export default confirm;
