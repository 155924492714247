import { contractsTypes } from './contracts.types';

export const getContractsListSuccess = list => ({
  type: contractsTypes.GET_CONTRACTS_LIST_SUCCESS,
  payload: list,
});

export const getContractsListError = error => ({
  type: contractsTypes.GET_CONTRACTS_LIST_ERROR,
  payload: error,
});

export const getContractsListLoading = () => ({
  type: contractsTypes.GET_CONTRACTS_LIST_LOADING,
});

export const getContractByIDSuccess = data => ({
  type: contractsTypes.GET_CONTRACT_BY_ID_SUCCESS,
  payload: data,
});
export const getContractByIDLoading = () => ({
  type: contractsTypes.GET_CONTRACT_BY_ID_LOADING,
});

export const deleteContractSuccess = id => ({
  type: contractsTypes.DELETE_CONTRACT_SUCCESS,
  payload: id,
});

export const contractClearItem = () => ({
  type: contractsTypes.CLEAR_ITEM,
});
