import React from 'react';
import { Toast, ToastBody, ToastHeader } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { toastMessageSelector } from '../../redux/selectors/selectors';
import { hideToastMessage } from '../../redux/toast-message/toast-message.action';
import { TOAST_MESSAGE_TYPES } from './config';

import './styles.css';

const ToastMessage = () => {
  const dispatch = useDispatch();
  const { title, content, type, isVisible } = useSelector(toastMessageSelector);

  const setToastBackgroundColor = () => {
    switch (type) {
      case TOAST_MESSAGE_TYPES.SUCCESS:
        return 'bg-success';
      case TOAST_MESSAGE_TYPES.ERROR:
        return 'bg-danger';
      default:
        return 'bg-danger';
    }
  };

  return (
    <div className="fixed-top left-unset mr-5 mt-5 shadow-lg">
      <Toast
        className={`${setToastBackgroundColor()} text-white`}
        onClose={() => dispatch(hideToastMessage())}
        delay={5000}
        show={isVisible}
        autohide
      >
        <ToastHeader className="d-flex justify-content-between">
          <strong className="me-auto">{title}</strong>
        </ToastHeader>
        <ToastBody>{content}</ToastBody>
      </Toast>
    </div>
  );
};

export default ToastMessage;
