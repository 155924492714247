export const deleteDogOptionsTypes = {
  GET_DELETE_DOG_OPTIONS_LIST_SUCCESS:
    'delete-dog/GET_DELETE_DOG_OPTIONS_LIST_SUCCESS',
  GET_DELETE_DOG_OPTIONS_LIST_LOADING:
    'delete-dog/GET_DELETE_DOG_OPTIONS_LIST_LOADING',

  GET_DELETE_DOG_OPTIONS_BY_ID_SUCCESS:
    'delete-dog/GET_DELETE_DOG_OPTIONS_BY_ID_SUCCESS',
  GET_DELETE_DOG_OPTIONS_BY_ID_LOADING:
    'delete-dog/GET_DELETE_DOG_OPTIONS_BY_ID_LOADING',

  DELETE_DOG_OPTIONS_SUCCESS: 'delete-dog/DELETE_DOG_OPTIONS_SUCCESS',

  CLEAR_ITEM: 'delete-dog/CLEAR_ITEM',
};
