import React from 'react';

const TextArea = ({ name, register, label, validationRules, error }) => {
  return (
    <>
      {label && (
        <label htmlFor={name} className="form-label">
          {label}
        </label>
      )}

      <div className="input-group">
        <textarea
          className="form-control text-left ml-0 mr-0 mt-0"
          {...register(name, validationRules)}
          rows="10"
          cols="70"
        />
        {error?.message && (
          <p className="error">
            <i className="fa fa-exclamation-circle"></i> {error.message}
          </p>
        )}
      </div>
    </>
  );
};

export default TextArea;
