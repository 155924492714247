export const destinationUrls = {
  RECOMMENDATION: '/publication',
  QUIZ_SECTION: '/quiz/section/',
  QUIZ_QUESTION: '/quiz/question/',
  SPLASH_PHOTOS: '/splash-photos',
  DR_POOP_USER_DATA: '/dr-poop/user-data',
  DR_POOP_STATEMENTS: '/dr-poop/statements',
  DR_POOP_ANALYTICS: '/dr-poop/analytics',
  REWARDS_COUNT_WALK: '/rewards/count-walk',
  REWARDS_DISTANCE_WALK: '/rewards/distance-walk',
  REWARDS_ACTION: '/rewards/actions',
  REWARDS_MOOD: '/rewards/mood',
  REWARDS_WEIGHT: '/rewards/weight',
  REWARDS_COINS_MODIFICATORS: '/rewards/coins-modificators',
  SCORE_WEIGHT: '/score/weight',
  SCORE_FITNESS: '/score/fitness',
  NOTIFICATIONS: '/notifications',
  NOTIFICATIONS_CONFIG: '/notifications-config',
  CANCEL_REASONS: '/subscription/reason/',
  SERVICE_TYPE: '/service-type/',
  SERVICE_PARTNER: '/service-partner/',
  FEE_TEMPLATE: '/additional-fee-template',
  ADDITIONAL_SERVICE: '/additional-service',
  PARTNER_TYPE: '/partner-type/',
  PARTNER_PROGRAM: '/partner-program/',
  PARTNER_WORKER: '/partner-worker/',
  WORKER_TYPE: '/worker-type',
  DELETE_DOG_OPTIONS: '/delete-dog-options',
  DELETED_DOGS: '/deleted-dogs',
  CONTRACTS: '/contracts',
  REVIEWS: '/reviews',
  BILLING: '/billing',
  DISCOUNT_REASONS: '/discount-reasons',
  DR_POOP_MASK: '/dr-poop-mask',
  COUNTRIES: '/countries',
  CITIES: '/cities',
  BREEDS: '/breeds',
  FOOD_PRICES_MODIFICATORS: '/food-prices-modificators',
  SMS_CONFIGS: '/sms-configs',
};

export const menuItems = [
  {
    label: 'Recommendation',
    path: destinationUrls.RECOMMENDATION,
    icon: 'fa-thumbs-up',
    isCollapsable: false,
  },
  {
    label: 'Quiz',
    path: '',
    icon: 'fa-question-circle',
    toggleId: '0',
    isCollapsable: [
      {
        label: 'Section',
        path: destinationUrls.QUIZ_SECTION,
        icon: 'fa-circle',
      },
      {
        label: 'Question',
        path: destinationUrls.QUIZ_QUESTION,
        icon: 'fa-circle',
      },
    ],
  },
  {
    label: 'Splash photos',
    path: destinationUrls.SPLASH_PHOTOS,
    icon: 'fa-image',
    isCollapsable: false,
  },
  {
    label: 'Dr. Poop',
    path: '',
    icon: 'fa-search',
    toggleId: '1',
    isCollapsable: [
      {
        label: 'User data',
        path: destinationUrls.DR_POOP_USER_DATA,
        icon: 'fa-circle',
      },
      {
        label: 'Statements',
        path: destinationUrls.DR_POOP_STATEMENTS,
        icon: 'fa-circle',
      },
      {
        label: 'Analytics',
        path: destinationUrls.DR_POOP_ANALYTICS,
        icon: 'fa-chart-mixed',
      },
    ],
  },
  {
    label: 'Rewards',
    path: '',
    icon: 'fa-trophy',
    toggleId: '2',
    isCollapsable: [
      {
        label: 'Count walk',
        path: destinationUrls.REWARDS_COUNT_WALK,
        icon: 'fa-circle',
      },
      {
        label: 'Distance walk',
        path: destinationUrls.REWARDS_DISTANCE_WALK,
        icon: 'fa-circle',
      },
      {
        label: 'Mood',
        path: destinationUrls.REWARDS_MOOD,
        icon: 'fa-circle',
      },
      {
        label: 'Weight',
        path: destinationUrls.REWARDS_WEIGHT,
        icon: 'fa-circle',
      },
      {
        label: 'Actions',
        path: destinationUrls.REWARDS_ACTION,
        icon: 'fa-circle',
      },
      {
        label: 'Coins modificators',
        path: destinationUrls.REWARDS_COINS_MODIFICATORS,
        icon: 'fa-circle',
      },
    ],
  },
  {
    label: 'Score',
    path: '',
    icon: 'fa-star',
    toggleId: '3',
    isCollapsable: [
      {
        label: 'Weight groups',
        path: destinationUrls.SCORE_WEIGHT,
        icon: 'fa-circle',
      },
      {
        label: 'Fitness',
        path: destinationUrls.SCORE_FITNESS,
        icon: 'fa-circle',
      },
    ],
  },
  {
    label: 'Notifications',
    path: '',
    icon: 'fa-bell',
    toggleId: '4',
    isCollapsable: [
      {
        label: 'Notifications',
        path: destinationUrls.NOTIFICATIONS,
      },
      {
        label: 'Config',
        path: destinationUrls.NOTIFICATIONS_CONFIG,
      },
    ],
  },
  {
    label: 'Subscription',
    path: '',
    icon: 'fa-rocket',
    toggleId: '5',
    isCollapsable: [
      {
        label: 'Cancel reasons',
        path: destinationUrls.CANCEL_REASONS,
        icon: 'fa-circle',
      },
    ],
  },
  {
    label: 'Services',
    path: '',
    icon: 'fa-cubes',
    toggleId: '6',
    isCollapsable: [
      {
        label: 'Template',
        path: destinationUrls.SERVICE_TYPE,
        icon: 'fa-circle',
      },
      {
        label: 'Partner',
        path: destinationUrls.SERVICE_PARTNER,
        icon: 'fa-circle',
      },
      {
        label: 'Additional fee template',
        path: destinationUrls.FEE_TEMPLATE,
        icon: 'fa-circle',
      },
      {
        label: 'Additional service',
        path: destinationUrls.ADDITIONAL_SERVICE,
        icon: 'fa-circle',
      },
    ],
  },
  {
    label: 'Partners',
    path: '',
    icon: 'fa-users',
    toggleId: '7',
    isCollapsable: [
      {
        label: 'Type (verticals)',
        path: destinationUrls.PARTNER_TYPE,
        icon: 'fa-circle',
      },
      {
        label: 'Program',
        path: destinationUrls.PARTNER_PROGRAM,
        icon: 'fa-circle',
      },
      {
        label: 'Partner creation',
        path: destinationUrls.PARTNER_WORKER,
        icon: 'fa-circle',
      },
    ],
  },
  {
    label: 'Workers',
    path: '',
    icon: 'fa-address-card-o',
    toggleId: '8',
    isCollapsable: [
      {
        label: 'Type (area)',
        path: destinationUrls.WORKER_TYPE,
        icon: 'fa-circle',
      },
    ],
  },
  {
    label: 'Delete dog',
    path: '',
    icon: 'fa-paw',
    toggleId: '9',
    isCollapsable: [
      {
        label: 'Delete dog options',
        path: destinationUrls.DELETE_DOG_OPTIONS,
        icon: 'fa-circle',
      },
      {
        label: 'Deleted dogs',
        path: destinationUrls.DELETED_DOGS,
        icon: 'fa-circle',
      },
    ],
  },
  {
    label: 'Contract types',
    path: destinationUrls.CONTRACTS,
    icon: 'fa-file',
    isCollapsable: false,
  },
  {
    label: 'Reviews',
    path: destinationUrls.REVIEWS,
    icon: 'fa-list-ol',
    isCollapsable: false,
  },
  {
    label: 'Billing',
    path: destinationUrls.BILLING,
    icon: 'fa-money',
    isCollapsable: false,
  },
  {
    label: 'Discount reasons',
    path: destinationUrls.DISCOUNT_REASONS,
    icon: 'fa fa-percent',
    isCollapsable: false,
  },
  {
    label: 'Dr.Poop mask',
    path: destinationUrls.DR_POOP_MASK,
    icon: 'fa fa-magic',
    isCollapsable: false,
  },
  {
    label: 'Countries',
    path: destinationUrls.COUNTRIES,
    icon: 'fa fa-globe',
    isCollapsable: false,
  },
  {
    label: 'Cities',
    path: destinationUrls.CITIES,
    icon: 'fa fa-globe',
    isCollapsable: false,
  },
  {
    label: 'Breeds',
    path: destinationUrls.BREEDS,
    icon: 'fa fa-tag',
    isCollapsable: false,
  },
  {
    label: 'Food prices modificators',
    path: destinationUrls.FOOD_PRICES_MODIFICATORS,
    icon: 'fa fa-circle',
    isCollapsable: false,
  },
  {
    label: 'SMS Configs',
    path: destinationUrls.SMS_CONFIGS,
    icon: 'fa fa-cog',
    isCollapsable: false,
  },
];
