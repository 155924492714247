import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import ErrorBoundary from '../errorBoundary';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuth = useSelector(state => state.auth.authSession);

  return (
    <Route
      {...rest}
      render={props =>
        isAuth || rest.path === '/auth' ? (
          <ErrorBoundary>
            <Component {...props} />
          </ErrorBoundary>
        ) : (
          <Redirect
            to={{
              pathname: '/auth',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default withRouter(PrivateRoute);
