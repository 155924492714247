import { reasonTypes } from './reason.types';

export const createReasonSuccess = () => ({
  type: reasonTypes.CREATE_REASON_SUCCESSs,
});

export const getReasonListSuccess = list => ({
  type: reasonTypes.GET_REASON_LIST_SUCCESS,
  payload: list,
});

export const getReasonByIdSuccess = reason => ({
  type: reasonTypes.GET_REASON_BY_ID_SUCCESS,
  payload: reason,
});
export const getReasonByIdFailed = err => ({
  type: reasonTypes.GET_REASON_BY_ID_FAIL,
  payload: err,
});

export const deleteReasonSuccess = id => ({
  type: reasonTypes.DELETE_REASON_SUCCESS,
  payload: id,
});
export const deleteReasonFailed = err => ({
  type: reasonTypes.DELETE_REASON_FAIL,
  payload: err,
});

export const clearItemState = () => ({
  type: reasonTypes.CLEAR_ITEM,
});
export const updateReasonFailed = err => ({
  type: reasonTypes.UPDATE_REASON_FAIL,
  payload: err,
});
export const updateReasonSuccess = (id, updatedData) => ({
  type: reasonTypes.UPDATE_REASON_SUCCESS,
  payload: { updatedData, id },
});
