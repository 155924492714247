export async function importPublicKeyAndEncrypt(publicKey, string) {
  try {
    const pub = await importPublicKey(publicKey);
    const encrypted = await encryptRSA(pub, new TextEncoder().encode(string));

    return window.btoa(arrayBufferToString(encrypted));
  } catch (error) {
    console.log(error);
  }
}

async function importPublicKey(spkiPem) {
  return await window.crypto.subtle.importKey(
    'spki',
    getSpkiDer(spkiPem),
    {
      name: 'RSA-OAEP',
      hash: 'SHA-1',
    },
    true,
    ['encrypt'],
  );
}

async function encryptRSA(key, arrayBuffer) {
  let encrypted = await window.crypto.subtle.encrypt(
    {
      name: 'RSA-OAEP',
    },
    key,
    arrayBuffer,
  );
  return encrypted;
}

function getSpkiDer(spkiPem) {
  const pemHeader = '-----BEGIN PUBLIC KEY-----';
  const pemFooter = '-----END PUBLIC KEY-----';
  const pemContents = spkiPem.substring(
    pemHeader.length,
    spkiPem.length - pemFooter.length,
  );
  const binaryDerString = window.atob(pemContents);
  return stringToArrayBuffer(binaryDerString);
}

function stringToArrayBuffer(str) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

function arrayBufferToString(buf) {
  return String.fromCharCode.apply(null, new Uint8Array(buf));
}
