const POOP_STATUSES = [
  {
    label: 'Good',
    value: 'GOOD',
  },
  {
    label: 'Medium',
    value: 'MEDIUM',
  },
  {
    label: 'Bad',
    value: 'BAD',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
];

const DEFAULT_POOP_STATUS_VALUE = 'PROCESSING';

export { POOP_STATUSES, DEFAULT_POOP_STATUS_VALUE };
