import './index.css';
import logoImg from '../../source/img/logo.svg';
import { Button } from 'react-bootstrap';

const FormLogin = ({ send, children, buttonName }) => {
  return (
    <div className="wrapper">
      <div id="formContent">
        <div className="pt-30">
          <img src={logoImg} alt="logo" className="logo" />
        </div>
        <form onSubmit={send}>
          {children}
          <Button type="submit" size="md" variant="success">
            {buttonName}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default FormLogin;
