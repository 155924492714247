import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import GoBack from '../../component/buttons/goBackButton';
import CustomSpinner from '../../component/spinner';
import ContractForm from '../../component/contract-form/contract-form';

import { contractsSelector } from '../../redux/selectors/selectors';
import {
  getContractById,
  updateContractByID,
} from '../../service/contracts/contracts';
import { contractClearItem } from '../../redux/contracts/contracts.action';

const EditContract = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = props.match.params;
  const { item } = useSelector(contractsSelector);

  useEffect(() => {
    getContractById(id)(dispatch);

    return () => dispatch(contractClearItem());
  }, [dispatch, id]);

  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      {Object.keys(item).length ? (
        <ContractForm
          defaultValues={item}
          onSubmit={data => updateContractByID(id, data, history)(dispatch)}
        />
      ) : (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default EditContract;
