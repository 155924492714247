import CITIES_ACTIONS_TYPES from './cities.types';

const initialState = {
  loading: false,
  error: null,
  list: [],
  selectedItem: null,
};

const citiesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CITIES_ACTIONS_TYPES.OPERATION_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case CITIES_ACTIONS_TYPES.OPERATION_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case CITIES_ACTIONS_TYPES.GET_CITY_BY_ID:
      return {
        ...state,
        loading: false,
        error: null,
        selectedItem: payload,
      };

    case CITIES_ACTIONS_TYPES.GET_CITIES_LIST:
      return {
        ...state,
        loading: false,
        error: null,
        list: payload.result,
      };

    case CITIES_ACTIONS_TYPES.DELETE_CITY:
      return {
        ...state,
        loading: false,
        error: null,
        list: state.list.filter(i => i._id !== payload),
      };

    case CITIES_ACTIONS_TYPES.CLEAR_SELECTED_CITY:
      return {
        ...state,
        selectedItem: null,
      };

    default:
      return state;
  }
};

export default citiesReducer;
