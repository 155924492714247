import SectionList from "./list";
import SectionDetails from "./details";
import SectionCreate from "./create";
import SectionEdit from "./edit";

const Section = {
    list: SectionList,
    details: SectionDetails,
    create: SectionCreate,
    edit: SectionEdit
};

export default Section;
