import React from 'react';

const Select = ({
  defaultHiddenValue,
  optionsList,
  label,
  name,
  register,
  disabled = false,
  error = null,
  validationRules = {},
}) => {
  return (
    <>
      <select
        disabled={disabled}
        className="custom-select"
        {...register(name, validationRules)}
      >
        <option hidden value={defaultHiddenValue}>
          {label}
        </option>
        {optionsList.map(item => (
          <option value={item.value} key={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      {error?.message && (
        <p className="error">
          <i className="fa fa-exclamation-circle"></i> {error.message}
        </p>
      )}
    </>
  );
};

export default Select;
