import { partnerTypes } from './partnerType.types';

export const createPartnerTypeSuccess = () => ({
  type: partnerTypes.CREATE_PARTNER_SUCCESSs,
});

export const getPartnerTypeListSuccess = list => ({
  type: partnerTypes.GET_PARTNER_TYPE_LIST_SUCCESS,
  payload: list,
});

export const getPartnerTypeByIdSuccess = reason => ({
  type: partnerTypes.GET_PARTNER_TYPE_BY_ID_SUCCESS,
  payload: reason,
});
export const getPartnerTypeByIdFailed = err => ({
  type: partnerTypes.GET_PARTNER_TYPE_BY_ID_FAIL,
  payload: err,
});

export const deletePartnerTypeSuccess = id => ({
  type: partnerTypes.DELETE_PARTNER_TYPE_SUCCESS,
  payload: id,
});
export const deletePartnerTypeFailed = err => ({
  type: partnerTypes.DELETE_PARTNER_TYPE_FAIL,
  payload: err,
});

export const clearPartnerTypeItemState = () => ({
  type: partnerTypes.CLEAR_ITEM,
});
export const updatePartnerTypeFailed = err => ({
  type: partnerTypes.UPDATE_PARTNER_TYPE_FAIL,
  payload: err,
});
export const updatePartnerTypeSuccess = (id, updatedData) => ({
  type: partnerTypes.UPDATE_PARTNER_TYPE_SUCCESS,
  payload: { updatedData, id },
});
