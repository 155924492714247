export const validationRules = {
  country: {
    required: 'Country field is required!',
  },

  priceIncreasePercent: {
    required: 'This field is required!',
    min: {
      value: 0,
      message: 'Percent cant be less then 0',
    },
    max: {
      value: 100,
      message: 'Percent cant be more then 100',
    },
  },
};
