import { partnerProgramTypes } from './partnerProgram.types';

const initialSate = {
  loading: false,
  error: false,
  list: null,
  item: false,
};

const partnerProgram = (state = initialSate, { type, payload }) => {
  switch (type) {
    case partnerProgramTypes.GET_PARTNER_PROGRAM_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case partnerProgramTypes.GET_PARTNER_PROGRAM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload,
      };
    case partnerProgramTypes.GET_PARTNER_PROGRAM_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };
    case partnerProgramTypes.DELETE_PARTNER_PROGRAM_SUCCESS:
      const newList = state.list.filter(item => item._id !== payload);
      return {
        ...state,
        list: newList,
        loading: false,
      };
    case partnerProgramTypes.CLEAR_ITEM:
      return {
        ...state,
        item: null,
      };
    case partnerProgramTypes.UPDATE_PARTNER_PROGRAM_SUCCESS:
      // const { updatedData, id } = payload;
      // console.log({ payload });
      // const index = state.list.findIndex(item => item._id !== id);
      // let updatedList = [...state.list];
      // const oldItem = state.list[index];
      // updatedList[index] = { ...oldItem, ...updatedData };

      // return {
      //   ...state,
      //   list: updatedList,
      // };

      return { ...state };

    case partnerProgramTypes.GET_PARTNER_PROGRAM_LIST_FAIL:
    case partnerProgramTypes.CREATE_PARTNER_PROGRAM_FAIL:
    case partnerProgramTypes.DELETE_PARTNER_PROGRAM_FAIL:
    case partnerProgramTypes.GET_PARTNER_PROGRAM_BY_ID_FAIL:
    case partnerProgramTypes.UPDATE_PARTNER_PROGRAM_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default partnerProgram;
