import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import AdditionalServiceForm from '../../../component/additional-service-form/additional-service-form';
import GoBack from '../../../component/buttons/goBackButton';
import CustomSpinner from '../../../component/spinner';

import { additionalFeeTemplateSelector } from '../../../redux/selectors/selectors';
import { createAdditionalService } from '../../../service/services/additional-service';
import { getAdditionalFeeTemplateList } from '../../../service/services/additional-fee-template';

const AdditionalServiceCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { list = [], loading } = useSelector(additionalFeeTemplateSelector);

  useEffect(() => {
    getAdditionalFeeTemplateList()(dispatch);
  }, [dispatch]);

  const transformArrayToObjectByKeys = list => {
    const result = {};

    list.map(
      item => (result[item._id] = item?.title_localizations?.en || item?.title),
    );

    return result;
  };

  const transformDataForCreatingService = data => {
    if (!data.evening) delete data.evening;
    if (!data.morning) delete data.morning;
    if (!data.weekend) delete data.weekend;

    return data;
  };

  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      {loading || !list ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <AdditionalServiceForm
          additionalServiceTypes={transformArrayToObjectByKeys(list)}
          defaultValues={{
            additionServiceType: list[0]._id,
          }}
          onSubmit={data => {
            createAdditionalService(
              transformDataForCreatingService(data),
              history,
            )(dispatch);
          }}
        />
      )}
    </Col>
  );
};

export default AdditionalServiceCreate;
