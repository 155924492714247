import { drPoopTypes } from './dr-poop.types';

const initialState = {
  userData: {
    loading: true,
    list: [],
    totalCount: 0,
    selected: {
      loading: true,
      item: {},
    },
  },
  statement: {
    loading: true,
    list: [],
    selected: {
      loading: true,
      item: {},
    },
  },
  countGradeDistribution: {
    loading: true,
    data: {},
  },
  stats: {
    loading: true,
    data: {},
  },
};

const drPoop = (state = initialState, { type, payload }) => {
  switch (type) {
    case drPoopTypes.GET_DR_POOP_USER_DATA_LIST_LOADING:
      return {
        ...state,
        userData: {
          ...state.userData,
          loading: true,
        },
      };

    case drPoopTypes.GET_DR_POOP_USER_DATA_LIST_SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          loading: false,
          list: payload.items,
          totalCount: payload.totalCount,
        },
      };
    case drPoopTypes.GET_DR_POOP_USER_DATA_LIST_FAILED:
      return {
        ...state,
        userData: {
          ...state.userData,
          loading: false,
        },
      };
    case drPoopTypes.GET_DR_POOP_STATEMENTS_LIST_SUCCESS:
      return {
        ...state,
        statement: {
          ...state.statement,
          loading: false,
          list: payload,
        },
      };
    case drPoopTypes.GET_DR_POOP_STATEMENTS_LIST_FAILED:
      return {
        ...state,
        statement: {
          ...state.statement,
          loading: false,
        },
      };
    case drPoopTypes.DELETE_ITEM_FROM_DR_POOP_USER_DATA_LIST:
      return {
        ...state,
        userData: {
          ...state.userData,
          list: state.userData.list.filter(item => item._id !== payload),
        },
      };
    case drPoopTypes.DELETE_ITEM_FROM_DR_POOP_STATEMENT_LIST:
      return {
        ...state,
        statement: {
          ...state.userData,
          list: state.statement.list.filter(item => item._id !== payload),
        },
      };
    case drPoopTypes.DR_POOP_USER_DATA_ITEM_REQUESTED:
      return {
        ...state,
        userData: {
          ...state.userData,
          selected: {
            ...state.userData.selected,
            loading: true,
          },
        },
      };
    case drPoopTypes.GET_DR_POOP_USER_DATA_ITEM_SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          selected: {
            ...state.userData.selected,
            loading: false,
            item: payload,
          },
        },
      };
    case drPoopTypes.GET_DR_POOP_USER_DATA_ITEM_FAILED:
      return {
        ...state,
        userData: {
          ...state.userData,
          selected: {
            ...state.userData.selected,
            loading: false,
          },
        },
      };

    case drPoopTypes.DR_POOP_STATEMENT_ITEM_REQUESTED:
      return {
        ...state,
        statement: {
          ...state.statement,
          selected: {
            ...state.statement.selected,
            loading: true,
          },
        },
      };

    case drPoopTypes.GET_DR_POOP_STATEMENT_ITEM_SUCCESS:
      return {
        ...state,
        statement: {
          ...state.statement,
          selected: {
            ...state.statement.selected,
            loading: false,
            item: payload,
          },
        },
      };
    case drPoopTypes.GET_DR_POOP_STATEMENT_ITEM_FAILED:
      return {
        ...state,
        statement: {
          ...state.statement,
          selected: {
            ...state.statement.selected,
            loading: false,
          },
        },
      };
    case drPoopTypes.SET_STATEMENT_LOADER:
      return {
        ...state,
        statement: {
          ...state.statement,
          selected: {
            ...state.statement.selected,
            loading: false,
          },
        },
      };

    case drPoopTypes.CLEAR_STATEMENT_ITEM:
      return {
        ...state,
        statement: {
          ...state.statement,
          selected: {
            ...state.statement.selected,
            loading: true,
            item: {},
          },
        },
      };
    case drPoopTypes.GET_DR_POOP_COUNT_GRADE_DISTRIBUTION_LOADING:
      return {
        ...state,
        countGradeDistribution: {
          ...state.countGradeDistribution,
          loading: true,
        },
      };
    case drPoopTypes.GET_DR_POOP_COUNT_GRADE_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        countGradeDistribution: {
          ...state.countGradeDistribution,
          data: { ...payload },
          loading: false,
        },
      };
    case drPoopTypes.GET_DR_POOP_COUNT_GRADE_DISTRIBUTION_FAILED:
      return {
        ...state,
        countGradeDistribution: {
          ...state.countGradeDistribution,
          data: {},
          loading: false,
        },
      };

    case drPoopTypes.GET_DR_POOP_STATS_SUCCESS:
      return {
        ...state,
        stats: {
          ...state.stats,
          data: payload,
          loading: false,
        },
      };
    case drPoopTypes.GET_DR_POOP_STATS_FAILED:
      return {
        ...state,
        stats: {
          ...state.stats,
          data: {},
          loading: false,
        },
      };
    case drPoopTypes.GET_DR_POOP_STATS_LOADING:
      return {
        ...state,
        stats: {
          ...state.stats,
          loading: true,
        },
      };
    default:
      return state;
  }
};

export default drPoop;
