import React from 'react';

const Checkbox = ({
  name,
  register,
  label,
  value = false,
  validationRules = {},
}) => {
  return (
    <>
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <div className="input-group">
        <input
          type="checkbox"
          {...register(name, validationRules)}
          value={value}
        />
      </div>
    </>
  );
};

export default Checkbox;
