import { FOOD_PRICES_MODIFICATORS_ACTIONS_TYPES } from './food-prices-modificators.types';
const initialState = {
  loading: false,
  error: '',
  list: [],
  selectedItem: null,
};

const foodPricesModificatorsReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case FOOD_PRICES_MODIFICATORS_ACTIONS_TYPES.LOADING:
      return {
        ...state,
        list: [...state.list],
        error: '',
        loading: true,
      };

    case FOOD_PRICES_MODIFICATORS_ACTIONS_TYPES.FAILED:
      return {
        ...state,
        list: [...state.list],
        loading: false,
        error: payload,
      };

    case FOOD_PRICES_MODIFICATORS_ACTIONS_TYPES.GET_LIST_SUCCESS:
      return {
        ...state,
        list: payload,
        loading: false,
        error: '',
      };

    case FOOD_PRICES_MODIFICATORS_ACTIONS_TYPES.GET_BY_ID_SUCCESS:
      return {
        ...state,
        list: [...state.list],
        selectedItem: payload,
        loading: false,
        error: '',
      };

    default:
      return state;
  }
};
export default foodPricesModificatorsReducer;
