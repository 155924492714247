import React, { useLayoutEffect, useState } from 'react';
import { Card, Button, Col, Form, Row, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import CustomSpinner from '../../../component/spinner';
import ConfigurableTable from '../../../component/table';
import { useQuery } from '../../../helpers/hooks';

import {
  DR_POOP_USER_DATA_TABLE_HEAD,
  TABLE_ITEM_TYPE,
} from '../../../component/table/config';

import Pagination from '../../../component/pagination/pagination';
import Input from '../../../component/inputs/index';
import EmptyResult from '../../../component/emptyResult/emptyResult';

import { drPoopUserDataSelector } from '../../../redux/selectors/selectors';
import { getDrPoopUserDataList } from '../../../service/dr-poop';
import { useExportCSV } from './hooks/useExportCSV';

const today = new Date().toISOString().split('T')[0];

const pageSize = 50;
const statuses = [
  { label: 'Processing', value: 'PROCESSING' },
  { label: 'Good', value: 'GOOD' },
  { label: 'Medium', value: 'MEDIUM' },
  { label: 'Bad', value: 'BAD' },
  { label: 'Rejected', value: 'REJECTED' },
];

const emptyFilters = {
  userId: '',
  drPoopId: '',
  dogId: '',
  status: '',
  dateBefore: '',
  dateAfter: '',
};

const DrPoop = () => {
  const { list, totalCount, loading } = useSelector(drPoopUserDataSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  const filtersFromLS = localStorage.getItem('dr-poop-filters');

  const defaultValues = {
    ...emptyFilters,
    ...JSON.parse(filtersFromLS),
  };

  const [filters, setFilters] = useState(defaultValues);
  const [isModalOpened, setIsModalOpened] = useState(false);

  // Filters
  const { handleSubmit, register, reset, setValue, watch } = useForm({
    defaultValues,
  });

  const pickedDateAfter = watch('dateAfter');

  useLayoutEffect(() => {
    dispatch(
      getDrPoopUserDataList({
        pageNumber: query.get('pageNumber')
          ? Number(query.get('pageNumber')) - 1
          : 0,
        pageSize,
        ...filters,
      }),
    );
  }, [dispatch, query, filters]);

  const onResetStatus = e => {
    e.preventDefault();
    setValue('status', '');
    let currentFilters = { ...filters, status: '' };

    setFilters(currentFilters);
  };

  const onSubmit = data => {
    localStorage.setItem('dr-poop-filters', JSON.stringify(data));
    setFilters(data);
    history.push(history.location.pathname + '?pageNumber=1');
  };

  const onReset = () => {
    localStorage.removeItem('dr-poop-filters');
    reset(emptyFilters);
    setFilters(emptyFilters);
    history.push(history.location.pathname + '?pageNumber=1');
  };

  const openModal = () => setIsModalOpened(true);
  const closeModal = () => setIsModalOpened(false);

  // Export
  const exportCSV = useExportCSV(closeModal);

  return (
    <Row className="mt-3 d-flex align-items-center w-100 ml-0 mr-0">
      {loading || exportCSV.isCsvLoading ? (
        <CustomSpinner />
      ) : (
        <div className="position-relative w-100">
          <Card className="p-3 w-100 mb-3 sticky-top">
            <Card.Title>Filters</Card.Title>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Row className="align-items-end">
                <Form.Group as={Col}>
                  <Input.text
                    label={'Submission Id'}
                    name="drPoopId"
                    register={register}
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Input.text
                    label={'Dog Id'}
                    name="dogId"
                    register={register}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Input.text
                    label={'User Id'}
                    name="userId"
                    register={register}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Input.Date
                    label={'From'}
                    name="dateAfter"
                    register={register}
                    max={today}
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Input.Date
                    label={'To'}
                    name="dateBefore"
                    register={register}
                    min={pickedDateAfter}
                    max={today}
                  />
                </Form.Group>
                <Form.Group as={Col} className="d-flex align-items-end">
                  <div>
                    <Form.Label>Status</Form.Label>
                    <Input.select
                      defaultHiddenValue={''}
                      label="Status"
                      optionsList={statuses}
                      name="status"
                      register={register}
                    />
                  </div>
                  <Button onClick={onResetStatus}>X</Button>
                </Form.Group>
                <Form.Group>
                  <div className="d-flex align-items-center justify-content-end">
                    <Button onClick={onReset} className="mr-3">
                      Reset filters
                    </Button>
                    <Button type="submit">Submit</Button>
                  </div>
                </Form.Group>
              </Form.Row>
            </Form>
            <Card.Footer>
              <Row className="justify-content-end">
                <Button onClick={openModal}>Export</Button>
              </Row>
            </Card.Footer>
          </Card>
          {totalCount === 0 && list.length === 0 ? (
            <EmptyResult />
          ) : (
            <ConfigurableTable
              tableHeaderColumns={DR_POOP_USER_DATA_TABLE_HEAD}
              itemVariant={TABLE_ITEM_TYPE.DR_POOP_USER_DATA_ITEM_TYPE}
              rows={list}
            />
          )}
          <Pagination
            total={totalCount}
            pageNumber={
              query.get('pageNumber') ? Number(query.get('pageNumber')) : 1
            }
            pageSize={pageSize}
            className="d-flex flex-wrap"
          />
          <Modal show={isModalOpened} onHide={closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Exporting</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={exportCSV.handleSubmit(exportCSV.onSubmit)}>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Input.Date
                      label={'From'}
                      name="dateAfter"
                      register={exportCSV.register}
                      max={today}
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Input.Date
                      label={'To'}
                      error={'Hello'}
                      name="dateBefore"
                      register={exportCSV.register}
                      min={exportCSV.minDate}
                      max={today}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="justify-content-end">
                  <Button variant="secondary" className="mr-2">
                    Close
                  </Button>
                  <Button variant="primary" type="submit">
                    Export as CSV
                  </Button>
                </Form.Row>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </Row>
  );
};

export default DrPoop;
