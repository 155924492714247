import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import GoBack from '../../component/buttons/goBackButton';
import CustomSpinner from '../../component/spinner';
import CityForm from '../../component/city-form/city-form';
import citiesOperations from '../../service/cities/cities';
import { citiesSelector } from '../../redux/selectors/selectors';
import { citiesActions } from '../../redux/cities/cities.actions';

const CityEdit = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { selectedItem: city, loading } = useSelector(citiesSelector);

  useEffect(() => {
    if (id) {
      dispatch(citiesOperations.getCityById(id));
    }

    return () => {
      dispatch(citiesActions.clearSelectedCity());
    };
  }, [dispatch, id]);

  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      {loading || !city ? (
        <CustomSpinner />
      ) : (
        <CityForm
          id={id}
          defaultValues={{
            name_localizations: {
              en: city?.name_localizations?.en || city?.name || '',
              il: city?.name_localizations?.il || '',
            },
            state: city?.state || '',
            countryId: city?.countryId || '',
          }}
        />
      )}
    </Col>
  );
};
export default CityEdit;
