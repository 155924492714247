import { Spinner } from 'react-bootstrap';
import './index.css';

const CustomSpinner = () => {
  return (
    <div className="mainSlider">
      <Spinner animation="border" variant="success" />
    </div>
  );
};

export default CustomSpinner;
