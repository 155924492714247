import { splashPhotosTypes } from './splash-photos.types';

const initialState = {
  loading: true,
  list: [],
  isListHasAnyChanges: false,
  selected: {},
};

const splashPhotos = (state = initialState, { type, payload }) => {
  switch (type) {
    case splashPhotosTypes.GET_SPLASH_PHOTOS_LIST_SUCCESS:
      const list = payload;

      list.sort((a, b) => {
        return a.order - b.order;
      });

      return {
        ...state,
        loading: false,
        list: list,
      };
    case splashPhotosTypes.GET_SPLASH_PHOTOS_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    case splashPhotosTypes.SET_ORDER_IN_SPLASH_PHOTOS_LIST:
      return {
        ...state,
        list: payload,
        isListHasAnyChanges: true,
      };
    case splashPhotosTypes.DELETE_SPLASH_PHOTOS_LIST_ITEM:
      return {
        ...state,
        list: state.list.filter(item => item._id !== payload),
      };
    case splashPhotosTypes.ADD_ITEM_TO_SPLASH_PHOTOS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, payload],
      };
    case splashPhotosTypes.ADD_ITEM_TO_SPLASH_PHOTOS_LIST_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case splashPhotosTypes.SET_IS_LIST_HAS_ANY_CHANGES_STATUS:
      return {
        ...state,
        isListHasAnyChanges: false,
      };
    case splashPhotosTypes.CHANGE_SPLASH_PHOTOS_LIST_ITEM_VISIBILITY_STATUS:
      return {
        ...state,
        isListHasAnyChanges: true,
        list: state.list.map(item => {
          if (item._id === payload) {
            item.isVisible = !item.isVisible;
            return item;
          }

          return item;
        }),
      };
    case splashPhotosTypes.SPLASH_PHOTOS_ITEM_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case splashPhotosTypes.SPLASH_PHOTOS_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        selected: payload,
      };

    case splashPhotosTypes.SPLASH_PHOTOS_ITEM_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default splashPhotos;
