import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import GoBack from '../../component/buttons/goBackButton';
import CoinsModificatorForm from '../../component/coinsModificatorForm/CoinsModificatorForm';
import CustomSpinner from '../../component/spinner';
import { clearSelectedCoinsModificator } from '../../redux/rewards/rewards.action';
import { getCoinsModificatorById } from '../../service/rewards';
import { rewardsSelector } from '../../redux/selectors/selectors';

const CoinsModificatorsDetails = ({ match }) => {
  const id = match.params?.id;
  const dispatch = useDispatch();

  const {
    coinsModificators: { loading, selectedItem },
  } = useSelector(rewardsSelector);

  const defaultValues = {
    modificator: selectedItem?.modificator,
    country: [
      selectedItem?.country?.name_localizations?.en ||
        selectedItem?.country?.name,
      selectedItem?.country?._id,
    ],
  };
  useEffect(() => {
    if (id) {
      dispatch(getCoinsModificatorById(id));
    }
    return () => dispatch(clearSelectedCoinsModificator());
  }, [id, dispatch]);

  return (
    <Col sm={12} className="mt-3">
      <Row className="d-flex">
        <GoBack />
      </Row>
      <Row className="mt-3 d-flex align-items-start justify-content-center">
        {loading && id ? (
          <CustomSpinner />
        ) : (
          <CoinsModificatorForm defaultValues={defaultValues} />
        )}
      </Row>
    </Col>
  );
};

export default CoinsModificatorsDetails;
