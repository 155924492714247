const breedsActionsTypes = {
  OPERATION_LOADING: 'breeds/OPERATION_LOADING',
  OPERATION_FAILED: 'breeds/OPERATION_FAILED',

  GET_LIST: 'breeds/GET_LIST',
  GET_BY_ID: 'breeds/GET_BY_ID',
  DELETE: 'breeds/DELETE',

  CLEAR_SELECTED: 'breeds/CLEAR_SELECTED',
};

export default breedsActionsTypes;
