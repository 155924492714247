import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Row, Button, Col } from 'react-bootstrap';

import CustomSpinner from '../../component/spinner';
import ConfigurableTable from '../../component/table';

import {
  DISCOUNT_REASONS_TABLE_HEAD,
  TABLE_ITEM_TYPE,
} from '../../component/table/config';

import { getDiscountReasonsList } from '../../service/discount-reasons/index';
import { discountReasonsSelector } from '../../redux/selectors/selectors';

const DiscountReasonsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, list } = useSelector(discountReasonsSelector);

  useEffect(() => {
    dispatch(getDiscountReasonsList());
  }, [dispatch]);

  return (
    <Row className="mt-3 d-flex align-items-center justify-content-center w-100 ml-0 mr-0">
      {loading ? (
        <CustomSpinner />
      ) : (
        <Col sm={12} className="p-0">
          <Button
            variant="primary"
            className="mb-3"
            onClick={() => history.push(window.location.pathname + '/create')}
          >
            Add new reason
          </Button>
          <ConfigurableTable
            tableHeaderColumns={DISCOUNT_REASONS_TABLE_HEAD}
            itemVariant={TABLE_ITEM_TYPE.DISCOUNT_REASON_ITEM}
            rows={list}
          />
        </Col>
      )}
    </Row>
  );
};

export default DiscountReasonsList;
