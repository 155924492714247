import React from 'react';
import { Row, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import Input from '../inputs';
import { validationRules } from './validation';

const ContractForm = ({ onSubmit, defaultValues }) => {
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-100"
      id="contract-form"
    >
      <Row className="d-flex align-items-center p-3">
        <Input.text
          register={register}
          label="Name"
          name="name"
          validationRules={validationRules.name}
          error={errors.name}
        />
      </Row>
      <Button
        disabled={!isDirty}
        type="submit"
        variant="primary"
        className="mt-3 p-3 w-100"
      >
        Save changes
      </Button>
    </form>
  );
};

export default ContractForm;
