import { useDispatch } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';

import { createWorkerType, updateWorkerType } from '../../service/workers/type';

const WorkerTypeForm = ({
  history,
  defaultValues = {},
  type = '',
  id = null,
}) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const onSubmit = data => {
    data.name = data.name.trim();
    type === 'CREATE' && dispatch(createWorkerType(data, history));
    type === 'EDIT' && dispatch(updateWorkerType(id, data, history));
  };

  return (
    <Form className="border p-4 mt-4" onSubmit={handleSubmit(onSubmit)}>
      <Form.Label>Type</Form.Label>
      <div className="position-relative">
        {errors?.name ? (
          <p
            className="text-danger position-absolute mb-0"
            style={{ bottom: -20, right: 0, fontSize: 12 }}
          >
            {errors.name.message}
          </p>
        ) : null}
        <Form.Control
          {...register('name', {
            required: { value: true, message: 'It is required field' },
            validate: value => value.trim().length >= 3 || 'Minimal 3 symbols',
          })}
          placeholder="Name of type..."
        />
      </div>
      <Button className="mt-4" variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

export default withRouter(WorkerTypeForm);
