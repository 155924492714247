import fetchWithAuth from '../index';
import setLoading from '../../redux/default';

import { URL_SUPER_ADMIN } from '../../constant';

import MESSAGES_FOR_USER from '../../constants/messages/en';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';

import { destinationUrls } from '../../component/menu/constants';
import {
  getAdditionalFeeTemplateListSuccess,
  getAdditionalFeeTemplateListLoading,
  getAdditionalFeeTemplateByIdLoading,
  getAdditionalFeeTemplateByIdSuccess,
  deleteAdditionalFeeTemplateSuccess,
} from '../../redux/additional-fee-template/additional-fee-template.action';

export const getAdditionalFeeTemplateList = () => async dispatch => {
  dispatch(getAdditionalFeeTemplateListLoading());

  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/addition-service-type/`,
    method: 'GET',
  });

  dispatch(getAdditionalFeeTemplateListSuccess(await result.json()));
  return;
};

export const createAdditionalFeeTemplate =
  (data, history) => async dispatch => {
    const result = await fetchWithAuth({
      path: `${URL_SUPER_ADMIN}/addition-service-type/`,
      method: 'POST',
      body: JSON.stringify(data),
    });

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.ADDITIONA_FEE_TEMPLATE.CREATE_FAILED.TITLE,
          content: MESSAGES_FOR_USER.ADDITIONA_FEE_TEMPLATE.CREATE_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );
      return;
    }

    history.push(destinationUrls.FEE_TEMPLATE);
    return;
  };

export const getAdditionalFeeTemplateById = id => async dispatch => {
  dispatch(getAdditionalFeeTemplateByIdLoading());
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/addition-service-type/${id}`,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.ADDITIONA_FEE_TEMPLATE.UPDATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.ADDITIONƒA_FEE_TEMPLATE.UPDATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  dispatch(getAdditionalFeeTemplateByIdSuccess(await result.json()));
};

export const deleteAdditionalFeeTemplateById = id => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/addition-service-type/${id}`,
    method: 'DELETE',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.ADDITIONA_FEE_TEMPLATE.DELETE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.ADDITIONƒA_FEE_TEMPLATE.DELETE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  dispatch(deleteAdditionalFeeTemplateSuccess(id));
};

export const updateAdditionalFeeTemplate =
  (id, data, history) => async dispatch => {
    let result = await fetchWithAuth({
      path: `${URL_SUPER_ADMIN}/addition-service-type/${id}`,
      method: 'PUT',
      body: JSON.stringify(data),
    });

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.ADDITIONA_FEE_TEMPLATE.UPDATE_FAILED.TITLE,
          content: MESSAGES_FOR_USER.ADDITIONA_FEE_TEMPLATE.UPDATE_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );
      return;
    }

    history.push(destinationUrls.FEE_TEMPLATE);
    return;
  };
