import { Col, Row } from 'react-bootstrap';

import GoBack from '../../component/buttons/goBackButton';
import FoodPricesModificatorsForm from './components/food-prices-modificators-form/FoodPricesModificatorsForm';

const FoodPricesModificatorsCreate = () => {
  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      <FoodPricesModificatorsForm />
    </Col>
  );
};

export default FoodPricesModificatorsCreate;
