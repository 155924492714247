import { TOAST_MESSAGE_TYPES } from '../component/toast-message/config';
import MESSAGES_FOR_USER from '../constants/messages/en';
import { showToastMessage } from './toast-message/toast-message.action';
import { toastMessageTypes } from './toast-message/toast-message.types';

const handleUnauthorizedUsers = store => next => action => {
  if (
    action.type === toastMessageTypes.SHOW_TOAST_MESSAGE &&
    !store.getState().auth.authSession
  ) {
    return next(
      showToastMessage({
        title: MESSAGES_FOR_USER.AUTH.EXPIRED_TOKEN.TITLE,
        content: MESSAGES_FOR_USER.AUTH.EXPIRED_TOKEN.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
  }

  return next(action);
};

export { handleUnauthorizedUsers };
