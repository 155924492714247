import { toastMessageTypes } from './toast-message.types';

export const showToastMessage = toastContent => ({
  type: toastMessageTypes.SHOW_TOAST_MESSAGE,
  payload: toastContent,
});

export const hideToastMessage = () => ({
  type: toastMessageTypes.HIDE_TOAST_MESSAGE,
});
