import { contractsTypes } from './contracts.types';

const initialSate = {
  loading: false,
  error: false,
  list: null,
  item: false,
};

const contracts = (state = initialSate, { type, payload }) => {
  switch (type) {
    case contractsTypes.GET_CONTRACTS_LIST_LOADING:
    case contractsTypes.GET_CONTRACT_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
      };
    case contractsTypes.GET_CONTRACTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload,
      };
    case contractsTypes.GET_CONTRACT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };
    case contractsTypes.DELETE_CONTRACT_SUCCESS:
      const newList = state.list.filter(item => item._id !== payload);
      return {
        ...state,
        list: newList,
        loading: false,
      };
    case contractsTypes.GET_CONTRACTS_LIST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case contractsTypes.CLEAR_ITEM:
      return {
        ...state,
        item: false,
      };

    default:
      return state;
  }
};

export default contracts;
