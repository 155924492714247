import { reasonTypes } from './reason.types';

const initialSate = {
  loading: false,
  error: false,
  list: [],
  item: false,
};

const reason = (state = initialSate, { type, payload }) => {
  switch (type) {
    case reasonTypes.GET_REASON_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case reasonTypes.GET_REASON_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload,
      };
    case reasonTypes.GET_REASON_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };
    case reasonTypes.DELETE_REASON_SUCCESS:
      const newList = state.list.filter(item => item._id !== payload);
      return {
        ...state,
        list: newList,
        loading: false,
      };
    case reasonTypes.CLEAR_ITEM:
      return {
        ...state,
        item: false,
      };
    case reasonTypes.UPDATE_REASON_SUCCESS:
      const { updatedData, id } = payload;
      const index = state.list.findIndex(item => item._id !== id);
      let updatedList = [...state.list];
      const oldItem = state.list[index];
      updatedList[index] = { ...oldItem, ...updatedData };

      return {
        ...state,
        list: updatedList,
      };

    case reasonTypes.GET_REASON_LIST_FAIL:
    case reasonTypes.CREATE_REASON_FAIL:
    case reasonTypes.DELETE_REASON_FAIL:
    case reasonTypes.GET_REASON_BY_ID_FAIL:
    case reasonTypes.UPDATE_REASON_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default reason;
