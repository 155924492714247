import { useEffect, useRef, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import { sendPhotoForMask } from '../../service/dr-poop';
import { createMask, rle2mask } from './utils';
import CustomSpinner from '../../component/spinner';

const DrPoopMask = () => {
  const canvasRef = useRef(null);
  const [image, setImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [mask, setMask] = useState(null);
  const [maskShape, setMaskShape] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (mask && maskShape && image) {
      createMask(canvasRef, image, rle2mask(mask, maskShape));
    }
  }, [mask, image, maskShape]);

  const onSend = async () => {
    setIsLoading(true);
    setError(false);
    try {
      const response = await sendPhotoForMask({ image });
      setMask(response.mask);
      setMaskShape(response.shape);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Row>
      <Col md={12}>
        <h2>Dr. Poop mask test</h2>
      </Col>
      <Col md={12}>
        <input
          type="file"
          onChange={e => {
            setImage(e.target.files[0]);
            setError(false);
            setMask(null);
            setMaskShape(null);
          }}
        />
      </Col>
      <Col md={12}>
        <Button onClick={onSend} disabled={isLoading}>
          Send
        </Button>
      </Col>
      <Col md={12}>
        {isLoading && !mask ? (
          <CustomSpinner />
        ) : (
          <>
            {error ? (
              <h3 className="text-danger">{error}</h3>
            ) : (
              <canvas ref={canvasRef}></canvas>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

export default DrPoopMask;
