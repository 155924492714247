export const validationRules = {
  title_localizations: {
    en: {
      required: 'Title field is required!',
    },
    il: {
      required: 'Title field is required!',
    },
  },

  subTitle_localizations: {
    en: {
      required: 'Subtitle field is required!',
    },
    il: {
      required: 'Subtitle field is required!',
    },
  },
};
