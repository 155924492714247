import { useDispatch } from 'react-redux';
import { Col, Button, Form, Row } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { withRouter } from 'react-router';

import { destinationType } from '../../constant';
import {
  addPublicationList,
  changePublicationItem,
} from '../../service/publication/publication';
import Suneditor from '../suneditor/Suneditor';
import UploadImage from '../uploadImage/UploadImage';

const PublicationForm = ({ history, id, defaultValues = {}, type = '' }) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const onSubmit = data => {
    const body = {
      ...data,
      title: data.title_localizations.en,
      subTitle: data.subTitle_localizations.en,
      content: data.content_localizations.en,
      redirect: {
        link: data.redirect_localizations.en.link,
        destination: data.redirect_localizations.en.destination,
      },
    };

    type === 'CREATE' && dispatch(addPublicationList(body, history));
    type === 'EDIT' && dispatch(changePublicationItem(id, body, history));
  };
  return (
    <Form className="border p-4 mt-4" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="formGridEmail">
        <Form.Label className="d-flex justify-content-end">
          {errors?.image && (
            <p style={{ fontSize: '12px' }} className="text-danger m-0">
              {errors?.image?.message}
            </p>
          )}
        </Form.Label>
        <Controller
          control={control}
          name="image"
          rules={{ required: 'Required field' }}
          render={({ field: { onChange } }) => (
            <UploadImage
              defaultImage={defaultValues.image}
              onChange={onChange}
            />
          )}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label className="d-flex justify-content-between align-items-center">
          Title (EN)
          {errors?.title_localizations?.en && (
            <p style={{ fontSize: '12px' }} className="text-danger m-0">
              {errors?.title_localizations?.en?.message}
            </p>
          )}
        </Form.Label>

        <Form.Control
          {...register('title_localizations.en', {
            required: 'Required field',
          })}
          as="textarea"
          placeholder="Publication title..."
        />

        <Form.Label className="d-flex justify-content-between align-items-center mt-2">
          Title (IL)
          {errors?.title_localizations?.il && (
            <p style={{ fontSize: '12px' }} className="text-danger m-0">
              {errors?.title_localizations?.il?.message}
            </p>
          )}
        </Form.Label>
        <Form.Control
          {...register('title_localizations.il', {
            required: 'Required field',
          })}
          as="textarea"
          placeholder="Publication title..."
        />
      </Form.Group>

      <Form.Group controlId="formGridPassword">
        <Form.Label className="d-flex justify-content-between align-items-center">
          Subtitle (EN)
          {errors?.subTitle_localizations?.en && (
            <p style={{ fontSize: '12px' }} className="text-danger m-0">
              {errors?.subTitle_localizations?.en?.message}
            </p>
          )}
        </Form.Label>
        <Form.Control
          {...register('subTitle_localizations.en', {
            required: 'Required fied',
          })}
          as="textarea"
          placeholder="Publication subtitle..."
        />

        <Form.Label className="d-flex justify-content-between align-items-center mt-2">
          Subtitle (IL)
          {errors?.subTitle_localizations?.il && (
            <p style={{ fontSize: '12px' }} className="text-danger m-0">
              {errors?.subTitle_localizations?.il?.message}
            </p>
          )}
        </Form.Label>
        <Form.Control
          {...register('subTitle_localizations.il', {
            required: 'Required fied',
          })}
          as="textarea"
          placeholder="Publication subtitle..."
        />
      </Form.Group>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label className="d-flex justify-content-between align-items-center">
            Redirection link (EN)
            {errors.redirect?.link && (
              <p style={{ fontSize: '12px' }} className="text-danger m-0">
                {errors.redirect.link.message}
              </p>
            )}
          </Form.Label>
          <Form.Control
            {...register('redirect_localizations.en.link', {
              required: 'Required field',
            })}
            placeholder="https://"
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridZip">
          <Form.Label className="d-flex justify-content-between align-items-center">
            Destination type (EN)
            {errors.redirect?.destination && (
              <p style={{ fontSize: '12px' }} className="text-danger m-0">
                {errors.redirect.destination.message}
              </p>
            )}
          </Form.Label>
          <Form.Control
            as="select"
            placeholder="Choose..."
            {...register('redirect_localizations.en.destination', {
              required: 'Required field',
            })}
          >
            {destinationType.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label className="d-flex justify-content-between align-items-center">
            Redirection link (IL)
            {errors.redirect?.link && (
              <p style={{ fontSize: '12px' }} className="text-danger m-0">
                {errors.redirect.link.message}
              </p>
            )}
          </Form.Label>
          <Form.Control
            {...register('redirect_localizations.il.link', {
              required: 'Required field',
            })}
            placeholder="https://"
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridZip">
          <Form.Label className="d-flex justify-content-between align-items-center">
            Destination type (IL)
            {errors.redirect?.destination && (
              <p style={{ fontSize: '12px' }} className="text-danger m-0">
                {errors.redirect.destination.message}
              </p>
            )}
          </Form.Label>
          <Form.Control
            as="select"
            placeholder="Choose..."
            {...register('redirect_localizations.il.destination', {
              required: 'Required field',
            })}
          >
            {destinationType.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Row>

      <Form.Group>
        <Form.Label className="d-flex justify-content-between align-items-center">
          Content (EN)
          {errors?.content_localizations?.en && (
            <p style={{ fontSize: '12px' }} className="text-danger m-0">
              {errors?.content_localizations?.en?.message}
            </p>
          )}
        </Form.Label>
        <Controller
          control={control}
          name="content_localizations.en"
          rules={{ required: 'Required field' }}
          render={({ field: { onChange, value } }) => (
            <Suneditor
              onChange={onChange}
              value={defaultValues.content_localizations?.en || value}
            />
          )}
        />

        <Form.Label className="d-flex justify-content-between align-items-center mt-2">
          Content (IL)
          {errors?.content_localizations?.il && (
            <p style={{ fontSize: '12px' }} className="text-danger m-0">
              {errors?.content_localizations?.il?.message}
            </p>
          )}
        </Form.Label>
        <Controller
          control={control}
          name="content_localizations.il"
          rules={{ required: 'Required field' }}
          render={({ field: { onChange, value } }) => (
            <Suneditor
              onChange={onChange}
              value={defaultValues.content_localizations?.il || value}
            />
          )}
        />
      </Form.Group>

      <Form.Group className="mt-3" id="formGridCheckbox">
        <Form.Check {...register('isShow')} type="checkbox" label="is show" />
      </Form.Group>

      <Button className="mt-4" variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

export default withRouter(PublicationForm);
