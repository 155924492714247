import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import GoBackButton from '../../component/buttons/goBackButton';
import BillingForm from '../../component/billing-form/billing-form';
import { createBilling } from '../../service/billing/billing';

const CreateBilling = () => {
  const dispatch = useDispatch();

  const handleSubmit = data => {
    let params =
      '?' +
      Object.values(data)
        .map(id => (id ? 'userId[]=' + id : undefined))
        .filter(id => id)
        .join('&');

    dispatch(createBilling(params));
  };

  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBackButton />
          </div>
        </Col>
      </Row>
      <BillingForm onSubmit={handleSubmit} />
    </Col>
  );
};

export default CreateBilling;
