import { additionalServiceTypes } from './additional-service.types';

const initialSate = {
  loading: false,
  error: false,
  list: null,
  item: false,
};

const additionalService = (state = initialSate, { type, payload }) => {
  switch (type) {
    case additionalServiceTypes.GET_ADDITIONAL_SERVICE_LIST_LOADING:
    case additionalServiceTypes.GET_ADDITIONAL_SERVICE_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
      };
    case additionalServiceTypes.GET_ADDITIONAL_SERVICE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload,
      };
    case additionalServiceTypes.GET_ADDITIONAL_SERVICE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };
    case additionalServiceTypes.DELETE_ADDITIONAL_SERVICE_SUCCESS:
      const newList = state.list.filter(item => item._id !== payload);
      return {
        ...state,
        list: newList,
        loading: false,
      };
    case additionalServiceTypes.CLEAR_ADDITIONAL_SERVICE_ITEM:
      return {
        ...state,
        item: false,
      };

    default:
      return state;
  }
};

export default additionalService;
