import { reviewsActionTypes } from './reviews.types';

const initialState = {
  loading: null,
  error: null,
  list: [],
};

const reviewsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    //loading
    case reviewsActionTypes.GET_REVIEWS_LOADING:
      return { ...state, loading: true };

    // success
    case reviewsActionTypes.GET_REVIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload,
      };
    case reviewsActionTypes.UPDATE_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [
          ...state.list.map(i => {
            if (i._id === payload._id) return payload;
            else return i;
          }),
        ],
      };

    //failed
    case reviewsActionTypes.GET_REVIEWS_FAIL:
    case reviewsActionTypes.UPDATE_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default reviewsReducer;
