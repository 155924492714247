import fetchWithAuth from '..';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';
import MESSAGES_FOR_USER from '../../constants/messages/en';
import { FOOD_PRICES_MODIFICATORS_ACTIONS } from '../../redux/food-prices-modificators/food-prices-modificators.actions';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';

const getList = () => async dispatch => {
  const url = `/super-admin/price-increase-percent`;

  dispatch(FOOD_PRICES_MODIFICATORS_ACTIONS.loading());

  let result = await fetchWithAuth({
    path: url,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.FOOD_PRICES_MODIFICATORS.GET_FAILED.TITLE,
        content: MESSAGES_FOR_USER.FOOD_PRICES_MODIFICATORS.GET_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );

    dispatch(FOOD_PRICES_MODIFICATORS_ACTIONS.failed('ERROR'));
    return;
  }

  const data = await result.json();

  dispatch(FOOD_PRICES_MODIFICATORS_ACTIONS.getListSuccess(data.result));
};

const getById = id => async dispatch => {
  const url = `/super-admin/price-increase-percent/${id}`;

  dispatch(FOOD_PRICES_MODIFICATORS_ACTIONS.loading());

  let result = await fetchWithAuth({
    path: url,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.FOOD_PRICES_MODIFICATORS.GET_FAILED.TITLE,
        content: MESSAGES_FOR_USER.FOOD_PRICES_MODIFICATORS.GET_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );

    dispatch(FOOD_PRICES_MODIFICATORS_ACTIONS.failed('ERROR'));
    return;
  }

  const data = await result.json();

  dispatch(FOOD_PRICES_MODIFICATORS_ACTIONS.getByIdSuccess(data));
};

const create = body => async dispatch => {
  const url = `/super-admin/price-increase-percent`;

  dispatch(FOOD_PRICES_MODIFICATORS_ACTIONS.loading());

  let result = await fetchWithAuth({
    path: url,
    method: 'POST',
    body: JSON.stringify(body),
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.FOOD_PRICES_MODIFICATORS.CREATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.FOOD_PRICES_MODIFICATORS.CREATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );

    dispatch(FOOD_PRICES_MODIFICATORS_ACTIONS.failed('ERROR'));
    return;
  }

  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.FOOD_PRICES_MODIFICATORS.CREATE_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.FOOD_PRICES_MODIFICATORS.CREATE_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );
};

const update =
  ({ body, id }) =>
  async dispatch => {
    const url = `/super-admin/price-increase-percent/${id}`;

    dispatch(FOOD_PRICES_MODIFICATORS_ACTIONS.loading());

    let result = await fetchWithAuth({
      path: url,
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.FOOD_PRICES_MODIFICATORS.UPDATE_FAILED.TITLE,
          content:
            MESSAGES_FOR_USER.FOOD_PRICES_MODIFICATORS.UPDATE_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );

      dispatch(FOOD_PRICES_MODIFICATORS_ACTIONS.failed('ERROR'));
      return;
    }

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.FOOD_PRICES_MODIFICATORS.UPDATE_SUCCESS.TITLE,
        content: MESSAGES_FOR_USER.FOOD_PRICES_MODIFICATORS.UPDATE_SUCCESS.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );
  };

const deleteById = id => async dispatch => {
  const url = `/super-admin/price-increase-percent/${id}`;

  dispatch(FOOD_PRICES_MODIFICATORS_ACTIONS.loading());

  let result = await fetchWithAuth({
    path: url,
    method: 'DELETE',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.FOOD_PRICES_MODIFICATORS.DELETE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.FOOD_PRICES_MODIFICATORS.DELETE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );

    dispatch(FOOD_PRICES_MODIFICATORS_ACTIONS.failed('ERROR'));
    return;
  }

  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.FOOD_PRICES_MODIFICATORS.DELETE_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.FOOD_PRICES_MODIFICATORS.DELETE_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );

  dispatch(getList());
};

export const FOOD_PRICES_MODIFICATORS_SERVICES = {
  getList,
  getById,
  create,
  update,
  delete: deleteById,
};
