import React from 'react';

const DeletedDogsItem = ({ index, row }) => {
  return (
    <tr>
      <td className="text-center align-middle">{row._id}</td>
      <td className="text-center align-middle">{row.dogId?.name || '-'}</td>
      <td className="text-center align-middle">{row.dogId?.age || '-'}</td>
      <td className="text-center align-middle">
        {row?.reasonId?.reason || '-'}
      </td>
    </tr>
  );
};

export default DeletedDogsItem;
