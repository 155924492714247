import fetchWithAuth from '../index';
import {
  countriesListReceivedSuccess,
  countriesListRequested,
  getNotificationsSettingsByIdSuccess,
  getNotificationsSettingsSuccess,
  notificationsSettingsLoading,
} from '../../redux/notifications/notifications.action';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';

import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';
import MESSAGES_FOR_USER from '../../constants/messages/en';

export const getCountriesList = () => async dispatch => {
  let result = await fetchWithAuth({
    path: `/user/countries`,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title:
          MESSAGES_FOR_USER.NOTIFICATIONS.COUNTRIES_LIST_RECEIVED_FAILED.TITLE,
        content:
          MESSAGES_FOR_USER.NOTIFICATIONS.COUNTRIES_LIST_RECEIVED_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  dispatch(countriesListReceivedSuccess(await result.json()));
};

export const sendPushNotification = notificationObj => async dispatch => {
  let result = await fetchWithAuth({
    path: `/super-admin/notification`,
    method: 'POST',
    body: JSON.stringify(notificationObj),
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.NOTIFICATIONS.SENT_ERROR.TITLE,
        content: MESSAGES_FOR_USER.NOTIFICATIONS.SENT_ERROR.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.NOTIFICATIONS.SENT_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.NOTIFICATIONS.SENT_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );
};

export const getNotificationsSettings = params => async dispatch => {
  const reqParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    reqParams.set(key, value);
  });

  dispatch(notificationsSettingsLoading());

  let result = await fetchWithAuth({
    path: '/app-settings?' + reqParams.toString(),
    method: 'GET',
  });

  if (!result.ok) {
    return;
  }

  dispatch(getNotificationsSettingsSuccess(await result.json()));
};

export const getNotificationsSettingsById = id => async dispatch => {
  dispatch(notificationsSettingsLoading());

  let result = await fetchWithAuth({
    path: '/app-settings/' + id,
    method: 'GET',
  });

  if (!result.ok) {
    return;
  }

  dispatch(getNotificationsSettingsByIdSuccess(await result.json()));
};

export const updateNofificationsSetting =
  ({ id, body }) =>
  async dispatch => {
    let result = await fetchWithAuth({
      path: '/app-settings/' + id,
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.NOTIFICATIONS.UPDATE_CONFIG_ERROR.TITLE,
          content: MESSAGES_FOR_USER.NOTIFICATIONS.UPDATE_CONFIG_ERROR.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );
      return;
    }

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.NOTIFICATIONS.UPDATE_CONFIG_SUCCESS.TITLE,
        content: MESSAGES_FOR_USER.NOTIFICATIONS.UPDATE_CONFIG_SUCCESS.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );
  };
