import React from 'react';

import './styles.css';

const LabelWithTooltip = ({ configs: { label, hiddenContent } }) => {
  return (
    <div className="w-100 d-flex">
      <span className="label mr-3">{label}</span>
      <div className="position-relative">
        <i className="fa fa-question-circle text-green tooltip-icon" />
        <span className="tooltip-content">{hiddenContent}</span>
      </div>
    </div>
  );
};

export default LabelWithTooltip;
