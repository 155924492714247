import { scoreTypes } from './score.types';

const initialState = {
  loading: true,
  lists: {
    weightGroups: [],
    fitness: [],
    parsedWeightGroups: [],
  },
  selectedItem: null,
};

const score = (state = initialState, { type, payload }) => {
  switch (type) {
    case scoreTypes.GET_SCORES_WEIGHT_GROUP_LIST_SUCCESS:
      if (payload.isNeedEnableLoader) {
        return {
          ...state,
          lists: {
            ...state.lists,
            parsedWeightGroups: payload.list.map(item => {
              return {
                label: item.name,
                value: item._id,
              };
            }),
          },
        };
      }

      return {
        ...state,
        loading: false,
        lists: {
          ...state.lists,
          weightGroups: payload.list,
        },
      };

    case scoreTypes.GET_SCORES_WEIGHT_GROUP_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };

    case scoreTypes.GET_SCORES_FITNESS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        lists: {
          ...state.lists,
          fitness: payload,
        },
      };

    case scoreTypes.GET_SCORES_FITNESS_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };

    case scoreTypes.DELETE_SCORES_WEIGHT_GROUP_LIST_ITEM:
      return {
        ...state,
        lists: {
          ...state.lists,
          weightGroups: state.lists.weightGroups.filter(
            item => item._id !== payload,
          ),
        },
      };

    case scoreTypes.DELETE_SCORES_FITNESS_LIST_ITEM:
      return {
        ...state,
        lists: {
          ...state.lists,
          fitness: state.lists.fitness.filter(item => item._id !== payload),
        },
      };

    case scoreTypes.SCORES_WEIGHT_GROUP_ITEM_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case scoreTypes.GET_SCORES_WEIGHT_GROUP_ITEM_SUCCESS:
      const {
        id: weightGroupID,
        _id: _weightGroupID,
        ...weightGroupRest
      } = payload;

      return {
        ...state,
        loading: false,
        selectedItem: weightGroupRest,
      };

    case scoreTypes.GET_SCORES_WEIGHT_GROUP_ITEM_FAIL:
      return {
        ...state,
        loading: false,
      };

    case scoreTypes.GET_SCORES_FITNESS_ITEM_SUCCESS:
      const { id: fitnessID, _id: _fitnessID, ...fitnessRest } = payload;

      if (!fitnessRest.group.includes('_WALK')) {
        fitnessRest.group = fitnessRest.group + '_WALK'; // I have no ideas what is it and why backend can't add it on his side...
      }

      fitnessRest.weight = fitnessRest.weight._id;

      return {
        ...state,
        loading: false,
        selectedItem: fitnessRest,
      };

    case scoreTypes.GET_SCORES_FITNESS_ITEM_FAIL:
      return {
        ...state,
        loading: false,
      };

    case scoreTypes.CLEAR_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: null,
      };

    case scoreTypes.SET_SCORE_LOADER:
      return {
        ...state,
        loading: payload,
      };

    default:
      return state;
  }
};

export default score;
