export const getNormalizedEntities = (entityList, fields = null) => {
  if (entityList === null) return [null, null];
  let normalized = {};
  let keys = [];

  Array.from(entityList, entity => {
    keys.push(entity._id);

    if (fields === null) {
      normalized[entity._id] = entity;
    } else {
      normalized[entity._id] = {};
      fields.forEach(field => {
        normalized[entity._id][field] = entity[field];
      });
    }

    return entity;
  });

  return [normalized, keys];
};
