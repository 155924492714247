export const FOOD_PRICES_MODIFICATORS_ACTIONS_TYPES = {
  LOADING: 'food-prices-modificators/LOADING',

  GET_LIST_SUCCESS: 'food-prices-modificators/GET_LIST_SUCCESS',
  GET_BY_ID_SUCCESS: 'food-prices-modificators/GET_BY_ID_SUCCESS',
  CREATE_SUCCESS: 'food-prices-modificators/CREATE_SUCCESS',
  UPDATE_SUCCESS: 'food-prices-modificators/UPDATE_SUCCESS',
  DELETE_SUCCESS: 'food-prices-modificators/DELETE_SUCCESS',

  FAILED: 'food-prices-modificators/FAILED',
};
