import FoodPricesModificatorsCreate from './create';
import FoodPricesModificatorsList from './list';
import FoodPricesModificatorsEdit from './edit';

const FoodPricesModificators = {
  List: FoodPricesModificatorsList,
  Create: FoodPricesModificatorsCreate,
  Edit: FoodPricesModificatorsEdit,
};

export default FoodPricesModificators;
