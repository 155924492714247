import { Row, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Input from '../../../component/inputs/index';
import { discountReasonsSelector } from '../../../redux/selectors/selectors';

const validationRules = {
  name_localizations: {
    en: {
      required: 'This field is required',
    },
  },
};

const DiscountReasonForm = ({ onSubmit, defaultValues }) => {
  const { loading } = useSelector(discountReasonsSelector);

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-100"
      id="contract-form"
    >
      <Row className="d-flex align-items-center p-3">
        <Input.text
          register={register}
          label="Name(en)"
          name="name_localizations.en"
          validationRules={validationRules.name_localizations.en}
          error={errors?.name_localizations?.en}
        />
      </Row>
      <Row className="d-flex align-items-center p-3">
        <Input.text
          register={register}
          label="Name(il)"
          name="name_localizations.il"
        />
      </Row>
      <Button
        disabled={!isDirty || loading}
        type="submit"
        variant="primary"
        className="mt-3 p-3 w-100"
      >
        Save changes
      </Button>
    </form>
  );
};

export default DiscountReasonForm;
