import { deletedDogsTypes } from './deleted-dogs.types';

const initialSate = {
  loading: false,
  error: false,
  list: null,
};

const deletedDogs = (state = initialSate, { type, payload }) => {
  switch (type) {
    case deletedDogsTypes.GET_DELETED_DOGS_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case deletedDogsTypes.GET_DELETED_DOGS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload,
      };

    default:
      return state;
  }
};

export default deletedDogs;
