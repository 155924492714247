import { useSelector } from 'react-redux';
import SetPhone from '../../component/setPhone';
import CheckCode from '../../component/checkCode';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

const Auth = () => {
  let history = useHistory();
  const step = useSelector(state => state.auth.step);

  const stepComponent = {
    setPhone: <SetPhone />,
    checkCode: <CheckCode />,
    mainPage: null,
  };

  useEffect(() => {
    const isAuth = localStorage.getItem('auth');
    if (step === 'mainPage' && isAuth) history.push('/publication');
  }, [step, history]);

  return stepComponent[step];
};

export default Auth;
