import QuestionList from "./list";
import QuestionDetails from "./details";
import QuestionCreate from "./create";
import QuestionEdit from "./edit";


const Question = {
    list: QuestionList,
    details: QuestionDetails,
    create: QuestionCreate,
    edit: QuestionEdit
};

export default Question;