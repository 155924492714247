import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';

import { useQuery } from '../../helpers/hooks';
import CustomSpinner from '../../component/spinner';
import ConfigurableTable from '../../component/table';
import Pagination from '../../component/pagination/pagination';
import { rewardsSelector } from '../../redux/selectors/selectors';
import {
  REWARDS_COINS_MODIFICATORS_TABLE_HEAD,
  TABLE_ITEM_TYPE,
} from '../../component/table/config';

import { getCoinsModificatorsList } from '../../service/rewards';
import { clearCoinsModificatorsState } from '../../redux/rewards/rewards.action';

const pageSize = 50;

const CoinsModificators = props => {
  const { history, location } = props;
  const dispatch = useDispatch();
  const query = useQuery();

  const {
    coinsModificators: { loading, list, totalCount },
  } = useSelector(rewardsSelector);

  useEffect(() => {
    dispatch(
      getCoinsModificatorsList(
        query.get('pageNumber') ? Number(query.get('pageNumber')) - 1 : 0,
        pageSize,
      ),
    );

    return () => dispatch(clearCoinsModificatorsState());
  }, [dispatch, query]);

  return (
    <Row className="mt-3 d-flex align-items-center justify-content-center w-100 ml-0 mr-0">
      {loading ? (
        <CustomSpinner />
      ) : (
        <Col sm={12} className="p-0">
          <Button
            variant="primary"
            className="mb-3"
            onClick={() => history.push(location.pathname + '/create')}
          >
            Add new
          </Button>

          <ConfigurableTable
            tableHeaderColumns={REWARDS_COINS_MODIFICATORS_TABLE_HEAD}
            itemVariant={TABLE_ITEM_TYPE.COINS_MODIFICATORS_ITEM_TYPE}
            rows={list}
          />
          {totalCount >= pageSize && (
            <Pagination
              total={totalCount}
              pageSize={pageSize}
              pageNumber={
                query.get('pageNumber') ? Number(query.get('pageNumber')) : 1
              }
            />
          )}
        </Col>
      )}
    </Row>
  );
};

export default CoinsModificators;
