import { Col, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomSpinner from '../../../component/spinner';
import ConfigurableTable from '../../../component/table';

import { TABLE_ITEM_TYPE, DELETED_DOGS } from '../../../component/table/config';
import { deletedDogsSelector } from '../../../redux/selectors/selectors';
import { getDeletedDogsList } from '../../../service/delete-dog/deleted-dogs';

const DeletedDogsList = props => {
  const dispatch = useDispatch();

  const { list = [], loading } = useSelector(deletedDogsSelector);

  useEffect(() => {
    getDeletedDogsList()(dispatch);
  }, [dispatch]);

  return (
    <Col sm={12} className="p-0 mt-3">
      {loading || !list ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <ConfigurableTable
          tableHeaderColumns={DELETED_DOGS}
          itemVariant={TABLE_ITEM_TYPE.DELETED_DOGS}
          rows={list}
        />
      )}
    </Col>
  );
};

export default DeletedDogsList;
