import React from 'react';
import { Row, Col, Button, Form, Dropdown } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';

import Input from '../inputs';
import { validationRules } from './validation';

const days = [
  {
    title: 'Sunday',
    value: 0,
  },
  {
    title: 'Monday',
    value: 1,
  },
  {
    title: 'Tuesday',
    value: 2,
  },
  {
    title: 'Wednesday',
    value: 3,
  },
  {
    title: 'Thursday',
    value: 4,
  },
  {
    title: 'Friday',
    value: 5,
  },
  {
    title: 'Saturday',
    value: 6,
  },
];

const timeSlots = [
  '00-02',
  '02-04',
  '04-06',
  '06-08',
  '08-10',
  '10-12',
  '12-14',
  '14-16',
  '16-18',
  '18-20',
  '20-22',
  '22-00',
];

const AdditionalServiceForm = ({
  onSubmit,
  defaultValues,
  additionalServiceTypes,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-100"
      id="addition-service-form"
    >
      <Row className="d-flex align-items-center">
        <Col className="mt-3">
          <Form.Label className="mt-3">Additional service type</Form.Label>
          <Controller
            control={control}
            name="additionServiceType"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => {
              return (
                <Dropdown onSelect={onChange}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {additionalServiceTypes[`${value}`]}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {Object.entries(additionalServiceTypes).map(
                      ([value, label]) => (
                        <Dropdown.Item key={value} eventKey={value}>
                          {label}
                        </Dropdown.Item>
                      ),
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              );
            }}
          />
          <Row className="w-50 pl-3 mt-3">
            <Input.number
              register={register}
              label="Price"
              name="price"
              validationRules={validationRules.price}
              error={errors.price}
            />
          </Row>
          <Form.Label className="mt-3">Weekend days</Form.Label>
          <Row className="w-50">
            {days.map(item => {
              return (
                <Col key={`weekend${item.value}`}>
                  <Input.checkbox
                    register={register}
                    label={item.title}
                    name="weekend"
                    value={item.value}
                  />
                </Col>
              );
            })}
          </Row>
          <Form.Label className="mt-3">Same day</Form.Label>
          <Form.Group className="m-0">
            <Form.Check {...register('sameDay')} type="checkbox" />
          </Form.Group>
          <Form.Label className="mt-3">Additional dogs</Form.Label>
          <Form.Group className="m-0">
            <Form.Check {...register('additionalDogs')} type="checkbox" />
          </Form.Group>
          <Form.Label className="mt-3">Morning</Form.Label>
          <Row className="w-100">
            {timeSlots.map(item => {
              return (
                <Col key={`morning${item}`}>
                  <Input.checkbox
                    register={register}
                    label={item}
                    name="morning"
                    value={item}
                  />
                </Col>
              );
            })}
          </Row>
          <Form.Label className="mt-3">Evening</Form.Label>
          <Row className="w-100">
            {timeSlots.map(item => {
              return (
                <Col key={`evening${item}`}>
                  <Input.checkbox
                    register={register}
                    label={item}
                    name="evening"
                    value={item}
                  />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      <Button
        disabled={!isDirty}
        type="submit"
        variant="primary"
        className="mt-3 p-3 w-100"
      >
        Save changes
      </Button>
    </form>
  );
};

export default AdditionalServiceForm;
