import { reviewsActionTypes } from './reviews.types';

//get
export const getReviewsLoading = () => ({
  type: reviewsActionTypes.GET_REVIEWS_LOADING,
});

export const getReviewsSuccess = list => ({
  type: reviewsActionTypes.GET_REVIEWS_SUCCESS,
  payload: list,
});

export const getReviewsFail = error => ({
  type: reviewsActionTypes.GET_REVIEWS_FAIL,
  payload: error,
});

//update
export const updateReviewLoading = () => ({
  type: reviewsActionTypes.UPDATE_REVIEW_LOADING,
});
export const updateReviewSuccess = review => ({
  type: reviewsActionTypes.UPDATE_REVIEW_SUCCESS,
  payload: review,
});
export const updateReviewFailed = error => ({
  type: reviewsActionTypes.UPDATE_REVIEW_FAIL,
  payload: error,
});
