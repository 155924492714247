import { notificationsTypes } from './notifications.types';

const initialState = {
  loading: true,
  countriesList: [],
  settings: {
    loading: false,
    list: [],
    selectedItem: null,
    totalCount: 0,
    error: null,
  },
};

const pushNotifications = (state = initialState, { type, payload }) => {
  switch (type) {
    case notificationsTypes.COUNTRIES_LIST_RECEIVED_SUCCESS:
      return {
        ...state,
        loading: false,
        countriesList: payload.map(item => {
          return {
            label: item?.name_localizations?.en || item?.name,
            value: item._id,
          };
        }),
      };

    case notificationsTypes.COUNTRIES_LIST_REQUESTED:
    case notificationsTypes.SETTINGS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case notificationsTypes.GET_SETTINGS_LIST_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          list: payload.result,
          totalCount: payload.totalCount,
          loading: false,
        },
      };

    case notificationsTypes.GET_SETTINGS_BY_ID_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          selectedItem: payload,
          loading: false,
        },
      };

    case notificationsTypes.SETTINGS_FAILED:
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: false,
          error: payload?.message,
        },
      };

    case notificationsTypes.CLEAR_SETTINGS_STATE:
      return {
        ...state,
        settings: {
          ...initialState.settings,
        },
      };

    case notificationsTypes.CLEAR_SELECTED_SETTING:
      return {
        ...state,
        settings: {
          ...state.settings,
          selectedItem: null,
        },
      };

    default:
      return state;
  }
};

export default pushNotifications;
