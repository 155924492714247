import { currencyTypes } from './currency.types';

export const getCurrencyListSuccess = list => ({
  type: currencyTypes.GET_CURRENCY_LIST_SUCCESS,
  payload: list,
});

export const getCurrencyListLoading = list => ({
  type: currencyTypes.GET_COUNTRY_LIST_LOADING,
  payload: list,
});

export const getCurrencyListFail = list => ({
  type: currencyTypes.GET_COUNTRY_LIST_FAIL,
  payload: list,
});
