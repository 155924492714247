import NotificationsPage from './push-notifications';
import NotificationsConfigList from './notifications-config/list';
import NotificationsConfigEdit from './notifications-config/edit';

const PushNotifications = {
  NotificationsPage,
  NotificationsConfig: {
    List: NotificationsConfigList,
    Edit: NotificationsConfigEdit,
  },
};

export default PushNotifications;
