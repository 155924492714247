import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import GoBack from '../../../component/buttons/goBackButton';
import CustomSpinner from '../../../component/spinner';
import FitnessForm from '../../../component/fitnessForm/FitnessForm';

import { getFitnessDetailsPageConfiguration } from '../../../service/score';
import { scoreSelector } from '../../../redux/selectors/selectors';
import { clearSelectedItemState } from '../../../redux/score/score.action';
import { isItemPage } from '../../../helpers/isItemPage';

const ScoresFitnessDetails = ({ match: { params } }) => {
  const dispatch = useDispatch();
  const { loading, selectedItem, lists: { parsedWeightGroups } } = useSelector(scoreSelector);

  useEffect(() => {
    const { id } = params;

    dispatch(getFitnessDetailsPageConfiguration(id));

    return () => dispatch(clearSelectedItemState());
  }, [dispatch]);

  return (
    <Col sm={12} className="mt-3">
      <Row className="d-flex">
        <GoBack />
      </Row>
      <Row className="mt-3 d-flex align-items-start justify-content-center">
        {loading ? (
          <CustomSpinner />
        ) : (
          
          <FitnessForm
            defaultValues={selectedItem}
            itemPageId={isItemPage(params.id)}
            parsedWeightGroups={parsedWeightGroups}
          />
        )}
      </Row>
    </Col>
  );
};

export default ScoresFitnessDetails;
