import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import GoBack from '../../../component/buttons/goBackButton';
import WorkerTypeForm from '../../../component/workerTypeForm/workerTypeForm';

const WorkerTypeCreate = () => {
  const defaultValues = {
    name: '',
  };

  return (
    <Col sm={9} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      <WorkerTypeForm type="CREATE" defaultValues={defaultValues} />
    </Col>
  );
};

export default WorkerTypeCreate;
