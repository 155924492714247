import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import GoBack from '../../../component/buttons/goBackButton';
import CustomSpinner from '../../../component/spinner';
import DrPoopStatementItemForm from '../../../component/forms/dr-poop-statement-item-form/dr-poop-statement-item-form';
import { isItemPage } from '../../../helpers/isItemPage';
import { getDrPoopStatementItemByID } from '../../../service/dr-poop';
import { drPoopStatementSelector } from '../../../redux/selectors/selectors';
import {
  clearStatementItem,
  setStatementLoader,
} from '../../../redux/dr-poop/dr-poop.action';

const DrPoopStatementDetails = ({ match: { params } }) => {
  const dispatch = useDispatch();
  const statementState = useSelector(drPoopStatementSelector);

  useEffect(() => {
    const { id } = params;

    if (isItemPage(id)) {
      dispatch(getDrPoopStatementItemByID(id));
    } else {
      dispatch(setStatementLoader(false));
    }

    return () => dispatch(clearStatementItem());
  }, [dispatch, params]);

  return (
    <Col sm={12} className="mt-3">
      <Row className="d-flex">
        <GoBack />
      </Row>
      <Row className="mt-3 d-flex align-items-start justify-content-center">
        {statementState.selected.loading ? (
          <CustomSpinner />
        ) : (
          <DrPoopStatementItemForm
            defaultValues={{
              answer_localizations: {
                en:
                  statementState.selected.item.answer_localizations?.en ||
                  statementState.selected.item.answer ||
                  '',
                il: statementState.selected.item.answer_localizations?.il || '',
              },
            }}
            itemPageId={isItemPage(params.id)}
          />
        )}
      </Row>
    </Col>
  );
};

export default DrPoopStatementDetails;
