import { useDispatch } from 'react-redux';
import { Col, Button, Form, Row, Dropdown } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { withRouter } from 'react-router';
import { MultiSelect } from 'react-multi-select-component';

import { destinationType } from '../../constant';
import { createServiceType, updateServiceType } from '../../service/services';
import { useEffect, useRef, useState } from 'react';
import {
  createServicePartner,
  updateServicePartner,
} from '../../service/services/servicePartner';

import './index.css';

const ServicePartnerForm = ({
  history,
  defaultValues = {},
  type = '',
  id = null,
  additionServiceList = [],
  serviceOptions = null,
}) => {
  const dispatch = useDispatch();
  const repeatRef = useRef(null);
  const subscriptionRef = useRef(null);
  const noneRef = useRef(null);
  const [cancellationTimeChecked, setCancellationTimeChecked] = useState(
    !!defaultValues.cancellation_time || false,
  );
  const [selected, setSelected] = useState(defaultValues.additionService || []);

  const cancellationTimeOptions = new Array(23).fill('').map((i, idx) => {
    if ((idx + 1).toString().length < 2)
      return [idx + 1, '0' + (idx + 1) + ':00'];
    else return [idx + 1, idx + 1 + ':00'];
  });

  useEffect(() => {
    if (defaultValues.repeat) {
      repeatRef.current.checked = true;
    } else if (defaultValues.subscription) {
      subscriptionRef.current.checked = true;
    } else noneRef.current.checked = true;
  }, [defaultValues.repeat]);

  const { register, handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const onSubmit = async data => {
    if (data.additionService) {
      data.additionService = data.additionService.map(item => item.value);
    }

    if (data.none) {
      data.repeat = false;
      data.subscription = false;
    }
    debugger;
    if (cancellationTimeChecked && data.cancellation_time) {
      data.cancellation_time = Number(data.cancellation_time);
    } else {
      data.cancellation_time = null;
    }

    delete data.none;

    type === 'CREATE' && dispatch(createServicePartner(data, history));
    type === 'EDIT' && dispatch(updateServicePartner(id, data, history));
  };

  return (
    <Form className="border p-4 mt-4" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="service"
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Dropdown onSelect={onChange}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {serviceOptions[value]}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {Object.entries(serviceOptions).map(([value, label]) => (
                <Dropdown.Item key={value} eventKey={value}>
                  {label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      />
      <div className="w-50">
        <Form.Label className="mt-3">Addition services </Form.Label>
        <Controller
          control={control}
          name="additionService"
          render={({ field: { onChange } }) => (
            <MultiSelect
              options={additionServiceList}
              value={selected}
              onChange={data => {
                onChange(data);
                setSelected(data);
              }}
              labelledBy="Select"
              cla
              hasSelectAll={false}
            />
          )}
        />
      </div>
      <Controller
        control={control}
        name="repeat"
        render={() => (
          <Form.Check
            className="mt-3"
            ref={repeatRef}
            onChange={() => {
              setValue('repeat', true);
              setValue('subscription', false);
            }}
            name={'repeat-or-subscription'}
            type={'radio'}
            id={'repeat'}
            label={'Repeat'}
          />
        )}
      />
      <Controller
        control={control}
        name="subscription"
        render={({ field: { onChange } }) => (
          <Form.Check
            ref={subscriptionRef}
            onChange={() => {
              setValue('subscription', true);
              setValue('repeat', false);
            }}
            name={'repeat-or-subscription'}
            type={'radio'}
            id={'subscription'}
            label={'Subscription'}
          />
        )}
      />
      <Controller
        control={control}
        name="none"
        render={() => (
          <Form.Check
            ref={noneRef}
            onChange={() => {
              setValue('repeat', false);
              setValue('subscription', false);
            }}
            name={'repeat-or-subscription'}
            type={'radio'}
            id={'none'}
            label={'None'}
          />
        )}
      />
      <Form.Label className="mt-3">Price </Form.Label>
      <Form.Control
        {...register('price', {
          required: true,
          valueAsNumber: true,
          min: 1,
        })}
        className="p-3 text-left d-block m-0"
        type="number"
      />

      <Form.Check
        type="checkbox"
        checked={cancellationTimeChecked}
        label="Cancellation time"
        className="mt-4 mb-3"
        onChange={() => {
          setCancellationTimeChecked(p => !p);
        }}
      />

      {cancellationTimeChecked && (
        <Controller
          control={control}
          name="cancellation_time"
          render={({ field: { onChange, value } }) => (
            <Dropdown onSelect={onChange}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {cancellationTimeOptions[value - 1]?.[1] ||
                  cancellationTimeOptions[0][1]}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {cancellationTimeOptions.map(([value, label]) => {
                  return (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          )}
        />
      )}
      <Button className="mt-4" variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

export default withRouter(ServicePartnerForm);
