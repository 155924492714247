export const rewardsTypes = {
  GET_REWARDS_LIST_SUCCESS: 'rewards/GET_REWARDS_LIST_SUCCESS',
  GET_REWARDS_LIST_FAIL: 'rewards/GET_REWARDS_LIST_FAIL',

  DELETE_REWARDS_LIST_ITEM: 'rewards/DELETE_REWARDS_LIST_ITEM',

  REWARD_ITEM_REQUESTED: 'rewards/REWARD_ITEM_REQUESTED',
  GET_REWARD_ITEM_SUCCESS: 'rewards/GET_REWARD_ITEM_SUCCESS',
  GET_REWARD_ITEM_FAIL: 'rewards/GET_REWARD_ITEM_FAIL',

  CLEAR_SELECTED_ITEM: 'rewards/CLEAR_SELECTED_ITEM',
  CLEAR_LIST_ON_ACTIONS_PAGE: 'rewards/CLEAR_LIST_ON_ACTIONS_PAGE',

  SET_REWARDS_LOADER: 'rewards/SET_REWARDS_LOADER',

  GET_COINS_MODIFICATORS_LOADING: 'rewards/GET_COINS_MODIFICATORS_LOADING',
  GET_COINS_MODIFICATORS_SUCCESS: 'rewards/GET_COINS_MODIFICATORS_SUCCESS',
  GET_COINS_MODIFICATORS_FAILED: 'rewards/GET_COINS_MODIFICATORS_FAILED',

  GET_COINS_MODIFICATOR_BY_ID_LOADING:
    'rewards/GET_COINS_MODIFICATOR_BY_ID_LOADING',
  GET_COINS_MODIFICATOR_BY_ID_SUCCESS:
    'rewards/GET_COINS_MODIFICATOR_BY_ID_SUCCESS',
  GET_COINS_MODIFICATOR_BY_ID_FAILED:
    'rewards/GET_COINS_MODIFICATOR_BY_ID_FAILED',

  CREATE_COINS_MODIFICATORS_LOADING:
    'rewards/CREATE_COINS_MODIFICATORS_LOADING',
  CREATE_COINS_MODIFICATORS_SUCCESS:
    'rewards/CREATE_COINS_MODIFICATORS_SUCCESS',
  CREATE_COINS_MODIFICATORS_FAILED: 'rewards/CREATE_COINS_MODIFICATORS_FAILED',

  UPDATE_COINS_MODIFICATORS_LOADING:
    'rewards/UPDATE_COINS_MODIFICATORS_LOADING',
  UPDATE_COINS_MODIFICATORS_SUCCESS:
    'rewards/UPDATE_COINS_MODIFICATORS_SUCCESS',
  UPDATE_COINS_MODIFICATORS_FAILED: 'rewards/UPDATE_COINS_MODIFICATORS_FAILED',

  CLEAR_COINS_MODIFICATORS_STATE: 'rewards/CLEAR_COINS_MODIFICATORS_STATE',
  CLEAR_SELECTED_COINS_MODIFICATOR: 'rewards/CLEAR_SELECTED_COINS_MODIFICATOR',
};
