import { breedsActions as actions } from '../../redux/breeds/breeds.actions';
import fetchWithAuth from '../';
import MESSAGES_FOR_USER from '../../constants/messages/en';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';

export const getBreedsList = () => async dispatch => {
  dispatch(actions.operationLoading());

  const result = await fetchWithAuth({
    path: '/dogs/breed',
    method: 'GET',
  });

  if (result.ok) {
    const breeds = await result.json();
    dispatch(actions.getBreedsList(breeds.result));
  } else {
    const { statusText } = result;

    dispatch(actions.operationFailed(statusText));

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.BREEDS.GET_FAILED.TITLE,
        content: MESSAGES_FOR_USER.BREEDS.GET_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
  }
};

export const getBreedById = id => async dispatch => {
  dispatch(actions.operationLoading());

  const result = await fetchWithAuth({
    path: '/dogs/breed/' + id,
    method: 'GET',
  });

  if (result.ok) {
    dispatch(actions.getBreedById(await result.json()));
  } else {
    const { statusText } = result;

    dispatch(actions.operationFailed(statusText));

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.BREEDS.GET_FAILED.TITLE,
        content: MESSAGES_FOR_USER.BREEDS.GET_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
  }
};

export const createBreed =
  ({ body }) =>
  async dispatch => {
    const result = await fetchWithAuth({
      path: '/dogs/breed',
      method: 'POST',
      body: JSON.stringify(body),
    });

    if (result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.BREEDS.CREATE_SUCCESS.TITLE,
          content: MESSAGES_FOR_USER.BREEDS.CREATE_SUCCESS.BODY,
          type: TOAST_MESSAGE_TYPES.SUCCESS,
        }),
      );
    } else {
      const { statusText } = result;

      dispatch(actions.operationFailed(statusText));

      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.BREEDS.CREATE_FAILED.TITLE,
          content: MESSAGES_FOR_USER.BREEDS.CREATE_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );
    }
  };

export const updateBreed =
  ({ id, body }) =>
  async dispatch => {
    const result = await fetchWithAuth({
      path: '/dogs/breed/' + id,
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.BREEDS.UPDATE_SUCCESS.TITLE,
          content: MESSAGES_FOR_USER.BREEDS.UPDATE_SUCCESS.BODY,
          type: TOAST_MESSAGE_TYPES.SUCCESS,
        }),
      );
    } else {
      const { statusText } = result;

      dispatch(actions.operationFailed(statusText));

      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.BREEDS.UPDATE_FAILED.TITLE,
          content: MESSAGES_FOR_USER.BREEDS.UPDATE_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );
    }
  };

export const deleteBreed = id => async dispatch => {
  const result = await fetchWithAuth({
    path: '/dogs/breed/' + id,
    method: 'DELETE',
  });

  if (result.ok) {
    dispatch(actions.deleteBreed(id));

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.BREEDS.DELETE_SUCCESS.TITLE,
        content: MESSAGES_FOR_USER.BREEDS.DELETE_SUCCESS.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );
  } else {
    const { statusText } = result;

    dispatch(actions.operationFailed(statusText));

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.BREEDS.DELETE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.BREEDS.DELETE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
  }
};

const breedsOperations = {
  getBreedsList,
  getBreedById,
  createBreed,
  updateBreed,
  deleteBreed,
};

export default breedsOperations;
