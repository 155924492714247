import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { changeConfirmStatus } from '../../../redux/confirm/confirm.action';
import { confirmationKeys } from '../../../constant';

const DrPoopItem = ({ index, row }) => {
  const dispatch = useDispatch();
  return (
    <tr>
      <td className="text-center align-middle font-weight-normal">
        {index + 1}
      </td>
      <td className="text-center align-middle">{row._id}</td>
      <td className="text-center align-middle">{row.userId}</td>
      <td className="text-center align-middle">{row.dog_owner_id}</td>
      <td className="text-center align-middle">
        {row.dogId ? row.dogId._id : '-'}
      </td>
      <td className="text-center align-middle">{row.senderType || '-'}</td>
      <td className="text-center align-middle">
        {row.ai_grade ? row.ai_grade.toFixed(2) : 'NULL'}
      </td>
      <td className="text-center align-middle">{row.ai_status || '-'}</td>
      <td className="text-center align-middle">{row.dogiz_grade || '-'}</td>
      <td className="text-center align-middle">{row.status}</td>
      <td className="align-middle font-weight-normal">
        {row.url ? (
          <img src={row.url} className="img-thumbnail w-vw-10" alt="" />
        ) : (
          '-'
        )}
      </td>
      <td className="text-center align-middle font-weight-normal">
        {new Date(row.createDate).toLocaleString()}
      </td>
      <td className="align-middle text-center">
        <Link to={`/dr-poop/user-data/${row._id}`}>
          <Button size={'sm'} variant="success" className="m-2">
            <i className="fa fa-pencil"></i>
          </Button>
        </Link>

        <Button
          size={'sm'}
          variant="danger"
          className="m-2"
          onClick={() =>
            dispatch(
              changeConfirmStatus({
                isVisible: true,
                content: row._id,
                customLabel: 'Submission id',
                type: confirmationKeys.questionDrPoopUserDataTableDeleteItem,
                actionParams: [row._id],
              }),
            )
          }
        >
          <i className="fa fa-trash-o"></i>
        </Button>
      </td>
    </tr>
  );
};

export default DrPoopItem;
