import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { validationRules } from './validation';
import countriesOperations from '../../service/countries/countries';

const defaultFormValues = {
  name_localizations: {
    en: '',
    il: '',
  },
  countryCode: '',
  flag: '',
  phoneCode: '',
};

const CountryForm = ({ defaultValues = defaultFormValues, id }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = data => {
    const body = {
      ...data,
      name: data.name_localizations.en,
    };

    if (id) {
      dispatch(countriesOperations.updateCountry({ id, body })).then(() => {
        history.goBack();
      });

      return;
    }

    dispatch(countriesOperations.createCountry({ body })).then(() => {
      history.goBack();
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group>
        <Form.Label className="d-flex justify-content-between align-items-center mt-2">
          Country name (EN)
          {errors.name_localizations?.en && (
            <p style={{ fontSize: '12px' }} className="text-danger m-0">
              {errors.name_localizations?.en?.message}
            </p>
          )}
        </Form.Label>
        <Form.Control
          {...register('name_localizations.en', {
            ...validationRules.name_localizations.en,
          })}
          placeholder="Country name..."
        />
        <Form.Label className="d-flex justify-content-between align-items-center mt-2">
          Country name (IL)
          {errors.name_localizations?.il && (
            <p style={{ fontSize: '12px' }} className="text-danger m-0">
              {errors.name_localizations?.il?.message}
            </p>
          )}
        </Form.Label>
        <Form.Control
          {...register('name_localizations.il', {
            ...validationRules.name_localizations.il,
          })}
          placeholder="Country name..."
        />
      </Form.Group>

      <Form.Group>
        <Form.Label className="d-flex justify-content-between align-items-center mt-2">
          Country code (emoji flag)
          {errors.countryCode && (
            <p style={{ fontSize: '12px' }} className="text-danger m-0">
              {errors.countryCode?.message}
            </p>
          )}
        </Form.Label>
        <Form.Control
          {...register('countryCode', { ...validationRules.countryCode })}
          placeholder="🇺🇦"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label className="d-flex justify-content-between align-items-center mt-2">
          Flag link
          {errors.flag && (
            <p style={{ fontSize: '12px' }} className="text-danger m-0">
              {errors.flag?.message}
            </p>
          )}
        </Form.Label>
        <Form.Control
          {...register('flag', { ...validationRules.flag })}
          placeholder="https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label className="d-flex justify-content-between align-items-center mt-2">
          Phone code
          {errors.phoneCode && (
            <p style={{ fontSize: '12px' }} className="text-danger m-0">
              {errors.phoneCode?.message}
            </p>
          )}
        </Form.Label>
        <Form.Control
          {...register('phoneCode', { ...validationRules.phoneCode })}
          placeholder="+380"
        />
      </Form.Group>

      <Button type="submit">{id ? 'Save changes' : 'Create country'}</Button>
    </Form>
  );
};

export default CountryForm;
