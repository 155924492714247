import fetchWithAuth from '../index';
import setLoading from '../../redux/default';

import { URL_SUPER_ADMIN } from '../../constant';

import MESSAGES_FOR_USER from '../../constants/messages/en';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';

import { destinationUrls } from '../../component/menu/constants';
import {
  getAdditionalServiceListSuccess,
  getAdditionalServiceListLoading,
  getAdditionalServiceByIdLoading,
  getAdditionalServiceByIdSuccess,
  deleteAdditionalServiceSuccess,
} from '../../redux/additional-service/additional-service.action';

export const getAdditionalServiceList = () => async dispatch => {
  dispatch(getAdditionalServiceListLoading());

  let result = await fetchWithAuth({
    path: `/addition-services`,
    method: 'GET',
  });

  dispatch(getAdditionalServiceListSuccess(await result.json()));
  return;
};

export const createAdditionalService = (data, history) => async dispatch => {
  const result = await fetchWithAuth({
    path: `/addition-services`,
    method: 'POST',
    body: JSON.stringify(data),
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.ADDITIONAL_SERVICE.CREATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.ADDITIONAL_SERVICE.CREATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  history.push(destinationUrls.ADDITIONAL_SERVICE);
  return;
};

export const getAdditionalServiceById = id => async dispatch => {
  dispatch(getAdditionalServiceByIdLoading());
  let result = await fetchWithAuth({
    path: `/addition-services/${id}`,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.ADDITIONAL_SERVICE.UPDATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.ADDITIONƒA_FEE_TEMPLATE.UPDATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  dispatch(getAdditionalServiceByIdSuccess(await result.json()));
};

export const deleteAdditionalServiceById = id => async dispatch => {
  let result = await fetchWithAuth({
    path: `/addition-services/${id}`,
    method: 'DELETE',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.ADDITIONAL_SERVICE.DELETE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.ADDITIONƒA_FEE_TEMPLATE.DELETE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  dispatch(deleteAdditionalServiceSuccess(id));
};

export const updateAdditionalService =
  (id, data, history) => async dispatch => {
    let result = await fetchWithAuth({
      path: `/addition-services/${id}`,
      method: 'PUT',
      body: JSON.stringify(data),
    });

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.ADDITIONAL_SERVICE.UPDATE_FAILED.TITLE,
          content: MESSAGES_FOR_USER.ADDITIONAL_SERVICE.UPDATE_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );
      return;
    }

    history.push(destinationUrls.ADDITIONAL_SERVICE);
    return;
  };
