import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const StakedBarChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey={'BAD'} stackId="a" fill={'#FF2F54'} />
        <Bar dataKey={'GOOD'} stackId="a" fill={'#01C297'} />
        <Bar dataKey={'MEDIUM'} stackId="a" fill={'#FF764A'} />
        <Bar dataKey={'REJECTED'} stackId="a" fill={'#2e282a'} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StakedBarChart;
