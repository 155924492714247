import { countryTypes } from './country.types';

const initialSate = {
  loading: false,
  error: false,
  list: null,
  search: null,
  item: {
    _id: null,
    name: null,
  },
};

const country = (state = initialSate, { type, payload }) => {
  switch (type) {
    case countryTypes.GET_COUNTRY_LIST_BY_SEARCH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case countryTypes.GET_COUNTRY_LIST_BY_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.countries,
        search: payload.search,
      };
    case countryTypes.SET_ITEM:
      return {
        ...state,
        item: payload,
      };

    case countryTypes.CLEAR_ITEM:
      return {
        ...state,
        search: null,
        list: null,
        item: {
          _id: null,
          name: null,
        },
      };

    case countryTypes.SET_SEARCH:
      return {
        ...state,
        search: payload,
      };

    case countryTypes.CLEAR_SEARCH:
      return {
        ...state,
        search: false,
      };

    case countryTypes.GET_COUNTRY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default country;
