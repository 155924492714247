import fetchWithAuth from '../index';
import setLoading from '../../redux/default';
import {
  getSectionListSuccess,
  getSectionListFail,
  getSectionDetailsSuccess,
  getSectionDetailsFail,
  editSectionSuccess,
  editSectionFail,
  deleteSectionSuccess,
  deleteSectionFail,
  sendModifiedQuestionsFail,
  sendModifiedQuestionsSuccess,
  getQuestionNotSectionSucces,
  getQuestionNotSectionFail,
  deleteQuestionFromSection,
} from '../../redux/quiz/section/section.action';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';

import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';
import MESSAGES_FOR_USER from '../../constants/messages/en';

import { URL_SUPER_ADMIN } from '../../constant';

export const createSection = (data, history) => async dispatch => {
  await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/quiz/section`,
    method: 'POST',
    body: JSON.stringify(data),
  });

  history.push('/quiz/section/');
  return;
};

export const getSection = () => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/quiz/section`,
    method: 'GET',
  });

  dispatch(setLoading('GET_SECTION_LIST'));
  if (!result.ok) {
    dispatch(getSectionListFail(await result.json()));
    return;
  }

  dispatch(getSectionListSuccess(await result.json()));
  return;
};

export const getSectionDetails = id => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/quiz/section/${id}`,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(getSectionDetailsFail(await result.json()));
    return;
  }

  dispatch(getSectionDetailsSuccess(await result.json()));
  return;
};

export const editSectionById = (id, data) => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/quiz/section/${id}`,
    method: 'PUT',
    body: JSON.stringify(data),
  });

  if (!result.ok) {
    dispatch(editSectionFail(await result.json()));
    return;
  }
  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.QUIZ.SECTION.UPDATED_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.QUIZ.SECTION.UPDATED_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );
  dispatch(editSectionSuccess(await result.json()));
  return;
};

export const deleteSectionById = id => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/quiz/section/${id}`,
    method: 'DELETE',
  });

  setLoading('DELETE_SECTION');

  if (!result.ok) {
    dispatch(deleteSectionFail(await result.json()));
    return;
  }

  dispatch(deleteSectionSuccess(id));
  return;
};

export const getQuestionNotInSection = () => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/quiz/question-not-section`,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(getQuestionNotSectionFail(await result.json()));
    return;
  }

  dispatch(getQuestionNotSectionSucces(await result.json()));
  return;
};

export const sendModifiedQuestions = (id, data) => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/quiz/section/${id}`,
    method: 'PUT',
    body: JSON.stringify(data),
  });

  if (!result.ok) {
    dispatch(sendModifiedQuestionsFail(await result.json()));
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.QUIZ.SECTION.UPDATED_FAILED.TITLE,
        content: MESSAGES_FOR_USER.QUIZ.SECTION.UPDATED_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );

    return;
  }

  dispatch(sendModifiedQuestionsSuccess(id));
  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.QUIZ.SECTION.UPDATED_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.QUIZ.SECTION.UPDATED_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );

  const response = await result.json();
  return response;
};

export const deleteSectionQuestionById =
  (questionId, sectionId, questions) => async dispatch => {
    const updatedQuestions = [...questions].filter(id => id !== questionId);
    const updatedData = { questions: updatedQuestions };

    let result = await fetchWithAuth({
      path: `${URL_SUPER_ADMIN}/quiz/section/${sectionId}`,
      method: 'PUT',
      body: JSON.stringify(updatedData),
    });

    if (!result.ok) {
      dispatch(editSectionFail(await result.json()));
      return;
    }
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.QUIZ.SECTION.UPDATED_SUCCESS.TITLE,
        content: MESSAGES_FOR_USER.QUIZ.SECTION.UPDATED_SUCCESS.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );
    dispatch(deleteQuestionFromSection(questionId));
    return;
  };
