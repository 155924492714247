import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import Input from '../inputs';

import { validationRules } from './validation';
import { createRewardItem, updateRewardItem } from '../../service/rewards';
import { getPhaseForCurrentRoute } from '../../helpers/rewards';

import './index.css';

const RewardsForm = ({ defaultValues = {}, itemPageId }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const onSubmit = values => {
    const body = {
      ...values,
      title: values.title_localizations.en,
      desc: values.desc_localizations.en,
    };

    if (!values.phase) {
      values.phase = getPhaseForCurrentRoute();
    }
    if (itemPageId) {
      updateRewardItem(body, itemPageId)(dispatch).then(() => history.goBack());
    } else {
      createRewardItem(body)(dispatch).then(() => history.goBack());
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-100" id="rewards-form">
      <Row className="pl-3 pr-3 pt-3">
        <Input.text
          register={register}
          label="Title (en)"
          name="title_localizations.en"
          validationRules={validationRules.title_localizations.en}
          error={errors.title_localizations?.en}
        />
      </Row>
      <Row className="pl-3 pr-3 pt-3">
        <Input.text
          register={register}
          label="Title (il)"
          name="title_localizations.il"
          validationRules={validationRules.title_localizations.il}
          error={errors.title_localizations?.il}
        />
      </Row>

      <Row className="pl-3 pr-3 pt-3">
        <Input.text
          register={register}
          label="Subtitle (en)"
          name="desc_localizations.en"
          validationRules={validationRules.desc_localizations.en}
          error={errors.desc_localizations?.en}
        />
      </Row>
      <Row className="p-3">
        <Input.text
          register={register}
          label="Subtitle (il)"
          name="desc_localizations.il"
          validationRules={validationRules.desc_localizations.il}
          error={errors.desc_localizations?.il}
        />
      </Row>

      <Row className="d-flex">
        <Col>
          <Input.number
            register={register}
            label="Condition"
            name="condition"
            validationRules={validationRules.condition}
            error={errors.condition}
          />
        </Col>
        <Col>
          <Input.number
            register={register}
            label="Reward"
            name="reward"
            validationRules={validationRules.reward}
            error={errors.reward}
          />
        </Col>
      </Row>
      <Row className="mt-5 ml-0 mr-0">
        <Input.checkbox
          register={register}
          label="Push notification"
          name="pushNotification"
        />
      </Row>
      <Row className="mt-5 ml-0 mr-0">
        <Button className="w-100 p-3" type="submit">
          Save
        </Button>
      </Row>
    </form>
  );
};

export default RewardsForm;
