import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import GoBack from '../../component/buttons/goBackButton';
import CustomSpinner from '../../component/spinner';
import CountryForm from '../../component/country-form/country-form';
import countriesOperations from '../../service/countries/countries';
import { countriesSelector } from '../../redux/selectors/selectors';
import { countriesActions } from '../../redux/countries/countries.actions';

const CountriesEdit = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { selectedItem: country, loading } = useSelector(countriesSelector);

  useEffect(() => {
    if (id) {
      dispatch(countriesOperations.getCountryById(id));
    }

    return () => {
      dispatch(countriesActions.clearSelectedCountry());
    };
  }, [dispatch, id]);

  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      {loading || !country ? (
        <CustomSpinner />
      ) : (
        <CountryForm
          id={id}
          defaultValues={{
            name_localizations: {
              en: country?.name_localizations?.en || country?.name || '',
              il: country?.name_localizations?.il || '',
            },
            countryCode: country?.countryCode || '',
            flag: country?.flag || '',
            phoneCode: country?.phoneCode || '',
          }}
        />
      )}
    </Col>
  );
};
export default CountriesEdit;
