import { additionalFeeTemplateTypes } from './additional-fee-template.types';

const initialSate = {
  loading: false,
  error: false,
  list: null,
  item: false,
};

const additionalFeeTemplate = (state = initialSate, { type, payload }) => {
  switch (type) {
    case additionalFeeTemplateTypes.GET_ADDITIONAL_FEE_TEMPLATE_LIST_LOADING:
    case additionalFeeTemplateTypes.GET_ADDITIONAL_FEE_TEMPLATE_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
      };
    case additionalFeeTemplateTypes.GET_ADDITIONAL_FEE_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload,
      };
    case additionalFeeTemplateTypes.GET_ADDITIONAL_FEE_TEMPLATE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };
    case additionalFeeTemplateTypes.DELETE_ADDITIONAL_FEE_TEMPLATE_SUCCESS:
      const newList = state.list.filter(item => item._id !== payload);
      return {
        ...state,
        list: newList,
        loading: false,
      };
    case additionalFeeTemplateTypes.CLEAR_ITEM:
      return {
        ...state,
        item: false,
      };

    default:
      return state;
  }
};

export default additionalFeeTemplate;
