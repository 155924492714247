import BREEDS_ACTIONS_TYPES from './breeds.types';

const initialState = {
  list: [],
  selectedItem: null,
  loading: false,
  error: null,
};

const breedsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case BREEDS_ACTIONS_TYPES.OPERATION_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case BREEDS_ACTIONS_TYPES.OPERATION_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case BREEDS_ACTIONS_TYPES.CLEAR_SELECTED:
      return {
        ...state,
        selectedItem: null,
      };

    case BREEDS_ACTIONS_TYPES.GET_LIST:
      return {
        ...state,
        loading: false,
        list: payload,
        error: null,
      };

    case BREEDS_ACTIONS_TYPES.GET_BY_ID:
      return {
        ...state,
        loading: false,
        selectedItem: payload,
        error: null,
      };

    case BREEDS_ACTIONS_TYPES.DELETE:
      return {
        ...state,
        list: state.list.filter(({ _id }) => _id !== payload),
      };

    default:
      return state;
  }
};

export default breedsReducer;
