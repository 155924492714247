import fetchWithAuth from '../index';
import {
  getRewardsListSuccess,
  getRewardsListFailed,
  deleteItemFromRewardsList,
  rewardItemRequested,
  getRewardItemFailed,
  getRewardItemSuccess,
  getCoinsModificatorsLoading,
  getCoinsModificatorsFailed,
  getCoinsModificatorsSucess,
  getCoinsModificatorByIdLoading,
  getCoinsModificatorByIdFailed,
  getCoinsModificatorByIdSucess,
  createCoinsModificatorsLoading,
  createCoinsModificatorsFailed,
  createCoinsModificatorsSucess,
} from '../../redux/rewards/rewards.action';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';

import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';
import MESSAGES_FOR_USER from '../../constants/messages/en';
import { PHASES } from '../../constants/rewards';

export const getRewardsList = phase => async dispatch => {
  let result = await fetchWithAuth({
    path: `/reward/phases/phase/?phase=${phase}`,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.REWARDS.GET_LIST_FAILED.TITLE,
        content: MESSAGES_FOR_USER.REWARDS.GET_LIST_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(getRewardsListFailed());
    return;
  }

  dispatch(getRewardsListSuccess(await result.json()));
};

export const getRewardsActionList = () => async dispatch => {
  let result = await fetchWithAuth({
    path: '/super-admin/reward/action',
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.REWARDS.GET_LIST_FAILED.TITLE,
        content: MESSAGES_FOR_USER.REWARDS.GET_LIST_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(getRewardsListFailed());
    return;
  }

  dispatch(getRewardsListSuccess(await result.json()));
};

export const deleteRewardItem = itemID => async dispatch => {
  let result = await fetchWithAuth({
    path: `/reward/phases/${itemID}`,
    method: 'DELETE',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.REWARDS.DELETING_FAILED.TITLE,
        content: MESSAGES_FOR_USER.REWARDS.DELETING_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.REWARDS,
      }),
    );
    return;
  }

  dispatch(deleteItemFromRewardsList(itemID));
  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.FILE_UPLOADER.DELETING_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.FILE_UPLOADER.DELETING_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );
};

export const getRewardItemByID = itemID => async dispatch => {
  dispatch(rewardItemRequested());
  const destinationUrl = window.location.pathname.includes(
    PHASES.ACTION.toLowerCase(),
  )
    ? `/super-admin/reward/action/${itemID}`
    : `/reward/phases/${itemID}`;

  let result = await fetchWithAuth({
    path: destinationUrl,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.REWARDS.GET_ITEM_FAILED.TITLE,
        content: MESSAGES_FOR_USER.REWARDS.GET_ITEM_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(getRewardItemFailed());

    return;
  }

  dispatch(getRewardItemSuccess(await result.json()));
};

export const getRewardActionItemByID = itemID => async dispatch => {
  dispatch(rewardItemRequested());

  let result = await fetchWithAuth({
    path: `/super-admin/reward/action/${itemID}`,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.REWARDS.GET_ITEM_FAILED.TITLE,
        content: MESSAGES_FOR_USER.REWARDS.GET_ITEM_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(getRewardItemFailed());

    return;
  }

  dispatch(getRewardItemSuccess(await result.json()));
};

export const createRewardItem = phaseObj => async dispatch => {
  return new Promise(async resolve => {
    let result = await fetchWithAuth({
      path: `/reward/phases`,
      method: 'POST',
      body: JSON.stringify(phaseObj),
    });

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.REWARDS.CREATING_FAILED.TITLE,
          content: MESSAGES_FOR_USER.REWARDS.CREATING_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );

      return;
    }

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.REWARDS.CREATING_SUCCESS.TITLE,
        content: MESSAGES_FOR_USER.REWARDS.CREATING_SUCCESS.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );
    resolve();
  });
};

export const updateRewardItem = (phaseObj, itemId) => async dispatch => {
  return new Promise(async resolve => {
    let result = await fetchWithAuth({
      path: `/reward/phases/${itemId}`,
      method: 'PUT',
      body: JSON.stringify(phaseObj),
    });

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.REWARDS.UPDATE_ITEM_FAILED.TITLE,
          content: MESSAGES_FOR_USER.REWARDS.UPDATE_ITEM_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );

      return;
    }

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.REWARDS.UPDATE_ITEM_SUCCESS.TITLE,
        content: MESSAGES_FOR_USER.REWARDS.UPDATE_ITEM_SUCCESS.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );
    resolve();
  });
};

export const updateRewardActionItem = (actionObj, itemId) => async dispatch => {
  return new Promise(async resolve => {
    let result = await fetchWithAuth({
      path: `/super-admin/reward/action/${itemId}`,
      method: 'PUT',
      body: JSON.stringify(actionObj),
    });

    if (!result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.REWARDS.UPDATE_ITEM_FAILED.TITLE,
          content: MESSAGES_FOR_USER.REWARDS.UPDATE_ITEM_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );

      return;
    }

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.REWARDS.UPDATE_ITEM_SUCCESS.TITLE,
        content: MESSAGES_FOR_USER.REWARDS.UPDATE_ITEM_SUCCESS.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );
    resolve();
  });
};

export const getCoinsModificatorsList =
  (pageNumber = 0, pageSize = 50) =>
  async dispatch => {
    const url = `/coins-modificator/?pageNumber=${pageNumber}&pageSize=${pageSize}`;

    dispatch(getCoinsModificatorsLoading());

    const response = await fetchWithAuth({
      path: url,
      method: 'GET',
    });

    if (!response.ok) {
      dispatch(
        showToastMessage({
          title:
            MESSAGES_FOR_USER.REWARDS.COINS_MODIFICATORS.GET_LIST_FAILED.TITLE,
          content:
            MESSAGES_FOR_USER.REWARDS.COINS_MODIFICATORS.GET_LIST_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );

      dispatch(getCoinsModificatorsFailed());

      return;
    }

    dispatch(getCoinsModificatorsSucess(await response.json()));
  };

export const getCoinsModificatorById = id => async dispatch => {
  const url = `/coins-modificator/${id}`;

  dispatch(getCoinsModificatorByIdLoading());

  const response = await fetchWithAuth({
    path: url,
    method: 'GET',
  });

  if (!response.ok) {
    dispatch(
      showToastMessage({
        title:
          MESSAGES_FOR_USER.REWARDS.COINS_MODIFICATORS.GET_ITEM_FAILED.TITLE,
        content:
          MESSAGES_FOR_USER.REWARDS.COINS_MODIFICATORS.GET_ITEM_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(getCoinsModificatorByIdFailed());

    return;
  }

  dispatch(getCoinsModificatorByIdSucess(await response.json()));
};

export const createCoinsModificator = body => async dispatch => {
  const url = `/coins-modificator`;

  dispatch(createCoinsModificatorsLoading());

  const response = await fetchWithAuth({
    path: url,
    method: 'POST',
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.REWARDS.COINS_MODIFICATORS.CREATE_FAILED.TITLE,
        content:
          MESSAGES_FOR_USER.REWARDS.COINS_MODIFICATORS.CREATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );

    dispatch(createCoinsModificatorsFailed());

    return;
  }
  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.REWARDS.COINS_MODIFICATORS.CREATE_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.REWARDS.COINS_MODIFICATORS.CREATE_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );

  dispatch(createCoinsModificatorsSucess());
};

export const updateCoinsModificator = (id, body) => async dispatch => {
  const url = `/coins-modificator/${id}`;

  dispatch(createCoinsModificatorsLoading());

  const response = await fetchWithAuth({
    path: url,
    method: 'PUT',
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.REWARDS.COINS_MODIFICATORS.UPDATE_FAILED.TITLE,
        content:
          MESSAGES_FOR_USER.REWARDS.COINS_MODIFICATORS.UPDATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );

    dispatch(createCoinsModificatorsFailed());

    return;
  }
  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.REWARDS.COINS_MODIFICATORS.UPDATE_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.REWARDS.COINS_MODIFICATORS.UPDATE_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );

  dispatch(createCoinsModificatorsSucess());
};
