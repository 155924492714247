export const drPoopTypes = {
  GET_DR_POOP_USER_DATA_LIST_LOADING:
    'drPoop/GET_DR_POOP_USER_DATA_LIST_LOADING',

  GET_DR_POOP_USER_DATA_LIST_SUCCESS:
    'drPoop/GET_DR_POOP_USER_DATA_LIST_SUCCESS',
  GET_DR_POOP_USER_DATA_LIST_FAILED: 'drPoop/GET_DR_POOP_USER_DATA_LIST_FAILED',

  DELETE_ITEM_FROM_DR_POOP_USER_DATA_LIST:
    'drPoop/DELETE_ITEM_FROM_DR_POOP_USER_DATA_LIST',

  DR_POOP_USER_DATA_ITEM_REQUESTED: 'drPoop/DR_POOP_USER_DATA_ITEM_REQUESTED',
  GET_DR_POOP_USER_DATA_ITEM_SUCCESS:
    'drPoop/GET_DR_POOP_USER_DATA_ITEM_SUCCESS',
  GET_DR_POOP_USER_DATA_ITEM_FAILED: 'drPoop/GET_DR_POOP_USER_DATA_ITEM_FAILED',

  GET_DR_POOP_STATEMENTS_LIST_SUCCESS:
    'drPoop/GET_DR_POOP_STATEMENTS_LIST_SUCCESS',
  GET_DR_POOP_STATEMENTS_LIST_FAILED:
    'drPoop/GET_DR_POOP_STATEMENTS_LIST_FAILED',
  DELETE_ITEM_FROM_DR_POOP_STATEMENT_LIST:
    'drPoop/DELETE_ITEM_FROM_DR_POOP_STATEMENT_LIST',

  DR_POOP_STATEMENT_ITEM_REQUESTED: 'drPoop/DR_POOP_STATEMENT_ITEM_REQUESTED',
  GET_DR_POOP_STATEMENT_ITEM_SUCCESS:
    'drPoop/GET_DR_POOP_STATEMENT_ITEM_SUCCESS',
  GET_DR_POOP_STATEMENT_ITEM_FAILED: 'drPoop/GET_DR_POOP_STATEMENT_ITEM_FAILED',
  SET_STATEMENT_LOADER: 'drPoop/SET_STATEMENT_LOADER',
  CLEAR_STATEMENT_ITEM: 'drPoop/CLEAR_STATEMENT_ITEM',

  GET_DR_POOP_COUNT_GRADE_DISTRIBUTION_LOADING:
    'drPoop/GET_DR_POOP_COUNT_GRADE_DISTRIBUTION_LOADING',
  GET_DR_POOP_COUNT_GRADE_DISTRIBUTION_SUCCESS:
    'drPoop/GET_DR_POOP_COUNT_GRADE_DISTRIBUTION_SUCCESS',
  GET_DR_POOP_COUNT_GRADE_DISTRIBUTION_FAILED:
    'drPoop/GET_DR_POOP_COUNT_GRADE_DISTRIBUTION_FAILED',

  GET_DR_POOP_STATS_LOADING: 'drPoop/GET_DR_POOP_STATS_LOADING',
  GET_DR_POOP_STATS_SUCCESS: 'drPoop/GET_DR_POOP_STATS_SUCCESS',
  GET_DR_POOP_STATS_FAILED: 'drPoop/GET_DR_POOP_STATS_FAILED',
};
