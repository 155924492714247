import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import Input from '../inputs';
import { validationRules } from './validation';

const AdditionalFeeTemplateForm = ({ onSubmit, defaultValues }) => {
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-100"
      id="addition-fee-template-form"
    >
      <Row className="d-flex align-items-center">
        <Col className="mt-3">
          <Row className="p-3">
            <Input.text
              register={register}
              label="Title(en)"
              name="title_localizations.en"
              validationRules={validationRules.title}
              error={errors.title}
            />
          </Row>
        </Col>
        <Col className="mt-3">
          <Row className="p-3">
            <Input.text
              register={register}
              label="Title(il)"
              name="title_localizations.il"
              validationRules={validationRules.title}
              error={errors.title}
            />
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="mt-3">
          <Row className="p-3">
            <Input.text
              register={register}
              label="Description(en)"
              name="desc_localizations.en"
            />
          </Row>
        </Col>
        <Col className="mt-3">
          <Row className="p-3">
            <Input.text
              register={register}
              label="Description(il)"
              name="desc_localizations.il"
            />
          </Row>
        </Col>
      </Row>
      <Button
        disabled={!isDirty}
        type="submit"
        variant="primary"
        className="mt-3 p-3 w-100"
      >
        Save changes
      </Button>
    </form>
  );
};

export default AdditionalFeeTemplateForm;
