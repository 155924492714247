export const additionalServiceTypes = {
  GET_ADDITIONAL_SERVICE_LIST_SUCCESS:
    'service_partner/GET_ADDITIONAL_SERVICE_LIST_SUCCESS',
  GET_ADDITIONAL_SERVICE_LIST_LOADING:
    'service_partner/GET_ADDITIONAL_SERVICE_LIST_LOADING',

  GET_ADDITIONAL_SERVICE_BY_ID_SUCCESS:
    'service_partner/GET_ADDITIONAL_SERVICE_BY_ID_SUCCESS',
  GET_ADDITIONAL_SERVICE_BY_ID_LOADING:
    'service_partner/GET_ADDITIONAL_SERVICE_BY_ID_LOADING',

  DELETE_ADDITIONAL_SERVICE_SUCCESS:
    'service_partner/DELETE_ADDITIONAL_SERVICE_SUCCESS',

  CLEAR_ADDITIONAL_SERVICE_ITEM: 'service_partner/CLEAR_ADDITIONAL_SERVICE_ITEM',
};
