import { sectionTypes } from './section.types';

export const createSectionSuccess = () => ({
  type: sectionTypes.CREATE_SECTION_SUCCESSs,
});

export const getSectionListSuccess = list => ({
  type: sectionTypes.GET_SECTION_LIST_SUCCESS,
  payload: list,
});

export const getSectionListFail = error => ({
  type: sectionTypes.GET_SECTION_LIST_FAIL,
  payload: error,
});

export const getSectionDetailsSuccess = section => ({
  type: sectionTypes.GET_SECTION_DATAILS_SUCCESS,
  payload: section,
});

export const getSectionDetailsFail = error => ({
  type: sectionTypes.GET_SECTION_DATAILS_FAIL,
  payload: error,
});

export const editSectionSuccess = section => ({
  type: sectionTypes.EDIT_SECTION_SUCCESS,
  payload: section,
});

export const editSectionFail = error => ({
  type: sectionTypes.EDIT_SECTION_FAIL,
  payload: error,
});

export const deleteSectionSuccess = id => ({
  type: sectionTypes.DELETE_SECTION_SUCCESS,
  payload: id,
});

export const deleteSectionFail = error => ({
  type: sectionTypes.DELETE_SECTION_FAIL,
  payload: error,
});

export const getQuestionNotSectionSucces = questions => ({
  type: sectionTypes.GET_QUESTION_NOT_SECTION_SUCCESS,
  payload: questions,
});

export const getQuestionNotSectionFail = error => ({
  type: sectionTypes.GET_QUESTION_NOT_SECTION_FAIL,
  payload: error,
});

export const updateTitleSection = title => ({
  type: sectionTypes.UPDATE_TITLE_SECTION,
  payload: title,
});

export const addQuestionBySection = id => ({
  type: sectionTypes.ADD_QUESTION_BY_SECTION,
  payload: id,
});

export const deleteQuestionFromSection = id => ({
  type: sectionTypes.DELETE_QUESTION_FROM_SECTION,
  payload: id,
});

export const sendModifiedQuestionsSuccess = id => ({
  type: sectionTypes.SEND_MODIFIED_QUESTION_SUCCESS,
  payload: id,
});

export const sendModifiedQuestionsFail = error => ({
  type: sectionTypes.SEND_MODIFIED_QUESTION_FAIL,
  payload: error,
});

export const clearSectionPageData = () => ({
  type: sectionTypes.CLEAR_SECTION_PAGE_DATA,
});
