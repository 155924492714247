export const confirmStatusSelector = state => state.confirm;
export const splashPhotosSelector = state => state.splashPhotos;
export const toastMessageSelector = state => state.toastMessage;
export const drPoopUserDataSelector = state => state.drPoop.userData;
export const drPoopStatementSelector = state => state.drPoop.statement;
export const drPoopCountGradeDistributionSelector = state =>
  state.drPoop.countGradeDistribution;
export const drPoopStatsSelector = state => state.drPoop.stats;
export const rewardsSelector = state => state.rewards;
export const scoreSelector = state => state.score;
export const pushNotificationsSelector = state => state.pushNotifications;
export const reasonSelector = state => state.subscription.reason;
export const serviceTypeSelector = state => state.services.serviceType;
export const servicePartnerSelector = state => state.services.servicePartner;
export const additionalFeeTemplateSelector = state =>
  state.services.additionalFeeTemplate;
export const additionalServiceSelector = state =>
  state.services.additionalService;
export const partnerTypeSelector = state => state.partners.partnerType;
export const partnerProgramSelector = state => state.partners.partnerProgram;
export const partnerWorkerSelector = state => state.partners.partnerWorker;
export const userCountrySelector = state => state.user.country;
export const userCitySelector = state => state.user.city;
export const currencySelector = state => state.currency;
export const deleteDogOptionsSelector = state =>
  state.deleteDog.deleteDogOptions;
export const deletedDogsSelector = state => state.deleteDog.deletedDogs;
export const contractsSelector = state => state.contracts;
export const workerTypeSelector = state => state.workers.workerType;
export const reviewsSelector = state => state.reviews;
export const discountReasonsSelector = state => state.discountReasons;
export const countriesSelector = state => state.countries;
export const citiesSelector = state => state.cities;
export const breedsSelector = state => state.breeds;
export const foodPricesModificatorsSelector = state => state.foodPrices;
export const smsConfigsSelector = state => state.smsConfigs;
