import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import GoBack from '../../../component/buttons/goBackButton';
import CustomSpinner from '../../../component/spinner';
import WeightGroupForm from '../../../component/weightGroupForm/WeightGroupForm';

import { getScoresWeightGroupItemByID } from '../../../service/score';
import { scoreSelector } from '../../../redux/selectors/selectors';
import {
  clearSelectedItemState,
  setScoresLoader,
} from '../../../redux/score/score.action';
import { isItemPage } from '../../../helpers/isItemPage';

const ScoresWeightGroupDetails = ({ match: { params } }) => {
  const dispatch = useDispatch();
  const { loading, selectedItem } = useSelector(scoreSelector);

  useEffect(() => {
    const { id } = params;
    if (isItemPage(id)) {
      dispatch(getScoresWeightGroupItemByID(id));
    } else if (loading) {
      dispatch(setScoresLoader(false));
    }

    return () => dispatch(clearSelectedItemState());
  }, [dispatch]);

  return (
    <Col sm={12} className="mt-3">
      <Row className="d-flex">
        <GoBack />
      </Row>
      <Row className="mt-3 d-flex align-items-start justify-content-center">
        {loading ? (
          <CustomSpinner />
        ) : (
          <WeightGroupForm
            defaultValues={selectedItem}
            itemPageId={isItemPage(params.id)}
          />
        )}
      </Row>
    </Col>
  );
};

export default ScoresWeightGroupDetails;
