import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Details from '../../../component/detail';
import { ListGroup } from 'react-bootstrap';

import { getSectionDetails } from '../../../service/quiz/section';

const SectionDetails = props => {
  const dispatch = useDispatch();

  const {
    item: { title, title_localizations, questions = [] },
    ...other
  } = useSelector(state => state.quiz.section);

  useEffect(() => {
    getSectionDetails(props.match.params.id)(dispatch);
  }, [props.match.params.id, dispatch]);

  return (
    <>
      <Details {...other}>
        <h2>EN: {title_localizations?.en || title || '-'}</h2>
        <h2>IL: {title_localizations?.il || '-'}</h2>
        <ListGroup className="col-12">
          {questions.map(({ question, question_localizations }) => (
            <ListGroup.Item>
              EN: {question_localizations?.en || question || '-'}
              <br />
              IL: {question_localizations?.il || '-'}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Details>
    </>
  );
};

export default withRouter(SectionDetails);
