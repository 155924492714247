export const contractsTypes = {
  GET_CONTRACTS_LIST_SUCCESS: 'contracts/GET_CONTRACTS_LIST_SUCCESS',
  GET_CONTRACTS_LIST_LOADING: 'contracts/GET_CONTRACTS_LIST_LOADING',
  GET_CONTRACTS_LIST_ERROR: 'contracts/GET_CONTRACTS_LIST_ERROR',

  GET_CONTRACT_BY_ID_SUCCESS: 'contracts/GET_CONTRACT_BY_ID_SUCCESS',
  GET_CONTRACT_BY_ID_LOADING: 'contracts/GET_CONTRACT_BY_ID_LOADING',

  DELETE_CONTRACT_SUCCESS: 'contracts/DELETE_CONTRACT_SUCCESS',

  CLEAR_ITEM: 'contracts/CLEAR_ITEM',
};
