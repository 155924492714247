import { rewardsTypes } from './rewards.types';

export const getRewardsListSuccess = list => ({
  type: rewardsTypes.GET_REWARDS_LIST_SUCCESS,
  payload: list,
});

export const getRewardsListFailed = () => ({
  type: rewardsTypes.GET_REWARDS_LIST_FAIL,
});

export const deleteItemFromRewardsList = id => ({
  type: rewardsTypes.DELETE_REWARDS_LIST_ITEM,
  payload: id,
});

export const rewardItemRequested = () => ({
  type: rewardsTypes.REWARD_ITEM_REQUESTED,
});

export const getRewardItemSuccess = itemData => ({
  type: rewardsTypes.GET_REWARD_ITEM_SUCCESS,
  payload: itemData,
});

export const getRewardItemFailed = () => ({
  type: rewardsTypes.GET_REWARD_ITEM_FAIL,
});

export const clearSelectedItemState = () => ({
  type: rewardsTypes.CLEAR_SELECTED_ITEM,
});

export const clearListOnActionsPage = () => ({
  type: rewardsTypes.CLEAR_LIST_ON_ACTIONS_PAGE,
});

export const setRewardsLoader = status => ({
  type: rewardsTypes.SET_REWARDS_LOADER,
  payload: status,
});

//Coins modificators

export const getCoinsModificatorsLoading = () => ({
  type: rewardsTypes.GET_COINS_MODIFICATORS_LOADING,
});
export const getCoinsModificatorsSucess = payload => ({
  type: rewardsTypes.GET_COINS_MODIFICATORS_SUCCESS,
  payload,
});
export const getCoinsModificatorsFailed = payload => ({
  type: rewardsTypes.GET_COINS_MODIFICATORS_FAILED,
  payload,
});

export const getCoinsModificatorByIdLoading = () => ({
  type: rewardsTypes.GET_COINS_MODIFICATOR_BY_ID_LOADING,
});
export const getCoinsModificatorByIdSucess = payload => ({
  type: rewardsTypes.GET_COINS_MODIFICATOR_BY_ID_SUCCESS,
  payload,
});
export const getCoinsModificatorByIdFailed = payload => ({
  type: rewardsTypes.GET_COINS_MODIFICATOR_BY_ID_FAILED,
  payload,
});

export const createCoinsModificatorsLoading = () => ({
  type: rewardsTypes.CREATE_COINS_MODIFICATORS_LOADING,
});
export const createCoinsModificatorsSucess = payload => ({
  type: rewardsTypes.CREATE_COINS_MODIFICATORS_SUCCESS,
  payload,
});
export const createCoinsModificatorsFailed = payload => ({
  type: rewardsTypes.CREATE_COINS_MODIFICATORS_FAILED,
  payload,
});

export const updateCoinsModificatorsLoading = () => ({
  type: rewardsTypes.UPDATE_COINS_MODIFICATORS_LOADING,
});
export const updateCoinsModificatorsSucess = payload => ({
  type: rewardsTypes.UPDATE_COINS_MODIFICATORS_SUCCESS,
  payload,
});
export const updateCoinsModificatorsFailed = payload => ({
  type: rewardsTypes.UPDATE_COINS_MODIFICATORS_FAILED,
  payload,
});

export const clearCoinsModificatorsState = () => ({
  type: rewardsTypes.CLEAR_COINS_MODIFICATORS_STATE,
});
export const clearSelectedCoinsModificator = () => ({
  type: rewardsTypes.CLEAR_SELECTED_COINS_MODIFICATOR,
});
