export const questionTypes = {
  GET_QUESTION_LIST_SUCCESS: 'question/GET_QUESTION_LIST_SUCCESS',
  GET_QUESTION_LIST_FAIL: 'question/GET_QUESTION_LIST_FAIL',
  GET_QUESTION_DATAILS_SUCCESS: 'question/GET_QUESTION_DATAILS_SUCCESS',
  GET_QUESTION_DATAILS_FAIL: 'question/GET_QUESTION_DATAILS_FAIL',
  EDIT_QUESTION_SUCCESS: 'question/EDIT_QUESTION_SUCCESS',
  EDIT_QUESTION_FAIL: 'question/EDIT_QUESTION_FAIL',
  DELETE_QUESTION_SUCCESS: 'question/DELETE_QUESTION_SUCCESS',
  DELETE_QUESTION_FAIL: 'question/DELETE_QUESTION_FAIL',
  DELETE_ANSWER_SUCCESS: 'question/DELETE_ANSWER_SUCCESS',
  DELETE_ANSWER_FAIL: 'question/DELETE_ANSWER_FAIL',
  EDIT_ANSWER_SUCCESS: 'question/EDIT_ANSWER_SUCCESS',
  EDIT_ANSWER_FAIL: 'question/EDIT_ANSWER_FAIL',
  ADD_NEW_ANSWER_SUCCESS: 'question/ADD_NEW_ANSWER_SUCCESS',
  ADD_NEW_ANSWER_FAIL: 'question/ADD_NEW_ANSWER_FAIL',
  CLEAR_QUESTION_PAGE_DATA: 'question/CLEAR_QUESTION_PAGE_DATA',
  CREATE_NEW_QUESTION_SUCCESS: 'question/CREATE_NEW_QUESTION_SUCCESS',
  CREATE_NEW_QUESTION_FAIL: 'question/CREATE_NEW_QUESTION_FAIL',
};
