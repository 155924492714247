import { withRouter, Link } from 'react-router-dom';
import { Col, Row, Alert, Button } from 'react-bootstrap';
import CustomSpinner from '../../component/spinner/';
import GoBack from '../buttons/goBackButton';

const Details = props => {
  const {
    loading,
    error,
    children,
    match: { url },
  } = props;

  if (loading) return <CustomSpinner />;
  return (
    <Col sm={12}>
      {error && <Alert variant={'danger'}>{error.message}</Alert>}
      <Row sm={12} className="d-flex justify-content-between mt-3 mb-3">
        <GoBack />

        <Link to={`${url}/edit`}>
          <Button variant="success">Edit</Button>
        </Link>
      </Row>
      {children}
    </Col>
  );
};

export default withRouter(Details);
