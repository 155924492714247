import fetchWithAuth from '../index';
import setLoading from '../../redux/default';

import { URL_SUPER_ADMIN } from '../../constant';

import MESSAGES_FOR_USER from '../../constants/messages/en';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';
import {
  getServiceTypeListSuccess,
  deleteServiceTypeFailed,
  deleteServiceTypeSuccess,
  getServiceTypeByIdFailed,
  getServiceTypeByIdSuccess,
  updateServiceTypeSuccess,
  updateServiceTypeFailed,
} from '../../redux/serviceType/serviceType.action';
import { createFormData } from '../../helpers/formDataConvertor';
import { getAdditionalServiceList } from './additional-service';

export const createServiceType = (data, history) => async dispatch => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'multipart/form-data');

  const body = {
    ...data,
    title_localizations: JSON.stringify(data.title_localizations),
    desc_localizations: JSON.stringify(data.desc_localizations),
  };

  const formData = createFormData(body);
  const result = await fetchWithAuth(
    {
      path: `${URL_SUPER_ADMIN}/service-type/`,
      method: 'POST',
      body: formData,
    },
    true,
  );

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SERVICE_TYPE.CREATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.SERVICE_TYPE.CREATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  history.push('/service-type/');
  return;
};

export const getServiceType = () => async dispatch => {
  dispatch(setLoading('service_type/GET_SERVICE_TYPE_LIST'));

  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/service-type/`,
    method: 'GET',
  });

  await dispatch(getAdditionalServiceList());

  dispatch(getServiceTypeListSuccess(await result.json()));
  return;
};

export const deleteServiceTypeById = id => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/service-type/${id}`,
    method: 'DELETE',
  });

  setLoading('DELETE_SERVICE_TYPE');

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SERVICE_TYPE.DELETE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.SERVICE_TYPE.DELETE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(deleteServiceTypeFailed(await result.json()));
    return;
  }

  dispatch(deleteServiceTypeSuccess(id));
  return;
};

export const getServiceTypeById = id => async dispatch => {
  let result = await fetchWithAuth({
    path: `${URL_SUPER_ADMIN}/service-type/${id}`,
    method: 'GET',
  });

  setLoading('GET_SERVICE_TYPE_BY_ID');

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SERVICE_TYPE.GET_BY_ID_FAILED.TITLE,
        content: MESSAGES_FOR_USER.SERVICE_TYPE.GET_BY_ID_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(getServiceTypeByIdFailed(await result.json()));
    return;
  }

  dispatch(getServiceTypeByIdSuccess(await result.json()));
};

export const updateServiceType = (id, formData, history) => async dispatch => {
  const { icon, image, ...updatedData } = formData;
  if (icon instanceof File) updatedData.icon = icon;
  if (image instanceof File) updatedData.image = image;

  const body = {
    ...updatedData,
    title_localizations: JSON.stringify(formData.title_localizations),
    desc_localizations: JSON.stringify(formData.desc_localizations),
  };

  const data = createFormData(body);

  let result = await fetchWithAuth(
    {
      path: `${URL_SUPER_ADMIN}/service-type/${id}`,
      method: 'PUT',
      body: data,
    },
    true,
  );

  setLoading('UPDATE_SERVICE_TYPE');

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.SERVICE_TYPE.UPDATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.SERVICE_TYPE.UPDATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(updateServiceTypeFailed(await result.json()));
    return;
  }

  dispatch(updateServiceTypeSuccess(id, formData));
  history.push('/service-type/');
  return;
};
