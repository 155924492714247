import React, { useEffect } from 'react';
import { Row, Button, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import DraggableTable from '../../component/draggableTable';
import ImageUploadCard from '../../component/uploadImage/UploadImage';
import CustomSpinner from '../../component/spinner';

import { setOrderInSplashPhotosList } from '../../redux/splash-photos/splash-photos.action';
import { getSlashPhotosList } from '../../service/splash-photo';
import {
  addSplashPhotoItem,
  updateSplashPhotosList,
} from '../../service/splash-photo';

import { splashPhotosSelector } from '../../redux/selectors/selectors';
import {
  DRAGGABLE_ITEM_TYPE,
  SPLASH_PHOTOS_DRAGGABLE_TABLE_HEAD,
} from '../../component/draggableTable/config';

const SplashPhotos = () => {
  const { list, isListHasAnyChanges, loading } =
    useSelector(splashPhotosSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSlashPhotosList());
  }, [dispatch]);

  return (
    <Col sm={12}>
      <Row className="mt-3">
        <Col className="p-0 d-flex align-items-center">
          <Button
            variant="primary"
            disabled={!isListHasAnyChanges}
            onClick={() => dispatch(updateSplashPhotosList(list))}
          >
            Save changes
          </Button>
        </Col>
        <Col className="p-0 d-flex justify-content-end">
          <ImageUploadCard
            handleUploadImageChange={file => {
              if (file) {
                dispatch(addSplashPhotoItem(file, list.length + 1));
              }
            }}
            isNeedToAddImagePlaceholder={false}
          />
        </Col>
      </Row>

      <Row className="mt-3 d-flex align-items-center justify-content-center">
        {loading ? (
          <CustomSpinner />
        ) : (
          <DraggableTable
            draggableItemVariant={DRAGGABLE_ITEM_TYPE.SPLASH_PHOTOS_ITEM}
            draggableRows={list}
            onDragFinished={updatedList =>
              dispatch(setOrderInSplashPhotosList(updatedList))
            }
            tableHeaderColumn={SPLASH_PHOTOS_DRAGGABLE_TABLE_HEAD}
          />
        )}
      </Row>
    </Col>
  );
};

export default SplashPhotos;
