import { citiesActions } from '../../redux/cities/cities.actions';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';
import MESSAGES_FOR_USER from '../../constants/messages/en';
import fetchWithAuth from '../';

export const getCitiesList = () => async dispatch => {
  dispatch(citiesActions.citiesOperationLoading());

  let result = await fetchWithAuth({
    path: `/address/city?countryId=60f6bdd0cb99b4395cc43e4f`,
    method: 'GET',
  });

  if (result.ok) {
    dispatch(citiesActions.getCitiesList(await result.json()));
    return;
  } else {
    const { statusText } = result;

    dispatch(citiesActions.citiesOperationFailed(statusText));

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.CITIES.GET_FAILED.TITLE,
        content: MESSAGES_FOR_USER.CITIES.GET_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
  }
};

export const getCityById = id => async dispatch => {
  dispatch(citiesActions.citiesOperationLoading());

  let result = await fetchWithAuth({
    path: `/address/city/${id}`,
    method: 'GET',
  });

  if (result.ok) {
    dispatch(citiesActions.getCityById(await result.json()));
    return;
  } else {
    const { statusText } = result;

    dispatch(citiesActions.citiesOperationFailed(statusText));
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.CITIES.GET_BY_ID_FAILED.TITLE,
        content: MESSAGES_FOR_USER.CITIES.GET_BY_ID_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
  }
};

export const createCity =
  ({ body }) =>
  async dispatch => {
    let result = await fetchWithAuth({
      path: `/address/city`,
      method: 'POST',
      body: JSON.stringify(body),
    });

    if (result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.CITIES.CREATE_SUCCESS.TITLE,
          content: MESSAGES_FOR_USER.CITIES.CREATE_SUCCESS.BODY,
          type: TOAST_MESSAGE_TYPES.SUCCESS,
        }),
      );
      return;
    } else {
      const { statusText } = result;

      dispatch(citiesActions.citiesOperationFailed(statusText));

      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.CITIES.CREATE_FAILED.TITLE,
          content: MESSAGES_FOR_USER.CITIES.CREATE_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );
    }
  };

export const updateCity =
  ({ id, body }) =>
  async dispatch => {
    let result = await fetchWithAuth({
      path: `/address/city/${id}`,
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.CITIES.UPDATE_SUCCESS.TITLE,
          content: MESSAGES_FOR_USER.CITIES.UPDATE_SUCCESS.BODY,
          type: TOAST_MESSAGE_TYPES.SUCCESS,
        }),
      );
      return;
    } else {
      const { statusText } = result;

      dispatch(citiesActions.citiesOperationFailed(statusText));

      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.CITIES.UPDATE_FAILED.TITLE,
          content: MESSAGES_FOR_USER.CITIES.UPDATE_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );
    }
  };

export const deleteCity = id => async dispatch => {
  let result = await fetchWithAuth({
    path: `/address/city/${id}`,
    method: 'DELETE',
  });

  if (result.ok) {
    dispatch(citiesActions.deleteCity(id));

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.CITIES.DELETE_SUCCESS.TITLE,
        content: MESSAGES_FOR_USER.CITIES.DELETE_SUCCESS.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );
    return;
  } else {
    const { statusText } = result;

    dispatch(citiesActions.citiesOperationFailed(statusText));

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.CITIES.DELETE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.CITIES.DELETE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
  }
};

const countriesOperations = {
  getCitiesList,
  getCityById,
  createCity,
  updateCity,
  deleteCity,
};

export default countriesOperations;
