import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';

import { renderActiveShape, usePieActiveIndex } from '../utils/charts';

import './chart.css';

const CustomPieChart = ({
  data,
  innerRadius = 50,
  outerRadius = 100,
  cx = '50%',
  cy = '40%',
  width = 350,
  height = 350,
  dataKey = 'value',
}) => {
  const { activeIndex, onPieEnter } = usePieActiveIndex();

  return (
    <ResponsiveContainer width={'100%'} aspect={1 / 1}>
      <PieChart width={width} height={height}>
        <Pie
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          dataKey={dataKey}
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          onMouseEnter={onPieEnter}
        >
          {data.map(entry => (
            <Cell key={`cell-${entry.name}`} fill={entry.color || '#333'} />
          ))}
        </Pie>
        <Legend
          iconSize={16}
          iconType="rect"
          layout="centric"
          align="center"
          verticalAlign="bottom"
          wrapperStyle={{
            width: '100%',
            left: 0,
            bottom: 0,
          }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CustomPieChart;
