import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import { getQuestionDetails } from '../../../service/quiz/question';
import { clearQuestionPageData } from '../../../redux/quiz/question/question.action';

import CreateQuestionFrom from '../../../component/quizForms/CreateQuestionForm';
import GoBack from '../../../component/buttons/goBackButton';
import CustomSpinner from '../../../component/spinner';

const QuestionEdit = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { item } = useSelector(state => state.quiz.question);

  useEffect(() => {
    if (id) {
      dispatch(getQuestionDetails(id));
    }

    return () => dispatch(clearQuestionPageData());
  }, [id, dispatch]);

  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      {!Object.entries(item).length ? (
        <CustomSpinner />
      ) : (
        <CreateQuestionFrom
          id={id}
          defaultValues={{
            question_localizations: {
              en: item.question_localizations?.en || item.question || '',
              il: item.question_localizations?.il || '',
            },
            answers_localizations: item.answers_localizations?.length
              ? item.answers_localizations
              : item.answers?.map(i => ({
                  answer: { en: i.answer, il: '' },
                  score: i.score,
                })),
            typeQuestion: item.typeQuestion,
          }}
        />
      )}
    </Col>
  );
};

export default withRouter(QuestionEdit);
