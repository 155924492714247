export const partnerTypes = {
  CREATE_PARTNER_TYPE_FAIL: 'partner_type/CREATE_PARTNER_TYPE_FAIL',

  GET_PARTNER_TYPE_LIST_SUCCESS: 'partner_type/GET_PARTNER_TYPE_LIST_SUCCESS',
  GET_PARTNER_TYPE_LIST_LOADING: 'partner_type/GET_PARTNER_TYPE_LIST_LOADING',
  GET_PARTNER_TYPE_LIST_FAIL: 'partner_type/GET_PARTNER_TYPE_LIST_FAIL',

  GET_PARTNER_TYPE_BY_ID_SUCCESS: 'partner_type/GET_PARTNER_TYPE_BY_ID_SUCCESS',
  GET_PARTNER_TYPE_BY_ID_FAIL: 'partner_type/GET_PARTNER_TYPE_BY_ID_FAIL',

  DELETE_PARTNER_TYPE_SUCCESS: 'partner_type/DELETE_PARTNER_TYPE_SUCCESS',
  DELETE_PARTNER_TYPE_FAIL: 'partner_type/DELETE_PARTNER_TYPE_FAIL',

  CLEAR_ITEM: 'partner_type/CLEAR_ITEM',
  UPDATE_PARTNER_TYPE_SUCCESS: 'partner_type/UPDATE_PARTNER_TYPE_SUCCESS',
  UPDATE_PARTNER_TYPE_FAIL: 'partner_type/UPDATE_PARTNER_TYPE_FAIL',
};
