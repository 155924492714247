import ScoresFitnessDetails from './fitness/details';
import ScoresFitnessList from './fitness/list';
import ScoresWeightGroupDetails from './weight-group/details';
import ScoresWeightGroupList from './weight-group/list';

const Score = {
  weightGroupList: ScoresWeightGroupList,
  weightGroupDetails: ScoresWeightGroupDetails,
  fitnessList: ScoresFitnessList,
  fitnessDetails: ScoresFitnessDetails,
};

export default Score;
