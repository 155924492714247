export const notificationsTypes = {
  COUNTRIES_LIST_REQUESTED: 'notifications/COUNTRIES_LIST_REQUESTED',
  COUNTRIES_LIST_RECEIVED_SUCCESS:
    'notifications/COUNTRIES_LIST_RECEIVED_SUCCESS',

  SETTINGS_LOADING: 'notifications/SETTINGS_LOADING',
  SETTINGS_FAILED: 'notifications/SETTINGS_FAILED',
  GET_SETTINGS_LIST_SUCCESS: 'notifications/GET_SETTINGS_LIST_SUCCESS',
  GET_SETTINGS_BY_ID_SUCCESS: 'notifications/GET_SETTINGS_BY_ID_SUCCESS',

  CLEAR_SETTINGS_STATE: 'notifications/CLEAR_SETTINGS_STATE',
  CLEAR_SELECTED_SETTING: 'notifications/CLEAR_SELECTED_SETTING',
};
