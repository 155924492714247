import { Col, Row, Button } from 'react-bootstrap';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CustomSpinner from '../../component/spinner';
import { destinationUrls } from '../../component/menu/constants';
import { citiesSelector } from '../../redux/selectors/selectors';
import citiesOperations from '../../service/cities/cities';
import ConfigurableTable from '../../component/table';
import { CITY_TABLE_HEAD, TABLE_ITEM_TYPE } from '../../component/table/config';

const CitiesList = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(citiesSelector);

  useEffect(() => {
    dispatch(citiesOperations.getCitiesList());
  }, [dispatch]);
  return (
    <Col sm={12} className="p-0 mt-3">
      <Link to={`${destinationUrls.CITIES}/create`}>
        <Button className="mb-3" variant="primary">
          Create city
        </Button>
      </Link>
      {loading || !list ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <ConfigurableTable
          tableHeaderColumns={CITY_TABLE_HEAD}
          itemVariant={TABLE_ITEM_TYPE.CITY_ITEM}
          rows={list}
        />
      )}
    </Col>
  );
};
export default CitiesList;
