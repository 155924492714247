import { cityTypes } from './city.types';

const initialSate = {
  loading: false,
  error: false,
  list: null,
  search: null,
  item: {
    countryId: null,
    name: null,
    _id: null,
  },
};

const city = (state = initialSate, { type, payload }) => {
  switch (type) {
    case cityTypes.GET_CITY_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case cityTypes.GET_CITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.cities,
        search: payload.search,
      };

    case cityTypes.CLEAR:
      return {
        ...initialSate,
      };

    case cityTypes.CLEAR_ITEM:
      return {
        ...state,
        list: null,
        search: null,
        item: { _id: null, countryId: null, name: null },
      };

    case cityTypes.SET_SEARCH:
      return {
        ...state,
        search: payload,
      };

    case cityTypes.SET_ITEM:
      return {
        ...state,
        item: payload,
      };

    case cityTypes.GET_COUNTRY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default city;
