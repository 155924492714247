import React from 'react';

const Text = ({ name, register, label, validationRules, error }) => {
  return (
    <>
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <div className="input-group">
        <input
          type="text"
          className="form-control text-left ml-0 mr-0 mt-0"
          {...register(name, validationRules)}
        />
        {error?.message && (
          <p className="error">
            <i className="fa fa-exclamation-circle"></i> {error.message}
          </p>
        )}
      </div>
    </>
  );
};

export default Text;
