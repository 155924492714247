import React from 'react';

import './styles.css';

const UploadedImage = ({ defaultImage, imageResetHandler, selectedFile }) => (
  <div className="uploaded-container">
    <div className="uploaded-image-container" onClick={imageResetHandler}>
      <img className="uploaded-image" src={selectedFile} alt="uploaded" />
    </div>
    <p className="uploaded-image-description">
      Click on the photo to delete it
    </p>
  </div>
);

export default UploadedImage;
