import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import auth from './auth/auth.reducer';
import section from './quiz/section/section.reducer';
import question from './quiz/question/question.reducer';
import confirm from './confirm/confirm.reducer';
import publication from './publication/publication.reducer';
import splashPhotos from './splash-photos/splash-photos.reducer';
import toastMessage from './toast-message/toast-message.reducer';
import drPoop from './dr-poop/dr-poop.reducer';
import rewards from './rewards/rewards.reducer';
import score from './score/score.reducer';
import pushNotifications from './notifications/notifications.reducer';
import reason from './subscription/reason/reason.reducer';

import { handleUnauthorizedUsers } from './middlewares';

import serviceType from './serviceType/serviceType.reducer';
import partnerType from './partnerType/partnerType.reducer';
import servicePartner from './servicePartner/servicePartner.reducer';
import partnerProgram from './partnerProgram/partnerProgram.reducer';
import country from './user/country/country.reducer';
import city from './user/city/city.reducer';
import currency from './currency/currency.reducer';
import partnerWorker from './partnerWorker/partnerWorker.reducer';
import additionalFeeTemplate from './additional-fee-template/additional-fee-template.reducer';
import additionalService from './additional-service/additional-service.reducer';
import deleteDogOptions from './delete-dog-options/delete-dog-options.reducer';
import deletedDogs from './deleted-dogs/deleted-dogs.reducer';
import contracts from './contracts/contracts.reducer';
import workerType from './workerType/workerType.reducer';
import reviews from './reviews/reviews.reducer';
import discountReasons from './discount-reasons/discount-reasons.reducer';
import countriesReducer from './countries/countries.reducer';
import citiesReducer from './cities/cities.reducer';
import breeds from './breeds/breeds.reducer';
import foodPricesModificatorsReducer from './food-prices-modificators/food-prices-modificators.reducer';
import smsConfigsReducer from './sms-configs/sms-configs.reducer';

const rootReducer = combineReducers({
  auth,
  quiz: combineReducers({
    section,
    question,
  }),
  publication,
  confirm,
  splashPhotos,
  toastMessage,
  drPoop,
  rewards,
  score,
  contracts,
  pushNotifications,
  services: combineReducers({
    serviceType,
    servicePartner,
    additionalFeeTemplate,
    additionalService,
  }),
  partners: combineReducers({
    partnerType,
    partnerProgram,
    partnerWorker,
  }),
  workers: combineReducers({
    workerType,
  }),
  subscription: combineReducers({
    reason,
  }),
  currency,
  user: combineReducers({
    country,
    city,
  }),
  deleteDog: combineReducers({
    deleteDogOptions,
    deletedDogs,
  }),
  reviews,
  discountReasons,
  countries: countriesReducer,
  cities: citiesReducer,
  breeds,
  foodPrices: foodPricesModificatorsReducer,
  smsConfigs: smsConfigsReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk /*handleUnauthorizedUsers*/)),
);

export default store;
