import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import GoBack from '../../../component/buttons/goBackButton';
import { getPartnerType } from '../../../service/partners';
import { useDispatch, useSelector } from 'react-redux';
import {
  partnerTypeSelector,
  servicePartnerSelector,
  currencySelector,
} from '../../../redux/selectors/selectors';
import CustomSpinner from '../../../component/spinner';
import PartnerProgramForm from '../../../component/partnerProgramForm/partnerProgramForm';
import { getNormalizedEntities } from '../../../helpers/getNormalizedEntities';
import { getServicePartner } from '../../../service/services/servicePartner';
import { getCurrency } from '../../../service/currency';

const PartnerProgramCreate = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(servicePartnerSelector);
  const { list: partnerTypeList, loading: partnerTypeListLoading } =
    useSelector(partnerTypeSelector);

  const { list: currencyList, loading: currencyListLoading } =
    useSelector(currencySelector);

  useEffect(() => {
    if (!list) getServicePartner()(dispatch);
  }, [dispatch, list]);

  useEffect(() => {
    if (!partnerTypeList) getPartnerType()(dispatch);
  }, [dispatch, partnerTypeList]);

  useEffect(() => {
    if (!currencyList) getCurrency()(dispatch);
  }, [dispatch, currencyList]);

  const [normalizedPartnerTypes, partnerTypeIds] = getNormalizedEntities(
    partnerTypeList,
    ['title', 'title_localizations'],
  );

  const [normalizedServicePartners, servicePartnerIds] = getNormalizedEntities(
    list,
    ['service'],
  );

  const [normalizedCurrency, currencyIds] = getNormalizedEntities(
    currencyList,
    ['_id', 'name', 'code'],
  );

  const defaultValues =
    !partnerTypeList || !servicePartnerIds || !currencyList
      ? null
      : {
          partnerType: partnerTypeIds[0],
          location: {
            country: '',
            city: '',
          },
          contractType: 'testContractType',
          currency: currencyIds[0],
          services: servicePartnerIds.map(() => false),
        };

  return (
    <Col sm={9} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>

      {loading ||
      !list ||
      !partnerTypeList ||
      partnerTypeListLoading ||
      !currencyList ||
      currencyListLoading ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <PartnerProgramForm
          normalizedServicePartners={normalizedServicePartners}
          partnerTypeIds={partnerTypeIds}
          servicePartnerIds={servicePartnerIds}
          normalizedPartnerTypes={normalizedPartnerTypes}
          normalizedCurrency={normalizedCurrency}
          currencyIds={currencyIds}
          defaultValues={defaultValues}
          type="CREATE"
        />
      )}
    </Col>
  );
};

export default PartnerProgramCreate;
