import { useEffect } from 'react';
import { Col, Table, Button, Alert, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPublicationList } from '../../../service/publication/publication';

import { changeConfirmStatus } from '../../../redux/confirm/confirm.action';
import { confirmationKeys } from '../../../constant';
import CustomSpinner from '../../../component/spinner';

const PublicationList = () => {
  const dispatch = useDispatch();
  const { list, loading, error } = useSelector(state => state.publication);

  useEffect(() => {
    getPublicationList()(dispatch);
  }, [dispatch]);

  const handleDeclineButton = (id, content) => {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        content,
        type: confirmationKeys.publicationTableDeleteItem,
        actionParams: [id],
      }),
    );
  };

  return (
    <Col sm={12} className="p-0 mt-3">
      {error && <Alert variant={'danger'}>{error.message}</Alert>}
      <Row>
        <Col>
          <div className="d-flex justify-content-between mb-3">
            <Link to={'/publication/create'}>
              <Button variant="primary">Add new recomendation</Button>
            </Link>
          </div>
        </Col>
      </Row>

      {loading && !list.length ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <Table bordered size="md" responsive="md">
          <thead>
            <tr>
              <th>Tiltle (EN)</th>
              <th>Tiltle (IL)</th>
              <th>Create Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {list.map(({ _id, title, title_localizations, createdAt }) => (
              <tr key={`section-${_id}`}>
                <td>{title_localizations?.en || title || '-'}</td>
                <td>{title_localizations?.il || '-'}</td>
                <td>{new Date(createdAt).toLocaleString()}</td>
                <td>
                  <ul className="list-inline m-0 p-0">
                    <li className="list-inline-item">
                      <Link to={`/publication/${_id}`}>
                        <Button size="sm" variant="primary">
                          <i className="fa fa-eye"></i>
                        </Button>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to={`/publication/${_id}/edit`}>
                        <Button size="sm" variant="success">
                          <i className="fa fa-pencil"></i>
                        </Button>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Button
                        size="sm"
                        variant="danger"
                        onClick={() => handleDeclineButton(_id, title)}
                      >
                        <i className="fa fa-trash-o"></i>
                      </Button>
                    </li>
                  </ul>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Col>
  );
};

export default PublicationList;
