import CITIES_ACTIONS_TYPES from './cities.types';

export const citiesActions = {
  citiesOperationLoading: () => ({
    type: CITIES_ACTIONS_TYPES.OPERATION_LOADING,
  }),

  citiesOperationFailed: payload => ({
    type: CITIES_ACTIONS_TYPES.OPERATION_FAILED,
    payload,
  }),

  getCitiesList: payload => ({
    type: CITIES_ACTIONS_TYPES.GET_CITIES_LIST,
    payload,
  }),

  getCityById: payload => ({
    type: CITIES_ACTIONS_TYPES.GET_CITY_BY_ID,
    payload,
  }),

  deleteCity: payload => ({
    type: CITIES_ACTIONS_TYPES.DELETE_CITY,
    payload,
  }),

  clearSelectedCity: () => ({
    type: CITIES_ACTIONS_TYPES.CLEAR_SELECTED_CITY,
  }),
};
