import { destinationUrls } from '../component/menu/constants';

import Auth from '../page/auth/';
import Section from '../page/quiz/section';
import CancelReason from '../page/subscription/cancelReason';
import Question from '../page/quiz/question';
import Rewards from '../page/rewards';
import PublicationPages from '../page/publication/pages';
import SplashPhotos from '../page/splash-photos';
import {
  DrPoopStatementDetails,
  DrPoopStatementsList,
} from '../page/dr-poop/statements';
import {
  DrPoopUserDataItem,
  DrPoopUserDataTable,
} from '../page/dr-poop/user-data';
import Score from '../page/score';
import SplashPhotoItem from '../page/splash-photos/details';
import PushNotifications from '../page/push-notifications';
import ServiceType from '../page/services/serviceType';
import PartnerType from '../page/partners/partnerType';
import ServicePartner from '../page/services/servicePartner';
import PartnerProgram from '../page/partners/partnerProgram';
import PartnerWorker from '../page/partners/worker';
import AdditionalFeeTemplate from '../page/services/additional-fee-template';
import AdditionalService from '../page/services/additional-service';
import DeleteDogOptions from '../page/delete-dog/delete-dog-options';
import DeletedDogs from '../page/delete-dog/deleted-dogs';
import Contracts from '../page/contracts';
import WorkerType from '../page/workers/workerType';
import { Reviews } from '../page/reviews';
import { Billing } from '../page/billing';
import DiscountReasons from '../page/discount-reasons';
import { DrPoopAnalyticsBoard } from '../page/dr-poop/analitics';
import DrPoopMask from '../page/dr-poop-mask/dr-poop-mask';
import Countries from '../page/countries/';
import Cities from '../page/cities/';
import Breeds from '../page/breeds';
import FoodPricesModificators from '../page/food-prices-modificator';
import SmsConfigs from '../page/sms-configs';

export const mainPath = [
  {
    path: '/auth',
    isExact: true,
    component: Auth,
  },
  {
    path: '/splash-photos',
    isExact: true,
    component: SplashPhotos,
  },
  {
    path: '/splash-photos/:id',
    isExact: true,
    component: SplashPhotoItem,
  },
  {
    path: '/dr-poop/user-data',
    isExact: true,
    component: DrPoopUserDataTable,
  },
  {
    path: '/dr-poop/user-data/:id',
    isExact: false,
    component: DrPoopUserDataItem,
  },
  {
    path: '/dr-poop/statements',
    isExact: true,
    component: DrPoopStatementsList,
  },
  {
    path: '/dr-poop/statements/:id',
    isExact: true,
    component: DrPoopStatementDetails,
  },
  {
    path: '/dr-poop/analytics',
    isExact: true,
    component: DrPoopAnalyticsBoard,
  },
  {
    path: '/publication/create',
    isExact: true,
    component: PublicationPages.create,
  },
  {
    path: '/publication/:id/edit',
    isExact: true,
    component: PublicationPages.edit,
  },
  {
    path: '/publication/:id',
    isExact: true,
    component: PublicationPages.details,
  },
  {
    path: '/publication',
    isExact: true,
    component: PublicationPages.list,
  },
  {
    path: '/quiz/section/create',
    isExact: true,
    component: Section.create,
  },
  {
    path: '/quiz/section/:id/edit',
    isExact: true,
    component: Section.edit,
  },
  {
    path: '/quiz/section/:id',
    isExact: true,
    component: Section.details,
  },
  {
    path: '/quiz/section/',
    isExact: true,
    component: Section.list,
  },
  {
    path: '/quiz/question/',
    isExact: true,
    component: Question.list,
  },
  {
    path: '/quiz/question/create',
    isExact: true,
    component: Question.create,
  },
  {
    path: '/quiz/question/:id',
    isExact: true,
    component: Question.details,
  },
  {
    path: '/quiz/question/:id/edit',
    isExact: true,
    component: Question.edit,
  },
  {
    path: '/rewards/count-walk',
    isExact: true,
    component: Rewards.list,
  },
  {
    path: '/rewards/count-walk/:id',
    isExact: true,
    component: Rewards.details,
  },
  {
    path: '/rewards/distance-walk',
    isExact: true,
    component: Rewards.list,
  },
  {
    path: '/rewards/distance-walk/:id',
    isExact: true,
    component: Rewards.details,
  },
  {
    path: '/rewards/mood',
    isExact: true,
    component: Rewards.list,
  },
  {
    path: '/rewards/mood/:id',
    isExact: true,
    component: Rewards.details,
  },
  {
    path: '/rewards/weight',
    isExact: true,
    component: Rewards.list,
  },
  {
    path: '/rewards/weight/:id',
    isExact: true,
    component: Rewards.details,
  },
  {
    path: '/rewards/actions',
    isExact: true,
    component: Rewards.actions,
  },
  {
    path: '/rewards/actions/:id',
    isExact: true,
    component: Rewards.actionDetails,
  },
  {
    path: '/rewards/coins-modificators',
    isExact: true,
    component: Rewards.coinsModificators,
  },
  {
    path: '/rewards/coins-modificators/create',
    isExact: true,
    component: Rewards.coinsModificatorsDetails,
  },
  {
    path: '/rewards/coins-modificators/:id',
    isExact: true,
    component: Rewards.coinsModificatorsDetails,
  },

  {
    path: '/rewards/actions',
    isExact: true,
    component: Rewards.actions,
  },
  {
    path: '/score/weight',
    isExact: true,
    component: Score.weightGroupList,
  },
  {
    path: '/score/weight/:id',
    isExact: true,
    component: Score.weightGroupDetails,
  },
  {
    path: '/score/weight/create',
    isExact: true,
    component: Score.weightGroupDetails,
  },
  {
    path: '/score/fitness',
    isExact: true,
    component: Score.fitnessList,
  },
  {
    path: '/score/fitness/create',
    isExact: true,
    component: Score.fitnessDetails,
  },
  {
    path: '/score/fitness/:id',
    isExact: true,
    component: Score.fitnessDetails,
  },
  {
    path: '/notifications',
    isExact: true,
    component: PushNotifications.NotificationsPage,
  },
  {
    path: '/notifications-config',
    isExact: true,
    component: PushNotifications.NotificationsConfig.List,
  },
  {
    path: '/notifications-config/:id',
    isExact: true,
    component: PushNotifications.NotificationsConfig.Edit,
  },

  {
    path: '/subscription/reason/create',
    isExact: true,
    component: CancelReason.create,
  },
  {
    path: '/subscription/reason/:id/edit',
    isExact: true,
    component: CancelReason.edit,
  },
  {
    path: '/subscription/reason/',
    isExact: true,
    component: CancelReason.list,
  },
  {
    path: '/service-type/',
    isExact: true,
    component: ServiceType.list,
  },
  {
    path: '/service-type/create',
    isExact: true,
    component: ServiceType.create,
  },
  {
    path: '/service-type/:id',
    isExact: true,
    component: ServiceType.edit,
  },
  {
    path: '/service-partner/',
    isExact: true,
    component: ServicePartner.list,
  },
  {
    path: '/service-partner/create',
    isExact: true,
    component: ServicePartner.create,
  },
  {
    path: '/service-partner/:id',
    isExact: true,
    component: ServicePartner.edit,
  },
  {
    path: '/partner-type/',
    isExact: true,
    component: PartnerType.list,
  },
  {
    path: '/partner-type/create',
    isExact: true,
    component: PartnerType.create,
  },
  {
    path: '/partner-type/:id',
    isExact: true,
    component: PartnerType.edit,
  },
  {
    path: destinationUrls.FEE_TEMPLATE,
    isExact: true,
    component: AdditionalFeeTemplate.list,
  },
  {
    path: `${destinationUrls.FEE_TEMPLATE}/create`,
    isExact: true,
    component: AdditionalFeeTemplate.create,
  },
  {
    path: `${destinationUrls.FEE_TEMPLATE}/:id`,
    isExact: true,
    component: AdditionalFeeTemplate.edit,
  },
  {
    path: destinationUrls.ADDITIONAL_SERVICE,
    isExact: true,
    component: AdditionalService.list,
  },
  {
    path: `${destinationUrls.ADDITIONAL_SERVICE}/create`,
    isExact: true,
    component: AdditionalService.create,
  },
  {
    path: `${destinationUrls.ADDITIONAL_SERVICE}/:id`,
    isExact: true,
    component: AdditionalService.edit,
  },

  {
    path: destinationUrls.PARTNER_PROGRAM,
    isExact: true,
    component: PartnerProgram.list,
  },
  {
    path: `${destinationUrls.PARTNER_PROGRAM}create`,
    isExact: true,
    component: PartnerProgram.create,
  },
  {
    path: `${destinationUrls.PARTNER_PROGRAM}:id`,
    isExact: true,
    component: PartnerProgram.edit,
  },
  {
    path: `${destinationUrls.PARTNER_WORKER}`,
    isExact: true,
    component: PartnerWorker.create,
  },
  {
    path: destinationUrls.DELETE_DOG_OPTIONS,
    isExact: true,
    component: DeleteDogOptions.list,
  },
  {
    path: `${destinationUrls.DELETE_DOG_OPTIONS}/create`,
    isExact: true,
    component: DeleteDogOptions.create,
  },
  {
    path: `${destinationUrls.DELETE_DOG_OPTIONS}/:id`,
    isExact: true,
    component: DeleteDogOptions.edit,
  },

  {
    path: destinationUrls.DELETED_DOGS,
    isExact: true,
    component: DeletedDogs.list,
  },

  {
    path: destinationUrls.CONTRACTS,
    isExact: true,
    component: Contracts.list,
  },
  {
    path: `${destinationUrls.CONTRACTS}/create`,
    isExact: true,
    component: Contracts.create,
  },
  {
    path: `${destinationUrls.CONTRACTS}/:id`,
    isExact: true,
    component: Contracts.edit,
  },
  {
    path: destinationUrls.WORKER_TYPE,
    isExact: true,
    component: WorkerType.list,
  },
  {
    path: `${destinationUrls.WORKER_TYPE}/create`,
    isExact: true,
    component: WorkerType.create,
  },
  {
    path: `${destinationUrls.WORKER_TYPE}/:id`,
    isExact: true,
    component: WorkerType.edit,
  },
  {
    path: `${destinationUrls.REVIEWS}`,
    isExact: true,
    component: Reviews.list,
  },
  {
    path: `${destinationUrls.BILLING}`,
    isExact: true,
    component: Billing.create,
  },
  {
    path: `${destinationUrls.DISCOUNT_REASONS}`,
    isExact: true,
    component: DiscountReasons.List,
  },
  {
    path: `${destinationUrls.DISCOUNT_REASONS}/create`,
    isExact: true,
    component: DiscountReasons.Create,
  },
  {
    path: `${destinationUrls.DISCOUNT_REASONS}/:id`,
    isExact: true,
    component: DiscountReasons.Edit,
  },
  {
    path: `${destinationUrls.DR_POOP_MASK}`,
    isExact: true,
    component: DrPoopMask,
  },
  {
    path: `${destinationUrls.COUNTRIES}`,
    isExact: true,
    component: Countries.List,
  },
  {
    path: `${destinationUrls.COUNTRIES}/create`,
    isExact: true,
    component: Countries.Create,
  },
  {
    path: `${destinationUrls.COUNTRIES}/:id`,
    isExact: true,
    component: Countries.Edit,
  },
  {
    path: `${destinationUrls.CITIES}`,
    isExact: true,
    component: Cities.List,
  },
  {
    path: `${destinationUrls.CITIES}/create`,
    isExact: true,
    component: Cities.Create,
  },
  {
    path: `${destinationUrls.CITIES}/:id`,
    isExact: true,
    component: Cities.Edit,
  },
  {
    path: `${destinationUrls.BREEDS}`,
    isExact: true,
    component: Breeds.List,
  },
  {
    path: `${destinationUrls.BREEDS}/create`,
    isExact: true,
    component: Breeds.Create,
  },
  {
    path: `${destinationUrls.BREEDS}/:id`,
    isExact: true,
    component: Breeds.Edit,
  },
  {
    path: `${destinationUrls.FOOD_PRICES_MODIFICATORS}`,
    isExact: true,
    component: FoodPricesModificators.List,
  },
  {
    path: `${destinationUrls.FOOD_PRICES_MODIFICATORS}/create`,
    isExact: true,
    component: FoodPricesModificators.Create,
  },
  {
    path: `${destinationUrls.FOOD_PRICES_MODIFICATORS}/:id`,
    isExact: true,
    component: FoodPricesModificators.Edit,
  },
  {
    path: destinationUrls.SMS_CONFIGS,
    isExact: true,
    component: SmsConfigs.List,
  },
  {
    path: `${destinationUrls.SMS_CONFIGS}/:id`,
    isExact: true,
    component: SmsConfigs.Edit,
  },
];
