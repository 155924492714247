import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import GoBack from '../../../component/buttons/goBackButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  serviceTypeSelector,
  servicePartnerSelector,
  additionalServiceSelector,
} from '../../../redux/selectors/selectors';
import { getServiceType } from '../../../service/services';
import ServicePartnerForm from '../../../component/servicePartnerForm/servicePartnerForm';
import CustomSpinner from '../../../component/spinner';
import { getServicePartnerById } from '../../../service/services/servicePartner';
import { clearServicePartnerItemState } from '../../../redux/servicePartner/servicePartner.action';

const ServiceTypeCreate = props => {
  const dispatch = useDispatch();
  const { id } = props.match.params;
  const { list, loading } = useSelector(serviceTypeSelector);
  const { list: additionServiceList } = useSelector(additionalServiceSelector);
  const { item, loading: servicePartnerLoading } = useSelector(
    servicePartnerSelector,
  );

  useEffect(() => {
    if (!list) getServiceType()(dispatch);
  }, [dispatch, list]);

  useEffect(() => {
    getServicePartnerById(id)(dispatch);
    return () => dispatch(clearServicePartnerItemState());
  }, [dispatch, id]);

  const serviceOptions =
    list &&
    Object.fromEntries(
      list.map(({ _id, title, title_localizations }) => [
        _id,
        title_localizations?.en || title,
      ]),
    );

  const transformListToOptions = () => {
    const result = [];

    additionServiceList.forEach(item => {
      if (item.additionServiceType) {
        result.push({
          label:
            item.additionServiceType?.title_localizations?.en ||
            item.additionServiceType?.title,
          value: item.additionServiceType?._id,
        });
      }
    });

    return result;
  };

  const transformAdditionServicesForDefaulValues = services => {
    if (services.length < 1) return [];
    else {
      return services.map(service => ({
        label: service.title_localizations?.en || service.title,
        value: service._id,
      }));
    }
  };

  const defaultValues = item && {
    additionService: transformAdditionServicesForDefaulValues(
      item.additionService,
    ),
    vouchers: item.vouchers,
    repeat: item.repeat,
    subscription: item.subscription,
    price: item.price,
    cancellation_time: item.cancellation_time,
    service: item.service._id,
  };

  return (
    <Col sm={9} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>

      {loading || servicePartnerLoading || !list || !item ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <ServicePartnerForm
          defaultValues={defaultValues}
          serviceOptions={serviceOptions}
          additionServiceList={transformListToOptions()}
          id={id}
          type="EDIT"
        />
      )}
    </Col>
  );
};

export default ServiceTypeCreate;
