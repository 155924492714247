import fetchWithAuth from '../';

export const getStatesList = async countryId => {
  let result = await fetchWithAuth({
    path: `/address/state${countryId ? '?countryId=' + countryId : ''}`,
    method: 'GET',
  });

  return result.json();
};
