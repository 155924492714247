import { partnerProgramTypes } from './partnerProgram.types';

export const createPartnerProgramSuccess = () => ({
  type: partnerProgramTypes.CREATE_PARTNER_SUCCESSs,
});

export const getPartnerProgramListSuccess = list => ({
  type: partnerProgramTypes.GET_PARTNER_PROGRAM_LIST_SUCCESS,
  payload: list,
});
export const getPartnerProgramListFailed = error => ({
  type: partnerProgramTypes.GET_PARTNER_PROGRAM_LIST_FAIL,
  payload: error,
});

export const getPartnerProgramByIdSuccess = reason => ({
  type: partnerProgramTypes.GET_PARTNER_PROGRAM_BY_ID_SUCCESS,
  payload: reason,
});
export const getPartnerProgramByIdFailed = err => ({
  type: partnerProgramTypes.GET_PARTNER_PROGRAM_BY_ID_FAIL,
  payload: err,
});

export const deletePartnerProgramSuccess = id => ({
  type: partnerProgramTypes.DELETE_PARTNER_PROGRAM_SUCCESS,
  payload: id,
});
export const deletePartnerProgramFailed = err => ({
  type: partnerProgramTypes.DELETE_PARTNER_PROGRAM_FAIL,
  payload: err,
});

export const clearPartnerProgramItemState = () => ({
  type: partnerProgramTypes.CLEAR_ITEM,
});
export const updatePartnerProgramFailed = err => ({
  type: partnerProgramTypes.UPDATE_PARTNER_PROGRAM_FAIL,
  payload: err,
});
export const updatePartnerProgramSuccess = (id, updatedData) => ({
  type: partnerProgramTypes.UPDATE_PARTNER_PROGRAM_SUCCESS,
  payload: { updatedData, id },
});
