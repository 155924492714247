import DeleteDogOptionsCreate from './create';
import DeleteDogOptionsEdit from './edit';
import DeleteDogOptionsList from './list';

const DeleteDogOptions = {
  list: DeleteDogOptionsList,
  create: DeleteDogOptionsCreate,
  edit: DeleteDogOptionsEdit,
};

export default DeleteDogOptions;
