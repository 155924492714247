import { Col, Button, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CustomSpinner from '../../../component/spinner';
import ConfigurableTable from '../../../component/table';

import {
  TABLE_ITEM_TYPE,
  DELETE_DOG_OPTIONS,
} from '../../../component/table/config';
import { deleteDogOptionsSelector } from '../../../redux/selectors/selectors';
import { destinationUrls } from '../../../component/menu/constants';
import { getDeleteDogOptionsList } from '../../../service/delete-dog/delete-dog-options';

const DeleteDogOptionsList = () => {
  const dispatch = useDispatch();

  const { list = [], loading } = useSelector(deleteDogOptionsSelector);

  useEffect(() => {
    getDeleteDogOptionsList()(dispatch);
  }, [dispatch]);

  return (
    <Col sm={12} className="p-0 mt-3">
      <Link to={`${destinationUrls.DELETE_DOG_OPTIONS}/create`}>
        <Button className="mb-3" variant="primary">
          Create reason
        </Button>
      </Link>
      {loading || !list ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <ConfigurableTable
          tableHeaderColumns={DELETE_DOG_OPTIONS}
          itemVariant={TABLE_ITEM_TYPE.DELETE_DOG_OPTIONS}
          rows={list}
        />
      )}
    </Col>
  );
};

export default DeleteDogOptionsList;
