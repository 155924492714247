import { sectionTypes } from './section.types';

const initialSate = {
  loading: false,
  error: false,
  list: null,
  item: false,
  questionNotSection: [],
};

const quiz = (state = initialSate, { type, payload }) => {
  switch (type) {
    case 'GET_SECTION_LIST_LOADING':
    case 'GET_SECTION_DATAILS_LOADING':
    case 'DELETE_SECTION_LOADING':
    case 'GET_QUESTION_NOT_SECTION_LOADING':
      return {
        ...state,
        loading: true,
      };
    case sectionTypes.GET_SECTION_LIST_FAIL:
    case sectionTypes.GET_SECTION_DATAILS_FAIL:
    case sectionTypes.DELETE_SECTION_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case sectionTypes.GET_SECTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload,
      };
    case sectionTypes.GET_SECTION_DATAILS_SUCCESS:
      return {
        ...state,
        item: {
          ...payload,
          questions: payload.questions.map((i, idx) => ({ ...i, order: idx })),
        },
        isChanged: false,
        loading: false,
      };
    case sectionTypes.DELETE_SECTION_SUCCESS:
      const newList = state.list.filter(item => item._id !== payload);
      return {
        ...state,
        list: newList,
        loading: false,
      };

    case sectionTypes.DELETE_QUESTION_FROM_SECTION:
      let NewListOfQuestions = state.item.questions.filter(
        item => item._id !== payload,
      );

      return {
        ...state,
        isChanged: true,
        item: {
          ...state.item,
          questions: NewListOfQuestions,
        },
        loading: false,
      };
    case sectionTypes.GET_QUESTION_NOT_SECTION_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        questionNotSection: payload,
      };
    case sectionTypes.UPDATE_TITLE_SECTION:
      return {
        ...state,
        item: {
          ...state.item,
          title: payload,
        },
      };
    case sectionTypes.ADD_QUESTION_BY_SECTION:
      state.item.questions.push(
        state.questionNotSection.filter(
          question => question._id === payload,
        )[0],
      );

      let listWithNewQuestion = state.item.questions;

      return {
        ...state,
        loading: false,
        questionNotSection: state.questionNotSection.filter(
          question => question._id !== payload,
        ),
        item: {
          ...state.item,
          questions: listWithNewQuestion,
        },
      };
    case sectionTypes.SEND_MODIFIED_QUESTION_SUCCESS:
      return {
        ...state,
        isChanged: false,
      };
    case sectionTypes.EDIT_SECTION_SUCCESS:
      return {
        ...state,
        item: payload,
      };

    case sectionTypes.CLEAR_SECTION_PAGE_DATA:
      return initialSate;
    default:
      return state;
  }
};

export default quiz;
