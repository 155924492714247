import { drPoopTypes } from './dr-poop.types';

export const getDrPoopUserDataListLoading = () => ({
  type: drPoopTypes.GET_DR_POOP_USER_DATA_LIST_LOADING,
});

export const getDrPoopUserDataListSuccess = list => ({
  type: drPoopTypes.GET_DR_POOP_USER_DATA_LIST_SUCCESS,
  payload: list,
});

export const getDrPoopUserDataListFail = () => ({
  type: drPoopTypes.GET_DR_POOP_USER_DATA_LIST_FAILED,
});

export const deleteItemFromDrPoopUserDataList = id => ({
  type: drPoopTypes.DELETE_ITEM_FROM_DR_POOP_USER_DATA_LIST,
  payload: id,
});

export const drPoopUserDataItemRequested = () => ({
  type: drPoopTypes.DR_POOP_USER_DATA_ITEM_REQUESTED,
});

export const getDrPoopUserDataItemFailed = () => ({
  type: drPoopTypes.DR_POOP_USER_DATA_ITEM_REQUESTED,
});

export const getDrPoopUserDataItemSuccess = drPoopItem => ({
  type: drPoopTypes.GET_DR_POOP_USER_DATA_ITEM_SUCCESS,
  payload: drPoopItem,
});

export const getDrPoopStatementListSuccess = list => ({
  type: drPoopTypes.GET_DR_POOP_STATEMENTS_LIST_SUCCESS,
  payload: list,
});

export const getDrPoopStatementListFail = () => ({
  type: drPoopTypes.GET_DR_POOP_STATEMENTS_LIST_FAILED,
});

export const deleteItemFromDrPoopStatementList = id => ({
  type: drPoopTypes.DELETE_ITEM_FROM_DR_POOP_STATEMENT_LIST,
  payload: id,
});

export const drPoopStatementItemRequested = () => ({
  type: drPoopTypes.DELETE_ITEM_FROM_DR_POOP_STATEMENT_LIST,
});

export const getDrPoopStatementItemSuccess = data => ({
  type: drPoopTypes.GET_DR_POOP_STATEMENT_ITEM_SUCCESS,
  payload: data,
});

export const getDrPoopStatementItemFailed = () => ({
  type: drPoopTypes.GET_DR_POOP_STATEMENT_ITEM_FAILED,
});

export const setStatementLoader = data => ({
  type: drPoopTypes.SET_STATEMENT_LOADER,
  payload: data,
});

export const clearStatementItem = () => ({
  type: drPoopTypes.CLEAR_STATEMENT_ITEM,
});

export const getDrPoopCountGradeDistributionLoading = () => ({
  type: drPoopTypes.GET_DR_POOP_COUNT_GRADE_DISTRIBUTION_LOADING,
});

export const getDrPoopCountGradeDistributionSucces = data => ({
  type: drPoopTypes.GET_DR_POOP_COUNT_GRADE_DISTRIBUTION_SUCCESS,
  payload: data,
});

export const getDrPoopCountGradeDistributionFailed = () => ({
  type: drPoopTypes.GET_DR_POOP_COUNT_GRADE_DISTRIBUTION_FAILED,
});

export const getDrPoopStatsSuccess = data => ({
  type: drPoopTypes.GET_DR_POOP_STATS_SUCCESS,
  payload: data,
});

export const getDrPoopStatsFailed = () => ({
  type: drPoopTypes.GET_DR_POOP_STATS_FAILED,
});

export const getDrPoopStatsLoading = () => ({
  type: drPoopTypes.GET_DR_POOP_STATS_LOADING,
});
