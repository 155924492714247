import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Card } from 'react-bootstrap';

import {
  DEFAULT_POOP_STATUS_VALUE,
  POOP_STATUSES,
} from '../../../constants/dr-poop';
import { updatePoopStatusForDrPoopUserDataItem } from '../../../service/dr-poop';
import Input from '../../inputs';

const DrPoopItemForm = ({ defaultValues = {}, itemId }) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const onSubmit = value => {
    dispatch(updatePoopStatusForDrPoopUserDataItem(itemId, value));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="DR_POOP_ITEM_FORM">
      <Card className="w-100">
        <Card.Header>Select poop grade</Card.Header>
        <Card.Body>
          <Input.number
            label="Type poop grade"
            name="dogiz_grade"
            register={register}
            validationRules={{
              validate: value => (value < -1 ? 'Minimal value: -1' : true),
            }}
            error={errors.dogiz_grade}
          />
        </Card.Body>
      </Card>

      <Card bg={'light'} className="w-100 mt-5">
        <Card.Header>Select poop status</Card.Header>
        <Card.Body>
          <Input.select
            defaultHiddenValue={DEFAULT_POOP_STATUS_VALUE}
            optionsList={POOP_STATUSES}
            label="Choose poop status"
            name="status"
            register={register}
          />
        </Card.Body>
      </Card>
      <Card bg={'light'} className="w-100 mt-5">
        <Card.Header>Doctor notes</Card.Header>
        <Card.Body>
          <Input.textarea name="drNote" register={register} />
        </Card.Body>
      </Card>
    </form>
  );
};

export default DrPoopItemForm;
