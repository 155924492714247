import fetchWithAuth from '../index';
import {
  getPublicationListSuccess,
  getPublicationListFail,
  deletePublicationSuccess,
  deletePublicationFail,
  postPublicationSuccess,
  postPublicationFail,
  getPublicationItemByIdFail,
  getPublicationItemByIdSuccess,
  getPublicationListLoading,
  postPublicationLoading,
  putPublicationLoading,
  deletePublicationLoading,
  getPublicationItemByIdLoading,
} from '../../redux/publication/publication.action';

import { createFormData } from '../../helpers/formDataConvertor';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import MESSAGES_FOR_USER from '../../constants/messages/en';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';

export const getPublicationList = () => async dispatch => {
  dispatch(getPublicationListLoading());
  let result = await fetchWithAuth({
    path: '/recommendation',
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(getPublicationListFail(await result.json()));
    return;
  }

  dispatch(getPublicationListSuccess(await result.json()));
  return;
};

export const getPublicationItemById = id => async dispatch => {
  dispatch(getPublicationItemByIdLoading());
  let result = await fetchWithAuth({
    path: `/recommendation/${id}`,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(getPublicationItemByIdFail(await result.json()));
    return;
  }

  dispatch(getPublicationItemByIdSuccess(await result.json()));
  return;
};

export const deletePublicationById = id => async dispatch => {
  dispatch(deletePublicationLoading());

  let result = await fetchWithAuth({
    path: `/recommendation/${id}`,
    method: 'DELETE',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.RECOMENDATION.DELETE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.RECOMENDATION.DELETE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );
    dispatch(deletePublicationFail(await result.json()));
    return;
  }

  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.RECOMENDATION.DELETE_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.RECOMENDATION.DELETE_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );

  dispatch(deletePublicationSuccess(id));
  return;
};

export const addPublicationList = (data, history) => async dispatch => {
  const normalizedObject = {
    ...data,
    redirect: JSON.stringify(data.redirect),
    title_localizations: JSON.stringify(data.title_localizations),
    subTitle_localizations: JSON.stringify(data.subTitle_localizations),
    content_localizations: JSON.stringify(data.content_localizations),
    redirect_localizations: JSON.stringify(data.redirect_localizations),
  };
  const changedDataFormat = createFormData(normalizedObject);

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'multipart/form-data');

  dispatch(postPublicationLoading());

  let result = await fetchWithAuth(
    {
      path: '/recommendation',
      method: 'POST',
      body: changedDataFormat,
    },
    'formdata',
  );

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.RECOMENDATION.CREATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.RECOMENDATION.CREATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(postPublicationFail(await result.json()));
    return;
  }
  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.RECOMENDATION.CREATE_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.RECOMENDATION.CREATE_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );

  dispatch(postPublicationSuccess(await result.json()));
  history.push('/publication');
  return;
};

export const changePublicationItem = (id, data, history) => async dispatch => {
  const normalizedObject = {
    ...data,
    redirect: JSON.stringify(data.redirect),
    title_localizations: JSON.stringify(data.title_localizations),
    subTitle_localizations: JSON.stringify(data.subTitle_localizations),
    content_localizations: JSON.stringify(data.content_localizations),
    redirect_localizations: JSON.stringify(data.redirect_localizations),
  };
  const changedDataFormat = createFormData(normalizedObject);

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'multipart/form-data');

  dispatch(putPublicationLoading());

  let result = await fetchWithAuth(
    {
      path: `/recommendation/${id}`,
      method: 'PUT',
      body: changedDataFormat,
    },
    'formdata',
  );

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.RECOMENDATION.UPDATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.RECOMENDATION.UPDATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    dispatch(postPublicationFail(await result.json()));
    return;
  }

  dispatch(
    showToastMessage({
      title: MESSAGES_FOR_USER.RECOMENDATION.UPDATE_SUCCESS.TITLE,
      content: MESSAGES_FOR_USER.RECOMENDATION.UPDATE_SUCCESS.BODY,
      type: TOAST_MESSAGE_TYPES.SUCCESS,
    }),
  );

  dispatch(postPublicationSuccess(await result.json()));
  history.push('/publication');
  return;
};
