import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import GoBack from '../../component/buttons/goBackButton';
import ContractForm from '../../component/contract-form/contract-form';

import { createContract } from '../../service/contracts/contracts';

const CreateContract = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Col sm={12} className="p-0 mt-3">
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <GoBack />
          </div>
        </Col>
      </Row>
      <ContractForm
        onSubmit={data => createContract(data, history)(dispatch)}
      />
    </Col>
  );
};

export default CreateContract;
