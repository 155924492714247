export const additionalFeeTemplateTypes = {
  GET_ADDITIONAL_FEE_TEMPLATE_LIST_SUCCESS:
    'service_partner/GET_ADDITIONAL_FEE_TEMPLATE_LIST_SUCCESS',
  GET_ADDITIONAL_FEE_TEMPLATE_LIST_LOADING:
    'service_partner/GET_ADDITIONAL_FEE_TEMPLATE_LIST_LOADING',

  GET_ADDITIONAL_FEE_TEMPLATE_BY_ID_SUCCESS:
    'service_partner/GET_ADDITIONAL_FEE_TEMPLATE_BY_ID_SUCCESS',
  GET_ADDITIONAL_FEE_TEMPLATE_BY_ID_LOADING:
    'service_partner/GET_ADDITIONAL_FEE_TEMPLATE_BY_ID_LOADING',

  DELETE_ADDITIONAL_FEE_TEMPLATE_SUCCESS:
    'service_partner/DELETE_ADDITIONAL_FEE_TEMPLATE_SUCCESS',

  CLEAR_ITEM: 'service_partner/CLEAR_ITEM',
};
