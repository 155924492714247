import { publicationTypes } from './publication.types';

export const getPublicationListLoading = () => ({
  type: publicationTypes.GET_PUBLICATION_LIST_LOADING,
});

export const getPublicationListSuccess = list => ({
  type: publicationTypes.GET_PUBLICATION_LIST_SUCCESS,
  payload: list,
});

export const getPublicationListFail = error => ({
  type: publicationTypes.GET_PUBLICATION_LIST_FAIL,
  payload: error,
});

export const getPublicationItemByIdLoading = () => ({
  type: publicationTypes.GET_PUBLICATION_ITEM_BY_ID_LOADING,
});

export const getPublicationItemByIdSuccess = item => ({
  type: publicationTypes.GET_PUBLICATION_ITEM_BY_ID_SUCCESS,
  payload: item,
});

export const getPublicationItemByIdFail = error => ({
  type: publicationTypes.GET_PUBLICATION_ITEM_BY_ID_FAIL,
  payload: error,
});

export const deletePublicationLoading = () => ({
  type: publicationTypes.DELETE_PUBLICATION_LOADING,
});

export const deletePublicationSuccess = id => ({
  type: publicationTypes.DELETE_PUBLICATION_SUCCESS,
  payload: id,
});

export const deletePublicationFail = error => ({
  type: publicationTypes.DELETE_PUBLICATION_FAIL,
  payload: error,
});

export const postPublicationLoading = () => ({
  type: publicationTypes.POST_PUBLICATION_LOADING,
});

export const postPublicationSuccess = id => ({
  type: publicationTypes.POST_PUBLICATION_SUCCESS,
  payload: id,
});

export const postPublicationFail = error => ({
  type: publicationTypes.POST_PUBLICATION_FAIL,
  payload: error,
});

export const putPublicationLoading = () => ({
  type: publicationTypes.PUT_PUBLICATION_ITEM_BY_ID_LOADING,
});

export const putPublicationSuccess = id => ({
  type: publicationTypes.PUT_PUBLICATION_ITEM_BY_ID_SUCCESS,
  payload: id,
});

export const putPublicationFail = error => ({
  type: publicationTypes.PUT_PUBLICATION_ITEM_BY_ID_FAIL,
  payload: error,
});

export const clearPublicationItem = () => ({
  type: publicationTypes.CLEAR_PUBLICATION_ITEM,
});
