import React, { useEffect } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import Input from '../inputs';

import { validationRules } from './validation';

import './index.css';
import { SearchDropdown } from '../inputs/searchDropdown';
import { userCountrySelector } from '../../redux/selectors/selectors';
import { getCountry } from '../../service/user';
import {
  clearCountryItem,
  setCountryItem,
} from '../../redux/user/country/country.action';
import {
  createCoinsModificator,
  updateCoinsModificator,
} from '../../service/rewards';

const CoinsModificatorForm = ({ defaultValues = {} }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      modificator: defaultValues?.modificator,
      country: defaultValues?.country[1],
    },
  });

  const {
    list: countryList = [],
    item: selectedCountry,
    loading: countryLoading,
    search,
  } = useSelector(userCountrySelector);

  const normalizedCountryOptions = countryList
    ? Object.fromEntries(
        countryList.map(({ name_localizations, _id }) => [
          _id,
          name_localizations?.en,
        ]),
      )
    : [];

  const handleCountrySearch = search => {
    dispatch(getCountry(search));
  };

  useEffect(() => {
    return () => {
      dispatch(clearCountryItem());
    };
  }, [dispatch]);

  const onSubmit = data => {
    if (id) {
      dispatch(updateCoinsModificator(id, data)).then(() => history.goBack());
      return;
    }
    dispatch(createCoinsModificator(data)).then(() => history.goBack());
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-100"
      id="coins-modificator-form"
    >
      <Row className="pl-3 pr-3 pt-3">
        <Controller
          control={control}
          name="country"
          defaultValue={defaultValues?.country?.value}
          rules={{ required: true }}
          render={({ field: { onChange } }) => {
            const handleSelect = newValue => {
              onChange(newValue);
              dispatch(
                setCountryItem({
                  _id: newValue,
                  name: normalizedCountryOptions[newValue],
                }),
              );
            };
            return (
              <SearchDropdown
                normalizedOptions={normalizedCountryOptions}
                onSearch={handleCountrySearch}
                search={search}
                selectedLabel={
                  selectedCountry.name || defaultValues?.country[0]
                }
                onSelect={handleSelect}
                isLoading={countryLoading}
              />
            );
          }}
        />
      </Row>
      <Row className="d-flex">
        <Col>
          <Input.number
            register={register}
            label="Modificator"
            name="modificator"
            validationRules={validationRules.modificator}
            error={errors.modificator}
          />
        </Col>
      </Row>
      <Row className="mt-5 ml-0 mr-0">
        <Button className="w-100 p-3" type="submit">
          Save
        </Button>
      </Row>
    </form>
  );
};

export default CoinsModificatorForm;
