import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Row, Button } from 'react-bootstrap';
import Input from '../../../component/inputs';

import { updateNofificationsSetting } from '../../../service/notifications';

const NotificationsConfigForm = ({ id, defaultValues = {} }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit } = useForm({ defaultValues });
  const onSubmit = data => {
    const body = {
      value: Object.fromEntries(
        Object.entries(data).filter(([_, value]) => value),
      ),
    };
    dispatch(updateNofificationsSetting({ id, body })).then(() => {
      history.goBack();
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row className="pl-3 pr-3 pt-3">
        <Input.text register={register} label="Hours" name="hours" />
      </Row>
      <Row className="pl-3 pr-3 pt-3">
        <Input.text register={register} label="Minutes" name="minutes" />
      </Row>

      <Row className="pl-3 pr-3 pt-3">
        <Button type="submit">Save changes</Button>
      </Row>
    </form>
  );
};

export default NotificationsConfigForm;
