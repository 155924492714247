import fetchWithAuth from '../index';

import MESSAGES_FOR_USER from '../../constants/messages/en';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';

import { destinationUrls } from '../../component/menu/constants';
import {
  getContractByIDLoading,
  getContractByIDSuccess,
  getContractsListLoading,
  getContractsListSuccess,
  deleteContractSuccess,
  getContractsListError,
} from '../../redux/contracts/contracts.action';

export const getContractsList = () => async dispatch => {
  dispatch(getContractsListLoading());

  let result = await fetchWithAuth({
    path: `/contract`,
    method: 'GET',
  });

  if (result.ok) {
    dispatch(getContractsListSuccess(await result.json()));
    return;
  } else {
    const { statusText } = result;

    dispatch(getContractsListError(statusText));

    dispatch(
      showToastMessage({
        title: 'Request error',
        content: statusText,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
  }
};

export const createContract = (data, history) => async dispatch => {
  const result = await fetchWithAuth({
    path: `/contract`,
    method: 'POST',
    body: JSON.stringify(data),
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.CONTRACTS.CREATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.CONTRACTS.CREATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  history.push(destinationUrls.CONTRACTS);
  return;
};

export const getContractById = id => async dispatch => {
  dispatch(getContractByIDLoading());
  let result = await fetchWithAuth({
    path: `/contract/${id}`,
    method: 'GET',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.CONTRACTS.UPDATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.CONTRACTS.UPDATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  dispatch(getContractByIDSuccess(await result.json()));
};

export const deleteContractByID = id => async dispatch => {
  let result = await fetchWithAuth({
    path: `/contract/${id}`,
    method: 'DELETE',
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.CONTRACTS.DELETE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.CONTRACTS.DELETE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  dispatch(deleteContractSuccess(id));
};

export const updateContractByID = (id, data, history) => async dispatch => {
  let result = await fetchWithAuth({
    path: `/contract/${id}`,
    method: 'PUT',
    body: JSON.stringify(data),
  });

  if (!result.ok) {
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.CONTRACTS.UPDATE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.CONTRACTS.UPDATE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
    return;
  }

  history.push(destinationUrls.CONTRACTS);
  return;
};
