import { useSelector, useDispatch } from 'react-redux';

import { Button, Modal } from 'react-bootstrap';

import { confirmStatusSelector } from '../../redux/selectors/selectors';
import { changeConfirmStatus } from '../../redux/confirm/confirm.action';

import { confirmationTypes } from './config';

const ConfirmationModal = () => {
  const { isVisible, type, actionParams, content } = useSelector(
    confirmStatusSelector,
  );
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(changeConfirmStatus({ isVisible: false, type: null }));
  };

  const configAction = confirmationTypes[type];

  return (
    <>
      <Modal
        show={isVisible}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Do you want to delete?</Modal.Title>
        </Modal.Header>
        <Modal.Body>{`You are going to delete the "${content}". Are you sure?`}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => configAction.action(dispatch, actionParams)}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
