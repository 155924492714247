import { notificationsTypes } from './notifications.types';

export const countriesListRequested = () => ({
  type: notificationsTypes.COUNTRIES_LIST_REQUESTED,
});

export const countriesListReceivedSuccess = data => ({
  type: notificationsTypes.COUNTRIES_LIST_RECEIVED_SUCCESS,
  payload: data.countries,
});

export const notificationsSettingsLoading = () => ({
  type: notificationsTypes.SETTINGS_LOADING,
});

export const notificationsSettingsFailed = payload => ({
  type: notificationsTypes.SETTINGS_FAILED,
  payload,
});

export const getNotificationsSettingsSuccess = payload => ({
  type: notificationsTypes.GET_SETTINGS_LIST_SUCCESS,
  payload,
});

export const getNotificationsSettingsByIdSuccess = payload => ({
  type: notificationsTypes.GET_SETTINGS_BY_ID_SUCCESS,
  payload,
});

export const clearNotificationsSettingsState = () => ({
  type: notificationsTypes.CLEAR_SETTINGS_STATE,
});

export const clearNotificationsSettingsItem = () => ({
  type: notificationsTypes.CLEAR_SELECTED_SETTING,
});
