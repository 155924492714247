import { countriesActions } from '../../redux/countries/countries.actions';
import { showToastMessage } from '../../redux/toast-message/toast-message.action';
import { TOAST_MESSAGE_TYPES } from '../../component/toast-message/config';
import MESSAGES_FOR_USER from '../../constants/messages/en';
import fetchWithAuth from '../';

export const getCountriesList = () => async dispatch => {
  dispatch(countriesActions.countriesOperationLoading());

  let result = await fetchWithAuth({
    path: `/address/country`,
    method: 'GET',
  });

  if (result.ok) {
    dispatch(countriesActions.getCountriesList(await result.json()));
    return;
  } else {
    const { statusText } = result;

    dispatch(countriesActions.countriesOperationFailed(statusText));

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.COUNTRIES.GET_FAILED.TITLE,
        content: MESSAGES_FOR_USER.COUNTRIES.GET_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
  }
};

export const getCountryById = id => async dispatch => {
  dispatch(countriesActions.countriesOperationLoading());

  let result = await fetchWithAuth({
    path: `/address/country/${id}`,
    method: 'GET',
  });

  if (result.ok) {
    dispatch(countriesActions.getCountryById(await result.json()));
    return;
  } else {
    const { statusText } = result;

    dispatch(countriesActions.countriesOperationFailed(statusText));
    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.COUNTRIES.GET_BY_ID_FAILED.TITLE,
        content: MESSAGES_FOR_USER.COUNTRIES.GET_BY_ID_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
  }
};

export const createCountry =
  ({ body }) =>
  async dispatch => {
    let result = await fetchWithAuth({
      path: `/address/country`,
      method: 'POST',
      body: JSON.stringify(body),
    });

    if (result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.COUNTRIES.CREATE_SUCCESS.TITLE,
          content: MESSAGES_FOR_USER.COUNTRIES.CREATE_SUCCESS.BODY,
          type: TOAST_MESSAGE_TYPES.SUCCESS,
        }),
      );
      return;
    } else {
      const { statusText } = result;

      dispatch(countriesActions.countriesOperationFailed(statusText));

      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.COUNTRIES.CREATE_FAILED.TITLE,
          content: MESSAGES_FOR_USER.COUNTRIES.CREATE_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );
    }
  };

export const updateCountry =
  ({ id, body }) =>
  async dispatch => {
    let result = await fetchWithAuth({
      path: `/address/country/${id}`,
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (result.ok) {
      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.COUNTRIES.UPDATE_SUCCESS.TITLE,
          content: MESSAGES_FOR_USER.COUNTRIES.UPDATE_SUCCESS.BODY,
          type: TOAST_MESSAGE_TYPES.SUCCESS,
        }),
      );
      return;
    } else {
      const { statusText } = result;

      dispatch(countriesActions.countriesOperationFailed(statusText));

      dispatch(
        showToastMessage({
          title: MESSAGES_FOR_USER.COUNTRIES.UPDATE_FAILED.TITLE,
          content: MESSAGES_FOR_USER.COUNTRIES.UPDATE_FAILED.BODY,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      );
    }
  };

export const deleteCountry = id => async dispatch => {
  let result = await fetchWithAuth({
    path: `/address/country/${id}`,
    method: 'DELETE',
  });

  if (result.ok) {
    dispatch(countriesActions.deleteCountry(id));

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.COUNTRIES.DELETE_SUCCESS.TITLE,
        content: MESSAGES_FOR_USER.COUNTRIES.DELETE_SUCCESS.BODY,
        type: TOAST_MESSAGE_TYPES.SUCCESS,
      }),
    );
    return;
  } else {
    const { statusText } = result;

    dispatch(countriesActions.countriesOperationFailed(statusText));

    dispatch(
      showToastMessage({
        title: MESSAGES_FOR_USER.COUNTRIES.DELETE_FAILED.TITLE,
        content: MESSAGES_FOR_USER.COUNTRIES.DELETE_FAILED.BODY,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }),
    );
  }
};

const countriesOperations = {
  getCountriesList,
  getCountryById,
  createCountry,
  updateCountry,
  deleteCountry,
};

export default countriesOperations;
