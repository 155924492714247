import { confirmationKeys } from '../../constant';

import { changeConfirmStatus } from '../../redux/confirm/confirm.action';

import { deleteQuestionById } from '../../service/quiz/question';
import {
  deleteSectionById,
  deleteSectionQuestionById,
} from '../../service/quiz/section';
import { deleteAnswerById } from '../../service/quiz/answer';
import { deleteQuestionFromSection } from '../../redux/quiz/section/section.action';
import { deletePublicationById } from '../../service/publication/publication';
import { deleteSlashPhotoItem } from '../../service/splash-photo';
import {
  deleteDrPoopStatementItem,
  deleteDrPoopUserDataItem,
} from '../../service/dr-poop';
import { deleteRewardItem } from '../../service/rewards';
import {
  deleteScoresFitnessItem,
  deleteScoresWeightGroupItem,
} from '../../service/score';
import { deleteReasonById } from '../../service/subscription/reason';
import { deleteServiceTypeById } from '../../service/services';
import { deleteServicePartnerById } from '../../service/services/servicePartner';
import { deletePartnerTypeById } from '../../service/partners';
import { deletePartnerProgramById } from '../../service/partners/partnerProgram';
import { deleteAdditionalFeeTemplateById } from '../../service/services/additional-fee-template';
import { deleteAdditionalServiceById } from '../../service/services/additional-service';
import { deleteDeleteDogOptionsById } from '../../service/delete-dog/delete-dog-options';
import { deleteContractByID } from '../../service/contracts/contracts';
import { deleteWorkerType } from '../../service/workers/type';
import { deleteDiscountReason } from '../../service/discount-reasons';
import { deleteCountry } from '../../service/countries/countries';
import { deleteCity } from '../../service/cities/cities';
import { deleteBreed } from '../../service/breeds/breeds';
import { FOOD_PRICES_MODIFICATORS_SERVICES } from '../../service/food-prices-modificators';

const initialConfirm = {
  isVisible: false,
  type: null,
  content: '',
  actionParams: [],
};

export const confirmationTypes = {
  [confirmationKeys.questionTableDeleteItem]: {
    action: (dispatch, params) => {
      deleteQuestionById(...params)(dispatch);
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.sectionTableDeleteItem]: {
    action: (dispatch, params) => {
      deleteSectionById(...params)(dispatch);
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.sectionEditDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deleteSectionQuestionById(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.questionEditDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deleteAnswerById(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.publicationTableDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deletePublicationById(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.questionSplashPhotosDraggableTableDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deleteSlashPhotoItem(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.questionDrPoopUserDataTableDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deleteDrPoopUserDataItem(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.questionDrPoopStatementTableDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deleteDrPoopStatementItem(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.rewardsDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deleteRewardItem(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.scoresWeightGroupDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deleteScoresWeightGroupItem(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.scoresFitnessDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deleteScoresFitnessItem(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.reasonTableDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deleteReasonById(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.serviceTypeTableDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deleteServiceTypeById(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.partnerTypeTableDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deletePartnerTypeById(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.servicePartnerDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deleteServicePartnerById(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.partnerProgramDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deletePartnerProgramById(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.additionalFeeTemplateDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deleteAdditionalFeeTemplateById(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.additionalServiceDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deleteAdditionalServiceById(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.deleteDogOptionsItem]: {
    action: (dispatch, params) => {
      dispatch(deleteDeleteDogOptionsById(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.contractDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deleteContractByID(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.workerTypeTableDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deleteWorkerType(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.discountReasonTableDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deleteDiscountReason(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.countryTableDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deleteCountry(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.cityTableDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deleteCity(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.breedsTableDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(deleteBreed(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
  [confirmationKeys.foodPricesModificatorsTableDeleteItem]: {
    action: (dispatch, params) => {
      dispatch(FOOD_PRICES_MODIFICATORS_SERVICES.delete(...params));
      dispatch(changeConfirmStatus(initialConfirm));
    },
  },
};
