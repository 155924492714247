import React, { useState } from 'react';

import { Accordion, Card, Nav, Navbar, Row, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { logOut } from '../../redux/auth/auth.action';
import logoImg from '../../source/img/logo.svg';
import { destinationUrls, menuItems } from './constants';

import './index.css';

const Menu = () => {
  const setDefaultCollapsableItem = () => {
    const { pathname } = window.location;

    const activeItem = menuItems.find(item => {
      if (item.isCollapsable) {
        if (
          item.isCollapsable.find(collapsableItem =>
            pathname.includes(collapsableItem.path),
          )
        ) {
          return item.toggleId.toString();
        }
      }
    });

    if (!activeItem) return null;

    return activeItem.toggleId;
  };

  const [activeId, setActiveId] = useState(setDefaultCollapsableItem());
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = () => {
    dispatch(logOut());
    history.push('/auth');
  };

  const toggleActive = id => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="d-flex flex-column align-items-center w-100 p-0 sticky-top"
    >
      <Row className="d-flex flex-column align-items-center mt-5 mb-5">
        <img src={logoImg} alt="logo" className="logo" />

        <Link to="/publication" className="mt-2 text-grey logo-title">
          Dogiz
        </Link>
      </Row>
      <Row>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      </Row>
      <Row className="w-100">
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto d-flex flex-column">
            {menuItems.map(
              ({ label, path, icon, isCollapsable, toggleId = null }) => {
                if (!isCollapsable) {
                  return (
                    <NavLink
                      to={path}
                      className="menu-item text-grey p-0"
                      key={label}
                    >
                      <i className={`fa ${icon} mr-3 w-20 text-green`}></i>
                      <span>{label}</span>
                    </NavLink>
                  );
                } else {
                  return (
                    <Accordion defaultActiveKey={activeId} key={label}>
                      <Accordion.Toggle
                        as={Card.Title}
                        eventKey={toggleId}
                        onClick={() => toggleActive(toggleId)}
                        className="mb-0 text-grey"
                      >
                        <i
                          className={`fa ${icon} mr-3 w-20 text-green p-0`}
                        ></i>
                        {label}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={toggleId}>
                        <Row className="d-flex flex-column">
                          {isCollapsable.map(collapsableItem => {
                            return (
                              <NavLink
                                to={collapsableItem.path}
                                className="ml-5 menu-item text-grey"
                                key={collapsableItem.label}
                              >
                                <span className="ml-3">
                                  {collapsableItem.label}
                                </span>
                              </NavLink>
                            );
                          })}
                        </Row>
                      </Accordion.Collapse>
                    </Accordion>
                  );
                }
              },
            )}
          </Nav>
        </Navbar.Collapse>
      </Row>
      <Button variant="danger" className="mt-5 mb-5" onClick={logout}>
        Log Out
      </Button>
    </Navbar>
  );
};

export default Menu;
