export const splashPhotosTypes = {
  GET_SPLASH_PHOTOS_LIST_SUCCESS: 'splashPhotos/GET_SPLASH_PHOTOS_LIST_SUCCESS',
  GET_SPLASH_PHOTOS_LIST_FAIL: 'splashPhotos/GET_SPLASH_PHOTOS_LIST_FAIL',

  DELETE_SPLASH_PHOTOS_LIST_ITEM: 'splashPhotos/DELETE_SPLASH_PHOTOS_LIST_ITEM',

  SET_ORDER_IN_SPLASH_PHOTOS_LIST:
    'splashPhotos/SET_ORDER_IN_SPLASH_PHOTOS_LIST',
  CHANGE_SPLASH_PHOTOS_LIST_ITEM_VISIBILITY_STATUS:
    'splashPhotos/CHANGE_SPLASH_PHOTOS_LIST_ITEM_VISIBILITY_STATUS',

  ADD_ITEM_TO_SPLASH_PHOTOS_LIST_SUCCESS:
    'splashPhotos/ADD_ITEM_TO_SPLASH_PHOTOS_LIST_SUCCESS',
  ADD_ITEM_TO_SPLASH_PHOTOS_LIST_REQUESTED:
    'splashPhotos/ADD_ITEM_TO_SPLASH_PHOTOS_LIST_REQUESTED',
  SET_IS_LIST_HAS_ANY_CHANGES_STATUS:
    'splashPhotos/SET_IS_LIST_HAS_ANY_CHANGES_STATUS',

  SPLASH_PHOTOS_ITEM_REQUESTED: 'splashPhotos/SPLASH_PHOTOS_ITEM_REQUESTED',
  SPLASH_PHOTOS_ITEM_SUCCESS: 'splashPhotos/SPLASH_PHOTOS_ITEM_SUCCESS',
  SPLASH_PHOTOS_ITEM_FAILED: 'splashPhotos/SPLASH_PHOTOS_ITEM_FAILED',
};
