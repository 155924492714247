import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Button, Col } from 'react-bootstrap';

import ConfigurableTable from '../../../component/table';
import CustomSpinner from '../../../component/spinner';

import { getScoresFitnessList } from '../../../service/score';
import { scoreSelector } from '../../../redux/selectors/selectors';
import {
  SCORE_FITNESS_TABLE_HEAD,
  TABLE_ITEM_TYPE,
} from '../../../component/table/config';

const ScoresFitnessList = ({ history }) => {
  const dispatch = useDispatch();
  const { lists, loading } = useSelector(scoreSelector);

  useEffect(() => {
    dispatch(getScoresFitnessList());
  }, [dispatch]);

  return (
    <Row className="mt-3 d-flex align-items-center justify-content-center w-100 ml-0 mr-0">
      {loading ? (
        <CustomSpinner />
      ) : (
        <Col sm={12} className="p-0">
          <Button
            variant="primary"
            className="mb-3"
            onClick={() => history.push(window.location.pathname + '/create')}
          >
            Add new
          </Button>
          <ConfigurableTable
            tableHeaderColumns={SCORE_FITNESS_TABLE_HEAD}
            itemVariant={TABLE_ITEM_TYPE.SCORE_FITNESS_ITEM_TYPE}
            rows={lists.fitness}
          />
        </Col>
      )}
    </Row>
  );
};

export default ScoresFitnessList;
