import { useLocation, useHistory } from 'react-router-dom';
import { Pagination as BootstrapPagination } from 'react-bootstrap';

const Pagination = ({
  pageNumber,
  total,
  onPaginate,
  className = '',
  pageSize = 50,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const totalPages = Math.ceil(total / pageSize);

  const paginateItems = new Array(totalPages).fill(1).map((_, idx) => ({
    page: idx + 1,
    onClick: () => {
      history.push(pathname + `?pageNumber=${idx + 1}`);
      onPaginate && onPaginate(idx + 1);
    },
  }));

  return (
    <BootstrapPagination className={className}>
      {paginateItems.map(i => (
        <BootstrapPagination.Item
          key={i.page - 1}
          active={pageNumber === i.page}
          onClick={i.onClick}
        >
          {i.page}
        </BootstrapPagination.Item>
      ))}
    </BootstrapPagination>
  );
};

export default Pagination;
