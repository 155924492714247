import { Col, Button, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CustomSpinner from '../../../component/spinner';
import ConfigurableTable from '../../../component/table';

import {
  TABLE_ITEM_TYPE,
  ADDITIONAL_FEE_TEMPLATE,
} from '../../../component/table/config';
import { additionalFeeTemplateSelector } from '../../../redux/selectors/selectors';
import { destinationUrls } from '../../../component/menu/constants';
import { getAdditionalFeeTemplateList } from '../../../service/services/additional-fee-template';

const AdditionalFeeTemplateList = () => {
  const dispatch = useDispatch();

  const { list = [], loading } = useSelector(additionalFeeTemplateSelector);

  useEffect(() => {
    getAdditionalFeeTemplateList()(dispatch);
  }, [dispatch]);

  return (
    <Col sm={12} className="p-0 mt-3">
      <Link to={`${destinationUrls.FEE_TEMPLATE}/create`}>
        <Button className="mb-3" variant="primary">
          Create additional fee template
        </Button>
      </Link>
      {loading || !list ? (
        <Row className="mt-5">
          <Col>
            <CustomSpinner />
          </Col>
        </Row>
      ) : (
        <ConfigurableTable
          tableHeaderColumns={ADDITIONAL_FEE_TEMPLATE}
          itemVariant={TABLE_ITEM_TYPE.ADDITIONAL_FEE_TEMPLATE_ITEM_TYPE}
          rows={list}
        />
      )}
    </Col>
  );
};

export default AdditionalFeeTemplateList;
