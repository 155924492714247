const citiesTypes = {
  OPERATION_LOADING: 'cities/OPERATION_LOADING',
  OPERATION_FAILED: 'cities/OPERATION_FAILED',

  GET_CITIES_LIST: 'cities/GET_CITIES_LIST',
  GET_CITY_BY_ID: 'cities/GET_CITY_BY_ID',
  DELETE_CITY: 'cities/DELETE_CITY',

  CLEAR_SELECTED_CITY: 'cities/CLEAR_SELECTED_CITY',
};

export default citiesTypes;
