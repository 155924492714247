import { workerTypes } from './workerType.types';

//get
export const getWorkerTypesLoading = () => ({
  type: workerTypes.GET_WORKER_TYPES_LOADING,
});

export const getWorkerTypesSuccess = list => ({
  type: workerTypes.GET_WORKER_TYPES_SUCCESS,
  payload: list,
});

export const getWorkerTypesFail = error => ({
  type: workerTypes.GET_WORKER_TYPES_FAIL,
  payload: error,
});

//get by id
export const getWorkerTypeByIdLoading = () => ({
  type: workerTypes.GET_WORKER_TYPE_BY_ID_LOADING,
});

export const getWorkerTypeByIdSuccess = list => ({
  type: workerTypes.GET_WORKER_TYPE_BY_ID_SUCCESS,
  payload: list,
});

export const getWorkerTypeByIdFail = error => ({
  type: workerTypes.GET_WORKER_TYPE_BY_ID_FAIL,
  payload: error,
});

//create
export const createWorkerTypesLoading = () => ({
  type: workerTypes.CREATE_WORKER_TYPES_LOADING,
});

export const createWorkerTypesSuccess = list => ({
  type: workerTypes.CREATE_WORKER_TYPES_SUCCESS,
});

export const createWorkerTypesFail = error => ({
  type: workerTypes.CREATE_WORKER_TYPES_FAIL,
  payload: error,
});

//update
export const updateWorkerTypesLoading = () => ({
  type: workerTypes.UPDATE_WORKER_TYPES_LOADING,
});

export const updateWorkerTypesSuccess = list => ({
  type: workerTypes.UPDATE_WORKER_TYPES_SUCCESS,
});

export const updateWorkerTypesFail = error => ({
  type: workerTypes.UPDATE_WORKER_TYPES_FAIL,
  payload: error,
});

//delete
export const deleteWorkerTypesLoading = () => ({
  type: workerTypes.DELETE_WORKER_TYPES_LOADING,
});

export const deleteWorkerTypesSuccess = list => ({
  type: workerTypes.DELETE_WORKER_TYPES_SUCCESS,
});

export const deleteWorkerTypesFail = error => ({
  type: workerTypes.DELETE_WORKER_TYPES_FAIL,
  payload: error,
});
