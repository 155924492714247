import ServicePartnerList from './list';
import ServicePartnerCreate from './create';
import ServicePartnerEdit from './edit';

const ServicePartner = {
  list: ServicePartnerList,
  create: ServicePartnerCreate,
  edit: ServicePartnerEdit,
};

export default ServicePartner;
