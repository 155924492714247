import { destinationUrls } from '../../component/menu/constants';
import { PHASES } from '../../constants/rewards';

export const getPhaseForCurrentRoute = () => {
  const { pathname } = window.location;

  if (pathname.includes(destinationUrls.REWARDS_DISTANCE_WALK)) {
    return PHASES.DISTANCE_WALK;
  } else if (pathname.includes(destinationUrls.REWARDS_COUNT_WALK)) {
    return PHASES.COUNT_WALK;
  } else if (pathname.includes(destinationUrls.REWARDS_WEIGHT)) {
    return PHASES.WEIGHT;
  } else if (pathname.includes(destinationUrls.REWARDS_ACTION)) {
    return PHASES.ACTION;
  } else if (pathname.includes(destinationUrls.REWARDS_MOOD)) {
    return PHASES.MOOD;
  } else {
    return PHASES.DISTANCE_WALK;
  }
};
