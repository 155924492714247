import List from './list';
import Create from './create';
import Edit from './edit';

const Countries = {
  List,
  Create,
  Edit,
};

export default Countries;
